import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';
import SignatureCanvas from 'react-signature-canvas'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faAmbulance, faWrench, faRedo, faSave, faShareAlt, faTimes, faCopy, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../AppContext.js';
import OffsetTop from '../../components/offsetTop.js';
import Preview from '../../components/preview.js';

const localization = {
    ua: {
        title: 'Заказ-наряд фіналізування',
        header: {
            order: 'Заказ-наряд',
            date: 'Дата',
            client: 'Клієнт',
            manufacturer: 'Виробник',
            type: 'Модель',
            chassis: '№ шасі',
            stateNumber: 'Державний реєстр.номер',
            year: 'Рік випуску',
            milleage: 'Пробіг'
        },
        recommendations: {
            title: 'Рекомендації',
            absent: 'Відсутні'
        },
        footer: {
            sum: 'Усього до оплати',
            signature: 'Підпис клієнта',
            link: 'Публічне посилання',
            open: 'Відкрити',
            share: 'Відправити',
            copy: 'Скопіювати'
        },
        noData: 'Данi вiдсутнi',
    },
    ru: {
        title: 'Заказ-наряд финализирование',
        header: {
            order: 'Заказ-наряд',
            date: 'Дата',
            client: 'Клиент',
            manufacturer: 'Производитель',
            type: 'Модель',
            chassis: '№ шасси',
            stateNumber: 'Гос.номер',
            year: 'Год выпуска',
            milleage: 'Пробег'
        },
        recommendations: {
            title: 'Рекомендации',
            absent: 'Отсутствуют'
        },
        footer: {
            sum: 'Всего к оплате',
            signature: 'Подпись клиента',
            link: 'Публичная ссылка',
            open: 'Открыть',
            share: 'Отправить',
            copy: 'Скопировать'
        },
        noData: 'Нет данных',
    },
    en: {
        title: 'Repair order summary',
        header: {
            order: 'Order',
            date: 'Date',
            client: 'Client',
            manufacturer: 'Manufacturer',
            type: 'Model',
            chassis: '№ chassis',
            stateNumber: 'State number',
            year: 'Year of production',
            milleage: 'Milleage'
        },
        recommendations: {
            title: 'Recommendations',
            absent: 'Absent'
        },
        footer: {
            sum: 'Total amount',
            signature: 'Signature',
            link: 'Public link',
            open: 'Open',
            share: 'Share',
            copy: 'Copy'
        },
        noData: 'No data',
    },
}

class Summary extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoadingHeader: true,
            isLoadingBlocks: true,
            isLoadingRecommendations: true,

            did: null,

            header: {},
            blocks: [],
            recommendations: []
        }

        this.shareLink = this.shareLink.bind(this);
        this.copyLink = this.copyLink.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        this.loadData();
    }

    loadData() {
        const {addNotification, removeNotification, token} = this.context;

        var did = Number(this.props.match.params.did);
        var item = null;
        if (this.props.match.params.item != undefined) {
            item =  Number(this.props.match.params.item);
        }

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");

            var h, t, r, hp, tp, rp;
            var hp = axios.post('/api/order/header', { token: token, did: did }).then((res) => {
                h = res.data.rows[0];
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                //removeNotification(u);
            });

            var tp = axios.post('/api/order/blocks/list', { token: token, did: did }).then((res) => {
                t = res.data.blocks;
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                //removeNotification(u);
            });

            var rp = axios.post('/api/order/recommendations/list', { token: token, did: did }).then((res) => {
                r = res.data.rows;
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                //removeNotification(u);
            });

            Promise.all([hp, tp, rp]).then(() => {
                this.setState({
                    did: did,
                    item: item,
                    header: h,
                    blocks: t, 
                    recommendations: r, 
                    isLoadingHeader: false,
                    isLoadingBlocks: false,
                    isLoadingRecommendations: false,
                }, () => {
                    removeNotification(u);
                });
            });
        } else {
            var did = Number(this.props.match.params.did);
            var item = null;
            if (this.props.match.params.item != undefined) {
                item =  Number(this.props.match.params.item);
            }

            this.setState({
                did: did,
                item: item,
                isLoadingHeader: false,
                isLoadingBlocks: false,
                isLoadingRecommendations: false,
                header: {
                    did: "90493",
                    doc: "1903605",
                    date: "10.07.2019",

                    company: "Промислово-технічна компанія у вигляді товариства з обмеженою відповідальністю \"АГРОМАТ\"",
                    driver: "Грінчук Віктор",
                    phone: "0961334347, +380 44 452-3406;+38050-4480968;+30850-3159564 Ігор",
                    address: "03115, м.Київ, проспект Перемоги, 89-А",
                    
                    manufacturer: "SCANIA",
                    type: "G400LA4X2HNA",
                    chassis: "5441919",
                    stateNumber: "AA8435PC",
                    vin: "YS2G4X20005441919",
                    year: "15.09.2016",
                    milleage: "393 905",

                    machineName: "",
                    machineVIN: "",
                    machineMilleage: "-",
                    
                    mechanicName: "vasia",
                    sum: "2 222",
                    sign: "folder",

                    readonly: 0,
                    hideHours: 1
                },
                blocks: [
                    { 
                        status:3,
                        sname:"Виконано",
                        item:1,
                        iname:"диагностика сцепления",
                        time:"0",
                        stime:"0",
                        folder:"0010TASKORDR000944590001",
                        sum:"2 222",
                        files:[ 
                        
                        ],
                        items:[ 
                            { 
                                titem:1,
                                item:1,
                                type:"P",
                                code:"1433203",
                                name:"Трубка гідропідсилювача зчеплення",
                                total:"0 001",
                                unit: "шт.",
                                sum:"4 360,05"
                            },
                            { 
                                titem:1,
                                item:2,
                                type:"P",
                                code:"KI.502161",
                                name:"Хомут пластмасовий 4.8x360",
                                total:"0 025",
                                unit: "шт.",
                                sum:"39,90"
                            },
                            { 
                                titem:1,
                                item:3,
                                type:"P",
                                code:"KI.089010810",
                                name:"Очисник універсальний плюс",
                                total:"0 001",
                                unit: "шт.",
                                sum:"79,45"
                            }
                        ]
                    },
                    { 
                        status:2,
                        sname:"Обід",
                        item:2,
                        iname:"Выездной сервис",
                        time:"745.14",
                        stime:"1",
                        folder:"0010TASKORDR000944590002",
                        sum:"2 222",
                        files:[ 
                            "IMG_20191211_101645_944.jpg",
                            "IMG_20191211_101723_859.mp4"
                        ],
                        items:[ 
                            { 
                                titem:2,
                                item:1,
                                type:"L",
                                code:"00005002-1",
                                name:"Виїзд механіка , перебування в дорозі(з урахуванням пробігу 50км)",
                                total:"0 001",
                                unit: "шт.",
                                sum:"1 001,70"
                            },
                        ]
                    }
                ],
                recommendations: [
                    {
                        did: "95593", 
                        rid: "77", 
                        remark: "asd", 
                        folder: "0010RECOMEND000955930077", 
                        files: ["1.jpg"]
                    }
                ]
            });
        }
    }

    renderBlocks() {
        return this.state.blocks.map((block, t) => {
            return (
                <div className="row mb-3" key={"block_" + t}>
                    <div className="col">
                        <div className="card agis-card pt-2 pb-2" style={{borderTopLeftRadius: "15px", borderTopRightRadius: "15px", backgroundColor: '#f1f3f4'}}>
                            <div className="row">
                                <div className="col">
                                    <h6><b>{block.item}. {block.iname}</b></h6>
                                </div>
                            </div>
                        </div>
                        {block.files.length > 0 && (
                            <div className="card agis-card">
                                <div className="row mt-2">
                                    {block.files.map((file) =>
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                            <Preview src={"/serve/order/blocks/"+block.folder+"/"+file} removeFile={null} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        {block.items.length > 0 && (
                            block.items.map((item, i) => {
                                var icon = null;

                                switch (item.type) {
                                    case "L":
                                        icon = <FontAwesomeIcon icon={faWrench} />;
                                        break;
                                    case "S":
                                        icon = <FontAwesomeIcon icon={faAmbulance} />;
                                        break;
                                    default: 
                                        icon = <FontAwesomeIcon icon={faCog} />;
                                        break;
                                }

                                return (
                                    <div className="card agis-card">
                                        <div className="row">
                                            <div className="col-12">
                                                <h6><b>{item.name}</b></h6>
                                            </div>
                                            <div className="col-5">
                                                <span className="text-muted">{icon} {item.code}</span>
                                            </div>
                                            <div className="col-3 text-center">
                                                <span>{item.total} {item.unit}</span>
                                            </div>
                                            <div className="col-4 text-right">
                                                <span>{item.sum} грн</span>
                                            </div>
                                            <hr/>
                                        </div>
                                    </div>
                                )
                            })
                        )}

                        <div className="card agis-card pt-2 pb-2" style={{borderBottomLeftRadius: "15px", borderBottomRightRadius: "15px"}}>
                            <div className="row">
                                <div className="col">
                                    {/* <span><b>2.48</b> годин в роботі</span> */}
                                </div>
                                <div className="col text-right">
                                    <h6><b>{block.sum} грн</b></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    renderRecommendations() {
        const {lang} = this.context;
        
        if (!this.state.recommendations.length > 0) {
            return (
                <div className="row">
                    <div className="col">
                        <span>{localization[lang].recommendations.absent}</span>
                    </div>
                </div>
            )
        } else {
            return this.state.recommendations.map((recommendation, r) => {
                return (
                    <div className="mb-2" key={"recommendation_"+r}>
                        <div className="row">
                            <div className="col">
                                <h6>{(r+1) + ". " + recommendation.remark}</h6>
                            </div>
                        </div>
                        {recommendation.files.length > 0 && (
                            <div className="row mt-1">
                                {recommendation.files.map((file) =>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                        <Preview src={"/serve/order/recommendations/attachments/"+recommendation.folder+"/"+file} removeFile={null} />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                );
            });
        }
    }

    shareLink() {
        const {addNotification, removeNotification, lang} = this.context;
        
        navigator.share({
            title: this.state.header.manufacturer + ' ' + this.state.header.stateNumber,
            text: 'ЗН від ' + this.state.header.date + ' на суму ' + this.state.header.sum,
            url: window.location.origin + '/public/order/' + this.state.did + '/tracker',
        }).then(() => {
            var u = addNotification("Відправлено...");
            setTimeout(() => {
                removeNotification(u);
            }, 2000);
        }).catch((error) => {
            addNotification('Could not share link: ' + error, 2);
        });
    }

    copyLink() {
        const {addNotification, removeNotification, lang} = this.context;
        navigator.permissions.query({name:'clipboard-write'}).then((res) => {
            if (res.state === 'granted') {
                navigator.clipboard.writeText(window.location.origin + '/public/order/' + this.state.did + '/tracker').then(() => {
                    var u = addNotification("Скопійовано...");
                    setTimeout(() => {
                        removeNotification(u);
                    }, 2000);
                }).catch(error => {
                    addNotification('Could not copy text: ' + error, 2);
                });
            } else if (res.state === 'prompt') {
                addNotification('Could not copy text: no permission', 2);
            }
        });
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        var isLoading = this.state.isLoadingHeader || this.state.isLoadingBlocks || this.state.isLoadingRecommendations;
        
        if (isLoading) {
            return <div>Loading...</div>
        }

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="container-fluid pl-0 pr-0">

                                <div className="row mb-2" style={{fontSize: "12px"}}>
                                    <div className="col-6">
                                        <h3><b>{this.state.header.doc}</b></h3>
                                    </div>
                                    <div className="col-6" style={{alignSelf: "flex-end"}}>
                                        <h6><b>{this.state.header.date}</b></h6>
                                    </div>
                                    <div className="col-6">
                                        <span className="text-muted">{localization[lang].header.order}</span>
                                    </div>
                                    <div className="col-6">
                                        <span className="text-muted">{localization[lang].header.date}</span>
                                    </div>
                                </div>

                                <div className="row mb-2" style={{fontSize: "12px"}}>
                                    <div className="col-12">
                                        <h6><b>{this.state.header.company}</b></h6>
                                    </div>
                                   
                                    <div className="col-12">
                                        <span className="text-muted">{localization[lang].header.client}</span>
                                    </div>
                                </div>

                                <div className="row mb-2" style={{fontSize: "12px"}}>
                                    <div className="col-6">
                                        <h6><b>{this.state.header.manufacturer}</b></h6>
                                    </div>
                                    <div className="col-6">
                                        <h6><b>{this.state.header.type}</b></h6>
                                    </div>
                                    <div className="col-6">
                                        <span className="text-muted">{localization[lang].header.manufacturer}</span>
                                    </div>
                                    <div className="col-6">
                                        <span className="text-muted">{localization[lang].header.type}</span>
                                    </div>
                                </div>

                                <div className="row mb-2" style={{fontSize: "12px"}}>
                                    <div className="col-6">
                                        <h6><b>{this.state.header.chassis}</b></h6>
                                    </div>
                                    <div className="col-6">
                                        <h6><b>{this.state.header.stateNumber}</b></h6>
                                    </div>
                                    <div className="col-6">
                                        <span className="text-muted">{localization[lang].header.chassis}</span>
                                    </div>
                                    <div className="col-6">
                                        <span className="text-muted">{localization[lang].header.stateNumber}</span>
                                    </div>
                                </div>

                                <div className="row mb-2" style={{fontSize: "12px"}}>
                                    <div className="col-6">
                                        <h6><b>{this.state.header.year}</b></h6>
                                    </div>
                                    <div className="col-6">
                                        <h6><b>{this.state.header.milleage}</b></h6>
                                    </div>
                                    <div className="col-6">
                                        <span className="text-muted">{localization[lang].header.year}</span>
                                    </div>
                                    <div className="col-6">
                                        <span className="text-muted">{localization[lang].header.milleage}</span>
                                    </div>
                                </div>

                                <br/>

                                {this.renderBlocks()}

                                <div className="row mb-3">
                                    <div className="col">
                                        <div className="card agis-card pt-2 pb-2" style={{borderTopLeftRadius: "15px", borderTopRightRadius: "15px", borderBottomLeftRadius: "15px", borderBottomRightRadius: "15px"}}>
                                            <div className="row">
                                                <div className="col">
                                                    <h6><b>{localization[lang].recommendations.title}</b></h6>
                                                </div>
                                            </div>
                                            <hr/>
                                            
                                            {this.renderRecommendations()}
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row mb-3">
                                    <div className="col">
                                        <div className="card agis-card pt-2 pb-2" style={{borderTopLeftRadius: "15px", borderTopRightRadius: "15px", borderBottomLeftRadius: "15px", borderBottomRightRadius: "15px"}}>
                                            <div className="row">
                                                <div className="col">
                                                    <span style={{lineHeight: "24px"}}>{localization[lang].footer.sum}</span>
                                                </div>
                                                <div className="col text-right">
                                                    <h5><b>{this.state.header.sum} грн</b></h5>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div className="row">
                                                <div id="signatureContainer" className="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                                                    <Signature did={this.state.did} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <br/>

                                <div className="row mb-2">
                                    <div className="col">
                                        <div className="card agis-card pt-2 pb-2" style={{borderTopLeftRadius: "15px", borderTopRightRadius: "15px", borderBottomLeftRadius: "15px", borderBottomRightRadius: "15px"}}>
                                            <div className="row">
                                                <div className="col text-center">
                                                    <span style={{lineHeight: "24px"}}>{localization[lang].footer.link}</span>
                                                </div>
                                            </div>
                                            <hr/>
                                            {navigator.share ? (
                                                <div className="row mt-1">
                                                    <div className="col text-center">
                                                        <Link to={"/public/order/" + this.state.did + "/tracker"} target="_blank" className="a-agis-light-outline" style={{height: "40px", width: 'calc(99% - 10px)', minWidth: "0px"}}><FontAwesomeIcon icon={faExternalLinkAlt} /> {localization[lang].footer.open}</Link>
                                                    </div>
                                                    <div className="col text-center">
                                                        <Link onClick={this.shareLink} className="a-agis-light-outline" style={{height: "40px", width: 'calc(99% - 10px)', minWidth: "0px"}}><FontAwesomeIcon icon={faShareAlt} /> {localization[lang].footer.share}</Link>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="row mt-1">
                                                    <div className="col text-center">
                                                        <Link to={"/public/order/" + this.state.did + "/tracker"} target="_blank" className="a-agis-light-outline" style={{height: "40px", width: 'calc(99% - 10px)', minWidth: "0px"}}><FontAwesomeIcon icon={faExternalLinkAlt} /> {localization[lang].footer.open}</Link>
                                                    </div>
                                                    <div className="col text-center">
                                                        <Link onClick={this.copyLink} className="a-agis-light-outline" style={{height: "40px", width: 'calc(99% - 10px)', minWidth: "0px"}}><FontAwesomeIcon icon={faCopy} /> {localization[lang].footer.copy}</Link>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class Signature extends React.Component {
    static contextType = AppContext;
    
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,

            signature: null,
            width: 0
        }

        this.signatureCanvas = React.createRef();
        this.signatureDelete = this.signatureDelete.bind(this);
        this.signatureClean = this.signatureClean.bind(this);
        this.signatureSave = this.signatureSave.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    load() {
        const {token} = this.context;

        var width = document.getElementById("signatureContainer").offsetWidth - 30;

        axios.get('/serve/order/summary/signatures/' + this.props.did + '.png', { responseType: 'arraybuffer' }).then((res) => {
            this.setState({
                isLoading: false,
                width: width,
                signature: `data:${res.headers['content-type']};base64,${Buffer.from(res.data, 'binary').toString('base64')}`
            });
        }).catch((error) => {
            this.setState({
                isLoading: false,
                width: width,
                signature: null, 
            });
        }).finally(function () {

        });
    }

    signatureDelete() {
        const {addNotification, removeNotification, token} = this.context;

        var u = addNotification("Завантаження...");
        axios.post('/api/order/summary/signature/remove', { did: this.props.did }).then((res) => {
            this.load();
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    signatureClean() {
        this.signatureCanvas.current.clear();
    }

    signatureSave() {
        const {addNotification, removeNotification, token} = this.context;

        var data = this.signatureCanvas.current.toDataURL();
        var blob = this.dataURItoBlob(data);

        var data = new FormData();
        data.append('file', blob, this.props.did + '.png');
        
        var u = addNotification("Завантаження...");
        axios.post('/api/order/summary/signature/add', data).then((res) => {
            this.load();
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    dataURItoBlob(dataURI) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);
    
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    
        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
    
        return new Blob([ab], {type: mimeString});
    }

    render() {
        const {lang} = this.context;

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            )
        }

        if (this.state.signature == null) {
            return (
                <div>
                    <SignatureCanvas ref={this.signatureCanvas}
                        penColor='#4f81bd'
                        canvasProps={{width: this.state.width, height: 180}} 
                    />
                    <hr/>
                    <div className="row">
                        <div className="col text-center">
                            <small className="text-muted">{localization[lang].footer.signature}</small>
                        </div>
                        <div className="col text-right">
                            <button className={'btn-agis-light-outline'} style={{height: "30px", minWidth: "0px", fontSize: "10px"}} onClick={this.signatureClean}><FontAwesomeIcon icon={faRedo}/></button>
                            <button className={'btn-agis-light-outline'} style={{height: "30px", minWidth: "0px", fontSize: "10px"}} onClick={this.signatureSave}><FontAwesomeIcon icon={faSave}/></button>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="text-center">
                <img src={this.state.signature} className="card-img" />
                <hr/>
                <div className="row">
                    <div className="col text-center">
                        <small className="text-muted">{localization[lang].footer.signature}</small>
                    </div>
                    <div className="col text-right">
                        <button className={'btn-agis-light-outline'} style={{height: "30px", minWidth: "0px", fontSize: "10px"}} onClick={this.signatureDelete}><FontAwesomeIcon icon={faTimes}/></button>
                    </div>
                </div>
            </div>
        )
    }
}

export default Summary;