import React from 'react'
import DocumentMeta from 'react-document-meta';
import axios from 'axios';
import ScaleLoader from "react-spinners/ScaleLoader";

import AppContext from '../../../AppContext';
import OffsetTop from '../../../components/offsetTop.js';

import BeepSound from '../../../files/audio/beep.mp3';
import ErrorSound from '../../../files/audio/error.wav';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes, faSave, faMapMarkerAlt, faCheck, faCamera, faQrcode } from '@fortawesome/free-solid-svg-icons'

const localization = {
    ua: {
        title: 'Акт інвентаризації',
        code: 'Код',
        total: 'Кількість',

        discard: 'Усунути',
        save: 'Зберегти',

        errors: {
            error1: 'Не внесений код ЗЧ!',
            error2: 'Не знайдений акт з DID = ',
            error3: 'Акт вже закритий для змін та перерахування!'
        },
    },
    ru: {
        title: 'Акт инвентаризации',
        code: 'Код',
        total: 'Количество',

        discard: 'Очистить',
        save: 'Сохранить',

        errors: {
            error1: 'Не внесен код ЗЧ!',
            error2: 'не найден акт с DID = ',
            error3: 'Акт уже закрыт для изменений и пересчета!'
        },
    },
    en: {
        title: 'Inventorization act',
        code: 'Code',
        total: 'Total',

        discard: 'Discard',
        save: 'Save',

        errors: {
            error1: 'No code!',
            error2: 'No document with DID = ',
            error3: 'Act is closed!'
        },
    },
}

class Recount extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,
            isCameraOn: false,
            isScannerOn: true,
            isFocusOn: false,
            
            videoSide: null,
            videoDevice: null,
            videoDevices: [],
            videoError: null,
            tracks: null,
            stream: null,

            did: null,
            header: null,
            counted: null,

            text: "",
            isChecking: false,
            
            check: null,
        }

        this.video = React.createRef();

        this.cameraToogle = this.cameraToogle.bind(this);
        this.scannerToogle = this.scannerToogle.bind(this);

        this.onChange = this.onChange.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onPaste = this.onPaste.bind(this);
        this.onScan = this.onScan.bind(this);
        this.onCheck = this.onCheck.bind(this);

        this.discard = this.discard.bind(this);
        this.save = this.save.bind(this);

        this.activate = this.activate.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        this.init();
        //this.detectDeices();

        window.addEventListener("keypress", this.barcodeScan.bind(this));
    }

    barcodeScan(event) {
        const {addNotification, removeNotification, token} = this.context;

        if (!this.state.isFocusOn) {
            if (event.key === 'Enter') {
                // if code was checked, save prev res before chekc scanned code
                if (this.state.check && this.state.check.ok == 1) {
                    var u = addNotification("Завантаження...");
                    axios.post('/api/stock/inventorization/add', {
                        token: token,
                        did: this.state.did,
                        code: this.state.check.code,
                        total: this.state.check.total,
                    }).then((res) => {
                        axios.post('/api/stock/inventorization/counted', { token: token, did: this.state.did }).then((res) => {
                            this.setState({
                                isChecking: true,

                                counted: res.data.rows,
                                check: null
                            }, () => {
                                this.onCheck();
                            });
                        }).catch((error) => {
                            addNotification(error.response.data, error.response.status);
                        }).finally(() => {
                            //removeNotification(u);
                        });
                    }).catch((error) => {
                        addNotification(error.response.data, error.response.status);
                    }).finally(() => {
                        removeNotification(u);
                    });
                } else {
                    this.setState({
                        isChecking: true,
                    }, () => {
                        this.onCheck();
                    });
                }
            } else if (event.key.match(/^[0-9a-zA-Z]+$/)) {
                this.setState({text: this.state.text + event.key});
            }
        }
    }

    componentWillUnmount() {
        if (this.state.tracks) {
            this.state.tracks.getTracks().forEach(track => track.stop());
        }
    }

    init() {
        const {addNotification, removeNotification, token, videoSide, videoDevice} = this.context;

        var did = Number(this.props.match.params.did);

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");

            var headerPromise = axios.post('/api/stock/inventorization/header', { token: token, did: did });
            var countedPromise = axios.post('/api/stock/inventorization/counted', { token: token, did: did });
            var devicesPromise = navigator.mediaDevices.enumerateDevices();

            axios.all([headerPromise, countedPromise, devicesPromise]).then(axios.spread((h, c, d) => {
                this.setState({
                    //isLoading: false,

                    did: did,

                    header: h.data.rows[0],
                    counted: c.data.rows,

                    videoDevices: d.filter((device) => {
                        return device.kind === 'videoinput';
                    }),

                    videoSide: videoSide,
                    videoDevice: videoDevice,
                }, () => {
                    this.runCamera();
                });
            })).catch(error => {
                addNotification(error.response.data, error.response.status);

                this.setState({
                    isLoading: false,
                    //videoError: error
                })
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            var devicesPromise = navigator.mediaDevices.enumerateDevices();

            var u = addNotification("Завантаження...");

            axios.all([devicesPromise]).then(axios.spread((d) => {
                this.setState({
                    //isLoading: false,

                    did: did,

                    header: {
                        name: "1900012 вiд 16.10.2019, 4СУ - МАКАРОВ - ЗАПЧАСТИ4, Стойка WURTH .. Стойка WURTH",
                        number: "2000010",
                        date: "20.09.2020",
                        stock: "ЗАПЧАСТИ",
                        from: "1 B 1",
                        to: "1 B 10",
                    },
                    counted: [
                        {
                            active: 0,
                            code: "1108017",
                            did: 79,
                            item: 36,
                            location: "1 A 1",
                            name: "кронштейн задній правий",
                            total: "1",
                            unit: "шт"
                        },
                        {
                            active: 1,
                            code: "1108017",
                            did: 79,
                            item: 36,
                            location: "1 A 1",
                            name: "кронштейн задній правий",
                            total: "1",
                            unit: "шт"
                        },
                        {
                            active: 1,
                            code: "1108017",
                            did: 79,
                            item: 36,
                            location: "1 A 1",
                            name: "кронштейн задній правий",
                            total: "1",
                            unit: "шт"
                        },
                        {
                            active: 1,
                            code: "1108017",
                            did: 79,
                            item: 36,
                            location: "1 A 1",
                            name: "кронштейн задній правий",
                            total: "1",
                            unit: "шт"
                        },
                        {
                            active: 1,
                            code: "1108017",
                            did: 79,
                            item: 36,
                            location: "1 A 1",
                            name: "кронштейн задній правий",
                            total: "1",
                            unit: "шт"
                        },
                        {
                            active: 0,
                            code: "1108017",
                            did: 79,
                            item: 36,
                            location: "1 A 1",
                            name: "кронштейн задній правий",
                            total: "1",
                            unit: "шт"
                        },
                    ],

                    videoDevices: d.filter((device) => {
                        return device.kind === 'videoinput';
                    }),

                    videoSide: videoSide,
                    videoDevice: videoDevice,
                }, () => {
                    this.runCamera();
                });
            })).catch(error => {
                addNotification(error.response.data, error.response.status);

                this.setState({
                    isLoading: false,
                    //videoError: err
                })
            }).finally(function () {
                removeNotification(u);
            });
        }
    }

    cameraToogle() {
        this.setState({isCameraOn: true, isScannerOn: false}, () => this.runCamera());
    }

    scannerToogle() {
        this.setState({isCameraOn: false, isScannerOn: true}, () => this.state.tracks && this.state.tracks.getTracks().forEach(track => track.stop()));
    }

    runCamera() {
        if (!this.state.isCameraOn) {
            this.setState({
                isLoading: false,
            }, () => {
                //document.getElementById('textInput').focus();
            });
            return
        }

        if (this.state.videoError == null) {
            this.video.current.srcObject = null;
        }

        if (this.state.videoSide && this.state.videoDevice) {
            const constraints = {
                formats: [
                    'aztec',
                    'code_128',
                    'code_39',
                    'code_93',
                    'codabar',
                    'data_matrix',
                    'ean_13',
                    'ean_8',
                    'itf',
                    'pdf417',
                    'qr_code',
                    'upc_a',
                    'upc_e'
                  ],
                video: {
                    facingMode: {exact: this.state.videoSide },
                    deviceId: { exact: this.state.videoDevice }
                }
            };
    
            navigator.mediaDevices.getUserMedia(constraints).then(res => {
                const track = res.getVideoTracks()[0];
                let imageCapture = new ImageCapture(track);
                
                this.setState({
                    isLoading: false,

                    tracks: res, 
                    stream: imageCapture, 
                    videoError: null
                }, () => {
                    //document.getElementById('textInput').focus();
                });
    
                this.video.current.srcObject = res;
            }).catch(err => {
                console.log(err);
                this.setState({
                    isLoading: false,
                    videoError: err
                });
            });
        } else {
            this.setState({
                isLoading: false,
                videoError: {name: "No device selected"}
            }, () => {
                //document.getElementById('textInput').focus();
            });
        }
    }

    onChange(event) {
        this.setState({
            text: event.target.value, 
        }, () => {
            //this.checkWorker();
        });
    }

    onKeyPress(e) {
        if (e.key === 'Enter') {
            this.setState({
                isChecking: true,
            }, () => {
                this.onCheck();
            });
        }
    }

    onPaste() {
        setTimeout(() => {
            this.setState({
                text: document.getElementById('textInput').value,
                isChecking: true,
            }, () => {
                this.onCheck();
            });
        }, 100)
    }

    onFocus(event) {
        this.setState({isFocusOn: true});
    }

    onBlur(event) {
        this.setState({isFocusOn: false});
    }

    onScan() {
        const {addNotification, removeNotification, token} = this.context;

        if (process.env.NODE_ENV === 'production') {
            //save if exist before scanning
            if (this.state.check && this.state.check.ok == 1) {
                var u = addNotification("Завантаження...");

                axios.post('/api/stock/inventorization/add', {
                    token: token,
                    did: this.state.did,
                    code: this.state.check.code,
                    total: this.state.check.total,
                }).then((res) => {
                    axios.post('/api/stock/inventorization/counted', { token: token, did: this.state.did }).then((res) => {
                        this.setState({
                            counted: res.data.rows,
                            check: null
                        }, () => {
                            this.state.stream.grabFrame().then(blob => {
                                console.log('Took photo:', blob);
                
                                let barcodeDetector = new window.BarcodeDetector();
                                barcodeDetector.detect(blob).then(detected => {
                                    if (detected.length == 1) {
                                        this.beep(true);
                
                                        this.setState({
                                            text: detected[0].rawValue, 
                                            isChecking: true,
                                        }, () => {
                                            this.onCheck();
                                        }, () => {
                                            //document.getElementById('textInput').focus();
                                        });
                                    } else {
                                        this.beep(false);
                                    }
                                }).catch(err => {
                                    this.beep(false);
                                    //this.setState({code: err+""});
                                });
                            });
                        });
                    }).catch((error) => {
                        addNotification(error.response.data, error.response.status);
                    }).finally(() => {
                        //removeNotification(u);
                    });
                }).catch((error) => {
                    addNotification(error.response.data, error.response.status);
                }).finally(() => {
                    removeNotification(u);
                });
            } else {
                this.state.stream.grabFrame().then(blob => {
                    console.log('Took photo:', blob);
    
                    let barcodeDetector = new window.BarcodeDetector();
                    barcodeDetector.detect(blob).then(detected => {
                        if (detected.length == 1) {
                            this.beep(true);
    
                            this.setState({
                                text: detected[0].rawValue, 
                                isChecking: true,
                            }, () => {
                                this.onCheck();
                            });
                        } else {
                            this.beep(false);
                        }
                    }).catch(err => {
                        console.log(err);
                        this.beep(false);
                        //this.setState({code: err+""});
                    });
                });
            }
        } else {
            this.beep(true);

            this.setState({
                text: "123456789", 
                isChecking: true,
                check: null,
            }, () => {
                this.onCheck();
            });
        }
    }

    onCheck() {
        const {addNotification, removeNotification, token} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var data;

            console.log(this.state.check);

            if (this.state.check && this.state.check.ok == 1) {
                //количество
                data = {
                    code: this.state.check.code,
                    text: this.state.text,
                    type: "3",
                    token: token,
                    did: this.state.did
                }
            } else {
                //код
                data = {
                    code: "",
                    text: this.state.text,
                    type: "2",
                    token: token,
                    did: this.state.did
                }
            }

            var u = addNotification("Завантаження...");

            axios.post('/api/stock/inventorization/check', data).then((res) => {
                console.log(res.data);

                this.setState({
                    isChecking: false,
                    text: "",
                    check: {
                        ok: res.data.rows[0].ok,
                        error: res.data.rows[0].error,
                        warning: res.data.rows[0].warning,
                        code: res.data.rows[0].code,
                        name: res.data.rows[0].name,
                        location: res.data.rows[0].location,
                        total: res.data.rows[0].total ? res.data.rows[0].total : 1,
                        unit: res.data.rows[0].unit
                    }
                }, () => {
                    document.getElementById('textInput').blur();
                });
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            setTimeout(() => {
                this.setState({
                    isChecking: false,
                    text: "",
                    check: {
                        ok: 1,
                        error: "Запчасть 66493 в справочнике не найдена!",
                        warning: "warning",
                        code: "123456",
                        name: "Запчасть 66493 в справочнике",
                        location: "1 0 1",
                        total: "1",
                        unit: "шт"
                    }
                });
            }, 1000);
        }
    }

    discard() {
        this.setState({
            text: "",
            check: null
        }, () => {
            //document.getElementById('textInput').focus();
        });
    }

    save() {
        const {addNotification, removeNotification, token, lang} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");

            axios.post('/api/stock/inventorization/add', {
                token: token,
                did: this.state.did,
                code: this.state.check.code,
                total: this.state.check.total,
            }).then((res) => {
                console.log(res.data);

                switch (res.data.result) {
                    case -1: 
                        addNotification(localization[lang].errors.error1, 2);
                        break;
                    case -2:
                        addNotification(localization[lang].errors.error2, 2);
                        break;
                    case -3:
                        addNotification(localization[lang].errors.error3, 2);
                        break;
                    default:
                        axios.post('/api/stock/inventorization/counted', { token: token, did: this.state.did }).then((res) => {
                            this.setState({
                                text: "",
                                check: null,
                                
                                counted: res.data.rows
                            }, () => {
                                //document.getElementById('textInput').focus();
                            });
                        }).catch((error) => {
                            addNotification(error.response.data, error.response.status);
                        }).finally(() => {
                            //removeNotification(u);
                        });
                        break;
                } 
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            setTimeout(() => {
                this.setState({
                    counted: [

                    ]
                });
            }, 1000);
        }
    }

    activate(item, active) {
        const {addNotification, removeNotification, token, lang} = this.context;

        var u = addNotification("Завантаження...");

        axios.post('/api/stock/inventorization/activate', {
            token: token,
            did: this.state.did,
            item: item,
            active: active ? 1 : 0,
        }).then((res) => {
            switch (res.data.result) {
                case -2:
                    addNotification(localization[lang].errors.error2, 2);
                    break;
                case -3:
                    addNotification(localization[lang].errors.error3, 2);
                    break;
                default:
                    axios.post('/api/stock/inventorization/counted', { token: token, did: this.state.did }).then((res) => {
                        this.setState({
                            //text: "",
                            //check: null,
                            
                            counted: res.data.rows
                        }, () => {
                            //document.getElementById('textInput').focus();
                        });
                    }).catch((error) => {
                        addNotification(error.response.data, error.response.status);
                    }).finally(() => {
                        //removeNotification(u);
                    });
                    break;
            }
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(() => {
            removeNotification(u);
        });
    }

    beep(status) {
        if (status) {
            var snd = new Audio(BeepSound);  
            snd.play();
        } else {
            var snd = new Audio(ErrorSound); 
            snd.play();
        }
    }

    renderList() {
        if (this.state.counted && this.state.counted.length > 0) {
            return this.state.counted.map((item, i) => {
                return (
                    <div className="agis-card mb-2" style={item.active == 0 ? {backgroundColor: "#eee"} : {}}>
                        <div class="d-flex justify-content-between">
                            <div className="row">
                                <div className="col-12 mb-1">
                                    <h6><b>{item.name}</b></h6>
                                </div>
                                <div className="col">
                                    <span>{item.code}</span>
                                </div>
                                <div className="col text-center">
                                    <span>{item.location}</span>
                                </div>
                                <div className="col text-right">
                                    <span><b>{item.total}</b> {item.unit}</span>
                                </div>
                            </div>
                            <div className="align-middle pl-3" style={{display: "flex", alignItems: "center"}}>
                                {item.active == 1 ? (
                                    <button className="btn-agis-light-outline" style={{height: '30px', width: "30px", lineHeight: "22px", minWidth: "0px"}} onClick={() => this.activate(item.item, false)}><FontAwesomeIcon icon={faTimes} style={{fontSize: "14px"}} /></button>
                                ) : (
                                    <button className="btn-agis-light-outline" style={{height: '30px', width: "30px", lineHeight: "22px", minWidth: "0px"}} onClick={() => this.activate(item.item, true)}><FontAwesomeIcon icon={faCheck} style={{fontSize: "14px"}} /></button>
                                )}
                            </div>
                        </div>
                    </div>
                );
            });
        }
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                {this.state.isLoading && (
                    <div className="text-center">
                    </div>
                )}
                
                <div className="container-fluid" style={this.state.isLoading ? {display: "none"} : {}}>
                    <div className="row">
                        <div className="col-md-10 offset-md-1">

                            {this.state.header && (
                                <div className="d-flex align-items-center justify-content-between mb-2">
                                    <div className="row pr-2">
                                        <div className="col d-flex align-items-center">
                                            <small style={{color: "#6f42c1"}}>{this.state.header.name}</small>
                                        </div>
                                    </div>
                                    <button onClick={this.scannerToogle} className={this.state.isScannerOn ? "btn-agis-primary-outline" : "btn-agis-light-outline"} style={{height: "24px", lineHeight: "16px", width: '40px', minWidth: "0px", fontSize: "12px"}}><FontAwesomeIcon icon={faQrcode} className="text-muted" /></button>
                                    <button onClick={this.cameraToogle} className={this.state.isCameraOn ? "btn-agis-primary-outline" : "btn-agis-light-outline"} style={{height: "24px", lineHeight: "16px", width: '40px', minWidth: "0px", fontSize: "12px"}}><FontAwesomeIcon icon={faCamera} className="text-muted" /></button>
                                </div>
                            )}

                            {(this.state.videoError == null && this.state.isCameraOn) && (
                                <div className="col">
                                    <video ref={this.video} className="w-100" autoPlay></video>
                                </div>
                            )}

                            {(this.state.videoError != null || !this.state.isCameraOn) && (
                                <div className="agis-card mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="w-100">
                                            <span className="text-muted">{(!this.state.check || this.state.check.ok == 0) ? localization[lang].code : localization[lang].total}</span>
                                            <input
                                                id="textInput"
                                                type={(this.state.check && this.state.check.ok == 1 && this.state.isFocusOn) ? "number" : "search"}
                                                className="w-100"
                                                style={{border: "0", color: "#464a4e", fontSize: "14px", lineHeight: "15px", fontSize: "1.1rem"}}
                                                value={this.state.text}
                                                onChange={this.onChange}
                                                onFocus={this.onFocus}
                                                onBlur={this.onBlur}
                                                onKeyPress={this.onKeyPress}
                                                onPaste={this.onPaste}
                                                //autoFocus={true}
                                            />
                                        </div>
                                        {this.state.isChecking ? (
                                            <div style={{height: "20px", width: "40px", textAlign: "center"}}>
                                                <ScaleLoader height="20px" width="3px" radius="3px" margin="2px" color={"#4F81BC"} />
                                            </div>
                                        ) : (
                                            <div style={{height: "20px", width: "40px", textAlign: "center"}}>
                                                <button onClick={() => this.setState({ isChecking: true }, () => { this.onCheck() })} className="text-muted" style={{height: "20px", minWidth: "20px", fontSize: "18px", border: "0", backgroundColor: "#fff"}}><FontAwesomeIcon icon={faSearch} /></button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {(this.state.videoError != null || !this.state.isCameraOn) && (
                                this.state.check && (
                                    this.state.check.ok == 1 ? (
                                        <div>
                                            {this.state.check.warning && (
                                                <div className="alert alert-warning mb-2" role="alert">{this.state.check.warning}</div>
                                            )}

                                            <div className="agis-card mb-3">
                                                <div className="row">
                                                    <div className="col-12 mb-1">
                                                        <h6><b>{this.state.check.name}</b></h6>
                                                    </div>
                                                    <div className="col">
                                                        <big className="mb-1">{this.state.check.code}</big>
                                                    </div>
                                                    <div className="col text-center">
                                                        <big className="mb-1"><FontAwesomeIcon icon={faMapMarkerAlt} /> {this.state.check.location}</big>
                                                    </div>
                                                    <div className="col text-right">
                                                        <big className="mb-1"><b>{this.state.check.total}</b> {this.state.check.unit}</big>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <button onClick={this.discard} className="btn-agis-light-outline" style={{width: "48%"}}><FontAwesomeIcon icon={faTimes} className="text-danger" /> {localization[lang].discard}</button>
                                                    <button onClick={this.save} className="btn-agis-light-outline" style={{width: "48%"}}><FontAwesomeIcon icon={faSave} className="text-success" /> {localization[lang].save}</button>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="alert alert-danger mb-2">{this.state.check.error}</div>
                                    )
                                )
                            )}

                            <br/>

                            {this.renderList()}

                            <br/>
                        </div>
                    </div>
                </div>

                {(this.state.videoError == null && this.state.isCameraOn) && (
                    <div className="fixed-bottom pt-3 pb-3" style={{zIndex: 2000, backgroundColor: "#F1F3F4", boxShadow: "0px -1px 0px rgba(0,0,0,.15)"}}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-10 offset-md-1">
                                    <div className="container-fluid pl-0 pr-0">

                                        {this.state.check && (
                                            this.state.check.ok == 1 ? (
                                                <div>
                                                    {this.state.check.warning && (
                                                        <div className="alert alert-warning mb-2" role="alert">{this.state.check.warning}</div>
                                                    )}

                                                    <div className="agis-card mb-2">
                                                        <div className="row">
                                                            <div className="col-12 mb-1">
                                                                <h6><b>{this.state.check.name}</b></h6>
                                                            </div>
                                                            <div className="col">
                                                                <big className="mb-1">{this.state.check.code}</big>
                                                            </div>
                                                            <div className="col text-center">
                                                                <big className="mb-1"><FontAwesomeIcon icon={faMapMarkerAlt} /> {this.state.check.location}</big>
                                                            </div>
                                                            <div className="col text-right">
                                                                <big className="mb-1"><b>{this.state.check.total}</b> {this.state.check.unit}</big>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <button onClick={this.discard} className="btn-agis-light-outline" style={{width: "48%"}}><FontAwesomeIcon icon={faTimes} className="text-danger" /> {localization[lang].discard}</button>
                                                            <button onClick={this.save} className="btn-agis-light-outline" style={{width: "48%"}}><FontAwesomeIcon icon={faSave} className="text-success" /> {localization[lang].save}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="alert alert-danger mb-2">{this.state.check.error}</div>
                                            )
                                        )}
                                    
                                        <div className="agis-card">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="w-100">
                                                    <span className="text-muted">{(!this.state.check || this.state.check.ok == 0) ? localization[lang].code : localization[lang].total}</span>
                                                    <input
                                                        id="textInput"
                                                        type={(this.state.check && this.state.check.ok == 1 && this.state.isFocusOn) ? "number" : "search"}
                                                        className="w-100"
                                                        style={{border: "0", color: "#464a4e", fontSize: "14px", lineHeight: "15px", fontSize: "1.1rem"}}
                                                        value={this.state.text}
                                                        onChange={this.onChange}
                                                        onFocus={this.onFocus}
                                                        onBlur={this.onBlur}
                                                        onKeyPress={this.onKeyPress}
                                                        onPaste={this.onPaste}
                                                    />
                                                </div>
                                                {this.state.isChecking ? (
                                                    <div style={{height: "20px", width: "40px", textAlign: "center"}}>
                                                        <ScaleLoader height="20px" width="3px" radius="3px" margin="2px" color={"#4F81BC"} />
                                                    </div>
                                                ) : (
                                                    <div style={{height: "26px", width: "40px", textAlign: "center"}}>
                                                        <button onClick={this.onScan} className="text-muted" style={{height: "26px", minWidth: "30px", fontSize: "18px", border: "0", backgroundColor: "#fff"}}><svg style={{fontSize: "26px"}} aria-hidden="true" focusable="false" data-prefix="fal" data-icon="barcode-read" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="svg-inline--fa fa-barcode-read fa-w-20 fa-1x"><path fill="currentColor" d="M152 0H8C3.6 0 0 3.6 0 8v152c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V32h120c4.4 0 8-3.6 8-8V8c0-4.4-3.6-8-8-8zm0 480H32V352c0-4.4-3.6-8-8-8H8c-4.4 0-8 3.6-8 8v152c0 4.4 3.6 8 8 8h144c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zM632 0H488c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h120v128c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V8c0-4.4-3.6-8-8-8zm0 344h-16c-4.4 0-8 3.6-8 8v128H488c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h144c4.4 0 8-3.6 8-8V352c0-4.4-3.6-8-8-8zM152 96h-48c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8zm336 320h48c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8zM408 96h-48c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8zm-192 0h-16c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8zm64 0h-16c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8z"></path></svg></button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}


            </div>
        )
    }
}

export default Recount;