import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../AppContext.js';
import OffsetTop from '../../../components/offsetTop.js';

const localization = {
    ua: {
        title: 'Створення витрати',
        header: 'Створення витрати',
        type: 'Тип витрати',
        country: 'Країна',
        payment: 'Тип оплати',
        currency: 'Валюта',
        date: 'Дата',
        sum: 'Сума',
        liters: 'Літри',
        currencyExch: 'Валюта після обміну',
        sumExch: 'Сума після обміну',
        expedition: 'Рейс',
        expeditionError: 'Необхідно обрати рейс',
        check: 'Є чек',
        checkYes: 'Так',
        checkNo: 'Ні',
        checkError: 'Не вказано чи є чек',
        remark: 'Примітка',
        button: 'Зберегти',
    },
    ru: {
        title: 'Создание траты',
        header: 'Создание затраты',
        type: 'Тип траты',
        country: 'Страна',
        payment: 'Тип оплаты',
        currency: 'Валюта',
        date: 'Дата',
        sum: 'Сумма',
        liters: 'Литры',
        currencyExch: 'Валюта после обмена',
        sumExch: 'Сумма после обмена',
        expedition: 'Рейс',
        expeditionError: 'Необходимо выбрать рейс',
        check: 'Есть чек',
        checkYes: 'Да',
        checkNo: 'Нет',
        checkError: 'Не указано есть ли чек',
        remark: 'Примечание',
        button: 'Сохранить',
    },
    en: {
        title: 'Create expenses',
        header: 'Create expenses',
        type: 'Type',
        country: 'Country',
        payment: 'Payment type',
        currency: 'Currency',
        date: 'Date',
        sum: 'Sum',
        liters: 'Liters',
        currencyExch: 'Currency after exchange',
        sumExch: 'Sum after exchange',
        expedition: 'Expedition',
        expeditionError: 'Expedition should be selected',
        check: 'Receipt',
        checkYes: 'Yes',
        checkNo: 'No',
        checkError: 'Receipt field should be filled',
        remark: 'Remark',
        button: 'Save',
    },
}

class CheckListNew extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,
            redirect: null,

            types: [],
            countries: [],
            payments: [],
            currencies: [],
            expeditions: [],

            type: null,
            country: null,
            payment: null,
            currency: null,
            date: "",
            sum: "",
            liters: "",
            currencyExch: null,
            sumExch: "",
            expedition: null,
            check: null,
            remark: "",
        }

        this.onInputChange = this.onInputChange.bind(this);
        this.onCheckClick = this.onCheckClick.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        this.setState({
            did: Number(this.props.match.params.did),
            cid: this.props.match.params.cid,
            type: Number(this.props.match.params.tid),
        }, () => this.loadList());
    }

    dateToInputFormat(date) {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
            
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
    }

    dateToDatabaseFormat(date) {
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [day, month, year].join('.');
    }

    loadList() {
        const {addNotification, removeNotification, token} = this.context;
        
        if (process.env.NODE_ENV === 'production') {
            // If it's a new cost
            if (this.state.cid == "new") {
                var u = addNotification("Завантаження...");

                var typePromise = axios.post('/api/driver/expenses/types', { token: token, type: "SPENTTYPE" });
                var countryPromise = axios.post('/api/driver/expenses/types', { token: token, type: "COUNTRY" });
                var paymentPromise = axios.post('/api/driver/expenses/types', { token: token, type: "PAYMKIND" });
                var currencyPromise = axios.post('/api/driver/expenses/types', { token: token, type: "CURRENCY" });
                var expeditionPromise = axios.post('/api/driver/expenses/expeditions', { token: token });
                
                axios.all([typePromise, countryPromise, paymentPromise, currencyPromise, expeditionPromise]).then(axios.spread((types, countries, payments, currencies, expeditions) => {
                    this.setState({
                        isLoading: false,

                        types: types.data.rows,
                        countries: countries.data.rows,
                        payments: payments.data.rows,
                        currencies: currencies.data.rows,
                        expeditions: expeditions.data.rows,

                        country: countries.data.rows.length > 0 ? countries.data.rows[0].id : null,
                        payment: payments.data.rows.length > 0 ? payments.data.rows[0].id : null,
                        currency: currencies.data.rows.length > 0 ? currencies.data.rows[0].id : null,
                        currencyExch: currencies.data.rows.length > 0 ? currencies.data.rows[0].id : null,
                        
                        date: Date.now(),
                    });
                })).catch((error) => {
                    addNotification(error.response.data, error.response.status);
                }).finally(function () {
                    removeNotification(u);
                });
            } else {
                var u = addNotification("Завантаження...");

                var cardPromise = axios.post('/api/driver/expenses/cost', { token: token, did: this.state.did, item: Number(this.state.cid) });
                var typePromise = axios.post('/api/driver/expenses/types', { token: token, type: "SPENTTYPE" });
                var countryPromise = axios.post('/api/driver/expenses/types', { token: token, type: "COUNTRY" });
                var paymentPromise = axios.post('/api/driver/expenses/types', { token: token, type: "PAYMKIND" });
                var currencyPromise = axios.post('/api/driver/expenses/types', { token: token, type: "CURRENCY" });
                var expeditionPromise = axios.post('/api/driver/expenses/expeditions', { token: token });
                
                axios.all([cardPromise, typePromise, countryPromise, paymentPromise, currencyPromise, expeditionPromise]).then(axios.spread((card, types, countries, payments, currencies, expeditions) => {
                    const d = card.data.rows[0].date.split('.');
                    
                    this.setState({
                        isLoading: false,

                        types: types.data.rows,
                        countries: countries.data.rows,
                        payments: payments.data.rows,
                        currencies: currencies.data.rows,
                        expeditions: expeditions.data.rows,

                        type: card.data.rows[0].type,
                        country: card.data.rows[0].country,
                        payment: card.data.rows[0].payment,
                        date: [d[2], d[1], d[0]].join('-'),
                        currency: card.data.rows[0].currency,
                        sum: card.data.rows[0].sum,
                        liters: card.data.rows[0].liters,
                        currencyExch: card.data.rows[0].currencyExch,
                        sumExch: card.data.rows[0].sumExch,
                        expedition: card.data.rows[0].expedition,
                        check: card.data.rows[0].check === 1 ? true : false,
                        remark: card.data.rows[0].remark,
                    });
                })).catch((error) => {
                    addNotification(error.response.data, error.response.status);
                }).finally(function () {
                    removeNotification(u);
                });
            }
        } else {
            this.setState({
                isLoading: false,
                types: [
                    { id: 1, name: "Стоянка", description: "" },
                    { id: 7, name: "Стоянка", description: "" },
                    { id: 9, name: "Стоянка", description: "" },
                    { id: 11, name: "Motel", description: "" },
                ],
                countries: [
                    { id: 11, name: "UA", description: "Ukraine" },
                    { id: 22, name: "PL", description: "Poland" },
                ],
                payments: [
                    { id: 111, name: "Cash", description: "" },
                    { id: 222, name: "Card", description: "" },
                ],
                currencies: [
                    { id: 1111, name: "UAH", description: "Ukrainian Hryvna" },
                    { id: 2222, name: "PLN", description: "Polish Zloty" },
                ],
                expeditions: [
                    { id: 11111, name: "Іван Євдокимов, DAF BK0792HT, WIELTON BK2692XF" },
                    { id: 22222, name: " Ігор Климчук, SCANIA BK2903IC, WIELTON BK4323XF" },
                ],
                country: 11,
                payment: 111,
                currency: 1111,
                date: Date.now(),
            });
        }
    }

    onInputChange(e, field) {
        switch (field) {
            case "country":
                this.setState({country: Number(e.target.value)});
                break
            case "payment":
                this.setState({payment: Number(e.target.value)});
                break
            case "currency":
                this.setState({currency: Number(e.target.value)});
                break
            case "date":
                this.setState({date: new Date(e.target.value).getTime()});
                break
            case "sum":
                this.setState({sum: e.target.value});
                break
            case "liters":
                this.setState({liters: e.target.value});
                break
            case "currencyExch":
                this.setState({currencyExch: Number(e.target.value)});
                break
            case "sumExch":
                this.setState({sumExch: e.target.value});
                break
            case "expedition":
                this.setState({expedition: Number(e.target.value)});
                break
            case "remark":
                this.setState({remark: e.target.value});
                break
        }
    }

    onCheckClick(e, status) {
        this.setState({check: status});
    }

    save() {
        const {addNotification, removeNotification, token, lang} = this.context;

        if (this.state.type == 6 || this.state.type == 8) {
            if (this.state.expedition === null) {
                addNotification(localization[lang].expeditionError, 2);
                return
            }
        } else {
            if (this.state.check === null) {
                addNotification(localization[lang].checkError, 2);
                return
            }
        }

        var u = addNotification("Завантаження...");
        axios.post('/api/driver/expenses/save', { 
            token: token, 
            did: this.state.did,
            item: this.state.cid == "new" ? 0 : Number(this.state.cid),
            type: this.state.type,
            country: this.state.country,
            payment: this.state.payment,
            currency: this.state.currency,
            date: this.dateToDatabaseFormat(this.state.date),
            sum: this.state.sum,

            liters: this.state.liters,
            currencyExch: this.state.currencyExch,
            sumExch: this.state.sumExch,

            expedition: (this.state.type != 6 && this.state.type != 8) ? 0 : this.state.expedition,
            
            check: (this.state.type != 6 && this.state.type != 8) ? this.state.check ? 1 : 0 : 0,
            remark: this.state.remark,
        }).then((res) => {
            if (res.data.serror == "") {
                this.setState({redirect: "/driver/expenses/" + this.state.did});
            } else {
                addNotification(res.data.serror, 2);
            }
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    render() {
        const {lang} = this.context;

        if (this.state.redirect !== null) {
            return <Redirect to={this.state.redirect} />
        }

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            
                            <div className="card agis-card pt-1 pb-1 mt-3" style={{backgroundColor: '#f1f3f4'}}>
                                <div className="row">
                                    <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                        <div>
                                            <h6><b>{this.state.types.find(t => t.id == this.state.type).name}</b></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card agis-card">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].country}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col d-flex justify-content-between">
                                        <select autoComplete="new-password" className="agis-input" onChange={(e) => this.onInputChange(e, "country")} value={this.state.country}>
                                            {this.state.countries.map((t) => <option value={t.id}>{t.name} {t.description != "" ? " - " + t.description : ""}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="card agis-card">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].payment}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col d-flex justify-content-between">
                                        <select autoComplete="new-password" className="agis-input" onChange={(e) => this.onInputChange(e, "payment")} value={this.state.payment}>
                                            {this.state.payments.map((t) => <option value={t.id}>{t.name} {t.description != "" ? " - " + t.description : ""}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="card agis-card">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].date}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col d-flex justify-content-between">
                                        <input type="date" autoComplete="new-password" className="agis-input" onChange={(e) => this.onInputChange(e, "date")} value={this.dateToInputFormat(this.state.date)} />
                                    </div>
                                </div>
                            </div>
                            {this.state.type != 8 && (
                                <div className="card agis-card">
                                    <div className="row">
                                        <div className="col">
                                            <span>{localization[lang].currency}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col d-flex justify-content-between">
                                            <select autoComplete="new-password" className="agis-input" onChange={(e) => this.onInputChange(e, "currency")} value={this.state.currency}>
                                                {this.state.currencies.map((t) => <option value={t.id}>{t.name} {t.description != "" ? " - " + t.description : ""}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {this.state.type != 8 && (
                                <div className="card agis-card">
                                    <div className="row">
                                        <div className="col">
                                            <span>{localization[lang].sum}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col d-flex justify-content-between">
                                            <input type="text" autoComplete="new-password" className="agis-input" onChange={(e) => this.onInputChange(e, "sum")} value={this.state.sum} />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {(this.state.type == 3 || this.state.type == 8 || this.state.type == 11) && (
                                <div className="card agis-card">
                                    <div className="row">
                                        <div className="col">
                                            <span>{localization[lang].liters}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col d-flex justify-content-between">
                                            <input type="text" autoComplete="new-password" className="agis-input" onChange={(e) => this.onInputChange(e, "liters")} value={this.state.liters} />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {this.state.type == 5 && (
                                <>
                                    <div className="card agis-card">
                                        <div className="row">
                                            <div className="col">
                                                <span>{localization[lang].currencyExch}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col d-flex justify-content-between">
                                                <select autoComplete="new-password" className="agis-input" onChange={(e) => this.onInputChange(e, "currencyExch")} value={this.state.currencyExch}>
                                                    {this.state.currencies.map((t) => <option value={t.id}>{t.name} {t.description != "" ? " - " + t.description : ""}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card agis-card">
                                        <div className="row">
                                            <div className="col">
                                                <span>{localization[lang].sumExch}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col d-flex justify-content-between">
                                                <input type="text" autoComplete="new-password" className="agis-input" onChange={(e) => this.onInputChange(e, "sumExch")} value={this.state.sumExch} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {(this.state.type == 6 || this.state.type == 8) && (
                                <div className="card agis-card">
                                    <div className="row">
                                        <div className="col">
                                            <span>{localization[lang].expedition}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col d-flex justify-content-between">
                                            <select autoComplete="new-password" className="agis-input" onChange={(e) => this.onInputChange(e, "expedition")} value={this.state.expedition}>
                                                <option value={null}></option>
                                                {this.state.expeditions.map((t) => <option value={t.id}>{t.name}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            )}       
                            {(this.state.type != 6 && this.state.type != 8) && (                     
                                <div className="card agis-card">
                                    <div className="row">
                                        <div className="col">
                                            <span>{localization[lang].check}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col d-flex justify-content-between">
                                            <button className={this.state.check === true ? "btn-agis-success-outline" : "btn-agis-light-outline"} style={{height: "30px", width: '49%', minWidth: "0px", border: "1px solid #ebebeb"}} onClick={(e) => this.onCheckClick(e, true)}>{localization[lang].checkYes}</button>
                                            <button className={this.state.check === false ? "btn-agis-danger-outline" : "btn-agis-light-outline"} style={{height: "30px", width: '49%', minWidth: "0px", border: "1px solid #ebebeb"}} onClick={(e) => this.onCheckClick(e, false)}>{localization[lang].checkNo}</button>
                                        </div>
                                    </div>
                                </div>
                            )}       
                            <div className="card agis-card">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].remark}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col d-flex justify-content-between">
                                        <input type="text" autoComplete="new-password" className="agis-input" onChange={(e) => this.onInputChange(e, "remark")} value={this.state.remark} />
                                    </div>
                                </div>
                            </div>

                            <button className="btn-agis-primary mt-3 w-100" onClick={this.save}>{localization[lang].button}</button>

                            <br/>
                            <br/>

                            <button className="btn-agis-light-outline" onClick={() => this.setState({redirect: '/driver/expenses/' + this.state.did})} style={{height: "30px", width: '100%', minWidth: "0px", border: "1px solid #ebebeb"}}>Назад до рейсу</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CheckListNew;