import React from 'react'
import { Link } from 'react-router-dom'
import DocumentMeta from 'react-document-meta';
import axios from 'axios';
import bwipjs from 'bwip-js';

import AppContext from '../AppContext';
import OffsetTop from '../components/offsetTop.js';

const localization = {
    ua: {
        title: 'Налаштування',
        session: {
            title: "Налаштування сесії",
            questionText: "Сесія буде завершена. Продовжити?",
            exitButtonText: "Вийти",
        },
        general: {
            title: "Налаштування iнтерфейсу",
            language: "Мова iнтерфейсу",
        },
        telegram: {
            title: 'Telegram',
            description: 'Підписатися на повідомлення?',
            subscribe: 'Підписатися'
        },
        camera: {
            title: 'Камера',
            configure: 'Налаштувати',
        },
        version: {
            title: 'Переглянути опис версії'
        }
    },
    ru: {
        title: 'Настройки',
        session: {
            title: "Настройки сессии",
            questionText: "Сессия будет завершена. Продолжить?",
            exitButtonText: "Выйти",
        },
        general: {
            title: "Настройки интерфейса",
            language: "Язык интерфейса",
        },
        telegram: {
            title: 'Telegram',
            description: 'Подписаться на оповещения?',
            subscribe: 'Подписаться'
        },
        camera: {
            title: 'Камера',
            configure: 'Настроить',
        },
        version: {
            title: 'Просмотреть описание версии'
        }
    },
    en: {
        title: 'Settings',
        session: {
            title: "Session settings",
            questionText: "Session will be finished. Proceed?",
            exitButtonText: "Logout",
        },
        general: {
            title: "Interface settings",
            language: "Language",
        },
        telegram: {
            title: 'Telegram',
            description: 'Subscribe to notification?',
            subscribe: 'Subscribe'
        },
        camera: {
            title: 'Camera',
            configure: 'Сonfigure',
        },
        version: {
            title: 'Version description'
        }
    },
}

class Profile extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            telegramBot: "",
        }

        this.languageChange = this.languageChange.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        this.init();
    }

    init() {
        const {token, addNotification, removeNotification} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");

            var barcodePromise = axios.post('/api/profile/barcode', { token: token });
            var telegramPromise = axios.get('/api/telegram/botname');
            

            axios.all([barcodePromise, telegramPromise]).then(axios.spread((b, t) => {
                this.setState({
                    isLoading: false,

                    barcode: b.data.rows[0].barcode,
                    telegramBot: t.data.telegramBot,
                }, () => {
                    let canvas = bwipjs.toCanvas('barcode', {
                        bcid:        'ean13',
                        text:        this.state.barcode,
                        scale:       3,
                        height:      6,
                        includetext: false
                    });

                    removeNotification(u);
                });
            }));
        } else {
            this.setState({
                isLoading: false,

                barcode: '222222222222',
                telegramBot: "ScaniaUkraineBot",
            }, () => {
                let canvas = bwipjs.toCanvas('barcode', {
                    bcid:        'ean13',
                    text:        this.state.barcode,
                    scale:       3,
                    height:      6,
                    includetext: false
                });
            });
        }
    }

    languageChange(lang) {
        const {addNotification, removeNotification, token, settingsLang, setMenuExpandText} = this.context;

        var u = addNotification("Завантаження...");
        axios.post('/api/profile/language', { token: token, lang: "FNAME=LANG;FVALUE="+lang }).then((res) => {
            settingsLang(lang);
            setMenuExpandText(localization[lang].title);
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    render() {
        const {lang, username, token, authLogout, videoDevice} = this.context;

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="container-fluid pl-0 pr-0">

                                <div className="row mb-2">
                                    <div className="col">
                                        <h3>{username}</h3>
                                    </div>
                                </div>

                                <div className="row mb-2">
                                    <div className="col text-center text-sm-left">
                                        <canvas id="barcode"></canvas>
                                    </div>
                                </div>

                                <div className="row mb-2">
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb-4">
                                        <div className="card agis-card pt-1 pb-1" style={{borderTopLeftRadius: "15px", borderTopRightRadius: "15px", backgroundColor: '#f1f3f4'}}>
                                            <div className="row">
                                                <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                                    <div>
                                                        <h6><b>{localization[lang].general.title}</b></h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card agis-card" style={{borderBottomLeftRadius: "15px", borderBottomRightRadius: "15px"}}>
                                            <div className="row">
                                                <div className="col">
                                                    <span>{localization[lang].general.language}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col d-flex justify-content-between">
                                                    <button className={lang == "ua" ? 'btn-agis-primary-outline' : 'btn-agis-light-outline'} style={{height: "30px", width: '48%', minWidth: "0px"}} onClick={(e) => {this.languageChange("ua")}}> Українська</button>
                                                    <button className={lang == "ru" ? 'btn-agis-primary-outline' : 'btn-agis-light-outline'} style={{height: "30px", width: '48%', minWidth: "0px"}} onClick={(e) => {this.languageChange("ru")}}> Русский</button>
                                                    <button className={lang == "en" ? 'btn-agis-primary-outline' : 'btn-agis-light-outline'} style={{height: "30px", width: '48%', minWidth: "0px"}} onClick={(e) => {this.languageChange("en")}}> English</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb-4">
                                        <div className="card agis-card pt-1 pb-1" style={{borderTopLeftRadius: "15px", borderTopRightRadius: "15px", backgroundColor: '#f1f3f4'}}>
                                            <div className="row">
                                                <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                                    <div>
                                                        <h6><b>{localization[lang].telegram.title}</b></h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card agis-card" style={{borderBottomLeftRadius: "15px", borderBottomRightRadius: "15px"}}>
                                            <div className="row">
                                                <div className="col">
                                                    <span>{localization[lang].telegram.description}</span>
                                                </div>
                                            </div>
                                            <div className="row mb-1">
                                                <div className="col text-right">
                                                    <a href={'https://telegram.me/'+this.state.telegramBot+'?start=' + token} target={"_blank"} className="btn-agis-primary">{localization[lang].telegram.subscribe}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb-4">
                                        <div className="card agis-card pt-1 pb-1" style={{borderTopLeftRadius: "15px", borderTopRightRadius: "15px", backgroundColor: '#f1f3f4'}}>
                                            <div className="row">
                                                <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                                    <div>
                                                        <h6><b>{localization[lang].camera.title}</b></h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card agis-card" style={{borderBottomLeftRadius: "15px", borderBottomRightRadius: "15px"}}>
                                            <div className="row mb-2">
                                                <div className="col text-right">
                                                    <Link to={"/settings/camera"} className="btn-agis-primary">{localization[lang].camera.configure}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-2">
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 offset-lg-8 mb-4">
                                        <div className="card agis-card pt-1 pb-1" style={{borderTopLeftRadius: "15px", borderTopRightRadius: "15px", backgroundColor: '#f1f3f4'}}>
                                            <div className="row">
                                                <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                                    <div>
                                                        <h6><b>{localization[lang].session.title}</b></h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card agis-card" style={{borderBottomLeftRadius: "15px", borderBottomRightRadius: "15px"}}>
                                            <div className="row">
                                                <div className="col">
                                                    <span>{localization[lang].session.questionText}</span>
                                                </div>
                                            </div>
                                            <div className="row mb-1">
                                                <div className="col text-right">
                                                    <button className="btn-agis-danger" style={{height: "30px"}} onClick={() => {authLogout()}}>{localization[lang].session.exitButtonText}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col text-center">
                                        <Link to={"/"} style={{fontSize: "12px"}}>{localization[lang].version.title}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Profile;