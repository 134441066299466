import React from 'react'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';
import imageCompression from 'browser-image-compression';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faCamera } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../AppContext.js';
import OffsetTop from '../../components/offsetTop.js';
import Preview from '../../components/preview.js';

const localization = {
    ua: {
        title: 'Рекомендації по наряду',
        recommendationText: 'Текст рекомендації...',
        recommendationButtonText: 'Додати',
        noData: 'Данi вiдсутнi',
    },
    ru: {
        title: 'Рекомендації по наряду',
        recommendationText: 'Текст рекомендации...',
        recommendationButtonText: 'Добавить',
        noData: 'Нет данных',
    },
    en: {
        title: 'Recommendations',
        recommendationText: 'Recommendation text...',
        recommendationButtonText: 'Add',
        noData: 'No data',
    },
}

class Recommendations extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            did: null,
            isLoading: true,
            header: null,
            recommendations: [],
            uploadFolder: ""
        }

        this.recommendationTextChange = this.recommendationTextChange.bind(this);
        this.recommendationTextKeyPress = this.recommendationTextKeyPress.bind(this);
        this.createRecommendation = this.createRecommendation.bind(this);

        this.removeRecommendation = this.removeRecommendation.bind(this);

        this.blocksAcceptanceClick = this.blocksAcceptanceClick.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        this.loadData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.did !== prevProps.match.params.did) {
            this.setState({did: this.props.match.params.did});
        }
    }

    loadData() {
        const {addNotification, removeNotification, token} = this.context;

        var did = Number(this.props.match.params.did);

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");

            var h, r, hp, rp;
            var hp = axios.post('/api/order/header', { token: token, did: did }).then((res) => {
                h = res.data.rows[0];
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                //removeNotification(u);
            });

            var rp = axios.post('/api/order/recommendations/list', { token: token, did: did }).then((res) => {
                r = res.data.rows;
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                //removeNotification(u);
            });

            Promise.all([hp, rp]).then(() => {
                this.setState({
                    did: did,
                    header: h,
                    recommendations: r,
                    isLoading: false,
                }, () => {
                    removeNotification(u);
                });
            });
        } else {
            this.setState({
                did: did,
                isLoading: false,
                header: {
                    did: "90493",
                    doc: "1903605",
                    status: "22",
                    date: "10.07.2019",

                    company: "Промислово-технічна компанія у вигляді товариства з обмеженою відповідальністю \"АГРОМАТ\"",
                    driver: "Грінчук Віктор",
                    phone: "0961334347, +380 44 452-3406;+38050-4480968;+30850-3159564 Ігор",
                    address: "03115, м.Київ, проспект Перемоги, 89-А",
                    
                    manufacturer: "SCANIA",
                    type: "G400LA4X2HNA",
                    chassis: "5441919",
                    stateNumber: "AA8435PC",
                    vin: "YS2G4X20005441919",
                    year: "15.09.2016",
                    milleage: "393 905",

                    machineName: "",
                    machineVIN: "",
                    machineMilleage: "-",
                    
                    mechanicName: "vasia",
                    sum: "2 222",
                    sign: "folder",

                    acceptanceblock: 1,
                    acceptance: 1,
                    acceptancestatus: 1,

                    readonly: 0,
                    hideHours: 1,

                    workpost: "Улица мойка"
                },
                recommendations: [
                    {
                        did: 95593,
                        rid: "77",
                        remark: "asd",
                        folder: "0010RECOMEND000955930077",
                        files: ["1.jpg"]
                    }
                ]
            });
        }
    }

    recommendationTextChange(e) {
        this.setState({recommendationText: e.target.value});
    }
    
    recommendationTextKeyPress(e) {
        if (e.key === 'Enter') {
            this.createRecommendation();
        }
    }

    createRecommendation() {
        const {addNotification, removeNotification, token} = this.context;

        var remark = this.state.recommendationText;

        var u = addNotification("Завантаження...");
        axios.post('/api/order/recommendations/add', { token: token, did: this.state.did, remark: remark }).then((res) => {
            if (res.data.serror == "") {
                this.state.recommendations.push({rid: res.data.rid, remark: remark, folder: res.data.folder, files: []});
                this.state.recommendationText = '';
                this.forceUpdate();
            } else {
                addNotification(res.data.serror, 2);
            }
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    removeFile = (filename, folder) => {
        const {addNotification, removeNotification} = this.context;

        var u = addNotification("Завантаження...");
        axios.post('/api/order/recommendations/attachment/remove', { filename: filename, folder: folder }).then((res) => {
            //remove file from list
            var ri, fi;
           
            var ri = this.state.recommendations.findIndex((ritem) => {
                return ritem.folder == folder
            });

            if (ri != -1) {
                var fi = this.state.recommendations[ri].files.findIndex((fitem) => {
                    return fitem == filename
                });
                this.state.recommendations[ri].files.splice(fi, 1);
                this.forceUpdate();
            }
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    triggerFileInput = (uploadFolder) => {
        this.setState({uploadFolder: uploadFolder}, () => {
            document.getElementById("fileInput").click();
        });
    }

    changeFileInput = event => {
        const {addNotification, removeNotification} = this.context;

        // Copy files to array
        var files = [];
        for (var i = 0; i < event.target.files.length; i++) {
            files.push(event.target.files[i]);
        }
        event.target.value = null;

        // Create data object
        var data = new FormData();
        data.append('length', files.length);
        data.append('folder', this.state.uploadFolder)

        // Attach files to data object
        var compressPromises = [];
        var fileIndex = 0; // file index because compressed images has different order

        for (var i = 0; i < files.length; i++) {
            if (files[i].type == "video/mp4") {
                data.append("file"+fileIndex, files[i]);
                fileIndex = fileIndex++;
            } else {
                compressPromises.push(imageCompression(files[i], {
                    maxSizeMB: 10,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true
                }));
            }
        }

        // Images should be compressed, wait
        Promise.all(compressPromises).then(results => {
            results.forEach(compressedFile => {
                data.append("file"+fileIndex, compressedFile, compressedFile.name);
                fileIndex = fileIndex++;
            });

            var u = addNotification("Завантаження...");
            axios.post('/api/order/recommendations/attachment/add', data).then((res) => {
                //add file to list
                var ri;
            
                var ri = this.state.recommendations.findIndex((ritem) => {
                    return ritem.folder == this.state.uploadFolder
                });

                if (ri != -1) {
                    this.state.recommendations[ri].files.push(res.data.filename);
                    this.state.uploadFolder = "";
                    this.forceUpdate();
                }
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        });
    }

    removeRecommendation(rid) {
        const {addNotification, removeNotification, token} = this.context;

        var u = addNotification("Завантаження...");
        axios.post('/api/order/recommendations/remove', { token: token, did: this.state.did, rid: rid }).then((res) => {
            if (res.data.serror == "") {
                var ri = this.state.recommendations.findIndex((item) => {
                    return item.rid == rid
                });

                this.state.recommendations.splice(ri, 1)
                this.forceUpdate();
            } else {
                addNotification(res.data.serror, 2);
            }
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    blocksAcceptanceClick(status) {
        const {addNotification, removeNotification, token} = this.context;

        var u = addNotification("Завантаження...");
        axios.post('/api/order/blocks/accept', { 
            token: token, 
            did: this.state.did,
            acceptanceid: this.state.header.acceptance, 
            acceptancestatus: status, 
            acceptanceremark: ''
        }).then((res) => {
            this.loadData();
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    renderList() {
        const {lang} = this.context;

        if (this.state.recommendations.length === 0) {
            return (
                <div className="row">
                    <div className="col">
                        <div className="card agis-card">
                            <div className="row">
                                <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                    <div>
                                        <h6><b>{localization[lang].noData}</b></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return this.state.recommendations.map((item, i) => {
                return (
                    <div className="row" key={"question_"+item.qid}>
                        <div className="col">
                            <div className="card agis-card pt-1 pb-1">
                                <div className="row">
                                    <div className="col-9 col-sm-11 col-md-11 col-lg-11 col-xl-11" style={{display: "flex", alignItems: "center"}}>
                                        <div>
                                            <h6><b>{item.remark}</b></h6>
                                        </div>
                                    </div>
                                    {this.state.header.readonly == 0 && (
                                        <div className="col-3 col-sm-1 col-md-1 col-lg-1 col-xl-1" style={{display: "flex", alignItems: "center"}}>
                                            <div className="w-100">
                                                <div className="d-flex justify-content-end">
                                                    <button className="btn-agis-light-outline" style={{height: "30px", width: "30px", minWidth: "30px"}} onClick={() => {this.triggerFileInput(item.folder)}}><FontAwesomeIcon icon={faCamera}/></button>
                                                    <button className="btn-agis-light-outline" style={{height: "30px", width: "30px", minWidth: "30px"}} onClick={() => {this.removeRecommendation(item.rid)}}><FontAwesomeIcon icon={faTimes}/></button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {item.files.length > 0 && (
                                    <div className="row mt-2">
                                        {item.files.map((file) =>
                                            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                <Preview src={"/serve/order/recommendations/attachments/"+item.folder+"/"+file} removeFile={this.state.header.readonly == 0 ? () => {this.removeFile(file, item.folder)} : null} />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )
            })
        }
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                <input type="file" id="fileInput" className="d-none" onChange={this.changeFileInput} accept=".jpg,.jpeg,.png,.bmp,.tiff,.gif,.mp4"/>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="container-fluid pl-0 pr-0">
                                {this.state.header && this.state.header.readonly == 0 && (
                                    <div className="row mb-4">
                                        <div className="col d-flex justify-content-between">
                                            <input type="text" className="input-agis-block" style={{width: 'calc(100% - 115px)'}} placeholder={localization[lang].recommendationText} value={this.state.recommendationText} onChange={(e) => this.recommendationTextChange(e)} onKeyPress={(e) => this.recommendationTextKeyPress(e)} />
                                            <button className="btn-agis-primary" onClick={this.createRecommendation}>{localization[lang].recommendationButtonText}</button>
                                        </div>
                                    </div>
                                )}

                                {this.renderList()}

                                {this.state.header && this.state.header.acceptance != 0 && (
                                    <div className="row mt-4 mb-3">
                                        <div className="col">
                                            <div className="card agis-card pt-2 pb-2" style={{borderTopLeftRadius: "15px", borderTopRightRadius: "15px", borderBottomLeftRadius: "15px", borderBottomRightRadius: "15px"}}>
                                                <div className="row">
                                                    <div className="col d-flex justify-content-between">
                                                        <button className={this.state.header.acceptancestatus == 1 ? 'btn-agis-success-outline' : 'btn-agis-light-outline'} style={{height: "30px", width: '100%', minWidth: "0px"}} onClick={(e) => {this.blocksAcceptanceClick(1)}}>Ознайомлений</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Recommendations;