import React from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../AppContext.js';
import OffsetTop from '../../../components/offsetTop.js';

const localization = {
    ua: {
        title: 'Створення чек-листа',
        searchText: 'Пошук...',
        searchDescription: 'Держ.реєстр.номер або номер шасi. Мiнiмум 4 символи',
        noData: 'Данi вiдсутнi'

    },
    ru: {
        title: 'Создание чек-листа',
        searchPlaceholder: 'Поиск...',
        searchDescription: 'Гос.номер или номер шасси. Минимум 4 символа',
        noData: 'Нет данных'
    },
    en: {
        title: 'Mechanic checklist creation',
        searchText: 'Search...',
        searchDescription: 'State number or chassis. Min 4 symbols',
        noData: 'No data'
    },
}

class CheckListNew extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            redirect: null,

            searchText: '',
            rows: []
        }

        this.searchTextChange = this.searchTextChange.bind(this);
        this.searchTextBlur = this.searchTextBlur.bind(this);
        this.searchTextKeyPress = this.searchTextKeyPress.bind(this);

        this.createCheckList = this.createCheckList.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);
    }

    searchTextChange(e) {
        this.setState({searchText: e.target.value});
    }
    
    searchTextKeyPress(e) {
        if (e.key === 'Enter') {
            e.target.blur();
        }
    }

    searchTextBlur() {
        const {addNotification, removeNotification, token} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/order/search', { token: token, searchText: this.state.searchText }).then((res) => {
                this.setState({rows: res.data.rows});
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            this.setState({rows: [
                { chassis: "159975236542", stateRegistrationNumber: "AA9921PA", brand: "Scania", model: "FX 505" }
            ]});
        }
    }

    createCheckList(chassis) {
        const {addNotification, removeNotification, token} = this.context;

        var u = addNotification("Завантаження...");
        axios.post('/api/order/check-list/mechanic/create', { token: token, chassis: chassis }).then((res) => {
            if (res.data.serror == "") {
                this.setState({redirect: "/order/check-list-mechanic/" + res.data.did});
            } else {
                addNotification(res.data.serror, 2);
            }
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    renderList() {
        const {lang} = this.context;
        
        if (this.state.rows.length === 0) {
            return (
                <div className="row">
                    <div className="col">
                        <div className="card agis-card">
                            <div className="row">
                                <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                    <div>
                                        <h6><b>{localization[lang].noData}</b></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return this.state.rows.map((item, i) => {
                return (
                    <div className="row" onClick={() => {this.createCheckList(item.chassis)}}>
                        <div className="col">
                            <div className="card agis-card">
                                <div class="d-flex justify-content-between">
                                    <div className="row">
                                        <div className="col">
                                            <big><b>{item.stateNumber + ", " + item.chassis}</b></big><br/>
                                            <big>{item.manufacturer + ", " + item.model}</big>
                                        </div>
                                    </div>
                                    <div className="align-middle" style={{display: "flex", alignItems: "center"}}>
                                        <span className="mr-1"><FontAwesomeIcon icon={faPlus}/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
        }
    }

    render() {
        const {lang} = this.context;

        if (this.state.redirect !== null) {
            return <Redirect to={this.state.redirect} />
        }

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="container-fluid pl-0 pr-0">
                                <div className="d-flex justify-content-between">
                                    <input type="text" className="question-input" style={{height: "40px", width: "100%", fontSize: "16px", borderRadius: "10px", borderTopRightRadius: "0px", borderBottomRightRadius: "0px", backgroundColor: "#fff"}} placeholder={localization[lang].searchText} value={this.state.searchText} onChange={(e) => this.searchTextChange(e)} onBlur={() => this.searchTextBlur()} onKeyPress={(e) => this.searchTextKeyPress(e)} />
                                    <button onClick={() => this.searchTextBlur()} className="a-agis-light-outline" style={{height: '40px', width: "40px", minWidth: "0px", borderRadius: "10px", borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px", lineHeight: "34px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 0 0 1px rgba(0,0,0,.05), 0 2px 3px 0 rgba(0,0,0,.1)"}}><FontAwesomeIcon icon={faSearch} style={{fontSize: "20px"}} /></button>
                                </div>
                                <div className="mb-3">
                                    <small className="agis-input-description">{localization[lang].searchDescription}</small>
                                </div>

                                {this.renderList()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CheckListNew;