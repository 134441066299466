import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faSearch, faPlus, faFilter } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../AppContext.js';
import OffsetTop from '../../../components/offsetTop.js';

const localization = {
    ua: {
        title: 'Листи огляду ремзони',
        filter: {
            date: 'Дата створення'
        },
        noData: 'Данi вiдсутнi',
    },
    ru: {
        title: 'Листы осмотра ремзони',
        filter: {
            date: 'Дата создания'
        },
        noData: 'Нет данных',
    },
    en: {
        title: 'Workshop inspection checklists',
        filter: {
            date: 'Date of creation'
        },
        noData: 'No data',
    },
}

class InspectionWorkshopList extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,

            filterDateFrom: null,
            filterDateTo: null,

            rows: []
        }

        this.dateToInputFormat= this.dateToInputFormat.bind(this);
        this.dateToDatabaseFormat= this.dateToDatabaseFormat.bind(this);

        this.changeDateFrom= this.changeDateFrom.bind(this);
        this.changeDateTo= this.changeDateTo.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        var dateTo = Date.now();
        var dateFrom = Date.now() - 604800000; // week in millisec

        this.setState({filterDateFrom: dateFrom, filterDateTo: dateTo}, () => {
            this.loadList();
        });
    }

    dateToInputFormat(date) {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
            
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
    }

    dateToDatabaseFormat(date) {
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [day, month, year].join('.');
    }

    changeDateFrom(event) {
        this.setState({filterDateFrom: new Date(event.target.value).getTime()}, () => {
            this.loadList();
        });
    }

    changeDateTo(event) {
        this.setState({filterDateTo: new Date(event.target.value).getTime()}, () => {
            this.loadList();
        });
    }

    loadList() {
        const {addNotification, removeNotification, token} = this.context;
        
        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/workshop/inspection/list', { token: token, from: this.dateToDatabaseFormat(this.state.filterDateFrom), to: this.dateToDatabaseFormat(this.state.filterDateTo) }).then((res) => {
                this.setState({rows: res.data.rows, isLoading: false,});
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            this.setState({
                isLoading: false,
                rows: [
                    {
                        did: "13", 
                        date: "20.10.2019", 
                        name: "Ремзона", 
                        time: "08:01", 
                        person: "Мудрик Олексій Володимирович"
                    }
                ]
            });
        }
    }

    renderList() {
        const {lang} = this.context;

        if (this.state.rows.length === 0) {
            return (
                <div className="row">
                    <div className="col">
                        <div className="card agis-card">
                            <div className="row">
                                <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                    <div>
                                        <h6><b>{localization[lang].noData}</b></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return this.state.rows.map((item, i) => {
                return (
                    <div className="row" key={"list_" + i}>
                        <div className="col">
                            <Link to={"/workshop/inspection/" + item.did} className="card-agis clickable">
                                <div class="d-flex justify-content-between">
                                    <big><b>{item.name}</b></big>
                                    <big>{item.date + " " + item.time}</big>
                                </div>
                                <div>
                                    <big>{item.person}</big>
                                </div>
                            </Link>
                        </div>
                    </div>
                );
            });
        }
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="container-fluid pl-0 pr-0">
                                
                                <div className="row mb-2">
                                    <div className="col">
                                        <div style={{padding: ".3rem .35rem", backgroundColor: "#eee", borderRadius: "10px"}}>
                                            <small id="emailHelp" className="form-text text-muted">{localization[lang].filter.date}</small>
                                            <div className="d-flex justify-content-between">
                                                <input type="date" className="form-control form-control-sm" onChange={this.changeDateFrom} value={this.dateToInputFormat(this.state.filterDateFrom)}/>
                                                <input type="date" className="form-control form-control-sm" onChange={this.changeDateTo}   value={this.dateToInputFormat(this.state.filterDateTo)}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {this.renderList()}

                                <div className="fixed-bottom text-right" style={{zIndex: "4", paddingRight: "10px", paddingBottom: "10px", height: "60px"}}>
                                    <Link to={"/workshop/inspection/new"} className="a-agis-light-outline" style={{height: '50px', width: "50px", minWidth: "0px", borderRadius: "25px", lineHeight: "44px", backgroundColor: "#1a73e8", color: "white", border: "1px solid #ebebeb", boxShadow: "0 1px 1px rgba(0,0,0,.15)"}}><FontAwesomeIcon icon={faPlus} style={{fontSize: "20px"}} /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default InspectionWorkshopList;