import React from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../AppContext.js';
import OffsetTop from '../../components/offsetTop.js';

const localization = {
    ua: {
        title: 'Створення перепустки',
        pass: {
            header: 'Дані перепустки',
            type: 'Призначення',
            reason: 'Ціль',
            vehicle: 'Транспорт',
        },
        plates: {
            header: 'Номерні знаки',
            vehicle: 'Транспортний засіб',
            trailer: 'Прицеп',
        },
        driver: {
            header: 'Водій',
            name: "Им'я",
            phone: 'Телефон',
            remark: 'Примітка',
        },
        button: 'Створити',
    },
    ru: {
        title: 'Создание пропуска',
        pass: {
            header: 'Данные пропуска',
            type: 'Назначение',
            reason: 'Цель',
            vehicle: 'Транспорт',
        },
        plates: {
            header: 'Номерные знаки',
            vehicle: 'Транспортное средство',
            trailer: 'Прицеп',
        },
        driver: {
            header: 'Водитель',
            name: 'Имя',
            phone: 'Телефон',
            remark: 'Примечание',
        },
        button: 'Создать',
    },
    en: {
        title: 'Vehicle pass',
        pass: {
            header: 'Дані перепустки',
            type: 'Призначення',
            reason: 'Ціль',
            vehicle: 'Транспорт',
        },
        plates: {
            header: 'Номерні знаки',
            vehicle: 'Транспортний засіб',
            trailer: 'Прицеп',
        },
        driver: {
            header: 'Водій',
            name: 'Имя',
            phone: 'Телефон',
            remark: 'Примітка',
        },
        button: 'Створити',
    },
}

class CheckListNew extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            redirect: null,
            isLoading: true,

            passType: null,
            reasonType: null,
            vehicleType: null,

            vehiclePlates: null,
            trailerPlates: null,

            driverName: null,
            driverPhone: null,
            remark: null,

            passTypes: [],
            reasonTypes: [],
            vehicleTypes: [],
        }

        this.onInputChange = this.onInputChange.bind(this);
        this.createCard = this.createCard.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);
        this.load();
    }

    load() {
        const {addNotification, removeNotification, token} = this.context;
        
        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");

            var c, cp, pt, ptp, rt, rtp, vt, vtp;

            var cp = axios.post('/api/territory/vehicle/create', { token: token }).then((res) => {
                c = res.data;
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                //removeNotification(u);
            });

            var ptp = axios.post('/api/territory/vehicle/types', { token: token, type: "PASSTYPE" }).then((res) => {
                pt = res.data.rows;
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                //removeNotification(u);
            });

            var rtp = axios.post('/api/territory/vehicle/types', { token: token, type: "PASSOBJECT" }).then((res) => {
                rt = res.data.rows;
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                //removeNotification(u);
            });

            var vtp = axios.post('/api/territory/vehicle/types', { token: token, type: "TRANSPORT" }).then((res) => {
                vt = res.data.rows;
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                //removeNotification(u);
            });

            Promise.all([cp, ptp, rtp, vtp]).then(() => {
                this.setState({
                    isLoading: false,

                    passType: c.passType,
                    reasonType: c.reasonType,
                    vehicleType: c.vehicleType,

                    vehiclePlates: c.vehiclePlates,
                    trailerPlates: c.trailerPlates,
                    
                    driverName: c.driverName,
                    driverPhone: c.driverPhone,
                    remark: c.remark,

                    passTypes: pt,
                    reasonTypes: rt,
                    vehicleTypes:vt,
                }, () => {
                    removeNotification(u);
                });
            });
        } else {
            this.setState({
                isLoading: false,

                passType: 2,
                reasonType: 2,
                vehicleType: 2,

                vehiclePlates: "KA2082EI",
                trailerPlates: "",
                
                driverName: "Yevhenii",
                driverPhone: "",
                remark: "",

                passTypes: [
                    { id: 1, name: "СТО" },
                    { id: 2, name: "Мийка" },
                ],
                reasonTypes: [
                    { id: 1, name: "Ремонт" },
                    { id: 2, name: "ТО" },
                    { id: 3, name: "Мийка" },
                ],
                vehicleTypes: [
                    { id: 1, name: "Тягач" },
                    { id: 2, name: "Тягач з прицепом" },
                    { id: 3, name: "Вантажівка" },
                ],
            });
        }
    }

    onInputChange(e, field) {
        switch (field) {
            case "passType":
                this.setState({passType: Number(e.target.value)});
                break
            case "reasonType":
                this.setState({reasonType: Number(e.target.value)});
                break
            case "vehicleType":
                this.setState({vehicleType: Number(e.target.value)});
                break
            case "vehiclePlates":
                this.setState({vehiclePlates: e.target.value.toUpperCase()});
                break
            case "trailerPlates":
                this.setState({trailerPlates: e.target.value.toUpperCase()});
                break
            case "driverName":
                this.setState({driverName: e.target.value});
                break
            case "driverPhone":
                this.setState({driverPhone: e.target.value});
                break
            case "remark":
                this.setState({remark: e.target.value});
                break
        }
    }

    createCard() {
        const {addNotification, removeNotification, token} = this.context;

        var u = addNotification("Завантаження...");
        axios.post('/api/territory/vehicle/save', { 
            token: token,
            passType: this.state.passType,
            reasonType: this.state.reasonType,
            vehicleType: this.state.vehicleType,
            vehiclePlates: this.state.vehiclePlates,
            trailerPlates: this.state.trailerPlates,
            driverName: this.state.driverName,
            driverPhone: this.state.driverPhone,
            remark: this.state.remark,
        }).then((res) => {
            this.setState({error: res.data.serror}, () => {
                if (res.data.serror == "") {
                    this.setState({redirect: "/territory/vehicles"});
                } else {
                    addNotification(res.data.serror, 2);
                }
            });
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    render() {
        const {lang} = this.context;

        if (this.state.redirect !== null) {
            return <Redirect to={this.state.redirect} />
        }

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="card agis-card pt-1 pb-1" style={{backgroundColor: '#f1f3f4'}}>
                                <div className="row">
                                    <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                        <div>
                                            <h6><b>{localization[lang].pass.header}</b></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card agis-card">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].pass.type}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col d-flex justify-content-between">
                                        <select autoComplete="new-password" className="agis-input" onChange={(e) => this.onInputChange(e, "passType")} value={this.state.passType}>
                                            {this.state.passTypes.map((t) => <option value={t.id}>{t.name}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="card agis-card">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].pass.reason}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col d-flex justify-content-between">
                                        <select autoComplete="new-password" className="agis-input" onChange={(e) => this.onInputChange(e, "reasonType")} value={this.state.reasonType}>
                                            {this.state.reasonTypes.map((t) => <option value={t.id}>{t.name}</option>)}
                                        </select>                                   
                                    </div>
                                </div>
                            </div>
                            <div className="card agis-card">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].pass.vehicle}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col d-flex justify-content-between">
                                        <select autoComplete="new-password" className="agis-input" onChange={(e) => this.onInputChange(e, "vehicleType")} value={this.state.vehicleType}>
                                            {this.state.vehicleTypes.map((t) => <option value={t.id}>{t.name}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="card agis-card pt-1 pb-1 mt-3" style={{backgroundColor: '#f1f3f4'}}>
                                <div className="row">
                                    <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                        <div>
                                            <h6><b>{localization[lang].plates.header}</b></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card agis-card">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].plates.vehicle}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col d-flex justify-content-between">
                                        <input type="text" autoComplete="new-password" className="agis-input" onChange={(e) => this.onInputChange(e, "vehiclePlates")} value={this.state.vehiclePlates} />
                                    </div>
                                </div>
                            </div>
                            <div className="card agis-card">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].plates.trailer}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col d-flex justify-content-between">
                                        <input type="text" autoComplete="new-password" className="agis-input" onChange={(e) => this.onInputChange(e, "trailerPlates")} value={this.state.trailerPlates} />
                                    </div>
                                </div>
                            </div>

                            <div className="card agis-card pt-1 pb-1 mt-3" style={{backgroundColor: '#f1f3f4'}}>
                                <div className="row">
                                    <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                        <div>
                                            <h6><b>{localization[lang].driver.header}</b></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card agis-card">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].driver.name}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col d-flex justify-content-between">
                                        <input type="text" autoComplete="new-password" className="agis-input" onChange={(e) => this.onInputChange(e, "driverName")} value={this.state.driverName} />
                                    </div>
                                </div>
                            </div>
                            <div className="card agis-card">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].driver.phone}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col d-flex justify-content-between">
                                        <input type="text" autoComplete="new-password" className="agis-input" onChange={(e) => this.onInputChange(e, "driverPhone")} value={this.state.driverPhone} />
                                    </div>
                                </div>
                            </div>
                            <div className="card agis-card">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].driver.remark}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col d-flex justify-content-between">
                                        <input type="text" autoComplete="new-password" className="agis-input" onChange={(e) => this.onInputChange(e, "remark")} value={this.state.remark} />
                                    </div>
                                </div>
                            </div>
                            <button className="btn-agis-primary mt-3 w-100" onClick={this.createCard}>{localization[lang].button}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CheckListNew;