import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';
import imageCompression from 'browser-image-compression';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCamera } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../AppContext.js';
import OffsetTop from '../../../components/offsetTop.js';
import Preview from '../../../components/preview.js';

const localization = {
    ua: {
        title: 'Рейс',
        filter: {
            date: 'Дата'
        },
        noData: 'Данi вiдсутнi',
    },
    ru: {
        title: 'Рейс',
        filter: {
            date: 'Дата'
        },
        noData: 'Данi вiдсутнi',
    },
    en: {
        title: 'Рейс',
        filter: {
            date: 'Дата'
        },
        noData: 'Данi вiдсутнi',
    },
}

class InspectionWorkshopList extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,
            redirect: null,
            stateChangeConfirm: true,

            did: null,

            costs: [],

            balance: '',

            uploadFolder: "",
        }

        this.load = this.load.bind(this);
        this.expenseStateChange = this.expenseStateChange.bind(this);
        this.delete = this.delete.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        this.setState({did: Number(this.props.match.params.did)}, () => {
            this.load();
        });
    }

    load() {
        const {addNotification, removeNotification, token, setMenuExpandText, lang} = this.context;
        
        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");

            var cardPromise = axios.post('/api/driver/expenses/card', { token: token, did: this.state.did });
            var costsPromise = axios.post('/api/driver/expenses/costs', { token: token, did: this.state.did });
            var balancePromise = axios.post('/api/driver/expenses/balance', { token: token });

            axios.all([cardPromise, costsPromise, balancePromise]).then(axios.spread((card, costs, balance) => {
                this.setState({
                    isLoading: false,

                    did: card.data.rows[0].did, 
                    date: card.data.rows[0].date, 
                    number: card.data.rows[0].number, 
                    status: card.data.rows[0].status, 
                    state: card.data.rows[0].state, 
                    driver: card.data.rows[0].driver, 
                    vehicle: card.data.rows[0].vehicle, 
                    name: card.data.rows[0].name, 
                    time: card.data.rows[0].time, 
                    remark: card.data.rows[0].remark, 
                    expenses: card.data.rows[0].expenses, 
                    files: card.data.rows[0].files,

                    costs: costs.data.rows,

                    balance: balance.data.rows[0].balance,
                });
            })).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            this.setState({
                isLoading: false,
                
                did: "13", 
                date: "20.10.2019", 
                number: "12345678",
                status: "Done",
                state: 33,
                driver: "Yevhenii",
                vehicle: "Kia Sportage",
                name: "Fuel check", 
                time: "30.08.2023 13:00:00 .. 02.09.2023 16:05:00", 
                remark: "Remark text example",
                expenses: "1549 UAH",
                files: ["", ""],

                costs: [
                    {
                        did: 13, 
                        item: 1,
                        type: "Fuel",
                        tid: 3,
                        payment: "Cash",
                        date: "30.08.2023 13:00:00",
                        country: "Ukraine",
                        currency: "UAH",
                        sumExch: "225",
                        currencyExch: "UAH",
                        liters: "15L",
                        expedition: "UA->PL",
                        sum: "1499.50",
                        remark: "Remark text example",
                    },
                    {
                        did: 13, 
                        item: 1,
                        type: "Fuel",
                        tid: 3,
                        payment: "Cash",
                        date: "30.08.2023 13:00:00",
                        currency: "UAH",
                        sum: "1499.50",
                        remark: "Remark text example",
                    },
                    {
                        did: 13, 
                        item: 1,
                        type: "Fuel",
                        tid: 3,
                        payment: "Cash",
                        date: "30.08.2023 13:00:00",
                        currency: "UAH",
                        sum: "1499.50",
                        remark: "Remark text example",
                    }
                ],

                balance: '468.40 UAH, 170.00 EUR, -777.85 AZN, -3043.00 MDL, -13.00 PLN, 31.00 CZK'
            }, () => {
                setMenuExpandText(localization[lang].title + ": " + this.state.number);
            });
        }
    }

    expenseStateChange(state) {
        const {addNotification, removeNotification, token} = this.context;

        if (this.state.stateChangeConfirm && state == "33") {
            this.setState({stateChangeConfirm: false});
            return
        }

        if (this.state.stateChangeConfirm && state == "13") {
            this.setState({stateChangeConfirm: false});
            return
        }

        var u = addNotification("Завантаження...");
        axios.post('/api/driver/expenses/state', { 
            token: token, 
            did: Number(this.state.did),
            state: Number(state),
        }).then((res) => {
            if (res.data.serror == "") {
                this.setState({stateChangeConfirm: true}, () => this.load());
            } else {
                addNotification(res.data.serror, 2);
            }
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    delete(item) {
        const {addNotification, removeNotification, token} = this.context;

        var u = addNotification("Завантаження...");
        axios.post('/api/driver/expenses/delete', { 
            token: token, 
            did: Number(this.state.did),
            item: Number(item),
        }).then((res) => {
            if (res.data.serror == "") {
                this.load();
            } else {
                addNotification(res.data.serror, 2);
            }
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    removeFile = (filename, folder) => {
        const {addNotification, removeNotification} = this.context;

        var u = addNotification("Завантаження...");
        axios.post('/api/driver/expenses/attachment/remove', { filename: filename, folder: folder + "" }).then((res) => {
            //remove file names to list
            this.removeFromFileList(folder, filename);
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    triggerFileInput = (uploadFolder) => {
        this.setState({uploadFolder: uploadFolder + ""}, () => {
            document.getElementById("fileInput").click();
        });
    }

    changeFileInput = event => {
        const {addNotification, removeNotification} = this.context;

        // Copy files to array
        var files = [];
        for (var i = 0; i < event.target.files.length; i++) {
            files.push(event.target.files[i]);
        }
        event.target.value = null;

        // Create data object
        var data = new FormData();
        data.append('length', files.length);
        data.append('folder', this.state.uploadFolder)

        // Attach files to data object
        var compressPromises = [];
        var fileIndex = 0; // file index because compressed images has different order

        for (var i = 0; i < files.length; i++) {
            if (files[i].type == "video/mp4") {
                data.append("file"+fileIndex, files[i]);
                fileIndex = fileIndex++;
            } else {
                compressPromises.push(imageCompression(files[i], {
                    maxSizeMB: 10,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true
                }));
            }
        }

        // Images should be compressed, wait
        Promise.all(compressPromises).then(results => {
            results.forEach(compressedFile => {
                data.append("file"+fileIndex, compressedFile, compressedFile.name);
                fileIndex = fileIndex++;
            });

            var u = addNotification("Завантаження...");
            axios.post('/api/driver/expenses/attachment/add', data).then((res) => {
                this.addToFileList(this.state.uploadFolder, res.data.filename);
                this.setState({uploadFolder: ""});
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        });
    }

    addToFileList(folfer, filename) {
        this.state.files.push(filename);
        this.forceUpdate();
    }

    removeFromFileList(folfer, filename) {
        var fi = this.state.files.findIndex((fitem) => {
            return fitem == filename
        });
        this.state.files.splice(fi, 1);
        this.forceUpdate();
    }

    renderList() {
        const {lang} = this.context;

        if (this.state.costs.length === 0) {
            return (
                <div className="row">
                    <div className="col">
                        <div className="card agis-card">
                            <div className="row">
                                <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                    <div>
                                        <h6><b>{localization[lang].noData}</b></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return this.state.costs.map((item, i) => {
                return (
                    <div className="row mb-2" key={"list_" + i}>
                        <div className="col">
                            <div className="card-agis pt-2 pb-2" style={{borderRadius: "15px"}}>
                                <div>
                                    <big>{item.date}, {item.country}, {item.type} {item.expedition != "" ? "("+item.expedition+")" : ""}</big>
                                </div>
                                <div>
                                    <big className='text-info'>{item.payment}</big>
                                </div>
                                <div>
                                    <big><i>{item.remark}</i></big>
                                </div>
                                <div className="text-right">
                                    <big className="text-success"><b>{item.sum} {item.currency}{item.liters != "0.00" ? ", "+item.liters+" літрів" : ""}{item.sumExch != "0.00" ? " -> "+item.sumExch+" "+item.currencyExch : ""}</b></big>
                                </div>
                                {(this.state.state == 33 && item.tid != 7 && item.tid != 9) && (
                                    <div class="d-flex justify-content-between mt-2">
                                        <button className="btn-agis-light-outline" style={{height: "30px", width: '49%', minWidth: "0px", border: "1px solid #ebebeb"}} onClick={() => this.setState({redirect: "/driver/expenses/"+this.state.did+"/"+item.item+"/"+item.tid})}>Редагувати</button>
                                        <button className="btn-agis-danger-outline" style={{height: "30px", width: '49%', minWidth: "0px"}} onClick={() => this.delete(item.item)}>Видалити</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                );
            });
        }
    }

    render() {
        const {lang} = this.context;

        if (this.state.redirect !== null) {
            return <Redirect to={this.state.redirect} />
        }

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                <input type="file" id="fileInput" className="d-none" onChange={this.changeFileInput} accept=".jpg,.jpeg,.png,.bmp,.tiff,.gif,.mp4"/>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="container-fluid pl-0 pr-0">

                                <div className="row mb-4">
                                    <div className="col">
                                        <div className="card-agis pt-2 pb-2" style={{borderRadius: "15px", backgroundColor: "rgb(209, 231, 221)", borderColor: "rgb(163, 207, 187)", color: "rgb(10, 54, 34)" }}>
                                            <div class="mb-1">
                                                <big className="">Поточний залишок коштів</big>
                                            </div>
                                            <div>
                                                <big><b>{this.state.balance}</b></big>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row mb-2">
                                    <div className="col">
                                        <div className="card-agis pt-2 pb-2" style={{borderRadius: "15px"}}>
                                            <div class="d-flex justify-content-between">
                                                <big><b>{this.state.number}</b> від {this.state.date}, <span className={this.state.state == 8 ? "text-warning" : this.state.state == 13 ? "text-danger" : this.state.state == 33 ? "text-success": ""}>{this.state.status}</span></big>
                                            </div>
                                            <div>
                                                <big>{this.state.driver}</big>
                                            </div>
                                            <div>
                                                <big>{this.state.vehicle}</big>
                                            </div>
                                            <div>
                                                <big><b>{this.state.name}</b></big>
                                            </div>
                                            <div>
                                                <big className="text-info">{this.state.time}</big>
                                            </div>
                                            <div>
                                                <big>{this.state.remark}</big>
                                            </div>

                                            {this.state.state != 8 && this.state.state != 33 ? (
                                                <div class="d-flex align-items-center justify-content-between mt-2">
                                                    <button className="btn-agis-light-outline" style={{height: "30px", width: "50px", minWidth: "30px"}} onClick={() => {this.triggerFileInput(this.state.did)}}><FontAwesomeIcon icon={faCamera}/></button>
                                                    <big className="text-success"><b>{this.state.expenses}</b></big>
                                                </div>
                                            ) : (
                                                <div className="text-right">
                                                    <big className="text-success"><b>{this.state.expenses}</b></big>
                                                </div>
                                            )}

                                            {this.state.state == 8 && (
                                                <div class="d-flex justify-content-between mt-2">
                                                    <button className="btn-agis-light-outline" style={{height: "30px", width: "50px", minWidth: "30px"}} onClick={() => {this.triggerFileInput(this.state.did)}}><FontAwesomeIcon icon={faCamera}/></button>
                                                    <button className="btn-agis-success-outline" style={{height: "30px", width: '100%', minWidth: "0px"}} onClick={(e) => {this.expenseStateChange("33")}}>Старт{this.state.stateChangeConfirm ? '' : ', я впевнений?'}</button>
                                                </div>
                                            )}

                                            {this.state.state == 33 && (
                                                <div class="d-flex justify-content-between mt-2">
                                                    <button className="btn-agis-light-outline" style={{height: "30px", width: "50px", minWidth: "30px"}} onClick={() => {this.triggerFileInput(this.state.did)}}><FontAwesomeIcon icon={faCamera}/></button>
                                                    <button className="btn-agis-danger-outline" style={{height: "30px", width: '100%', minWidth: "0px"}} onClick={(e) => {this.expenseStateChange("13")}}>Фініш{this.state.stateChangeConfirm ? '' : ', я впевнений?'}</button>
                                                </div>
                                            )}

                                            {this.state.files.length > 0 && (
                                                <div className="row mt-2">
                                                    {this.state.files.map((file) =>
                                                        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                            <Preview src={"/serve/driver/expenses/"+this.state.did+"/"+file} removeFile={this.state.state != 13 && this.state.state != 23 ? () => {this.removeFile(file, this.state.did)} : null} />
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                {this.renderList()}

                                <div className="fixed-bottom d-flex align-items-center justify-content-between" style={{zIndex: "4", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "10px", height: "60px"}}>
                                    <button className="btn-agis-light-outline" onClick={() => this.setState({redirect: '/driver/expenses'})} style={{height: "40px", width: '50%', minWidth: "0px", border: "1px solid #ebebeb", backgroundColor: '#fff', boxShadow: '0 1px 6px 0 rgba(32,33,36,0.28)'}}>Назад до рейсів</button>
                                    {this.state.state == 33 && (
                                        <Link to={"/driver/expenses/new/"+ this.state.did} className="a-agis-light-outline" style={{height: '50px', width: "50px", minWidth: "0px", borderRadius: "25px", lineHeight: "44px", backgroundColor: "#1a73e8", color: "white", border: "1px solid #ebebeb", boxShadow: "0 1px 1px rgba(0,0,0,.15)"}}><FontAwesomeIcon icon={faPlus} style={{fontSize: "20px"}} /></Link>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default InspectionWorkshopList;