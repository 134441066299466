import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faSearch, faPlus, faFilter, faChevronLeft, faChevronDown, faWrench, faSmoking, faRoad, faBed, faBuilding, faHome } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../AppContext.js';
import OffsetTop from '../../../components/offsetTop.js';

const localization = {
    ua: {
        title: 'Стемпінг механіка',
        choose: 'Виберіть механіка',
        filter: {
            date: 'Дата'
        },
        noData: 'Данi вiдсутнi',
    },
    ru: {
        title: 'Стемпинг работы механика',
        choose: 'Выберите механика',
        filter: {
            date: 'Дата'
        },
        noData: 'Нет данных',
    },
    en: {
        title: 'Stamping mechanic',
        choose: 'Choose mechanic',
        filter: {
            date: 'Date'
        },
        noData: 'No data',
    },
}

class ManagerStamping extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,

            filterDate: null,
            mechanics: [],

            isOpen: false,

            mechanic: null,
            rows: []
        }

        this.dateToInputFormat= this.dateToInputFormat.bind(this);
        this.dateToDatabaseFormat= this.dateToDatabaseFormat.bind(this);

        this.changeDate= this.changeDate.bind(this);
        this.datePrev = this.datePrev.bind(this);
        this.dateNext = this.dateNext.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        this.setState({filterDate: Date.now()}, () => {
            this.init();
        });
    }

    dateToInputFormat(date) {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
            
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
    }

    dateToDatabaseFormat(date) {
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [day, month, year].join('.');
    }

    changeDate(event) {
        this.setState({filterDate: new Date(event.target.value).getTime()}, () => {
            this.loadList();
        });
    }

    datePrev() {
        this.setState({filterDate: this.state.filterDate - 86400000}, () => {
            this.loadList();
        });
    }

    dateNext() {
        this.setState({filterDate: this.state.filterDate + 86400000}, () => {
            this.loadList();
        });
    }

    init() {
        const {addNotification, removeNotification, token} = this.context;
        
        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/service/manager/mechanics', { token: token }).then((res) => {
                this.setState({mechanics: res.data.rows, isLoading: false});
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            this.setState({
                isLoading: false,
                mechanics: [
                    {
                        mid: 1,
                        name: "Vasia",
                    },
                    {
                        mid: 2,
                        name: "Petia",
                    },
                ]
            });
        }
    }

    loadList() {
        const {addNotification, removeNotification, token} = this.context;
        
        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/service/manager/stamping', { token: token, mid: this.state.mechanic, date: this.dateToDatabaseFormat(this.state.filterDate) }).then((res) => {
                this.setState({rows: res.data.rows, isLoading: false});
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            this.setState({
                isLoading: false,
                rows: [
                    { 
                        gid: "1",
                        sid: 1,  
                        start: "25.10.19 8:12:09",
                        finish: "25.10.19 9:31:17",
                        hours: "1:10",
                        sname: "Підготовка до ремонту"
                    },
                    { 
                        gid: "2",
                        sid: 2,  
                        start: "25.10.19 09:32:28",
                        finish: "25.10.19 11:48:17",
                        hours: "2:20",
                        sname: "Обід"
                    },
                    { 
                        gid: "3",
                        sid: 3,  
                        start: "25.10.19 12:03:17",
                        finish: "",
                        hours: "3:30",
                        sname: "Дорога при ТО, ремонті автомобіля"
                    },
                    { 
                        gid: "4",
                        sid: 3,  
                        start: "25.10.19 2:03:17",
                        finish: "",
                        hours: "4:40",
                        sname: "Лікарняний"
                    },
                    { 
                        gid: "5",
                        sid: 3,  
                        start: "25.10.19 12:03:17",
                        finish: "",
                        hours: "5:50",
                        sname: "Консультація клієнта по телефону"
                    },
                    { 
                        gid: "6",
                        sid: 3,  
                        start: "25.10.19 12:03:17",
                        finish: "",
                        hours: "0",
                        sname: "Окончание рабочего дня"
                    }
                ]
            });
        }
    }

    groupColor(gid) {
        switch(gid) {
            case '1':
                return "#0091ea";
            case '2':  
                return "#ffab00";
            case '3':
                return "#b0bec5";
            case '4':  
                return "#c5cae9";
            case '5':
                return "#992450";
            default:
                return "";
          }
    }

    groupIcon(gid) {
        switch(gid) {
            case '1':
                return faWrench;
            case '2':  
                return faSmoking;
            case '3':
                return faRoad;
            case '4':  
                return faBed;
            case '5':
                return faBuilding;
            default:
                return faHome;
          }
    }

    normalizeStamp(stamp) {
        if (stamp == "") {
            return ". . .";
        }
        var datetime = stamp.split(" ");
        var time = datetime[1].split(":");
        var res = time[0] + ":" + time[1];

        if (res.length < 5) {
            res = "0"+res;
        }

        return res;
    }

    renderList() {
        const {lang} = this.context;

        if (this.state.rows.length === 0) {
            return (
                <div className="row">
                    <div className="col">
                        <h6><b>{localization[lang].noData}</b></h6>
                    </div>
                </div>
            );
        } else {
            return this.state.rows.map((item, i) => {
                var borderColor = this.groupColor(item.gid);

                return (
                    <div className="row pt-1 pb-1" key={"stamp_"+item.qid}>
                        <div className="col">
                            <div className="body pl-2" style={{borderLeft: "4px solid" + borderColor}}>
                                {(this.state.rows.length == ++i && item.hours == "0") ? (
                                    <small><b>. . .</b></small>
                                ) :(
                                    <small>{item.hours}</small>
                                )}
                            </div>
                            <div className="body pl-2" style={{borderLeft: "4px solid" + borderColor}}>
                                <h6><b>{this.normalizeStamp(item.start)}</b> <span dangerouslySetInnerHTML={{__html: item.sname}}></span></h6>
                            </div>
                        </div>
                    </div>
                );
            }).reverse();
        }
    }

    renderMechanicList() {
        const {lang} = this.context;

        if (this.state.mechanics.length === 0) {
            return (
                <div className="row">
                    <div className="col">
                        <h6><b>{localization[lang].noData}</b></h6>
                    </div>
                </div>
            );
        } else {
            return this.state.mechanics.map((item, i) => {
                return (
                    <div className="row mb-2" key={"list_" + i}>
                        <div className="col">
                            <div onClick={() => this.setState({isOpen: false, mechanic: item.mid, mechanicName: item.name}, () => this.loadList())} className="card-agis pt-2 pb-2 noselect clickable" style={{borderRadius: "15px"}}>
                                <h6><b>{item.name}</b></h6>
                            </div>
                        </div>
                    </div>
                );
            });
        }
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                {this.state.isOpen && (
                    <div className="fixed-bottom" onClick={() => this.setState({ isOpen: false })} style={{zIndex: 2000, height: "100%", backgroundColor: "#80808094"}}>
                        <div className="fixed-bottom" onClick={(e) => {e.stopPropagation()}} style={{height: "85%", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", backgroundColor: "#fff", overflowX: "auto"}}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-10 offset-md-1">
                                        <div className="container-fluid pl-0 pr-0">
                                        
                                            <br/>
                                            {this.renderMechanicList()}
                                            <br/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="container-fluid pl-0 pr-0">
                                
                                <div className="row mb-3">
                                    <div className="col d-flex justify-content-between">
                                        <button className="btn-agis-light-outline" style={{height: "36px", width: "36px", minWidth: "36px"}} onClick={this.datePrev}><FontAwesomeIcon icon={faChevronLeft}/></button>
                                        <input type="date" className="form-control form-control-sm ml-2 mr-2" style={{height: "36px", borderRadius: "10px"}} onChange={this.changeDate} value={this.dateToInputFormat(this.state.filterDate)}/>
                                        <button className="btn-agis-light-outline" style={{height: "36px", width: "36px", minWidth: "36px"}} onClick={this.dateNext}><FontAwesomeIcon icon={faChevronRight}/></button>
                                    </div>
                                </div>

                                <div onClick={() => this.setState({ isOpen: true })} className="card-agis pt-2 pb-2 mb-3" style={{borderRadius: "15px"}}>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="w-100" style={{color: "#464a4e", fontSize: "14px", lineHeight: "28px", fontSize: "1.1rem"}}>{this.state.mechanic ? this.state.mechanicName : localization[lang].choose}</div>
                                        <span className="text-muted" style={{paddingRight: "5px", height: "20px", lineHeight: "22px", minWidth: "20px", fontSize: "18px", border: "0", backgroundColor: "#fff"}}><FontAwesomeIcon icon={faChevronDown} /></span>
                                    </div>
                                </div>

                                {this.state.mechanic && this.renderList()}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ManagerStamping;