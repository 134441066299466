import React from 'react'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';
import ScaleLoader from "react-spinners/ScaleLoader";

import AppContext from '../../AppContext.js';

const localization = {
    ua: {
        title: 'Камера',
        front: 'Передня камера',
        back: 'Задня камера',
    },
    ru: {
        title: 'Камера',
        front: 'Передняя камера',
        back: 'Задняя камера',
    },
    en: {
        title: 'Camera',
        front: 'Front camera',
        back: 'Back camera',
    },
}

class Settings extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,

            videoSide: null,
            videoDevice: null,
            videoDevices: [],
            videoError: null,
            tracks: null,
            stream: null,
            code: ""
        }

        this.video = React.createRef();
        this.logContainer = React.createRef();
        this.onVideoSideChange = this.onVideoSideChange.bind(this);
        this.onVideoDeviceChange = this.onVideoDeviceChange.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        if (navigator.mediaDevices === undefined) {
            navigator.mediaDevices = {};
        }
        
        if (navigator.mediaDevices.getUserMedia === undefined) {
            navigator.mediaDevices.getUserMedia = function(constraints) {
                var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
            
                if (!getUserMedia) {
                    return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
                }
            
                return new Promise(function(resolve, reject) {
                    getUserMedia.call(navigator, constraints, resolve, reject);
                });
            }
        }
        
        navigator.mediaDevices.getUserMedia({audio: false, video: true}).then((stream) => {
            this.init();
        }).catch((err) => {
            this.setState({
                isLoading: false,
                videoError: {name: err.name + ": " + err.message}
            });
        });
    }

    componentWillUnmount() {
        if (this.state.tracks) {
            this.state.tracks.getTracks().forEach(track => track.stop());
        }
    }

    log(text) {
        var br = document.createElement("BR");
        var span = document.createElement("SPAN");
        span.appendChild(document.createTextNode(text));  
        this.logContainer.current.appendChild(span);
        this.logContainer.current.appendChild(br);
    }

    init() {
        const {videoSide, videoDevice} = this.context;

        this.log("INIT. 1");

        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
            this.log("INIT. 2");

            this.setState({
                isLoading: false,
                videoError: {name: "Camera not supported"}
            });

            return;
        }

        this.log("INIT. 3");
        
        var devicesPromise = navigator.mediaDevices.enumerateDevices();
        this.log("INIT. 4");

        axios.all([devicesPromise]).then(axios.spread((d) => {
            // this.log("INIT side: " + videoSide);
            // this.log("INIT device: " + videoDevice ? videoDevice.slice(0, 10) : "null");
            // this.log("INIT devices:");
            
            this.log("INIT. 5");

            this.log("enumerateDevices: done");
            d.filter((device) => {
                return device.kind === 'videoinput';
            }).forEach((item) => this.log(item.deviceId));

            this.setState({
                isLoading: false,
                
                videoDevices: d.filter((device) => {
                    return device.kind === 'videoinput';
                }),

                videoSide: videoSide,
                videoDevice: videoDevice,
            }, () => {
                //this.runCamera();
            });
        })).catch(err => {
            this.log("INIT. 6");

            this.log(err);

            this.setState({
                isLoading: false,
                videoError: err
            })
        });
    }

    runCamera() {
        this.log("RUN. 1");

        if (this.state.videoError == null) {
            this.video.current.srcObject = null;
        }

        this.log("Run camera");
        this.log("Video side: " + this.state.videoSide);
        this.log("Video device: " + this.state.videoDevice);

        if (this.state.videoSide && this.state.videoDevice) {
            var constraints = {
                video: {
                    facingMode: { exact: this.state.videoSide },
                    deviceId: { exact: this.state.videoDevice }
                },
                audio: false
            };
    
            navigator.mediaDevices.getUserMedia(constraints).then(res => {
                this.setState({
                    isLoading: false,
                    tracks: res, 
                    videoError: null
                }, () => {
                    this.video.current.srcObject = res;
                });
            }).catch(err => {
                console.log(err);
                this.setState({
                    isLoading: false,
                    videoError: err
                });
            });
        } else {
            this.setState({
                isLoading: false,
                videoError: {name: "No device selected"}
            });
        }
    }

    onVideoSideChange(event) {
        const {setVideoSide} = this.context;

        this.setState({videoSide: event.target.value}, () => {
            setVideoSide(this.state.videoSide);
            this.log("Set video side: " + this.state.videoSide);
        });
    }

    onVideoDeviceChange(event) {
        const {setVideoDevice} = this.context;

        this.setState({videoDevice: event.target.value}, () => {
            setVideoDevice(this.state.videoDevice);
            this.log("Set video device: " + this.state.videoDevice);
        });
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        return (
            <div>
                <DocumentMeta {...meta} />

                {this.state.isLoading && (
                    <div className="text-center">
                        <ScaleLoader height="20px" width="3px" radius="3px" margin="2px" color={"#4F81BC"} />
                    </div>
                )}

                <div className="container-fluid" style={this.state.isLoading ? {display: "none"} : {}}>
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="container-fluid pl-0 pr-0">
                                <div className="row mb-4">
                                    <div className="col-12 d-flex align-items-center">
                                        <h4 className="text-muted" style={{lineHeight: "40px"}}>{localization[lang].title}</h4>
                                    </div>
                                </div>

                                <div className="row mb-2">
                                    <div className="col">
                                        <select className="input-agis-block w-100" style={{height: "36px", borderRadius: "5px"}} value={this.state.videoSide ? this.state.videoSide : ""} onChange={this.onVideoSideChange}>
                                            <option value=""></option>
                                            <option value="environment">{localization[lang].back}</option>
                                            <option value="user">{localization[lang].front}</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col">
                                        <select className="input-agis-block w-100" style={{height: "36px", borderRadius: "5px"}} value={this.state.videoDevice ? this.state.videoDevice : ""} onChange={this.onVideoDeviceChange}>
                                            <option value=""></option>
                                            {this.state.videoDevices.map((item, i) => {
                                                return (
                                                    <option value={item.deviceId}>{item.label != "" ? item.label : "Camera " + i}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <br/>

                                <button onClick={() => this.runCamera()}>Run camera</button>

                                {this.state.videoError == null ? (
                                    <div className="row mb-2">
                                        <div className="col-12 mb-2">
                                            <video ref={this.video} className="w-100" muted={true} playsInline={true} autoPlay={true} ></video>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row mb-2">
                                        <div className="col text-center text-danger p-3">
                                            <span><b>{this.state.videoError.name}</b> {this.state.videoError.message}</span>
                                        </div>
                                    </div>
                                )}

                                <div className="row mt-2 mb-2">
                                    <div ref={this.logContainer} className="col">
                                        <span>Log:</span><br/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Settings;