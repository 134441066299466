import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faSearch, faPlus, faFilter, faChevronLeft, faChevronDown } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../AppContext.js';
import OffsetTop from '../../../components/offsetTop.js';

const localization = {
    ua: {
        title: 'Запланованi роботи механіка',
        choose: 'Виберіть механіка',
        filter: {
            date: 'Дата'
        },
        noData: 'Данi вiдсутнi',
    },
    ru: {
        title: 'Запланированные работы механика',
        choose: 'Выберите механика',
        filter: {
            date: 'Дата'
        },
        noData: 'Нет данных',
    },
    en: {
        title: 'Planned works mechanic',
        choose: 'Choose mechanic',
        filter: {
            date: 'Date'
        },
        noData: 'No data',
    },
}

class ManagerPlanning extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,

            filterDate: null,
            mechanics: [],

            isOpen: false,

            mechanic: null,
            rows: []
        }

        this.dateToInputFormat= this.dateToInputFormat.bind(this);
        this.dateToDatabaseFormat= this.dateToDatabaseFormat.bind(this);

        this.changeDate= this.changeDate.bind(this);
        this.datePrev = this.datePrev.bind(this);
        this.dateNext = this.dateNext.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        this.setState({filterDate: Date.now()}, () => {
            this.init();
        });
    }

    dateToInputFormat(date) {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
            
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
    }

    dateToDatabaseFormat(date) {
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [day, month, year].join('.');
    }

    changeDate(event) {
        this.setState({filterDate: new Date(event.target.value).getTime()}, () => {
            this.loadList();
        });
    }

    datePrev() {
        this.setState({filterDate: this.state.filterDate - 86400000}, () => {
            this.loadList();
        });
    }

    dateNext() {
        this.setState({filterDate: this.state.filterDate + 86400000}, () => {
            this.loadList();
        });
    }

    init() {
        const {addNotification, removeNotification, token} = this.context;
        
        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/service/manager/mechanics', { token: token }).then((res) => {
                this.setState({mechanics: res.data.rows, isLoading: false});
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            this.setState({
                isLoading: false,
                mechanics: [
                    {
                        mid: 1,
                        name: "Vasia",
                    },
                    {
                        mid: 2,
                        name: "Petia",
                    },
                ]
            });
        }
    }

    loadList() {
        const {addNotification, removeNotification, token} = this.context;
        
        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/service/manager/planning', { token: token, mid: this.state.mechanic, date: this.dateToDatabaseFormat(this.state.filterDate) }).then((res) => {
                this.setState({rows: res.data.rows, isLoading: false});
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            this.setState({
                isLoading: false,
                rows: [
                    {
                        item: 1,
                        start: "23.07.2020 8:00:00",
                        finish: "23.07.2020 8:15:00",
                        hours: 0.25,
                        description: "",
                        status: 1,
                        group: 1,
                    },
                    {
                        item: 2,
                        start: "23.07.2020 8:15:00",
                        finish: "23.07.2020 10:00:00",
                        hours: 1.75,
                        description: "ЗН <a href=\"https://sk.scania.ua/public/order/95681/tracker\">TR-1908790</a> вiд 22.10.2019<br/>Гелка С.В.<br/>WIELTON PC2, SUDPC200000045784, CE8222XT<br/><b>Діагностика (Несправність АБС причепа)</b>",
                        status: 201,
                        group: 2,
                    },
                    {
                        item: 3,
                        start: "23.07.2020 10:00:00",
                        finish: "23.07.2020 10:30:00",
                        hours: 0.5,
                        description: "",
                        status: 1,
                        group: 1,
                    },
                    {
                        item: 4,
                        start: "23.07.2020 10:30:00",
                        finish: "23.07.2020 12:15:00",
                        hours: 1.75,
                        description: "ЗН <a href=\"https://sk.scania.ua/public/order/95684/tracker\">CT-1908793</a> вiд 22.10.2019<br/>ОЛЛ ІНЖИНІРИНГ<br/>SCANIA G440A4X2NA, 5557630, AA0612EM<br/><b>R (R)</b>",
                        status: 201,
                        group: 2,
                    }
                ]
            });
        }
    }

    renderList() {
        const {lang} = this.context;

        if (this.state.rows.length === 0) {
            return (
                <div className="row">
                    <div className="col">
                        <h6><b>{localization[lang].noData}</b></h6>
                    </div>
                </div>
            );
        } else {
            return this.state.rows.map((item, i) => {
                return (
                    <div className="row mb-2" key={"list_" + i}>
                        <div className="col">
                            <div className="card-agis pt-2 pb-2 noselect clickable" style={item.group == 1 ? {backgroundColor: "#eee", borderRadius: "15px"} : {borderRadius: "15px"}}>
                                <big><b>{item.start.split(" ")[1] + " - " + item.finish.split(" ")[1]}</b> <span className="text-success">({item.hours})</span></big>
                                <span dangerouslySetInnerHTML={{__html: item.description}} />
                            </div>
                        </div>
                    </div>
                );
            });
        }
    }

    renderMechanicList() {
        const {lang} = this.context;

        if (this.state.mechanics.length === 0) {
            return (
                <div className="row">
                    <div className="col">
                        <h6><b>{localization[lang].noData}</b></h6>
                    </div>
                </div>
            );
        } else {
            return this.state.mechanics.map((item, i) => {
                return (
                    <div className="row mb-2" key={"list_" + i}>
                        <div className="col">
                            <div onClick={() => this.setState({isOpen: false, mechanic: item.mid, mechanicName: item.name}, () => this.loadList())} className="card-agis pt-2 pb-2 noselect clickable" style={{borderRadius: "15px"}}>
                                <h6><b>{item.name}</b></h6>
                            </div>
                        </div>
                    </div>
                );
            });
        }
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                {this.state.isOpen && (
                    <div className="fixed-bottom" onClick={() => this.setState({ isOpen: false })} style={{zIndex: 2000, height: "100%", backgroundColor: "#80808094"}}>
                        <div className="fixed-bottom" onClick={(e) => {e.stopPropagation()}} style={{height: "85%", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", backgroundColor: "#fff", overflowX: "auto"}}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-10 offset-md-1">
                                        <div className="container-fluid pl-0 pr-0">
                                        
                                            <br/>
                                            {this.renderMechanicList()}
                                            <br/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="container-fluid pl-0 pr-0">
                                
                                <div className="row mb-3">
                                    <div className="col d-flex justify-content-between">
                                        <button className="btn-agis-light-outline" style={{height: "36px", width: "36px", minWidth: "36px"}} onClick={this.datePrev}><FontAwesomeIcon icon={faChevronLeft}/></button>
                                        <input type="date" className="form-control form-control-sm ml-2 mr-2" style={{height: "36px", borderRadius: "10px"}} onChange={this.changeDate} value={this.dateToInputFormat(this.state.filterDate)}/>
                                        <button className="btn-agis-light-outline" style={{height: "36px", width: "36px", minWidth: "36px"}} onClick={this.dateNext}><FontAwesomeIcon icon={faChevronRight}/></button>
                                    </div>
                                </div>

                                <div onClick={() => this.setState({ isOpen: true })} className="card-agis pt-2 pb-2 mb-3" style={{borderRadius: "15px"}}>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="w-100" style={{color: "#464a4e", fontSize: "14px", lineHeight: "28px", fontSize: "1.1rem"}}>{this.state.mechanic ? this.state.mechanicName : localization[lang].choose}</div>
                                        <span className="text-muted" style={{paddingRight: "5px", height: "20px", lineHeight: "22px", minWidth: "20px", fontSize: "18px", border: "0", backgroundColor: "#fff"}}><FontAwesomeIcon icon={faChevronDown} /></span>
                                    </div>
                                </div>

                                {this.state.mechanic && this.renderList()}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ManagerPlanning;