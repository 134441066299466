import React from 'react'
import DocumentMeta from 'react-document-meta';
import axios from 'axios';
import ScaleLoader from "react-spinners/ScaleLoader";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../AppContext';
import OffsetTop from '../../../components/offsetTop.js';
import ScanButton from '../../../components/scanbutton.js';

const localization = {
    ua: {
        title: 'Повернення',
        chooseWorker: 'Виберіть працівника',
        chooseWorkOrder: 'Виберіть наряд',
        chooseGoods: 'Виберіть запчастину',
        chooseGoodsList: 'Натисніть для вибору',
        worker: 'Співробітник',
        workOrder: 'Заказ наряд',
        goods: 'Запчастина',
        quantity: 'Кількість',
        save: 'Повернути',
    },
    ru: {
        title: 'Возврат',
        chooseWorker: 'Выберите работника',
        chooseWorkOrder: 'Выберите наряд',
        chooseGoods: 'Выберите запчасть',
        chooseGoodsList: 'Нажмите чтобы выбрать',
        worker: 'Сотрудник',
        workOrder: 'Заказ наряд',
        goods: 'Запчасть',
        quantity: 'Количество',
        save: 'Вернуть',
    },
    en: {
        title: 'Check-in',
        chooseWorker: 'Choose worker',
        chooseWorkOrder: 'Choose work order',
        chooseGoods: 'Choose part',
        chooseGoodsList: 'Click for selecting',
        worker: 'Worker',
        workOrder: 'Work order',
        goods: 'Part',
        quantity: 'Quantity',
        save: 'Check-in',
    },
}

class CheckIn extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: false,

            workerText: "",
            worker: null,
            isWorkerLoading: false,
            isWorkerChecked: false,
            checkWorkerResult: null,

            workOrderText: "",
            workOrder: null,
            isWorkOrderLoading: false,
            isWorkOrderChecked: false,
            checkWorkOrderResult: null,

            goodsText: "",
            goods: null,
            isGoodsLoading: false,
            isGoodsChecked: false,
            checkGoodsResult: null,

            total: "0",
            totalDefault: "1",         

            errorSave: null
        }

        this.onWorkerChange = this.onWorkerChange.bind(this);
        this.onWorkerKeyPress = this.onWorkerKeyPress.bind(this);
        this.onWorkerPaste = this.onWorkerPaste.bind(this);
        this.scanWorker = this.scanWorker.bind(this);
        this.scanWorkerModal = this.scanWorkerModal.bind(this);
        this.checkWorker = this.checkWorker.bind(this);
        this.dismissWorker = this.dismissWorker.bind(this);

        this.onWorkOrderChange = this.onWorkOrderChange.bind(this);
        this.onWorkOrderKeyPress = this.onWorkOrderKeyPress.bind(this);
        this.onWorkOrderPaste = this.onWorkOrderPaste.bind(this);
        this.scanWorkOrder = this.scanWorkOrder.bind(this);
        this.scanWorkOrderModal = this.scanWorkOrderModal.bind(this);
        this.checkWorkOrder = this.checkWorkOrder.bind(this);
        this.dismissWorkOrder = this.dismissWorkOrder.bind(this);

        this.onGoodsChange = this.onGoodsChange.bind(this);
        this.onGoodsKeyPress = this.onGoodsKeyPress.bind(this);
        this.onGoodsPaste = this.onGoodsPaste.bind(this);
        this.scanGoods = this.scanGoods.bind(this);
        this.scanGoodsModal = this.scanGoodsModal.bind(this);
        this.checkGoods = this.checkGoods.bind(this);
        this.dismissGoods = this.dismissGoods.bind(this);

        this.onTotalChange = this.onTotalChange.bind(this);
        this.onTotalKeyPress = this.onTotalKeyPress.bind(this);

        this.save = this.save.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);
    }

    /*
        Worker
    */
    onWorkerChange(event) {
        if (event.target.value.length != 0) {
            this.setState({
                workerText: event.target.value, 
                isWorkerChecked: false,
                checkWorkerResult: null,
                worker: null,

                errorSave: null
            });
        } else {
            this.setState({
                workerText: event.target.value, 
                isWorkerChecked: false,
                checkWorkerResult: null,
                worker: null,

                errorSave: null
            });
        }
    }

    onWorkerKeyPress(e) {
        if (e.key === 'Enter') {
            this.setState({
                isWorkerLoading: true,
            }, () => {
                this.checkWorker();
            });
        }
    }

    onWorkerPaste() {
        this.setState({
            isWorkerLoading: true,
        }, () => {
            this.checkWorker();
        });
    }

    scanWorker(value) {
        this.setState({
            workerText: value, 
            isWorkerLoading: true,
            isWorkerChecked: false,
            checkWorkerResult: null,
            worker: null,

            errorSave: null
        }, () => {
            this.checkWorker();
        });
    }

    scanWorkerModal(status) {
        this.setState({
            isWorkerChecked: false,
            checkWorkerResult: null,
        });
    }

    checkWorker() {
        const {addNotification, removeNotification, token} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/stock/handover/check-in/check', {
                token: token,
                type: 2,
                text: this.state.workerText,
            }).then((res) => {
                console.log(res.data);

                if (res.data.rows.length == 1) {
                    this.setState({
                        isWorkerLoading: false,
                        worker: res.data.rows[0]
                    }, () => {
                        if (!this.state.workOrder) {
                            document.getElementById('workOrderInput').focus();
                        }
                    });
                } else {
                    if (res.data.rows.length != 0) {
                        document.getElementById('workerInput').blur();
                    }
                    
                    this.setState({
                        isWorkerLoading: false,
                        isWorkerChecked: true,
                        checkWorkerResult: res.data.rows
                    });
                }
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            setTimeout(() => {
                this.setState({
                    isWorkerLoading: false,
                    isWorkerChecked: true,
                    checkWorkerResult: [
                        {
                            id: 1,
                            name: "Дорошенко Євген Юрійович",
                        },
                        {
                            id: 2,
                            name: "Черніков Сергій Павлович",
                        }
                    ]
                });
            }, 1000);
        }
    }

    renderCheckWorkerResult() {
        return this.state.checkWorkerResult.map((item, i) => {
            return (
                <div className="row mt-3" key={"act_" + i}>
                    <div className="col">
                        <div onClick={() => this.pickCheckWorkerResult(item)} className="agis-card clickable noselect pt-2 pb-2" style={{borderRadius: "10px"}}>
                            <div className="row">
                                <div className="col">
                                    <h6 style={{lineHeight: "30px"}}><b>{item.name}</b></h6>
                                    {/* <big className="mt-1 mb-1">{item.name}</big> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    pickCheckWorkerResult(item) {
        this.setState({
            worker: item,
            isWorkerChecked: false,
            checkWorkerResult: null
        }, () => {
            if (!this.state.workOrder) {
                document.getElementById('workOrderInput').focus();
            }
        });
    }

    dismissWorker() {
        this.setState({
            workerText: "", 
            isWorkerLoading: false,
            isWorkerChecked: false,
            checkWorkerResult: null,
            worker: null,

            errorSave: null
        }, () => {
            document.getElementById('workerInput').focus();
        });
    }

    /*
        Work order
    */
    onWorkOrderChange(event) {
        if (event.target.value.length != 0) {
            this.setState({
                workOrderText: event.target.value, 
                isWorkOrderChecked: false,
                checkWorkOrderResult: null,
                workOrder: null,

                goodsText: "", 
                isGoodsLoading: false,
                isGoodsChecked: false,
                checkGoodsResult: null,
                goods: null,

                total: this.state.totalDefault,

                errorSave: null
            });
        } else {
            this.setState({
                workOrderText: event.target.value, 
                isWorkOrderChecked: false,
                checkWorkOrderResult: null,
                workOrder: null,

                goodsText: "", 
                isGoodsLoading: false,
                isGoodsChecked: false,
                checkGoodsResult: null,
                goods: null,

                total: this.state.totalDefault,

                errorSave: null
            });
        }
    }

    onWorkOrderKeyPress(e) {
        if (e.key === 'Enter') {
            this.setState({
                isWorkOrderLoading: true,
            }, () => {
                this.checkWorkOrder();
            });
        }
    }

    onWorkOrderPaste() {
        this.setState({
            isWorkOrderLoading: true,
        }, () => {
            this.checkWorkOrder();
        });
    }

    scanWorkOrder(value) {
        this.setState({
            workOrderText: value, 
            isWorkOrderLoading: true,
            isWorkOrderChecked: false,
            checkWorkOrderResult: null,
            workOrder: null,

            goodsText: "", 
            isGoodsLoading: false,
            isGoodsChecked: false,
            checkGoodsResult: null,
            goods: null,

            total: this.state.totalDefault,

            errorSave: null
        }, () => {
            this.checkWorkOrder();
        });
    }

    scanWorkOrderModal(status) {
        this.setState({
            isWorkOrderChecked: false,
            checkWorkOrderResult: null,
        });
    }

    checkWorkOrder() {
        const {addNotification, removeNotification, token} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/stock/handover/check-in/check', {
                token: token,
                type: 1,
                text: this.state.workOrderText,
            }).then((res) => {
                console.log(res.data);

                if (res.data.rows.length == 1) {
                    this.setState({
                        isWorkOrderLoading: false,
                        workOrder: res.data.rows[0]
                    }, () => {
                        if (!this.state.goods) {
                            document.getElementById('goodsInput').focus();
                        }
                    });
                } else {
                    if (res.data.rows.length != 0) {
                        document.getElementById('workOrderInput').blur();
                    }
                    
                    this.setState({
                        isWorkOrderLoading: false,
                        isWorkOrderChecked: true,
                        checkWorkOrderResult: res.data.rows
                    });
                }
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            setTimeout(() => {
                this.setState({
                    isWorkOrderLoading: false,
                    isWorkOrderChecked: true,
                    checkWorkOrderResult: [
                        {
                            id: 1,
                            name: "ЗН 10234",
                        },
                        {
                            id: 2,
                            name: "ЗН 20523",
                        }
                    ]
                });
            }, 1000);
        }
    }

    renderCheckWorkOrderResult() {
        return this.state.checkWorkOrderResult.map((item, i) => {
            return (
                <div className="row mt-3" key={"act_" + i}>
                    <div className="col">
                        <div onClick={() => this.pickCheckWorkOrderResult(item)} className="agis-card clickable noselect pt-2 pb-2" style={{borderRadius: "10px"}}>
                            <div className="row">
                                <div className="col">
                                    <h6 style={{lineHeight: "30px"}}><b>{item.name}</b></h6>
                                    {/* <big className="mt-1 mb-1">{item.name}</big> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    pickCheckWorkOrderResult(item) {
        this.setState({
            workOrder: item,
            isWorkOrderChecked: false,
            checkWorkOrderResult: null
        }, () => {
            if (!this.state.goods) {
                document.getElementById('goodsInput').focus();
            }
        });
    }

    dismissWorkOrder() {
        this.setState({
            workOrderText: "", 
            isWorkOrderLoading: false,
            isWorkOrderChecked: false,
            checkWorkOrderResult: null,
            workOrder: null,

            goodsText: "", 
            isGoodsLoading: false,
            isGoodsChecked: false,
            checkGoodsResult: null,
            goods: null,

            total: this.state.totalDefault,

            errorSave: null
        }, () => {
            document.getElementById('workOrderInput').focus();
        });
    }

    /*
        Goods
    */
    onGoodsChange(event) {
        if (event.target.value.length != 0) {
            this.setState({
                goodsText: event.target.value, 
                isGoodsChecked: false,
                checkGoodsResult: null,
                goods: null,

                total: this.state.totalDefault,

                errorSave: null
            });
        } else {
            this.setState({
                goodsText: event.target.value, 
                isGoodsChecked: false,
                checkGoodsResult: null,
                goods: null,

                total: this.state.totalDefault,

                errorSave: null
            });
        }
    }

    onGoodsKeyPress(e) {
        if (e.key === 'Enter') {
            this.setState({
                isGoodsLoading: true,
            }, () => {
                this.checkGoods();
            });
        }
    }

    onGoodsPaste() {
        setTimeout(() => {
            this.setState({
                isGoodsLoading: true,
            }, () => {
                this.checkGoods();
            });
        }, 100);
    }

    scanGoods(value) {
        this.setState({
            goodsText: value, 
            isGoodsLoading: true,
            isGoodsChecked: false,
            checkGoodsResult: null,
            goods: null,

            total: this.state.totalDefault,

            errorSave: null
        }, () => {
            this.checkGoods();
        });
    }

    scanGoodsModal(status) {
        this.setState({
            isGoodsChecked: false,
            checkGoodsResult: null,

            total: this.state.totalDefault,

            errorSave: null
        });
    }

    checkGoods() {
        const {addNotification, removeNotification, token} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/stock/handover/check-in/goods', { token: token, workOrderID: this.state.workOrder.id }).then((res) => {
                console.log(res.data);

                if (res.data.rows.length == 1) {
                    this.setState({
                        isGoodsLoading: false,
                        goods: res.data.rows[0],
                        total: res.data.rows[0].total
                    }, () => {
                        document.getElementById('totalInput').focus();
                    });
                } else {
                    if (res.data.rows.length != 0) {
                        document.getElementById('goodsInput').blur();
                    }

                    this.setState({
                        isGoodsLoading: false,
                        isGoodsChecked: true,
                        checkGoodsResult: res.data.rows
                    });
                }
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            setTimeout(() => {
                this.setState({
                    isGoodsLoading: false,
                    isGoodsChecked: true,
                    total: this.state.totalDefault,
                    checkGoodsResult: [
                        {
                            id: 1,
                            code: "14600",
                            name: "Наушники Talkman",
                            address: "B3C",
                            total: 11.5,
                        },
                        {
                            id: 2,
                            code: "1452366",
                            name: "Наушники Talkman 2",
                            address: "A2A",
                            total: 12,
                        }
                    ]
                });
            }, 1000);
        }
    }

    renderCheckGoodsResult() {
        const {lang} = this.context;

        return this.state.checkGoodsResult.map((item, i) => {
            return (
                <div className="row mt-3" key={"act_" + i}>
                    <div className="col">
                        <div onClick={() => this.pickCheckGoodsResult(item)} className="agis-card clickable noselect pt-2 pb-2" style={{borderRadius: "10px"}}>
                            <div className="row">
                                <div className="col">
                                    <h6><b>{item.name}</b></h6>
                                    <div className="d-flex justify-content-between mt-1 mb-1">
                                        <div className="d-flex justify-content-between" style={{minWidth: "190px"}}>
                                            <big>{item.code}</big>
                                            <big>{item.total + " шт"}</big>
                                        </div>
                                        <big>{item.address}</big>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    pickCheckGoodsResult(item) {
        this.setState({
            goods: item,
            isGoodsChecked: false,
            checkGoodsResult: null,
            total: item.total
        }, () => {
            document.getElementById('totalInput').focus();
        });
    }

    dismissGoods() {
        this.setState({
            goodsText: "", 
            isGoodsLoading: false,
            isGoodsChecked: false,
            checkGoodsResult: null,
            goods: null,

            total: this.state.totalDefault,

            errorSave: null
        }, () => {
            document.getElementById('goodsInput').focus();
        });
    }

    /*
        Confirm
    */
    onTotalChange(event){
        this.setState({total: event.target.value});
    }

    onTotalKeyPress(e) {
        if (e.key === 'Enter') {
            document.getElementById('totalInput').blur();
        }
    }

    save() {
        const {addNotification, removeNotification, token, lang} = this.context;

        var u = addNotification("Завантаження...");

        axios.post('/api/stock/handover/check-in/confirm', {
            token: token,
            workOrderID: this.state.workOrder.id,
            workerID:    this.state.worker.id,
            goodsID:     this.state.goods.id,
            total:       this.state.total,
        }).then((res) => {
            if (res.data.result > 0) {
                this.setState({
                    goodsText: "", 
                    isGoodsLoading: false,
                    isGoodsChecked: false,
                    checkGoodsResult: null,
                    goods: null,

                    total: this.state.totalDefault,

                    errorSave: null
                }, () => {
                    document.getElementById('goodsInput').focus();
                });
            } else {
                this.setState({errorSave: res.data.error});
            }
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
            this.setState({errorSave: error.response.data});
        }).finally(() => {
            removeNotification(u);
        });
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                {this.state.isLoading && (
                    <div className="text-center">
                        <ScaleLoader height="20px" width="3px" radius="3px" margin="2px" color={"#4F81BC"} />
                    </div>
                )}

                {(this.state.isWorkerChecked && this.state.checkWorkerResult.length > 1) && (
                    <div className="fixed-bottom" onClick={this.scanWorkerModal} style={{zIndex: 2000, height: "100%", backgroundColor: "#80808094"}}>
                        <div className="fixed-bottom" onClick={(e) => {e.stopPropagation()}} style={{height: "85%", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", backgroundColor: "#fff", overflowX: "auto"}}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-10 offset-md-1">
                                        <div className="container-fluid pl-0 pr-0">
                                        
                                            <br/>

                                            <div className="row mb-4">
                                                <div className="col d-flex align-items-center">
                                                    <h5 className="text-muted" style={{lineHeight: "40px"}}>{localization[lang].chooseWorker}</h5>
                                                </div>
                                            </div>

                                            {this.renderCheckWorkerResult()}
                                            <br/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {(this.state.isWorkOrderChecked && this.state.checkWorkOrderResult.length > 1) && (
                    <div className="fixed-bottom" onClick={this.scanWorkOrderModal} style={{zIndex: 2000, height: "100%", backgroundColor: "#80808094"}}>
                        <div className="fixed-bottom" onClick={(e) => {e.stopPropagation()}} style={{height: "85%", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", backgroundColor: "#fff", overflowX: "auto"}}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-10 offset-md-1">
                                        <div className="container-fluid pl-0 pr-0">
                                        
                                            <br/>

                                            <div className="row mb-4">
                                                <div className="col d-flex align-items-center">
                                                    <h5 className="text-muted" style={{lineHeight: "40px"}}>{localization[lang].chooseWorkOrder}</h5>
                                                </div>
                                            </div>

                                            {this.renderCheckWorkOrderResult()}
                                            <br/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {(this.state.isGoodsChecked && this.state.checkGoodsResult.length > 1) && (
                    <div className="fixed-bottom" onClick={this.scanGoodsModal} style={{zIndex: 2000, height: "100%", backgroundColor: "#80808094"}}>
                        <div className="fixed-bottom" onClick={(e) => {e.stopPropagation()}} style={{height: "85%", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", backgroundColor: "#fff", overflowX: "auto"}}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-10 offset-md-1">
                                        <div className="container-fluid pl-0 pr-0">
                                        
                                            <br/>

                                            <div className="row mb-4">
                                                <div className="col d-flex align-items-center">
                                                    <h5 className="text-muted" style={{lineHeight: "40px"}}>{localization[lang].chooseGoods}</h5>
                                                </div>
                                            </div>

                                            {this.renderCheckGoodsResult()}
                                            <br/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="container-fluid" style={this.state.isLoading ? {display: "none"} : {}}>
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            {this.state.worker ? (
                                <div className="agis-card pt-2 pb-2 mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="w-100">
                                            <span className="text-muted">{localization[lang].worker}</span>
                                            <h6><b>{this.state.worker.name}</b></h6>
                                        </div>
                                        <div style={{height: "20px", width: "40px", textAlign: "center"}}>
                                            <button onClick={this.dismissWorker} className="text-muted" style={{height: "20px", minWidth: "20px", fontSize: "18px", border: "0", backgroundColor: "#fff"}}><svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10 fa-1x"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path></svg></button>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="d-flex align-items-center justify-content-between pt-2 pb-2 mb-3">
                                    <div className="agis-card">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="w-100">
                                                <span className="text-muted">{localization[lang].worker}</span>
                                                <input
                                                    id="workerInput"
                                                    type="text"
                                                    className="w-100"
                                                    style={{border: "0", color: "#464a4e", fontSize: "14px", lineHeight: "15px", fontSize: "1.1rem"}}
                                                    value={this.state.workerText}
                                                    onChange={this.onWorkerChange}
                                                    //onBlur={this.onRemarkBlur}
                                                    onKeyPress={this.onWorkerKeyPress}
                                                    onPaste={this.onWorkerPaste}
                                                    autoFocus={true}
                                                />
                                            </div>
                                            <div style={{height: "20px", width: "40px", textAlign: "center"}}>
                                                <button onClick={() => this.setState({ isWorkerLoading: true }, () => { this.checkWorker() })} className="text-muted" style={{height: "20px", minWidth: "20px", fontSize: "18px", border: "0", backgroundColor: "#fff"}}><FontAwesomeIcon icon={faSearch} /></button>
                                            </div>
                                        </div>
                                    </div>
                                    <ScanButton onScan={this.scanWorker} />
                                </div>
                            )}

                            {this.state.workOrder ? (
                                <div className="agis-card pt-2 pb-2 mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="w-100">
                                            <span className="text-muted">{localization[lang].workOrder}</span>
                                            <h6><b>{this.state.workOrder.name}</b></h6>
                                        </div>
                                        <div style={{height: "20px", width: "40px", textAlign: "center"}}>
                                            <button onClick={this.dismissWorkOrder} className="text-muted" style={{height: "20px", minWidth: "20px", fontSize: "18px", border: "0", backgroundColor: "#fff"}}><svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10 fa-1x"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path></svg></button>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="d-flex align-items-center justify-content-between pt-2 pb-2 mb-3">
                                    <div className="agis-card">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="w-100">
                                                <span className="text-muted">{localization[lang].workOrder}</span>
                                                <input
                                                    id="workOrderInput"
                                                    type="text"
                                                    className="w-100"
                                                    style={{border: "0", color: "#464a4e", fontSize: "14px", lineHeight: "15px", fontSize: "1.1rem"}}
                                                    value={this.state.workOrderText}
                                                    onChange={this.onWorkOrderChange}
                                                    //onBlur={this.onRemarkBlur}
                                                    onKeyPress={this.onWorkOrderKeyPress}
                                                    onPaste={this.onWorkOrderPaste}
                                                    //autoFocus={true}
                                                />
                                            </div>
                                            <div style={{height: "20px", width: "40px", textAlign: "center"}}>
                                                <button onClick={() => this.setState({ isWorkOrderLoading: true }, () => { this.checkWorkOrder() })} className="text-muted" style={{height: "20px", minWidth: "20px", fontSize: "18px", border: "0", backgroundColor: "#fff"}}><FontAwesomeIcon icon={faSearch} /></button>
                                            </div>
                                        </div>
                                    </div>
                                    <ScanButton onScan={this.scanWorkOrder} />
                                </div>
                            )}

                            {this.state.workOrder && (
                                <div>
                                    {this.state.goods ? (
                                        <div className="agis-card pt-2 pb-2 mb-3">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="w-100">
                                                    <span className="text-muted">{localization[lang].goods}</span>
                                                    <h6><b>{this.state.goods.name}</b></h6>
                                                    <big className="mt-1 mb-1"><span className="mr-2">{this.state.goods.code}</span> <span className="mr-4">{this.state.goods.total + " шт"}</span> <span>{this.state.goods.address}</span></big>
                                                </div>
                                                <div style={{height: "20px", width: "40px", textAlign: "center"}}>
                                                    <button onClick={this.dismissGoods} className="text-muted" style={{height: "20px", minWidth: "20px", fontSize: "18px", border: "0", backgroundColor: "#fff"}}><svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10 fa-1x"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path></svg></button>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="d-flex align-items-center justify-content-between pt-2 pb-2 mb-3">
                                            <div className="agis-card">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div className="w-100">
                                                        <span className="text-muted">{localization[lang].goods}</span>
                                                        <input
                                                            id="goodsInput"
                                                            type="text"
                                                            className="w-100"
                                                            style={{border: "0", color: "#464a4e", fontSize: "14px", lineHeight: "15px", fontSize: "1.1rem"}}
                                                            value={localization[lang].chooseGoodsList}
                                                            onClick={this.checkGoods}
                                                            //onBlur={this.onRemarkBlur}
                                                            //onKeyPress={this.onGoodsKeyPress}
                                                            //onPaste={this.onGoodsPaste}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                    <div style={{height: "20px", width: "40px", textAlign: "center"}}>
                                                        <button onClick={() => this.setState({ isGoodsLoading: true }, () => { this.checkGoods() })} className="text-muted" style={{height: "20px", minWidth: "20px", fontSize: "18px", border: "0", backgroundColor: "#fff"}}><FontAwesomeIcon icon={faSearch} /></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    
                                    {this.state.goods && (
                                        <div className="agis-card pt-2 pb-2 mb-3">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="w-100">
                                                    <span className="text-muted">{localization[lang].quantity}</span>
                                                    <input 
                                                        id="totalInput"
                                                        type="number"
                                                        className="w-100"
                                                        style={{border: "0", color: "#464a4e", fontSize: "14px", lineHeight: "15px", fontSize: "1.1rem"}}
                                                        value={this.state.total} 
                                                        onChange={this.onTotalChange}
                                                        onKeyPress={this.onTotalKeyPress}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                            {this.state.errorSave && (
                                <div className="mb-3">
                                    <span style={{fontSize: "1rem"}}><span className="text-danger"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation-triangle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-exclamation-triangle fa-w-18 fa-1x"><path fill="currentColor" d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z" class=""></path></svg></span> <b>{this.state.errorSave}</b></span>
                                </div>
                            )}

                            {this.state.worker && this.state.workOrder && this.state.goods && this.state.total && (
                                <button onClick={this.save} className="btn-agis-primary w-100" style={{backgroundColor: "#4F81BC"}}>{localization[lang].save}</button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CheckIn;