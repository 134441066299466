import React from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../AppContext.js';
import OffsetTop from '../../../components/offsetTop.js';

const localization = {
    ua: {
        title: 'Анулювання заявки',
        header: 'Дані для анулювання',
        remark: 'Причина',
        button: 'Анулювати',
    },
    ru: {
        title: 'Аннулирование заявки',
        header: 'Данные для аннулирование',
        remark: 'Примечание',
        button: 'Аннулировать',
    },
    en: {
        title: 'Анулювання заявки',
        header: 'Дані для анулювання',
        remark: 'Примітка',
        button: 'Анулювати',
    },
}

class ServiceRequestCancel extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            redirect: null,
            did: null,
            remark: "",
        }

        this.onInputChange = this.onInputChange.bind(this);

        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        this.setState({did: this.props.match.params.did});
    }

    onInputChange(e, field) {
        switch (field) {
            case "remark":
                this.setState({remark: e.target.value});
                break
        }
    }

    cancel() {
        const {addNotification, removeNotification, token} = this.context;

        var u = addNotification("Завантаження...");
        axios.post('/api/service/request/cancel', { 
            token: token,
            did: this.state.did,
            remark: this.state.remark,
        }).then((res) => {
            if (res.data.serror == "") {
                this.setState({redirect: "/service/requests"});
            } else {
                addNotification(res.data.serror, 2);
            }
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    render() {
        const {lang} = this.context;

        if (this.state.redirect !== null) {
            return <Redirect to={this.state.redirect} />
        }

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            
                            <div className="card agis-card pt-1 pb-1 mt-3" style={{backgroundColor: '#f1f3f4'}}>
                                <div className="row">
                                    <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                        <div>
                                            <h6><b>{localization[lang].header}</b></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card agis-card">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].remark}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col d-flex justify-content-between">
                                        <input type="text" autoComplete="new-password" className="agis-input" onChange={(e) => this.onInputChange(e, "remark")} value={this.state.remark} />
                                    </div>
                                </div>
                            </div>

                            <button className="btn-agis-primary mt-3 w-100" onClick={this.cancel}>{localization[lang].button}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ServiceRequestCancel;