import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import uuid from 'uuid';

import BarLoader from "react-spinners/BarLoader";

import AppContext from "./AppContext.js";
import Menu from './components/menu.js';
import Notification from './components/notification.js';
import NewVersion from './components/newVerion.js';

import Login from './pages/login.js';

import Home from './pages/home.js';
import Profile from './pages/profile.js';
import SettingsCamera from './pages/settings/camera.js';

import Orders from './pages/order/list.js';
import OrderNew from './pages/order/new.js';
import OrderCard from './pages/order/card.js';
import OrderRecommendations from './pages/order/recommendations.js';
import OrderAbout from './pages/order/about.js';
import OrderSummary from './pages/order/summary.js';

import OrderStampingHistory from './pages/order/stamping/history.js';
import OrderStampingPin from './pages/order/stamping/pin.js';

import CheckListMechanicList   from './pages/order/check-list-mechanic/list.js';
import CheckListMechanicNew    from './pages/order/check-list-mechanic/new.js';
import CheckListMechanicEdit   from './pages/order/check-list-mechanic/edit.js';

import ControlListManagerList from './pages/order/control-list-manager/list.js';
import ControlListManagerEdit from './pages/order/control-list-manager/edit.js';

import WorkshopInspectionList from './pages/workshop/inspection/list.js';
import WorkshopInspectionNew  from './pages/workshop/inspection/new.js';
import WorkshopInspectionEdit from './pages/workshop/inspection/edit.js';

import StashList from './pages/stash/list.js';
import StashDocs from './pages/stash/docs.js';
import StashDoc  from './pages/stash/doc.js';

import ServiceMechanicPlanning from './pages/service/mechanic/planning.js';

import ServiceManagerStamping from './pages/service/manager/stamping.js';
import ServiceManagerPlanning from './pages/service/manager/planning.js';

import ServiceRequests from './pages/service/request/requests.js';
import ServiceRequestNew from './pages/service/request/new.js';
import ServiceRequestEdit from './pages/service/request/edit.js';
import ServiceRequestCancel from './pages/service/request/cancel.js';

import StockDetail   from './pages/stock/detail.js';
import StockRelocate from './pages/stock/relocate.js';

import TerritoryVehicles from './pages/territory/vehicles.js';
import TerritoryVehiclesNew from './pages/territory/new.js';

import StockInventorizationList    from './pages/stock/inventorization/list.js';
import StockInventorizationRecount from './pages/stock/inventorization/recount.js';

import StockReceiptList    from './pages/stock/receipt/list.js';
import StockReceiptRecount from './pages/stock/receipt/recount.js';

import CRMDriverFeedbackNPSAndSatisfaction        from './pages/crm/driver-feedback/nps-and-satisfaction.js';
import CRMDriverFeedbackTextAnswers               from './pages/crm/driver-feedback/text-answers.js';
import CRMDriverFeedbackResponseCard              from './pages/crm/driver-feedback/response-card.js';
import CRMDriverFeedbackActionList                from './pages/crm/driver-feedback/action-list.js';
import CRMDriverFeedbackСonsiderCard              from './pages/crm/driver-feedback/consider-card.js';
import CRMDriverFeedbackResultsPerWorkshop        from './pages/crm/driver-feedback/results-per-workshop.js';
import CRMDriverFeedbackSampleSummary             from './pages/crm/driver-feedback/sample-summary.js';
import CRMDriverFeedbackReasonsForDissatisfaction from './pages/crm/driver-feedback/reasons-for-dissatisfaction.js';

import StockHandoverCheckOut from './pages/stock/handover/check-out.js';
import StockHandoverCheckIn  from './pages/stock/handover/check-in.js';

import DriverExpensesList from './pages/driver/expenses/list.js';
import DriverExpensesCard from './pages/driver/expenses/card.js';
import DriverExpensesNew from './pages/driver/expenses/new.js';
import DriverExpensesGeneral from './pages/driver/expenses/general.js';

import './css/bootstrap.min.css';
import './css/app.css';
import './css/agis.css';
import './css/agis/btn-agis-link.css';
import './css/agis/btn-agis-primary.css';
import './css/agis/buttons.css';
import './css/agis/links.css';
import './css/agis/card.css';
import './css/agis/input.css';
import './css/error.css';
import './css/special/question-input.css';

import './css/menu.css';
import './css/position-component.css'

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isAppInit: false,
            isUpdate: false,

            token: null,
            access: [],
            username: null,
            lang: null,
            scale: null,
            height: null,
            width: null,
            online: null,

            videoSide: null,
            videoDevice: null,

            menuExpand: true,
            menuExpandText: '',
            notifications: [],

            authLogin: (token, access, username, lang) => {
                if (token != undefined && access != undefined && username != undefined && lang != undefined) {
                    localStorage.setItem("token", token);
                    localStorage.setItem("access", access);
                    localStorage.setItem("username", username);
                    localStorage.setItem("lang", lang);
    
                    this.setState({
                        token: token,
                        access: decodeURIComponent(access).split(';'),
                        username: username,
                        lang: lang,
                    });
                } else {
                    console.log("Undefined error!");
                }
            },

            authLogout: () => {
                localStorage.removeItem("token");
                localStorage.removeItem("access");
                localStorage.removeItem("username");
                localStorage.removeItem("lang");

                this.setState({token: null, access: [], username: null, lang: "ua"});
            },

            settingsLang: (lang) => {
                localStorage.setItem("lang", lang);
                this.setState({lang: lang});
            },

            setMenuExpand: (status) => {
                this.setState({menuExpand: status});
            },

            setMenuExpandText: (text) => {
                window.scrollTo(0, 0);
                this.setState({menuExpand: false, menuExpandText: text});
            },

            addNotification: (text, type) => {
                if (type === undefined) {
                    type = 1;
                }

                if (type == 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("access");
                    localStorage.removeItem("username");
                    localStorage.removeItem("lang");
    
                    this.setState({ token: null });
                }
                
                var u = uuid();
                var object = {id: u, type: type, text: text};
        
                var newNotifications = this.state.notifications;
                newNotifications.push(object);
                this.setState({notifications: newNotifications});
                return u;
            },

            removeNotification: (id) => {
                var newNotifications = [];
                for (var i = 0; i < this.state.notifications.length; i++) {
                    if (this.state.notifications[i].id !== id) {
                        newNotifications.push(this.state.notifications[i]);
                    }
                }
                this.setState({notifications: newNotifications});
            },

            setVideoSide: (side) => {
                console.log("setVideoSide " + side);

                localStorage.setItem("videoSide", side);
                this.setState({videoSide: side});
            },

            setVideoDevice: (device) => {
                console.log("setVideoDevice " + device);

                localStorage.setItem("videoDevice", device);
                this.setState({videoDevice: device});
            },
        }
    }

    componentDidMount() {
        var token;
        var access;
        var username;
        var lang;
        var scale;
        var height;
        var width;

        var videoSide;
        var videoDevice;
        
        //init token
        token = localStorage.getItem("token");

        //init access
        var accessData = localStorage.getItem("access");
        if (accessData == null) {
            if (process.env.NODE_ENV === 'production') {
                access = [];
            } else {
                access = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '10005', '12', '18'];
            }
        } else {
            access = decodeURIComponent(accessData).split(';');
        }

        //init username
        var usernameData = localStorage.getItem("username");
        if (usernameData == null) {
            username = 'Test build';
        } else {
            username = decodeURIComponent(usernameData);
        }
        
        //init lang
        var lang = localStorage.getItem("lang");
        if (lang == null) {
            lang = 'ua';
        }

        //init scale
        height = window.innerHeight;
        width = window.innerWidth;
		if (width >= 1200) {
			scale = 'xl';
		} else if (width > 993) {
			scale = 'lg';
		} else if (width > 768) {
			scale = 'md';
		} else if (width > 576) {
			scale = 'sm';
		} else {
			scale = 'xs';
        }

        //init videoDevice
        var videoSide   = localStorage.getItem("videoSide");
        var videoDevice = localStorage.getItem("videoDevice");

        window.addEventListener("resize", this.updateDimensions.bind(this));
        window.addEventListener('message', this.receiveMessage.bind(this));
        window.addEventListener('online',  this.updateOnlineStatus.bind(this));
        window.addEventListener('offline', this.updateOnlineStatus.bind(this));

        this.setState({
            token: token,
            access: access,
            username: username,
            lang: lang,
            scale: scale,
            height: height,
            width: width,

            videoSide: videoSide,
            videoDevice: videoDevice,
            
            online: navigator.onLine,
            isAppInit: true
        });
    }

    receiveMessage(event) {
        switch (event.data) {
            case "NEW_VERSION":
                this.setState({isUpdate: true});
                break;
            default: 
                break;
        }
    }

    updateOnlineStatus() {
        this.setState({online: navigator.onLine});
    }

    updateDimensions() {
        var height = window.innerHeight;
        var width = window.innerWidth;
        var scale;
        
		if (width >= 1200) {
			scale = 'xl';
		} else if (width > 993) {
			scale = 'lg';
		} else if (width > 768) {
			scale = 'md';
		} else if (width > 576) {
			scale = 'sm';
		} else {
			scale = 'xs';
        }

        this.setState({scale: scale, height: height, width: width});
    }

    render() {
        var checkListMechanicRoutes = [
            <Route exact  path={`/order/check-list-mechanic/new`}  component={CheckListMechanicNew} />,
            <Route strict path={`/order/check-list-mechanic/:did`} component={CheckListMechanicEdit} />,
            <Route exact  path={`/order/check-list-mechanic`}      component={CheckListMechanicList} />
        ];

        var controlListManagerRoutes = [
            <Route strict path={`/order/control-list-manager/:did`} component={ControlListManagerEdit} />,
            <Route exact  path={`/order/control-list-manager`}      component={ControlListManagerList} />
        ];

        var InspectionWorkshopRoutes = [
            <Route exact  path={`/workshop/inspection`}      component={WorkshopInspectionList} />,
            <Route exact  path={`/workshop/inspection/new`}  component={WorkshopInspectionNew} />,
            <Route strict path={`/workshop/inspection/:did`} component={WorkshopInspectionEdit} />
        ];

        var StampingRoutes = [
            <Route exact path={`/order/stamping/history`}  component={OrderStampingHistory} />,
            <Route exact path={`/order/stamping/pin/:gid`} component={OrderStampingPin} />,
            <Route exact path={`/order/stamping/pin`}      component={OrderStampingPin} />,
        ];

        var OrderRoutes = [
            // <Route strict path={`/order/new`}        component={OrderNew} />,
            <Route strict path={`/order/:did/card/:item`} component={OrderCard} />,
            <Route strict path={`/order/:did/card`}       component={OrderCard} />,
            <Route strict path={`/order/:did/summary`}    component={OrderSummary} />,

            <Route exact path={`/orders`} component={Orders} />
        ];

        var StashDocsRoutes = [
            <Route strict path={`/stash/doc/:did`} component={StashDoc} />,
            <Route exact  path={`/stash/docs`}     component={StashDocs} />
        ]

        var StashRoutes = [
            <Route exact path={`/stash`} component={StashList} />
        ]

        var DriverFeedbackRoutes = [
            <Route exact path={`/crm/driver-feedback/nps-and-satisfaction`} component={CRMDriverFeedbackNPSAndSatisfaction} />,
            <Route exact path={`/crm/driver-feedback/text-answers`} component={CRMDriverFeedbackTextAnswers} />,
            <Route exact path={`/crm/driver-feedback/response-card/:id`} component={CRMDriverFeedbackResponseCard} />,
            <Route exact path={`/crm/driver-feedback/action-list`} component={CRMDriverFeedbackActionList} />,
            <Route exact path={`/crm/driver-feedback/consider-card/:id`} component={CRMDriverFeedbackСonsiderCard} />,
            <Route exact path={`/crm/driver-feedback/results-per-workshop`} component={CRMDriverFeedbackResultsPerWorkshop} />,
            <Route exact path={`/crm/driver-feedback/sample-summary`} component={CRMDriverFeedbackSampleSummary} />,
            <Route exact path={`/crm/driver-feedback/reasons-for-dissatisfaction`} component={CRMDriverFeedbackReasonsForDissatisfaction} />
        ]

        var ExpensesDriverRoutes = [
            <Route exact  path={`/driver/expenses`} component={DriverExpensesList} />,
            <Route strict path={`/driver/expenses/:did/:cid/:tid`} component={DriverExpensesGeneral} />,
            <Route strict path={`/driver/expenses/new/:did`} component={DriverExpensesNew} />,
            <Route strict path={`/driver/expenses/:did`} component={DriverExpensesCard} />,
        ];

        if (this.state.isAppInit) {

            if (this.state.isUpdate) {
                // var text;

                // switch (this.stete.lang) {
                //     case "ua":
                //         text = "Оновлення...";
                //         break;
                //     case "ru":
                //         text = "Обновление...";
                //         break;
                //     default: 
                //         text = "Updating...";
                //         break;
                // }

                return (
                    <div style={{
                        display: "block",
                        position: "fixed",
        
                        left: "0px",
                        top: "0px",
                        right: "0px",
                        bottom: "0px",
        
                        backgroundColor: "#fff",
                    }}>
                        <div style={{height: "100%", width: "100%"}} className="d-flex align-items-center justify-content-center">
                            <div className="text-center">
                                <BarLoader width="200" color={"#4F81BC"} />

                                <h6 className="text-muted mt-2"><b>Updating...</b></h6>
                            </div>
                        </div>
                    </div>
                )
            }

            //if token exist
            if (this.state.token != null) {
                return (
                    <AppContext.Provider value={{...this.state}}>
                        <BrowserRouter basename={"/"}>
                            <div>
                                <Menu />
                                <Notification />
                                {/* <NewVersion /> */}
                            
                                <Switch>
                                    {/* Чек-листы механика */}
                                    {this.state.access.includes('1') && checkListMechanicRoutes.map((routes) =>
                                        routes
                                    )}

                                    {/* Контрольные листы менеджера */}
                                    {this.state.access.includes('2') && controlListManagerRoutes.map((routes) =>
                                        routes
                                    )}

                                    {/* Осмотр ремзоны */}
                                    {this.state.access.includes('4') && InspectionWorkshopRoutes.map((routes) =>
                                        routes
                                    )}

                                    {/* Стэмпинг */}
                                    {this.state.access.includes('5') && StampingRoutes.map((routes) =>
                                        routes
                                    )}

                                    {/* ЗН */}
                                    <Route strict path={`/order/:did/recommendations`} component={OrderRecommendations} />
                                    <Route strict path={`/order/:did/about`}           component={OrderAbout} />
                                   
                                    {(this.state.access.includes('6001') || this.state.access.includes('6002')) && (
                                        <Route strict path={`/order/new`} component={OrderNew} />
                                    )}

                                    {this.state.access.includes('6') && OrderRoutes.map((routes) =>
                                        routes
                                    )}

                                    {/* Выдано под отчёт акты */}
                                    {this.state.access.includes('8') && StashDocsRoutes.map((routes) =>
                                        routes
                                    )}

                                    {/* Выдано под отчёт */}
                                    {this.state.access.includes('7') && StashRoutes.map((routes) =>
                                        routes
                                    )}

                                    {/* Планирование механика */}
                                    {this.state.access.includes('9') && (
                                        <Route exact path={`/mechanic/planning`} component={ServiceMechanicPlanning} />
                                    )}

                                    {this.state.access.includes('10') && (
                                        <Route strict path={`/stock/detail/:code`} component={StockDetail} />
                                    )} 

                                    {/* Информация о запчасти */}
                                    {this.state.access.includes('10') && (
                                        <Route exact path={`/stock/detail`} component={StockDetail} />
                                    )}

                                    {this.state.access.includes('10005') && (
                                        <Route strict path={`/stock/relocate/:code`} component={StockRelocate} />
                                    )} 

                                    {/* Движение техникик по СТО */}
                                    {this.state.access.includes('12') && (
                                        <Route exact path={`/territory/vehicles`} component={TerritoryVehicles} />
                                    )} 
                                    {this.state.access.includes('12001') && (
                                        <Route exact path={`/territory/vehicle/new`} component={TerritoryVehiclesNew} />
                                    )} 

                                    {/* Планирование механика - интерфейс менеджера */}
                                    {this.state.access.includes('14') && (
                                        <Route exact path={`/service/manager/stamping`} component={ServiceManagerStamping} />
                                    )}

                                    {this.state.access.includes('14') && (
                                        <Route exact path={`/service/manager/planning`} component={ServiceManagerPlanning} />
                                    )}

                                    {/* Заявки на ремонт механика */}
                                    {this.state.access.includes('20') && (
                                        <Route exact path={`/service/requests`} component={ServiceRequests} />
                                    )} 
                                    {this.state.access.includes('20') && (
                                        <Route exact path={`/service/request/new`} component={ServiceRequestNew} />
                                    )} 
                                    {this.state.access.includes('20') && (
                                        <Route strict path={`/service/request/edit/:did`} component={ServiceRequestEdit} />
                                    )} 
                                    {this.state.access.includes('20') && (
                                        <Route strict path={`/service/request/cancel/:did`} component={ServiceRequestCancel} />
                                    )} 

                                    {/* Инвентаризация */}
                                    {this.state.access.includes('15') && (
                                        <Route exact path={`/stock/inventorization/list`} component={StockInventorizationList} />
                                    )}

                                    {this.state.access.includes('15') && (
                                        <Route strict path={`/stock/inventorization/recount/:did`} component={StockInventorizationRecount} />
                                    )}

                                    {/* Пересчёт */}
                                    {this.state.access.includes('16') && (
                                        <Route exact path={`/stock/receipt/list`} component={StockReceiptList} />
                                    )}

                                    {this.state.access.includes('16') && (
                                        <Route strict path={`/stock/receipt/recount/:did`} component={StockReceiptRecount} />
                                    )}

                                    {/* Выдача Возврат */}
                                    {this.state.access.includes('17') && (
                                        <Route strict path={`/stock/handover/check-out`} component={StockHandoverCheckOut} />
                                    )}

                                    {this.state.access.includes('11') && (
                                        <Route strict path={`/stock/handover/check-in`}  component={StockHandoverCheckIn} />
                                    )}

                                    {/* Driver Feedback */}
                                    {this.state.access.includes('18') && DriverFeedbackRoutes.map((routes) =>
                                        routes
                                    )}

                                    {/* Driver Expenses */}
                                    {this.state.access.includes('19') && ExpensesDriverRoutes.map((routes) =>
                                        routes
                                    )}

                                    <Route exact path={`/profile`} component={Profile} />
                                    <Route exact path={`/settings/camera`} component={SettingsCamera} />

                                    {/* <Route exact path={`/`} component={this.state.access.includes('6') ? Orders : Home} /> */}
                                    <Route exact path={`/`} component={Home} />
                                </Switch>

                                <br/><br/><br/>
                            </div>
                        </BrowserRouter>
                    </AppContext.Provider>
                );
            } else {
                return (
                    <AppContext.Provider value={{...this.state}}>
                        <BrowserRouter basename={"/"}>
                            <div>
                                {/* <MenuPublic /> */}
                                <Notification />
                            
                                <Switch>
                                    <Route exact path={`/`} component={Login} />
                                    <Route render={() => <Redirect to="/" />} />
                                </Switch>

                                <br/><br/>
                            </div>
                        </BrowserRouter>
                    </AppContext.Provider>
                );
            }
        }

        //Loading screen
        return (
            <div></div>
        );
    }
}

export default App;