import React from 'react'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';
import ScaleLoader from "react-spinners/ScaleLoader";

import AppContext from '../../../AppContext.js';
import Submenu from '../../../components/submenu.js';
import SubmenuContent from './submenu.js';
import OffsetTop from '../../../components/offsetTop.js';

import {dateToInputFormat, dateToDatabaseFormat} from '../../../utils/datetime.js';

import Graph from "../../../components/graph.js";

const localization = {
    ua: {
        title: 'Driver Feedback',
        site: 'Дилер',
        from: 'Від',
        to: 'До',
        lastMonth: 'Попередній місяць',
        currentWeek: 'Поточний тиждень',
        currentMonth: 'Поточний місяць',
        table: {
            date: 'Дата візиту',
            workshop: 'Дилер',
            driver: 'Водій',
            phone: 'Телефон',
            company: 'Компанія',
            reasons: {
                title: 'Причини',
                reason1: 'Привітання та прийом',
                reason2: 'Наявність послуги (можливість зарезервувати візит)',
                reason3: 'Час ремонту',
                reason4: 'Якість ремонту (повторне відвідування, неповне тощо)',
                reason5: 'Технічні / комерційні навички персоналу',
                reason6: 'Стан транспортного засобу при поверненні',
                reason7: 'Ціна',
                reason8: 'Інформація (про роботу, затримки, додаткові роботи / витрати)',
                reason9: 'Години роботи',
                reason10: 'Інше',
            }
        }
    },
    ru: {
        title: 'Driver Feedback',
        site: 'Дилер',
        from: 'С',
        to: 'По',
        lastMonth: 'Предыдущий месяц',
        currentWeek: 'Текущая неделя',
        currentMonth: 'Текущий месяц',
        table: {
            date: 'Дата визита',
            workshop: 'Дилер',
            driver: 'Водитель',
            phone: 'Телефон',
            company: 'Компания',
            reasons: {
                title: 'Причины',
                reason1: 'Приветствия и прием',
                reason2: 'Доступность услуги (возможность записи на визит)',
                reason3: 'Время ремонта',
                reason4: 'Качество ремонта (повторный визит, неполный и т.д)',
                reason5: 'Технические / коммерческие навыки персонала мастерской',
                reason6: 'Состояние автомобиля при возврате',
                reason7: 'Цена',
                reason8: 'Информация (о работе, задержках, дополнительных работах / затратах)',
                reason9: 'Часы работы',
                reason10: 'Другое',
            }
        }
    },
    en: {
        title: 'Driver Feedback',
        site: 'Site',
        from: 'From',
        to: 'To',
        lastMonth: 'Last month',
        currentWeek: 'Current week',
        currentMonth: 'Current month',
        table: {
            date: 'Visit date',
            workshop: 'Workshop',
            driver: 'Driver',
            phone: 'Phone',
            company: 'Company',
            reasons: {
                title: 'Reasons',
                reason1: 'Greetings & reception',
                reason2: 'Availability of service (possibility to book appointment)',
                reason3: 'Repair time',
                reason4: 'Repair quality (repeat visit, incomplete etc)',
                reason5: 'Technical / commercial skills of workshop staff',
                reason6: 'Condition of vehicle at return',
                reason7: 'Price',
                reason8: 'Information (about work, delays, additional work / costs)',
                reason9: 'Opening hours',
                reason10: 'Other',
            }
        }
    },
}

class ReasonsForDissatisfaction extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoadingSites: true,
            isLoadingReasons: true,

            sites: [],
            site: null,
            date: null,

            reasons: null,
        }

        this.changeSite = this.changeSite.bind(this);
        this.changeFrom = this.changeFrom.bind(this);
        this.changeTo = this.changeTo.bind(this);

        this.changeLastMonth = this.changeLastMonth.bind(this);
        this.changeCurrentWeek = this.changeCurrentWeek.bind(this);
        this.changeCurrentMonth = this.changeCurrentMonth.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        this.loadSites();
    }

    loadSites() {
        const {addNotification, removeNotification, token} = this.context;

        var site = localStorage.getItem("driver-feedback-site");
        var from = localStorage.getItem("driver-feedback-from");
        var to = localStorage.getItem("driver-feedback-to");
        
        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/crm/driver-feedback/sites', { token: token }).then((res) => {
                if (from == null || to == null) {
                    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
                    from = new Date(y, m - 1, 1).getTime();//first day of prev month
                    to = new Date(y, m, 0).getTime();//last day of prev month
        
                    localStorage.setItem('driver-feedback-from', from);
                    localStorage.setItem('driver-feedback-to', to);
                }
        
                if (site == null || (res.data.rows.filter(e => e.id == site).length == 0)) {
                    localStorage.setItem('driver-feedback-site', res.data.rows[0].id);
                    site = res.data.rows[0].id;
                }

                this.setState({
                    isLoadingSites: false,
                    sites: res.data.rows, 
                    site: site,
                    from: Number(from),
                    to: Number(to),
                }, () => this.loadReasons());
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            if (from == null || to == null) {
                var date = new Date(), y = date.getFullYear(), m = date.getMonth();
                from = new Date(y, m - 1, 1).getTime();//first day of prev month
                to = new Date(y, m, 0).getTime();//last day of prev month
    
                localStorage.setItem('driver-feedback-from', from);
                localStorage.setItem('driver-feedback-to', to);
            }
    
            if (site == null) {
                localStorage.setItem('driver-feedback-site', 5);
            }

            this.setState({
                isLoadingSites: false,

                site: site,
                from: Number(from),
                to: Number(to),
                sites: [
                    {
                        id: "1",
                        name: "ВСЕ",
                    },
                    {
                        id: "-1",
                        name: "All Captive",
                    },
                    {
                        id: "-2",
                        name: "All Non-Captive",
                    },
                    {
                        id: "5",
                        name: "SCANIA Kiev",
                    },
                ]
            }, () => this.loadReasons());
        }
    }

    changeSite(event) {
        this.setState({site: event.target.value, isLoadingReasons: true}, () => {
            localStorage.setItem('driver-feedback-site', this.state.site);
            this.loadReasons();
        });
    }

    changeFrom(event) {
        this.setState({from: new Date(event.target.value).getTime(), isLoadingReasons: true}, () => {
            localStorage.setItem('driver-feedback-from', this.state.from);
            this.loadReasons();
        });
    }

    changeTo(event) {
        this.setState({to: new Date(event.target.value).getTime(), isLoadingReasons: true}, () => {
            localStorage.setItem('driver-feedback-to', this.state.to);
            this.loadReasons();
        });
    }

    changeLastMonth() {
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        var from = new Date(y, m - 1, 1).getTime();//first day of prev month
        var to = new Date(y, m, 0).getTime();//last day of prev month

        this.setState({from: from, to: to, isLoadingReasons: true}, () => {
            localStorage.setItem('driver-feedback-from', from);
            localStorage.setItem('driver-feedback-to', to);
            this.loadReasons();
        });
    }

    changeCurrentWeek() {
        var date = new Date;

        var monday = date.getDate() - date.getDay() + 1;
        var sunday = monday + 6;

        var from = new Date(date.setDate(monday)).getTime();//first day of prev month
        var to = new Date(date.setDate(sunday)).getTime();//last day of prev month

        this.setState({from: from, to: to, isLoadingReasons: true}, () => {
            localStorage.setItem('driver-feedback-from', from);
            localStorage.setItem('driver-feedback-to', to);
            this.loadReasons();
        });
    }

    changeCurrentMonth() {
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();

        var from = new Date(y, m, 1, 0, 0, 0).getTime();  //first day of current month
        var to = new Date(y, m+1, 0, 23, 59, 59).getTime();  //last day of current month

        this.setState({from: from, to: to, isLoadingActions: true}, () => {
            localStorage.setItem('driver-feedback-from', from);
            localStorage.setItem('driver-feedback-to', to);
            this.loadReasons();
        });
    }

    loadReasons() {
        const {addNotification, removeNotification, token} = this.context;
        
        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");

            var chartPromise = axios.post('/api/crm/driver-feedback/reasons-for-dissatisfaction/chart', { token: token, site: this.state.site, from: dateToDatabaseFormat(this.state.from), to: dateToDatabaseFormat(this.state.to) });
            var listPromise = axios.post('/api/crm/driver-feedback/reasons-for-dissatisfaction/list', { token: token, site: this.state.site, from: dateToDatabaseFormat(this.state.from), to: dateToDatabaseFormat(this.state.to) });

            axios.all([chartPromise, listPromise]).then(axios.spread((chart, list) => {
                this.setState({
                    isLoadingReasons: false,
                    chart: chart.data.rows[0],
                    list: list.data.rows,
                });
            })).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            this.setState({
                isLoadingReasons: false,
                chart: {
                    reason1: 10,
                    reason2: 20,
                    reason3: 30,
                    reason4: 40,
                    reason5: 50,
                    reason6: 60,
                    reason7: 70,
                    reason8: 80,
                    reason9: 90,
                    reason10: 100,
                },
                list: [
                    {
                        date: "2020-07",
                        workshop: "Kyiv",
                        driver: "Vasia",
                        phone: "0508673516",
                        company: "Silpo",

                        reason1: 1,
                        reason2: 0,
                        reason3: 1,
                        reason4: 0,
                        reason5: 1,
                        reason6: 0,
                        reason7: 1,
                        reason8: 0,
                        reason9: 1,
                        reason10: 1,
                        reason10Remark: "ьуьуь",
                    },
                ],
            });
        }
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        return (
            <div>
                <DocumentMeta {...meta} />

                <Submenu>
                    <SubmenuContent />
                </Submenu>

                <br/><br/>

                <div className="container-fluid" style={this.state.isLoadingSites ? {display: "none"} : {}}>
                    <div className="row">
                        <div className="col-md-10 offset-md-1">

                            <div className="container-fluid pb-4 mb-4">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].site}:</span>
                                        <select className="question-input ml-1" style={{width: "220px"}} value={this.state.site + ""} onChange={this.changeSite}>
                                            {this.state.sites.map((item) => {
                                                return (
                                                    <option value={item.id}>{item.name}</option>
                                                )
                                            })}
                                        </select>

                                        <span className="ml-4">{localization[lang].from}:</span>
                                        <input type="date" className="question-input ml-1" style={{width: "220px"}} value={dateToInputFormat(this.state.from)} onChange={this.changeFrom} />

                                        <span className="ml-2">{localization[lang].to}:</span>
                                        <input type="date" className="question-input ml-1" style={{width: "220px"}} value={dateToInputFormat(this.state.to)} onChange={this.changeTo} />
                                        
                                        <button className="btn btn-link ml-4" onClick={this.changeLastMonth}>{localization[lang].lastMonth}</button>
                                        <button className="btn btn-link ml-2" onClick={this.changeCurrentWeek}>{localization[lang].currentWeek}</button>
                                        <button className="btn btn-link ml-2" onClick={this.changeCurrentMonth}>{localization[lang].currentMonth}</button>
                                    </div>
                                </div>
                            </div>

                            {!this.state.isLoadingReasons && (
                                <div className="container-fluid">
                                    <div className="row mb-3">
                                        <div className="col-8">
                                            <div style={{
                                                width: "100%",
                                                background: "var(--theme-primary)",
                                                color: "white",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flexDirection: "column"
                                            }}>
                                                <Graph
                                                    type={"horizontalBar"}
                                                    data={{
                                                        labels: [localization[lang].table.reasons.reason1, localization[lang].table.reasons.reason2, localization[lang].table.reasons.reason3, localization[lang].table.reasons.reason4, localization[lang].table.reasons.reason5, localization[lang].table.reasons.reason6, localization[lang].table.reasons.reason7, localization[lang].table.reasons.reason8, localization[lang].table.reasons.reason9, localization[lang].table.reasons.reason10],
                                                        datasets: [
                                                            {
                                                                label: "Top",
                                                                data: [this.state.chart.reason1, this.state.chart.reason2, this.state.chart.reason3, this.state.chart.reason4, this.state.chart.reason5, this.state.chart.reason6, this.state.chart.reason7, this.state.chart.reason8, this.state.chart.reason9, this.state.chart.reason10],
                                                                fill: false,
                                                                backgroundColor: "#041E42",
                                                                borderColor: "#041E42",
                                                            },
                                                        ]
                                                    }}

                                                    options={{
                                                        animation: {
                                                            duration: 0 // general animation time
                                                        },
                                                        hover: {
                                                            animationDuration: 0 // duration of animations when hovering an item
                                                        },
                                                        responsiveAnimationDuration: 0, // animation duration after a resize
                                                        elements: {
                                                            line: {
                                                                tension: 0 // disables bezier curves
                                                            }
                                                        },
                                                        scales: {
                                                            xAxes: [{
                                                                ticks: {
                                                                    suggestedMin: 0,
                                                                    beginAtZero: true,
                                                                    suggestedMax: 100,
                                                                }
                                                            }]
                                                        },
                                                        tooltips: {
                                                            mode: 'index',
                                                            //enabled: false,

                                                            callbacks: {
                                                                //title: (tooltipItems, data) => data.labels[tooltipItems[0].index],
                                                                //label: (tooltipItems, data) => data.datasets[tooltipItems.datasetIndex].label + ': ' + tooltipItems.value + '%'
                                                                title: (tooltipItems, data) => localization[lang].table.reasons["reason" + (tooltipItems[0].index + 1)],
                                                                label: (tooltipItems, data) => tooltipItems.value + "%"
                                                            }                 
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <table className="table table-bordered table-hover" style={{fontSize: "12px"}}>
                                                <thead>
                                                    <tr style={{backgroundColor: "#586981", color: "#fff"}}>
                                                        <th class="align-middle text-center" style={{width: "100px"}}>{localization[lang].table.date}</th>
                                                        <th class="align-middle text-center" style={{width: "220px"}}>{localization[lang].table.workshop}</th>
                                                        <th class="align-middle text-center">{localization[lang].table.driver}</th>
                                                        <th class="align-middle text-center">{localization[lang].table.phone}</th>
                                                        <th class="align-middle text-center">{localization[lang].table.company}</th>
                                                        <th class="align-middle text-center">{localization[lang].table.reasons.title}</th>
                                                    </tr>
                                                    {/* <tr>
                                                        <td className="p-1"><input type="text" className="form-control form-control-sm w-100" value={this.state.filterDate} onChange={(e) => this.setState({filterDate: e.target.value})} /></td>
                                                        <td className="p-1"><input type="text" className="form-control form-control-sm w-100" value={this.state.filterWorkshop} onChange={(e) => this.setState({filterWorkshop: e.target.value})} /></td>
                                                        <td className="p-1"><input type="text" className="form-control form-control-sm w-100" value={this.state.filterDriver} onChange={(e) => this.setState({filterDriver: e.target.value})} /></td>
                                                        <td className="p-1"><input type="text" className="form-control form-control-sm w-100" value={this.state.filterPhone} onChange={(e) => this.setState({filterPhone: e.target.value})} /></td>
                                                        <td className="p-1"><input type="text" className="form-control form-control-sm w-100" value={this.state.filterCompany} onChange={(e) => this.setState({filterCompany: e.target.value})} /></td>
                                                        <td className="p-1"><input type="text" className="form-control form-control-sm w-100" value={this.state.filterRecommendation} onChange={(e) => this.setState({filterRecommendation: e.target.value})} /></td>
                                                        <td className="p-1"><input type="text" className="form-control form-control-sm w-100" value={this.state.filterSatisfaction} onChange={(e) => this.setState({filterSatisfaction: e.target.value})} /></td>
                                                        <td className="p-1"><input type="text" className="form-control form-control-sm w-100" value={this.state.filterText} onChange={(e) => this.setState({filterText: e.target.value})} /></td>
                                                    </tr> */}
                                                </thead>
                                                <tbody>
                                                    {
                                                        // filter(x => x.date.toLowerCase().includes(this.state.filterDate)).
                                                        // filter(x => x.workshop.toLowerCase().includes(this.state.filterWorkshop)).
                                                        // filter(x => x.driver.toLowerCase().includes(this.state.filterDriver)).
                                                        // filter(x => x.phone.toLowerCase().includes(this.state.filterPhone)).
                                                        // filter(x => x.company.toLowerCase().includes(this.state.filterCompany)).
                                                        // filter(x => x.recommendation.toLowerCase().includes(this.state.filterRecommendation)).
                                                        // filter(x => x.satisfaction.toLowerCase().includes(this.state.filterSatisfaction)).
                                                        // filter(x => x.text.toLowerCase().includes(this.state.filterText)).
                                                        this.state.list.map((item) => {
                                                        return (
                                                            <tr>
                                                                <td class="align-middle pt-1 pb-1" style={{height: "40px"}}>{item.date}</td>
                                                                <td class="align-middle pt-1 pb-1">{item.workshop}</td>
                                                                <td class="align-middle pt-1 pb-1">{item.driver}</td>
                                                                <td class="align-middle pt-1 pb-1">{item.phone}</td>
                                                                <td class="align-middle pt-1 pb-1">{item.company}</td>
                                                                <td class="align-middle pt-1 pb-1">
                                                                    {item.reason1 == 1 && (
                                                                        <div><span className="text-muted">-</span> {localization[lang].table.reasons.reason1}</div>
                                                                    )}
                                                                    {item.reason2 == 1 && (
                                                                        <div><span className="text-muted">-</span> {localization[lang].table.reasons.reason2}</div>
                                                                    )}
                                                                    {item.reason3 == 1 && (
                                                                        <div><span className="text-muted">-</span> {localization[lang].table.reasons.reason3}</div>
                                                                    )}
                                                                    {item.reason4 == 1 && (
                                                                        <div><span className="text-muted">-</span> {localization[lang].table.reasons.reason4}</div>
                                                                    )}
                                                                    {item.reason5 == 1 && (
                                                                        <div><span className="text-muted">-</span> {localization[lang].table.reasons.reason5}</div>
                                                                    )}
                                                                    {item.reason6 == 1 && (
                                                                        <div><span className="text-muted">-</span> {localization[lang].table.reasons.reason6}</div>
                                                                    )}
                                                                    {item.reason7 == 1 && (
                                                                        <div><span className="text-muted">-</span> {localization[lang].table.reasons.reason7}</div>
                                                                    )}
                                                                    {item.reason8 == 1 && (
                                                                        <div><span className="text-muted">-</span> {localization[lang].table.reasons.reason8}</div>
                                                                    )}
                                                                    {item.reason9 == 1 && (
                                                                        <div><span className="text-muted">-</span> {localization[lang].table.reasons.reason9}</div>
                                                                    )}
                                                                    {item.reason10 == 1 && (
                                                                        <div><span className="text-muted">-</span> {item.reason10Remark}</div>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReasonsForDissatisfaction;