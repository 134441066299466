import React from 'react'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';
import ScaleLoader from "react-spinners/ScaleLoader";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload, faDownload } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../AppContext.js';
import Submenu from '../../../components/submenu.js';
import SubmenuContent from './submenu.js';
import OffsetTop from '../../../components/offsetTop.js';

import {dateToInputFormat, dateToDatabaseFormat} from '../../../utils/datetime.js';

const localization = {
    ua: {
        title: 'Driver Feedback',
        site: 'Дилер',
        from: 'Від',
        to: 'До',
        lastMonth: 'Попередній місяць',
        currentWeek: 'Поточний тиждень',
        currentMonth: 'Поточний місяць',
        table: {
            workshop: 'Дилер',
            satisfaction: 'Задоволення',
            nps: 'NPS',
            interviews: 'Опитано',
            actions: 'Очікують розглянення',
        }
    },
    ru: {
        title: 'Driver Feedback',
        site: 'Дилер',
        from: 'С',
        to: 'По',
        lastMonth: 'Предыдущий месяц',
        currentWeek: 'Текущая неделя',
        currentMonth: 'Текущий месяц',
        table: {
            workshop: 'Дилер',
            satisfaction: 'Удовлетворение',
            nps: 'NPS',
            interviews: 'Опрошено',
            actions: 'Ожидают рассмотрения',
        }
    },
    en: {
        title: 'Driver Feedback',
        site: 'Site',
        from: 'From',
        to: 'To',
        lastMonth: 'Last month',
        currentWeek: 'Current week',
        currentMonth: 'Current month',
        table: {
            workshop: 'Workshop',
            satisfaction: 'Satisfaction',
            nps: 'NPS',
            interviews: 'Interviews',
            actions: 'Open actions',
        }
    },
}

class ResultsPerWorkshop extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoadingSites: true,
            isLoadingResults: true,

            sites: [],
            site: null,
            from: null,
            to: null,

            results: null,
        }

        this.changeSite = this.changeSite.bind(this);
        this.changeFrom = this.changeFrom.bind(this);
        this.changeTo = this.changeTo.bind(this);

        this.changeLastMonth = this.changeLastMonth.bind(this);
        this.changeCurrentWeek = this.changeCurrentWeek.bind(this);
        this.changeCurrentMonth = this.changeCurrentMonth.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        this.loadSites();
    }

    loadSites() {
        const {addNotification, removeNotification, token} = this.context;

        var site = localStorage.getItem("driver-feedback-site");
        var from = localStorage.getItem("driver-feedback-from");
        var to = localStorage.getItem("driver-feedback-to");

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/crm/driver-feedback/sites', { token: token }).then((res) => {
                if (from == null || to == null) {
                    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
                    from = new Date(y, m - 1, 1).getTime();//first day of prev month
                    to = new Date(y, m, 0).getTime();//last day of prev month
        
                    localStorage.setItem('driver-feedback-from', from);
                    localStorage.setItem('driver-feedback-to', to);
                }
        
                if (site == null || (res.data.rows.filter(e => e.id == site).length == 0)) {
                    localStorage.setItem('driver-feedback-site', res.data.rows[0].id);
                    site = res.data.rows[0].id;
                }

                this.setState({
                    isLoadingSites: false,
                    sites: res.data.rows, 
                    site: site,
                    from: Number(from),
                    to: Number(to),
                }, () => this.loadResults());
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            if (from == null || to == null) {
                var date = new Date(), y = date.getFullYear(), m = date.getMonth();
                from = new Date(y, m - 1, 1).getTime();//first day of prev month
                to = new Date(y, m, 0).getTime();//last day of prev month
    
                localStorage.setItem('driver-feedback-from', from);
                localStorage.setItem('driver-feedback-to', to);
            }
    
            if (site == null) {
                localStorage.setItem('driver-feedback-site', 5);
            }

            this.setState({
                isLoadingSites: false,

                site: site,
                from: Number(from),
                to: Number(to),
                sites: [
                    {
                        id: "1",
                        name: "ВСЕ",
                    },
                    {
                        id: "-1",
                        name: "All Captive",
                    },
                    {
                        id: "-2",
                        name: "All Non-Captive",
                    },
                    {
                        id: "5",
                        name: "SCANIA Kiev",
                    },
                ]
            }, () => this.loadResults());
        }
    }

    changeSite(event) {
        this.setState({site: event.target.value, isLoadingResults: true}, () => {
            localStorage.setItem('driver-feedback-site', this.state.site);
            this.loadResults();
        });
    }

    changeFrom(event) {
        this.setState({from: new Date(event.target.value).getTime(), isLoadingResults: true}, () => {
            localStorage.setItem('driver-feedback-from', this.state.from);
            this.loadResults();
        });
    }

    changeTo(event) {
        this.setState({to: new Date(event.target.value).getTime(), isLoadingResults: true}, () => {
            localStorage.setItem('driver-feedback-to', this.state.to);
            this.loadResults();
        });
    }

    changeLastMonth() {
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        var from = new Date(y, m - 1, 1).getTime();//first day of prev month
        var to = new Date(y, m, 0).getTime();//last day of prev month

        this.setState({from: from, to: to, isLoadingResults: true}, () => {
            localStorage.setItem('driver-feedback-from', from);
            localStorage.setItem('driver-feedback-to', to);
            this.loadResults();
        });
    }

    changeCurrentWeek() {
        var date = new Date;

        var monday = date.getDate() - date.getDay() + 1;
        var sunday = monday + 6;

        var from = new Date(date.setDate(monday)).getTime();//first day of prev month
        var to = new Date(date.setDate(sunday)).getTime();//last day of prev month

        this.setState({from: from, to: to, isLoadingResults: true}, () => {
            localStorage.setItem('driver-feedback-from', from);
            localStorage.setItem('driver-feedback-to', to);
            this.loadResults();
        });
    }

    changeCurrentMonth() {
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();

        var from = new Date(y, m, 1, 0, 0, 0).getTime();  //first day of current month
        var to = new Date(y, m+1, 0, 23, 59, 59).getTime();  //last day of current month

        this.setState({from: from, to: to, isLoadingActions: true}, () => {
            localStorage.setItem('driver-feedback-from', from);
            localStorage.setItem('driver-feedback-to', to);
            this.loadResults();
        });
    }

    loadResults() {
        const {addNotification, removeNotification, token} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/crm/driver-feedback/results-per-workshop/results', { token: token, site: this.state.site, from: dateToDatabaseFormat(this.state.from), to: dateToDatabaseFormat(this.state.to) }).then((res) => {
                this.setState({
                    isLoadingResults: false,
                    results: res.data.rows,
                });
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            this.setState({
                isLoadingResults: false,
                results: [
                    {
                        workshop: "Kyiv",
                        satisfaction: "4.5",
                        nps: "100.0",
                        interviews: "2",
                        actions: "2",
                    },
                    {
                        workshop: "Lviv",
                        satisfaction: "3.5",
                        nps: "90.0",
                        interviews: "12",
                        actions: "-",
                    },
                ]
            });
        }
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        return (
            <div>
                <DocumentMeta {...meta} />
                
                <Submenu>
                    <SubmenuContent />
                </Submenu>

                <br/><br/>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="container-fluid pb-4 mb-4">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].site}:</span>
                                        <select className="question-input ml-1" style={{width: "220px"}} value={this.state.site + ""} onChange={this.changeSite}>
                                            {this.state.sites.map((item) => {
                                                return (
                                                    <option value={item.id}>{item.name}</option>
                                                )
                                            })}
                                        </select>

                                        <span className="ml-4">{localization[lang].from}:</span>
                                        <input type="date" className="question-input ml-1" style={{width: "220px"}} value={dateToInputFormat(this.state.from)} onChange={this.changeFrom} />

                                        <span className="ml-2">{localization[lang].to}:</span>
                                        <input type="date" className="question-input ml-1" style={{width: "220px"}} value={dateToInputFormat(this.state.to)} onChange={this.changeTo} />
                                        
                                        <button className="btn btn-link ml-4" onClick={this.changeLastMonth}>{localization[lang].lastMonth}</button>
                                        <button className="btn btn-link ml-2" onClick={this.changeCurrentWeek}>{localization[lang].currentWeek}</button>
                                        <button className="btn btn-link ml-2" onClick={this.changeCurrentMonth}>{localization[lang].currentMonth}</button>
                                    </div>
                                </div>
                            </div>

                            {!this.state.isLoadingResults && (
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col">
                                            <table className="table table-bordered table-hover" style={{fontSize: "12px"}}>
                                                <thead>
                                                    <tr style={{backgroundColor: "#586981", color: "#fff"}}>
                                                        <th class="align-middle text-center">{localization[lang].table.workshop}</th>
                                                        <th class="align-middle text-center" style={{width: "200px"}}>{localization[lang].table.satisfaction}</th>
                                                        <th class="align-middle text-center" style={{width: "200px"}}>{localization[lang].table.nps}</th>
                                                        <th class="align-middle text-center" style={{width: "200px"}}>{localization[lang].table.interviews}</th>
                                                        <th class="align-middle text-center" style={{width: "200px"}}>{localization[lang].table.actions}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.results.map((item) => {
                                                        return (
                                                            <tr>
                                                                <td class="align-middle pt-1 pb-1" style={{height: "40px"}}>{item.workshop}</td>
                                                                <td class="align-middle pt-1 pb-1">{item.satisfaction}</td>
                                                                <td class="align-middle pt-1 pb-1">{item.nps}</td>
                                                                <td class="align-middle pt-1 pb-1">{item.interviews}</td>
                                                                <td class="align-middle pt-1 pb-1">{item.actions}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ResultsPerWorkshop;