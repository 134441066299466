import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faSearch, faPlus, faFilter, faExpand, faChevronLeft, faListUl } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../AppContext.js';
import OffsetTop from '../../components/offsetTop.js';

import Scanner from '../../components/scanner.js';

const localization = {
    ua: {
        title: 'Зміна локації запчастини',
        location: 'Локація на складі',
        locationPlaceholder: 'Нова локація...',
        notRowAloneError: 'Помилка! Знайдено більше ніж один код або код не знайдено!',
        save: 'Зберегти',
        noData: 'Данi вiдсутнi',
    },
    ru: {
        title: 'Смена локации запчасти',
        location: 'Локация на складе',
        locationPlaceholder: 'Новая локация...',
        notRowAloneError: 'Ошыбка! Найден более чем один код или код не найден!',
        save: 'Сохранить',
        noData: 'Нет данных',
    },
    en: {
        title: 'Changing part location',
        location: 'Location',
        locationPlaceholder: 'New location...',
        notRowAloneError: 'Error! More that one code found or code doesn\'t exist!',
        save: 'Save',
        noData: 'No data',
    },
}

class StockRelocate extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            redirect: null,
            isLoading: true,

            isRowAlone: false,

            code: null,
            name: null,
            loc: null,

            isScannerOpened: false,

            location: "",
        }

        this.locationChange = this.locationChange.bind(this);
        this.locationKeyPress = this.locationKeyPress.bind(this);
        this.scanLocationButtonClick = this.scanLocationButtonClick.bind(this);

        this.onCodeScan = this.onCodeScan.bind(this);
        this.onCodeScanClose = this.onCodeScanClose.bind(this);

        this.save = this.save.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        this.init();
    }

    init() {
        const {addNotification, removeNotification, token} = this.context;

        var code = this.props.match.params.code;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/stock/detail', { token: token, searchCode: code }).then((res) => {
                if (res.data.rows.length == 1) {
                    this.setState({
                        isLoading: false,
                        isRowAlone: true,

                        code: res.data.rows[0].code,
                        name: res.data.rows[0].name,
                        loc: res.data.rows[0].location,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                        isRowAlone: false,
                    });
                }
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            this.setState({
                isLoading: false,
                isRowAlone: true,

                code: "123456789",
                name: "Name 1",
                loc: "Location",
            });
        }
    }

    locationChange(event) {
        this.setState({location: event.target.value});
    }

    locationKeyPress(e) {
        if (e.key === 'Enter') {
            //this.setState({redirect: "/stock/detail/" + this.state.searchCode});
            this.save();
        }
    }

    scanLocationButtonClick() {
        this.setState({isScannerOpened: true});
    }

    onCodeScan(value) {
        this.setState({location: value});
    }

    onCodeScanClose() {
        this.setState({isScannerOpened: false});
    }

    save() {
        const {addNotification, removeNotification, token} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/stock/relocate', { token: token, code: this.state.code, location: this.state.location }).then((res) => {
                if (res.data.serror.length == 0) {
                    this.setState({redirect: "/stock/detail/" + this.state.code});
                } else {
                    addNotification(res.data.serror, 2);
                }
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            
        }
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.redirect != null) {
            return <Redirect push to={this.state.redirect} />
        }

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        if (!this.state.isRowAlone) {
            return (
                <div>
                    <DocumentMeta {...meta} />
                    <OffsetTop submenu={false} />

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="container-fluid pl-0 pr-0">

                                    <div className="row mb-3">
                                        <div className="col">
                                            <h6><b>{localization[lang].notRowAloneError}</b></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                {this.state.isScannerOpened && (
                    <Scanner onCodeScan={this.onCodeScan} onCodeScanClose={this.onCodeScanClose} />
                )}

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="container-fluid pl-0 pr-0">

                                <div className="row mb-3">
                                    <div className="col">
                                        <div className="card-agis pt-2 pb-2 mb-2" style={{borderRadius: "15px"}}>
                                            <h3><b>{this.state.code}</b></h3>
                                            <p style={{fontSize: "1.3rem"}}>{this.state.name}</p>

                                            <div className="row">
                                                <div className="col">
                                                    <span className="text-muted">{localization[lang].location}</span>
                                                    <h6 style={{fontSize: "1.5rem"}}><b>{this.state.loc}</b></h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between mb-3">
                                    <input id="locationInput" type="text" className="question-input" style={{height: "50px", width: "calc(100% - 50px)", fontSize: "18px", borderRadius: "10px", backgroundColor: "#fff"}} placeholder={localization[lang].locationPlaceholder} value={this.state.location} onChange={this.locationChange} onKeyPress={this.locationKeyPress} autoFocus />
                                    {/* <button onClick={this.searchCodeButtonClick} className="a-agis-light-outline" style={{height: '50px', width: "50px", minWidth: "0px", borderRadius: "10px", borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px", lineHeight: "44px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 0 0 1px rgba(0,0,0,.05), 0 2px 3px 0 rgba(0,0,0,.1)"}}><FontAwesomeIcon icon={faListUl} style={{fontSize: "20px"}} /></button> */}

                                    <button onClick={this.scanLocationButtonClick} className="a-agis-light-outline ml-3" style={{height: '50px', width: "70px", minWidth: "0px", borderRadius: "10px", lineHeight: "54px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 0 0 1px rgba(0,0,0,.05), 0 2px 3px 0 rgba(0,0,0,.1)"}}><span style={{fontSize: "16px"}}><svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="barcode-read" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="svg-inline--fa fa-barcode-read fa-w-20 fa-2x"><path fill="currentColor" d="M152 0H8C3.6 0 0 3.6 0 8v152c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V32h120c4.4 0 8-3.6 8-8V8c0-4.4-3.6-8-8-8zm0 480H32V352c0-4.4-3.6-8-8-8H8c-4.4 0-8 3.6-8 8v152c0 4.4 3.6 8 8 8h144c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zM632 0H488c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h120v128c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V8c0-4.4-3.6-8-8-8zm0 344h-16c-4.4 0-8 3.6-8 8v128H488c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h144c4.4 0 8-3.6 8-8V352c0-4.4-3.6-8-8-8zM152 96h-48c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8zm336 320h48c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8zM408 96h-48c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8zm-192 0h-16c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8zm64 0h-16c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8z" class=""></path></svg></span></button>
                                </div>

                                <div className="d-flex justify-content-between mb-3">
                                    <button onClick={this.save} className="btn-agis-primary w-100" style={{height: '44px'}}>{localization[lang].save}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default StockRelocate;