import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faSearch, faPlus, faFilter, faChevronLeft, faCircle, faFlag, faCar } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../AppContext.js';
import OffsetTop from '../../components/offsetTop.js';

const localization = {
    ua: {
        title: 'Рух техніки на СТО',
        all: 'Увесь рух',
        in: 'Заїзди',
        out: 'Виїзди',
        now: 'На СТО',
        filter: 'Фільтр...',
        norows: 'Результатів не знайдено',
        noData: 'Данi вiдсутнi',
    },
    ru: {
        title: 'Движение техники на СТО',
        all: 'Всё движение',
        in: 'Заезды',
        out: 'Выезды',
        now: 'На СТО',
        filter: 'Фильтр...',
        norows: 'Результатов не найдено',
        noData: 'Нет данных',
    },
    en: {
        title: 'Vehicle movements on territory',
        all: 'All',
        in: 'In',
        out: 'Out',
        now: 'Now',
        filter: 'Filter...',
        norows: 'No result',
        noData: 'No data',
    },
}

class TerritoryVehicles extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,

            filterDate: null,
            type: 3,

            filterText: "",

            rows: []
        }

        this.dateToInputFormat= this.dateToInputFormat.bind(this);
        this.dateToDatabaseFormat= this.dateToDatabaseFormat.bind(this);

        this.changeDate= this.changeDate.bind(this);
        this.datePrev = this.datePrev.bind(this);
        this.dateNext = this.dateNext.bind(this);

        this.expand = this.expand.bind(this);
        this.typeChange = this.typeChange.bind(this);

        this.onFilterChange = this.onFilterChange.bind(this);

        this.vehicleActionClick = this.vehicleActionClick.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        this.setState({filterDate: Date.now()}, () => {
            this.loadList();
        });
    }

    dateToInputFormat(date) {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
            
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
    }

    dateToDatabaseFormat(date) {
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [day, month, year].join('.');
    }

    changeDate(event) {
        this.setState({filterDate: new Date(event.target.value).getTime()}, () => {
            this.loadList();
        });
    }

    datePrev() {
        this.setState({filterDate: this.state.filterDate - 86400000}, () => {
            this.loadList();
        });
    }

    dateNext() {
        this.setState({filterDate: this.state.filterDate + 86400000}, () => {
            this.loadList();
        });
    }

    typeChange(type) {
        this.setState({type: type}, () => {
            this.loadList();
        });
    }

    onFilterChange(event) {
        this.setState({filterText: event.target.value});
    }

    loadList() {
        const {addNotification, removeNotification, token} = this.context;
        
        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/territory/vehicles', { token: token, date: this.dateToDatabaseFormat(this.state.filterDate), type: this.state.type }).then((res) => {
                this.setState({rows: res.data.rows, isLoading: false});
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            this.setState({
                isLoading: false,
                rows: [
                    {
                        did: 34299,
                        short: "KRONE AA4640XO, В роботi<br/>Лущай, <a href=\"тел:+380987603629\">+380987603629</a>",
                        full: "KRONE AA4640XO, В роботi<br/>Прицеп, Ремонт<br/>Лущай, <a href=\"тел:+380987603629\">+380987603629</a><br/>ЗН <a href=\"https://demo.agis.group/order/95339/card\">9T-1908448</a> вiд 09.10.2019<br/>Канівська філія  ПАТ \"Миронівський хлібопродукт\"",
                        in: "2017-07-24 09:51",
                        out: "",
                        stayed: 62.7,
                        from: 8,
                        to: 14,
                        leave: 0,

                        lightLeave: 0,
                        lightDrive: 0,
                        leaveAllow: 1,
                        leaveLeave: 0,
                        driveAllow: 1,
                        driveLeave: 0,
                        driveBack: 0,

                        expand: false,
                    },
                    {
                        did: 34299,
                        short: "KRONE AA4640XO, В роботi<br/>Лущай, <a href=\"тел:+380987603629\">+380987603629</a>",
                        full: "KRONE AA4640XO, В роботi<br/>Прицеп, Ремонт<br/>Лущай, <a href=\"тел:+380987603629\">+380987603629</a><br/>ЗН <a href=\"https://demo.agis.group/order/95339/card\">9T-1908448</a> вiд 09.10.2019<br/>Канівська філія  ПАТ \"Миронівський хлібопродукт\"",
                        in: "2017-07-24 09:51",
                        out: "",
                        stayed: 26.7,
                        from: 8,
                        to: 14,
                        leave: 1,
                        
                        lightLeave: 1,
                        lightDrive: 0,
                        leaveAllow: 0,
                        leaveLeave: 1,
                        driveAllow: 0,
                        driveLeave: 0,
                        driveBack: 0,

                        expand: false,
                    },
                    {
                        did: 34299,
                        short: "KRONE AA4640XO, В роботi<br/>Лущай, <a href=\"тел:+380987603629\">+380987603629</a>",
                        full: "KRONE AA4640XO, В роботi<br/>Прицеп, Ремонт<br/>Лущай, <a href=\"тел:+380987603629\">+380987603629</a><br/>ЗН <a href=\"https://demo.agis.group/order/95339/card\">9T-1908448</a> вiд 09.10.2019<br/>Канівська філія  ПАТ \"Миронівський хлібопродукт\"",
                        in: "2017-07-24 09:51",
                        out: "2017-07-24 09:51",
                        stayed: 20.7,
                        from: 8,
                        to: 14,
                        leave: 0,
                        
                        lightLeave: 1,
                        lightDrive: 1,
                        leaveAllow: 1,
                        leaveLeave: 1,
                        driveAllow: 1,
                        driveLeave: 1,
                        driveBack: 1,

                        expand: false,
                    },
                ]
            });
        }
    }

    expand(i) {
        this.setState(state => {
            let rows = state.rows;
            rows[i].expand = !rows[i].expand;

            return {
                rows
            };
        });
    }

    vehicleActionClick(did, action) {
        const {addNotification, removeNotification, token} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/territory/vehicle/action', { 
                token: token,
                did: did,
                action: action,
            }).then((res) => {
                this.loadList();
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        }
    }

    renderList() {
        const {lang} = this.context;

        if (this.state.rows.length === 0) {
            return (
                <div className="row">
                    <div className="col">
                        <div className="card agis-card">
                            <div className="row">
                                <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                    <div>
                                        <h6><b>{localization[lang].noData}</b></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            let list = this.state.rows;

            if (this.state.filterText.length > 0) {
                list = list.filter(item => item.full.includes(this.state.filterText));
            }

            if (list.length == 0) {
                return (
                    <div className="row">
                        <div className="col">
                            <div className="card agis-card">
                                <div className="row">
                                    <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                        <div>
                                            <span className="text-muted">{localization[lang].norows}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }

            return list.map((item, i) => {
                var from = item.from * 100 / 24;
                var to = item.to * 100 / 24 - from;
                var progressColor = "#4184b4";

                return (
                    <div className="row mb-2" key={"list_" + i}>
                        <div className="col">
                            <div className="card-agis pt-2 pb-2 noselect clickable" style={item.leave == 1 ? {backgroundColor: "#eee", borderRadius: "15px"} : {borderRadius: "15px"}}>
                                <div onClick={() => this.expand(i)} >
                                    <div className="d-flex justify-content-between mb-1">
                                        <big><b>{item.in + (item.out !== "" ? " - " + item.out : " - ...")}</b> <span className="text-success">({item.stayed})</span></big>
                                        <span>
                                            <FontAwesomeIcon icon={faCircle} className={item.lightLeave == 1 ? "text-success" : "text-danger"} style={{fontSize: "16px"}} />
                                            {item.lightDrive == 1 && (
                                                <FontAwesomeIcon icon={faCar} className={item.lightDrive == 1 ? "text-info ml-1" : "text-danger ml-1"} style={{fontSize: "16px"}} />
                                            )}
                                        </span>
                                    </div>
                                    <span dangerouslySetInnerHTML={{__html: item.expand ? item.full : item.short}} />
                                </div>
                                <div class="progress w-100 mt-1" style={{height: "5px"}}>
                                    <div class="progress-bar" role="progressbar" style={{width: from + "%", backgroundColor: "inherit"}}></div>
                                    <div class="progress-bar" role="progressbar" style={{width: to + "%", backgroundColor: progressColor}}></div>
                                </div>
                                {item.leaveAllow == 1 && (
                                    <button className={'btn-agis-success-outline mt-2'} style={{height: "30px", width: '100%', minWidth: "0px"}} onClick={(e) => {this.vehicleActionClick(item.did, "BUTN_ACCEPT_OUT")}}>Дозволяю виїзд</button>
                                )}
                                {item.leaveLeave == 1 && (
                                    <button className={'btn-agis-danger-outline mt-2'} style={{height: "30px", width: '100%', minWidth: "0px"}} onClick={(e) => {this.vehicleActionClick(item.did, "BUTN_WORKSHOP_OUT")}}>Виїзд з СТО</button>
                                )}

                                {item.driveAllow == 1 && (
                                    <button className={'btn-agis-warning-outline mt-2'} style={{height: "30px", width: '100%', minWidth: "0px"}} onClick={(e) => {this.vehicleActionClick(item.did, "BUTN_TESTDRIVE_ACC")}}>Дозволяю виїзд на ТД</button>
                                )}
                                {item.driveLeave == 1 && (
                                    <button className={'btn-agis-danger-outline mt-2'} style={{height: "30px", width: '100%', minWidth: "0px"}} onClick={(e) => {this.vehicleActionClick(item.did, "BUTN_TESTDRIVE_OUT")}}>Виїзд на ТД</button>
                                )}
                                {item.driveBack == 1 && (
                                    <button className={'btn-agis-warning-outline mt-2'} style={{height: "30px", width: '100%', minWidth: "0px"}} onClick={(e) => {this.vehicleActionClick(item.did, "BUTN_TESTDRIVE_RET")}}>Повернення із ТД</button>
                                )}
                            </div>
                        </div>
                    </div>
                );
            });
        }
    }

    render() {
        const {lang, access} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="container-fluid pl-0 pr-0">
                                
                                <div className="row mb-3">
                                    <div className="col">
                                        <div className="card-agis pt-1 pb-1" style={{borderRadius: "15px"}}>
                                            <div className="d-flex justify-content-between mb-1">
                                                <button className="btn-agis-light-outline" style={{height: "36px", width: "36px", minWidth: "36px"}} onClick={this.datePrev}><FontAwesomeIcon icon={faChevronLeft}/></button>
                                                <input type="date" className="form-control form-control-sm ml-1 mr-1" style={{height: "36px", borderRadius: "10px", border: "0px", textAlign: "center"}} onChange={this.changeDate} value={this.dateToInputFormat(this.state.filterDate)}/>
                                                <button className="btn-agis-light-outline" style={{height: "36px", width: "36px", minWidth: "36px"}} onClick={this.dateNext}><FontAwesomeIcon icon={faChevronRight}/></button>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <button onClick={() => this.typeChange(3)} className={this.state.type == 3 ? 'btn-agis-primary-outline' : 'btn-agis-light-outline'} style={{height: "30px", width: '23%', minWidth: "0px", fontSize: "12px"}}>{localization[lang].now}</button>
                                                <button onClick={() => this.typeChange(1)} className={this.state.type == 1 ? 'btn-agis-primary-outline' : 'btn-agis-light-outline'} style={{height: "30px", width: '22%', minWidth: "0px", fontSize: "12px"}}>{localization[lang].in}</button>
                                                <button onClick={() => this.typeChange(2)} className={this.state.type == 2 ? 'btn-agis-primary-outline' : 'btn-agis-light-outline'} style={{height: "30px", width: '22%', minWidth: "0px", fontSize: "12px"}}>{localization[lang].out}</button>
                                                <button onClick={() => this.typeChange(0)} className={this.state.type == 0 ? 'btn-agis-primary-outline' : 'btn-agis-light-outline'} style={{height: "30px", width: '33%', minWidth: "0px", fontSize: "12px"}}>{localization[lang].all}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between mb-2">
                                    <input type="text" className="question-input" style={{height: "40px", width: "100%", fontSize: "16px", borderRadius: "10px", backgroundColor: "#fff"}} placeholder={localization[lang].filter} value={this.state.filterText} onChange={this.onFilterChange}  />
                                </div>

                                {this.renderList()}

                                {access.includes('12001') && (
                                    <div className="fixed-bottom text-right" style={{zIndex: "4", paddingRight: "10px", paddingBottom: "10px", height: "60px"}}>
                                        <Link to={"/territory/vehicle/new"} className="a-agis-light-outline" style={{height: '50px', width: "50px", minWidth: "0px", borderRadius: "25px", lineHeight: "44px", backgroundColor: "#1a73e8", color: "white", border: "1px solid #ebebeb", boxShadow: "0 1px 1px rgba(0,0,0,.15)"}}><FontAwesomeIcon icon={faPlus} style={{fontSize: "20px"}} /></Link>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TerritoryVehicles;