import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';
import imageCompression from 'browser-image-compression';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLayerGroup, faSave, faCamera, faChevronRight, faChevronLeft, faPaperclip, faComment, faTruck, faShareAlt, faCopy, faExternalLinkAlt, faCheck } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../AppContext.js';
import OffsetTop from '../../../components/offsetTop.js';
import Preview from '../../../components/preview.js';

const localization = {
    ua: {
        title: 'Чек-лист механiка',
        nearestKM: "Пробіг до ТО: ",
        nearestMH: "Мотогодин до ТО: ",
        priority: 'пріоритет:',
        submenu: {
            sections: 'Секції',
            recommendations: 'Рекомендації',
            information: 'Iнформацiя',
            save: 'Зберегти'
        },
        question: {
            good: 'Добре',
            planing: 'Планувати',
            critical: 'Критично',
            notchecked: 'Не перевірялося',
            replacement: 'Заміна', 
            restoration: 'Реставрація', 
            notestablished: 'Не встановлено',
            remarkPlaceholder: 'Примiтка...',
            prev: 'Попередня',
            next: 'Наступна'
        },
        save: {
            found: 'Виявлено:',
            button: 'Закрити чек-лист',
            link: 'Публічне посилання',
            open: 'Відкрити',
            share: 'Відправити',
            copy: 'Скопіювати'
        },
        noData: 'Данi вiдсутнi'
    },
    ru: {
        title: 'Чек-лист механика',
        nearestKM: "Пробег до ТО: ",
        nearestMH: "Моточасов до ТО: ",
        priority: 'Приоритет:',
        submenu: {
            sections: 'Секции',
            recommendations: 'Рекомендации',
            information: 'Информация',
            save: 'Сохранить'
        },
        question: {
            good: 'Хорошо',
            planing: 'Планировать',
            critical: 'Критично',
            notchecked: 'Не проверялось',
            replacement: 'Замена', 
            restoration: 'Реставрация', 
            notestablished: 'Не установлено',
            remarkPlaceholder: 'Примечание...',
            prev: 'Предыдущая',
            next: 'Следующая'
        },
        save: {
            found: 'Обнаружено:',
            button: 'Закрыть чек-лист',
            link: 'Публичная ссылка',
            open: 'Открыть',
            share: 'Отправить',
            copy: 'Скопировать'
        },
        noData: 'Нет данных'
    },
    en: {
        title: 'Mechanic checklist',
        nearestKM: "Milleage before service: ",
        nearestMH: "Motor hours before service: ",
        priority: 'Priority:',
        submenu: {
            sections: 'Sections',
            recommendations: 'Recommendations',
            information: 'Information',
            save: 'Save'
        },
        question: {
            good: 'Good',
            planing: 'Planing',
            critical: 'Critical',
            notchecked: 'Not checked',
            replacement: 'Replacement', 
            restoration: 'Restoration', 
            notestablished: 'Not established',
            remarkPlaceholder: 'Comment...',
            prev: 'Previous',
            next: 'Next'
        },
        save: {
            found: 'Found:',
            button: 'Save checklist',
            link: 'Public link',
            open: 'Open',
            share: 'Share',
            copy: 'Copy'
        },
        noData: 'No data'
    },
}

class Mechanic extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,

            did: null,

            redirect: null,

            currentView: 'sections',
            currentSection: null,

            header: null,
            sections: []
        }

        this.setPriority = this.setPriority.bind(this);

        this.setView = this.setView.bind(this);
        this.setSection = this.setSection.bind(this);
        this.nextSection = this.nextSection.bind(this);
        this.prevSection = this.prevSection.bind(this);

        this.groupClick = this.groupClick.bind(this);
        this.questionClick = this.questionClick.bind(this);
        this.remarkChange = this.remarkChange.bind(this);
        this.remarkBlur = this.remarkBlur.bind(this);
        this.remarkKeyPress = this.remarkKeyPress.bind(this);
        this.expandDescription = this.expandDescription.bind(this);
        this.attachmentFocus = this.attachmentFocus.bind(this);
        this.addToFileList = this.addToFileList.bind(this);
        this.removeFromFileList = this.removeFromFileList.bind(this);

        this.save = this.save.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        this.loadList();
    }

    loadList() {
        const {addNotification, removeNotification, token} = this.context;

        var did = this.props.match.params.did;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");

            var headerPromise = axios.post('/api/order/information', { token: token, did: did });
            var questionsPromise = axios.post('/api/order/check-list/mechanic/questions', { token: token, did: did });

            axios.all([headerPromise, questionsPromise]).then(axios.spread((header, questions) => {
                var sections = [];

                questions.data.rows.forEach((item) => {
                    var index = sections.findIndex(i => i.gid === item.gid);

                    if (index < 0) {
                        sections.push({gid: item.gid, gname: item.gname, questions: [{qid: item.qid, question: item.question, description: item.description, descriptionExpand: false, status: item.status, remark: item.remark, folder: item.folder, files: item.files}]});
                    } else {
                        sections[index].questions.push({qid: item.qid, question: item.question, description: item.description, descriptionExpand: false, status: item.status, remark: item.remark, folder: item.folder, files: item.files});
                    }
                });

                this.setState({
                    isLoading: false,

                    token: token, 
                    did: did, 

                    header: header.data.rows[0],
                    sections: sections
                });
            })).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            this.setState(
                {
                    isLoading: false,

                    header: {
                        address: "61162, м.Харків, пр. 50 років СРСР, 2/3",
                        chassis: "5306740",
                        company: "ПУБЛІЧНЕ АКЦІОНЕРНЕ ТОВАРИСТВО \"МИРОНІВСЬКИЙ ХЛІБОПРОДУКТ\" Харківська філія  ПАТ \"Миронівський хлібопродукт\"",
                        date: "03.12.2019",
                        did: "97184",
                        doc: "1910292",
                        driver: "Илья",
                        machineMilleage: "-",
                        machineName: "",
                        machineVIN: "",
                        manufacturer: "SCANIA",
                        milleage: "1 056 439",
                        phone: "057-713-69-12",
                        stateNumber: "AI2231HO",
                        type: "P380LA4X2HLA",
                        vin: "YS2P4X20005306740",
                        year: "15.10.2012",
                        priority: "2",
                        nearestKM: "128",
                        nearestMH: "50",
                    },

                    sections: [
                        {
                            gid: 1, 
                            gname: '3. Паливна і вихлопна системи',
                            questions: [
                                { 
                                    qid: 1, 
                                    question: 'Паливопровід на предмет витоків і пошкоджень', 
                                    description: "- Учтены запланированные объёмы работ.<br/>- Удобно перемещаться вокруг транспортных средств.", 
                                    expandDescription: false, 
                                    attachmentHover: false, 
                                    status: '2', 
                                    remark: 'line<br/>line2', 
                                    folder: "000940830001", 
                                    files: ["1.jpg", "2.mp4"] 
                                },
                                { 
                                    qid: 2, 
                                    question: 'Цілісність проводів кришки сепаратора', 
                                    description: "", 
                                    expandDescription: false, 
                                    attachmentHover: false, 
                                    status: '3', 
                                    remark: 'KJK', 
                                    folder: "000940830002", 
                                    files: [] 
                                },
                            ]
                        },
                        {
                            gid: 3, 
                            gname: '12-13. Підвіска і рульове управління',
                            questions: [
                                { qid: 1, question: 'Утечки масла', description: "", expandDescription: false, attachmentHover: false, status: '4', remark: '', folder: "000940830013", files: [] },
                            ]
                        }
                    ],
                    did: did
                },
            );
        }
    }

    setPriority(priority) {
        const {addNotification, removeNotification, token} = this.context;

        var u = addNotification("Завантаження...");
        axios.post('/api/order/information/edit', { token: token, did: this.state.did, fname: "FPRIORITY", fvalue: priority }).then((res) => {
            this.state.header.priority = priority;
            this.forceUpdate();
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    setView(view) {
        this.setState({currentView: view}, () => {
            window.scrollTo(0, 0);
        });
    }

    setSection(section) {
        this.setState({currentView: 'questions', currentSection: section}, () => {
            window.scrollTo(0, 0);
        });
    }

    nextSection() {
        var currentIndex = this.state.sections.findIndex(i => i.gid === this.state.currentSection);
        var newIndex = currentIndex + 1;

        if (newIndex >= this.state.sections.length) {
            newIndex = 0;
        }

        this.setState({currentSection: this.state.sections[newIndex].gid}, () => {
            window.scrollTo(0, 0);
        });
    }

    prevSection() {
        var currentIndex = this.state.sections.findIndex(i => i.gid === this.state.currentSection);
        var newIndex = currentIndex - 1;

        if (newIndex < 0) {
            newIndex = this.state.sections.length-1;
        }

        this.setState({currentSection: this.state.sections[newIndex].gid}, () => {
            window.scrollTo(0, 0);
        });
    }

    groupClick(id, status) {
        const {addNotification, removeNotification, token} = this.context;

        var si;

        var si = this.state.sections.findIndex((item) => {
            return item.gid == id
        });

        if (si != -1) {
            var u = addNotification("Завантаження...");
            axios.post('/api/order/check-list/mechanic/answerGroup', { token: token, did: this.state.did, gid: id, status: status }).then((res) => {
                //this.state.sections[si].questions[qi].status = status;
                this.state.sections[si].questions.forEach(question => question.status = status);
                this.forceUpdate();
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        }
    }

    questionClick(id, status) {
        const {addNotification, removeNotification, token} = this.context;

        var si, qi;

        var si = this.state.sections.findIndex((item) => {
            return item.gid == this.state.currentSection
        });

        if (si != -1) {
            var qi = this.state.sections[si].questions.findIndex((item) => {
                return item.qid == id
            });

            if (qi != -1) {
                var u = addNotification("Завантаження...");
                axios.post('/api/order/check-list/mechanic/answer', { token: token, did: this.state.did, qid: this.state.sections[si].questions[qi].qid, status: status, remark: this.state.sections[si].questions[qi].remark }).then((res) => {
                    this.state.sections[si].questions[qi].status = status;
                    this.forceUpdate();
                }).catch((error) => {
                    addNotification(error.response.data, error.response.status);
                }).finally(function () {
                    removeNotification(u);
                });
            }
        }
    }

    remarkChange(e, id) {
        var si, qi;

        var si = this.state.sections.findIndex((item) => {
            return item.gid == this.state.currentSection
        });

        if (si != -1) {
            var qi = this.state.sections[si].questions.findIndex((item) => {
                return item.qid == id
            });

            if (qi != -1) {
                this.state.sections[si].questions[qi].remark = e.target.value;
                this.forceUpdate();
            }
        }
    }

    remarkBlur(id) {
        const {addNotification, removeNotification, token} = this.context;

        var si, qi;

        var si = this.state.sections.findIndex((item) => {
            return item.gid == this.state.currentSection
        });

        if (si != -1) {
            var qi = this.state.sections[si].questions.findIndex((item) => {
                return item.qid == id
            });

            if (qi != -1) {
                var u = addNotification("Завантаження...");
                axios.post('/api/order/check-list/mechanic/answer', { token: token, did: this.state.did, qid: this.state.sections[si].questions[qi].qid, status: this.state.sections[si].questions[qi].status, remark: this.state.sections[si].questions[qi].remark }).then((res) => {
                }).catch((error) => {
                    addNotification(error.response.data, error.response.status);
                }).finally(function () {
                    removeNotification(u);
                });
            }
        }
    }

    remarkKeyPress(e) {
        if (e.key === 'Enter') {
            e.target.blur();
        }
    }

    expandDescription(id) {
        var si, qi;

        var si = this.state.sections.findIndex((item) => {
            return item.gid == this.state.currentSection
        });

        if (si != -1) {
            var qi = this.state.sections[si].questions.findIndex((item) => {
                return item.qid == id
            });

            if (qi != -1) {
                this.state.sections[si].questions[qi].descriptionExpand = !this.state.sections[si].questions[qi].descriptionExpand;
                this.forceUpdate();
            }
        }
    }

    attachmentFocus(qid, fid, status) {
        var si, qi;

        var si = this.state.sections.findIndex((item) => {
            return item.gid == this.state.currentSection
        });

        if (si != -1) {
            var qi = this.state.sections[si].questions.findIndex((item) => {
                return item.qid == qid
            });

            if (qi != -1) {
                var fi = this.state.sections[si].questions[qi].files.findIndex((item) => {
                    return item == fid
                });

                this.state.sections[si].questions[qi].files[fi].attachmentHover = status;
                this.forceUpdate();
            }
        }
    }

    addToFileList(folfer, filename) {
        var si, qi;

        var si = this.state.sections.findIndex((item) => {
            return item.gid == this.state.currentSection
        });

        if (si != -1) {
            var qi = this.state.sections[si].questions.findIndex((item) => {
                return item.folder == folfer
            });

            if (qi != -1) {
                this.state.sections[si].questions[qi].files.push(filename);
                this.forceUpdate();
            }
        }
    }

    removeFromFileList(folfer, filename) {
        var si, qi, fi;

        var si = this.state.sections.findIndex((sitem) => {
            return sitem.gid == this.state.currentSection
        });

        if (si != -1) {
            var qi = this.state.sections[si].questions.findIndex((qitem) => {
                return qitem.folder == folfer
            });

            if (qi != -1) {
                var fi = this.state.sections[si].questions[qi].files.findIndex((fitem) => {
                    return fitem == filename
                });
                this.state.sections[si].questions[qi].files.splice(fi, 1);
                this.forceUpdate();
            }
        }
    }

    save() {
        const {addNotification, removeNotification, token} = this.context;

        var u = addNotification("Завантаження...");
        axios.post('/api/order/check-list/mechanic/save', { token: token, did: this.state.did }).then((res) => {
            if (res.data.serror == "") {
                this.setState({redirect: "/"});
            } else {
                addNotification(res.data.serror, 2);
            }
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    render() {
        const {lang} = this.context;

        if (this.state.redirect !== null) {
            return <Redirect to={this.state.redirect} />
        }

        if (this.state.isLoading) {
            return (
                <div></div>
            )
        }
       
        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        var view = <div></div>;
        if (this.state.currentView === 'sections') {
            view = <SectionsView header={this.state.header} sections={this.state.sections} setPriority={this.setPriority} groupClick={this.groupClick} setSection={this.setSection} />
        } else if (this.state.currentView === 'questions') {
            if (this.state.currentSection !== null) {

                var s = this.state.sections.find((item) => {
                    return item.gid == this.state.currentSection
                });

                view = <QuestionsView section={s} nextSection={this.nextSection} prevSection={this.prevSection} questionClick={this.questionClick} remarkChange={this.remarkChange} remarkBlur={this.remarkBlur} remarkKeyPress={this.remarkKeyPress} expandDescription={this.expandDescription} attachmentFocus={this.attachmentFocus} addToFileList={this.addToFileList} removeFromFileList={this.removeFromFileList} />
            }
        }else if (this.state.currentView === 'save') {
            view = <SaveView save={this.save} did={this.state.did} sections={this.state.sections} />
        }

        return (
            <div>
                <DocumentMeta {...meta} />
                <Submenu did={this.state.did} setView={this.setView} view={this.state.currentView} />
                <OffsetTop submenu={true} />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            {view}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class Submenu extends React.Component {
    static contextType = AppContext;
    
    constructor(props) {
        super(props);
    }

    render() {
        const {scale, lang} = this.context;

        if (scale == 'xs') {
            return (
                <div className="fixed-bottom second-menu" style={{height: '40px', backgroundColor: '#F1F3F4', boxShadow: '0px -1px 0px rgba(0,0,0,.15)'}}>
                    <div className="container-fluid">
                        <div className="row pt-1 pb-1">
                            <div className="col text-center">
                                <button className="btn btn-link btn-sm" style={{color: this.props.view == 'sections' ? '#4184b4' : '#868e96', fontSize: '20px', lineHeight: '20px'}} onClick={() => this.props.setView('sections')}><FontAwesomeIcon icon={faLayerGroup} /> <span className="d-none d-sm-inline">{localization[lang].submenu.sections}</span></button>
                            </div>
                            <div className="col text-center">
                                <Link to={"/order/" + this.props.did + "/recommendations"} className="btn btn-link btn-sm" style={{color: this.props.view == 'recommendations' ? '#4184b4' : '#868e96', fontSize: '20px', lineHeight: '20px'}}><FontAwesomeIcon icon={faComment} /> <span className="d-none d-sm-inline">{localization[lang].submenu.recommendations}</span></Link>
                            </div>
                            <div className="col text-center">
                                <Link to={"/order/" + this.props.did + "/about"} className="btn btn-link btn-sm" style={{color: this.props.view == 'information' ? '#4184b4' : '#868e96', fontSize: '20px', lineHeight: '20px'}}><FontAwesomeIcon icon={faTruck} /> <span className="d-none d-sm-inline">{localization[lang].submenu.information}</span></Link>
                            </div>
                            <div className="col text-center">
                                <button className="btn btn-link btn-sm" style={{color: this.props.view == 'save' ? '#4184b4' : '#868e96', fontSize: '20px', lineHeight: '20px'}} onClick={() => this.props.setView('save')}><FontAwesomeIcon icon={faSave} /> <span className="d-none d-sm-inline">{localization[lang].submenu.save}</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="fixed-top second-menu second-menu-top">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <div className="container-fluid pl-0 pr-0">
                                <div className="row">
                                    <div className="col">
                                        <button className="btn btn-link btn-sm pl-0" style={{color: this.props.view == 'sections' ? '#2a5679' : '#868e96'}} onClick={() => this.props.setView('sections')}>{localization[lang].submenu.sections}</button>
                                        <Link to={"/order/" + this.props.did + "/recommendations"} className="btn btn-link btn-sm" style={{color: this.props.view == 'recommendations' ? '#2a5679' : '#868e96'}} onClick={() => this.props.setView('recommendations')}>{localization[lang].submenu.recommendations}</Link>
                                        <Link to={"/order/" + this.props.did + "/about"} className="btn btn-link btn-sm" style={{color: this.props.view == 'information' ? '#2a5679' : '#868e96'}} onClick={() => this.props.setView('information')}>{localization[lang].submenu.information}</Link>
                                        <button className="btn btn-link btn-sm" style={{color: this.props.view == 'save' ? '#2a5679' : '#868e96'}} onClick={() => this.props.setView('save')}>{localization[lang].submenu.save}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class SectionsView extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
    }

    renderList() {
        const {lang} = this.context;

        if (this.props.sections.length === 0) {
            return (
                <div className="row">
                    <div className="col">
                        <div className="card agis-card">
                            <div className="row">
                                <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                    <div>
                                        <h6><b>{localization[lang].noData}</b></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return this.props.sections.map((item, i) => {

                var progress = item.questions.filter((q) => {
                    return q.status !== "0";
                });

                var progressPercentage = Math.round(100 / item.questions.length * progress.length)

                var progressColor = "#4184b4";
                var qi = item.questions.findIndex((item) => {
                    return (item.status == "2" || item.status == "3")
                });
                if (qi != -1) {
                    progressColor = "#FFCF49";
                } else {
                    var allGrey = true;
                    item.questions.forEach(question => {if (question.status != "4"){allGrey = false;}});
                    if (allGrey) {
                        progressColor = "#868e96";
                    }
                }

                var fileQuantity = 0;
                for (const q of item.questions){
                    if (q.files.length > 0) {
                        fileQuantity += q.files.length;
                    }
                }

                return (
                    <div className="row" key={"section_"+item.gid}>
                        <div className="col">
                            <div className="card agis-card pt-1 pb-1 position-component" onClick={() => this.props.setSection(item.gid)}>
                                <div className="row">
                                    <div className="col">
                                        <h6><b>{item.gname}</b></h6>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="d-flex align-items-center justify-content-between mb-1">
                                            <div onClick={(e) => {e.stopPropagation()}} className="w-50 d-flex justify-content-between">
                                                <button className={item.status == "1" ? 'btn-agis-success-outline' : 'btn-agis-light-outline'} style={{height: "30px", width: 'calc(34% - 4px)', minWidth: "0px", fontSize: "10px", backgroundColor: "#f4f4f4"}} onClick={(e) => this.props.groupClick(item.gid, "1")}> {localization[lang].question.good}</button>
                                                <button className={item.status == "4" ? 'btn-agis-secondary-outline' : 'btn-agis-light-outline'}  style={{height: "30px", width: 'calc(66% - 4px)', minWidth: "0px", fontSize: "10px", backgroundColor: "#f4f4f4"}} onClick={(e) => this.props.groupClick(item.gid, "4")}> {localization[lang].question.notchecked}</button>
                                            </div>
                                            <div className="w-25 text-right">
                                                {fileQuantity > 0 && (
                                                    <span className="text-muted" style={{fontSize: "10px"}}>{fileQuantity} <FontAwesomeIcon icon={faCamera} /></span>
                                                )}
                                            </div>
                                            <div className="w-25 text-right">
                                                <span className="text-muted" style={{fontSize: "10px"}}>{progress.length + " / " + item.questions.length}</span>
                                            </div>
                                        </div>
                                        <div class="progress w-100" style={{height: "5px"}}>
                                            <div class="progress-bar" role="progressbar" style={{width: progressPercentage+'%', backgroundColor: progressColor}} aria-valuenow={progressPercentage} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        }
    }

    render() {
        const {lang} = this.context;

        return (
            <div className="container-fluid pl-0 pr-0">
                <div className="row mb-1">
                    <div className="col-md-10 offset-md-1">
                         <div className="row">
                            <div className="col">
                                <h5><b>{this.props.header.stateNumber}, {this.props.header.manufacturer}</b></h5>
                                {this.props.header.nearestKM != "" && (<small>{localization[lang].nearestKM}<b>{this.props.header.nearestKM}</b></small>)}{(this.props.header.nearestKM != "" && this.props.header.nearestMH != "") && (<small>. </small>)}{this.props.header.nearestMH != "" && (<small>{localization[lang].nearestMH}<b>{this.props.header.nearestMH}</b></small>)}
                            </div>
                         </div>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-md-10 offset-md-1">
                        <small>{localization[lang].priority}</small>
                        <div className="d-flex justify-content-between">
                            <button className={this.props.header.priority == "1" ? 'btn-agis-primary-outline' : 'btn-agis-light-outline bc-grey'} style={{height: "30px", width: 'calc(20% - 4px)', minWidth: "0px", fontSize: "14px"}} onClick={(e) => this.props.setPriority("1")}> <b>1</b></button>
                            <button className={this.props.header.priority == "2" ? 'btn-agis-primary-outline' : 'btn-agis-light-outline bc-grey'} style={{height: "30px", width: 'calc(20% - 4px)', minWidth: "0px", fontSize: "14px"}} onClick={(e) => this.props.setPriority("2")}> <b>2</b></button>
                            <button className={this.props.header.priority == "3" ? 'btn-agis-primary-outline' : 'btn-agis-light-outline bc-grey'} style={{height: "30px", width: 'calc(20% - 4px)', minWidth: "0px", fontSize: "14px"}} onClick={(e) => this.props.setPriority("3")}> <b>3</b></button>
                            <button className={this.props.header.priority == "4" ? 'btn-agis-primary-outline' : 'btn-agis-light-outline bc-grey'} style={{height: "30px", width: 'calc(20% - 4px)', minWidth: "0px", fontSize: "14px"}} onClick={(e) => this.props.setPriority("4")}> <b>4</b></button>
                            <button className={this.props.header.priority == "5" ? 'btn-agis-primary-outline' : 'btn-agis-light-outline bc-grey'} style={{height: "30px", width: 'calc(20% - 4px)', minWidth: "0px", fontSize: "14px"}} onClick={(e) => this.props.setPriority("5")}> <b>5</b></button>
                        </div>
                    </div>
                </div>

                {this.renderList()}
            </div>
        )
    }
}

class QuestionsView extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            uploadFolder: ""
        }
        
        this.removeFile = this.removeFile.bind(this);
        this.triggerFileInput = this.triggerFileInput.bind(this);
        this.changeFileInput = this.changeFileInput.bind(this);
    }

    removeFile = (filename, folder) => {
        const {addNotification, removeNotification} = this.context;

        var u = addNotification("Завантаження...");
        axios.post('/api/order/check-list/mechanic/attachment/remove', { filename: filename, folder: folder }).then((res) => {
            //remove file names to list
            this.props.removeFromFileList(folder, filename);
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    triggerFileInput = (uploadFolder) => {
        this.setState({uploadFolder: uploadFolder}, () => {
            document.getElementById("fileInput").click();
        });
    }

    changeFileInput = event => {
        const {addNotification, removeNotification} = this.context;

        // Copy files to array
        var files = [];
        for (var i = 0; i < event.target.files.length; i++) {
            files.push(event.target.files[i]);
        }
        event.target.value = null;

        // Create data object
        var data = new FormData();
        data.append('length', files.length);
        data.append('folder', this.state.uploadFolder)

        // Attach files to data object
        var compressPromises = [];
        var fileIndex = 0; // file index because compressed images has different order

        for (var i = 0; i < files.length; i++) {
            if (files[i].type == "video/mp4") {
                data.append("file"+fileIndex, files[i]);
                fileIndex = fileIndex++;
            } else {
                compressPromises.push(imageCompression(files[i], {
                    maxSizeMB: 10,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true
                }));
            }
        }

        // Images should be compressed, wait
        Promise.all(compressPromises).then(results => {
            results.forEach(compressedFile => {
                data.append("file"+fileIndex, compressedFile, compressedFile.name);
                fileIndex = fileIndex++;
            });

            var u = addNotification("Завантаження...");
            axios.post('/api/order/check-list/mechanic/attachment/add', data).then((res) => {
                this.props.addToFileList(this.state.uploadFolder, res.data.filename);
                this.setState({uploadFolder: ""});
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        });
    }

    renderList() {
        const {lang} = this.context;

        if (this.props.section.questions.length === 0) {
            return (
                <div className="row">
                    <div className="col">
                        <div className="card agis-card">
                            <div className="row">
                                <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                    <div>
                                        <h6><b>{localization[lang].noData}</b></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return this.props.section.questions.map((item, i) => {
                return (
                    <div className="row" key={"question_"+item.qid}>
                        <div className="col">
                            <div className="card agis-card pt-1 pb-1">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-8 order-lg-2 mb-2 mb-lg-0" style={{display: "flex", alignItems: "center"}}>
                                        <div>
                                            <h6><b>{item.question}</b></h6>
                                            {item.description != "" && (
                                                <div className="row">
                                                    <div className="col" dangerouslySetInnerHTML={{__html: item.description}}></div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-4 order-lg-1" style={{display: "flex", alignItems: "center"}}>
                                        <div className="w-100">
                                            <div className="d-flex justify-content-between">
                                                <button className={item.status == "1" ? 'btn-agis-success-outline' : 'btn-agis-light-outline'} style={{height: "30px", width: 'calc(20% - 4px)', minWidth: "0px", fontSize: "10px"}} onClick={(e) => this.props.questionClick(item.qid, "1")}> {this.props.section.gid >= 150 && this.props.section.gid <= 199 ? localization[lang].question.good : localization[lang].question.good}</button>
                                                <button className={item.status == "2" ? 'btn-agis-warning-outline' : 'btn-agis-light-outline'} style={{height: "30px", width: 'calc(24% - 4px)', minWidth: "0px", fontSize: "10px"}} onClick={(e) => this.props.questionClick(item.qid, "2")}> {this.props.section.gid >= 150 && this.props.section.gid <= 199 ? localization[lang].question.replacement : localization[lang].question.planing}</button>
                                                <button className={item.status == "3" ? 'btn-agis-danger-outline' : 'btn-agis-light-outline'}  style={{height: "30px", width: 'calc(22% - 4px)', minWidth: "0px", fontSize: "10px"}} onClick={(e) => this.props.questionClick(item.qid, "3")}> {this.props.section.gid >= 150 && this.props.section.gid <= 199 ? localization[lang].question.restoration : localization[lang].question.critical}</button>
                                                <button className={item.status == "4" ? 'btn-agis-secondary-outline' : 'btn-agis-light-outline'}  style={{height: "30px", width: 'calc(34% - 4px)', minWidth: "0px", fontSize: "10px"}} onClick={(e) => this.props.questionClick(item.qid, "4")}> {this.props.section.gid >= 150 && this.props.section.gid <= 199 ? localization[lang].question.notestablished : localization[lang].question.notchecked}</button>
                                                <button className="btn-agis-light-outline" style={{height: "30px", width: "30px", minWidth: "30px"}} onClick={() => {this.triggerFileInput(item.folder)}}><FontAwesomeIcon icon={faCamera}/></button>
                                            </div>
                                            
                                            {(item.status === "2" || item.status === "3") && (
                                                <div className="mt-2">
                                                    {/* <input type="text" className="input-agis-block w-100" placeholder={localization[lang].question.remarkPlaceholder} value={item.remark} onChange={(e) => this.props.remarkChange(e, item.qid)} onBlur={() => this.props.remarkBlur(item.qid)} onKeyPress={(e) => this.props.remarkKeyPress(e)} /> */}
                                                    <textarea className="textarea-agis-block w-100" rows="3" placeholder={localization[lang].question.remarkPlaceholder} value={item.remark} onChange={(e) => this.props.remarkChange(e, item.qid)} onBlur={() => this.props.remarkBlur(item.qid)}></textarea>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {item.files.length > 0 && (
                                    <div className="row mt-2">
                                        {item.files.map((file) =>
                                            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                <Preview src={"/serve/order/check-list/mechanic/attachments/"+item.folder+"/"+file} removeFile={() => {this.removeFile(file, item.folder)}} />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                );
            });
        }
    }

    render() {
        const {lang} = this.context;

        return (
            <div className="container-fluid pl-0 pr-0">
                {/* INPUT FILE UPLOAD */}
                <input type="file" id="fileInput" className="d-none" onChange={this.changeFileInput} accept=".jpg,.jpeg,.png,.bmp,.tiff,.gif,.mp4"/>

                <div className="row mb-2">
                    <div className="col">
                        <h3>{this.props.section.gname}</h3>
                    </div>
                </div>
                {this.renderList()}
                <div className="row mt-2">
                    <div className="col">
                        <button className="btn-agis-light-outline" onClick={(e) => this.props.prevSection()}><FontAwesomeIcon icon={faChevronLeft} style={{fontSize: "12px"}} /> {localization[lang].question.prev}</button>
                    </div>
                    <div className="col text-right">
                        <button className="btn-agis-light-outline" onClick={(e) => this.props.nextSection()}>{localization[lang].question.next} <FontAwesomeIcon icon={faChevronRight} style={{fontSize: "12px"}} /></button>
                    </div>
                </div>
            </div>
        )
    }
}

class SaveView extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.shareLink = this.shareLink.bind(this);
        this.copyLink = this.copyLink.bind(this);
    }

    shareLink() {
        const {addNotification, removeNotification, lang} = this.context;
        
        navigator.share({
            title: localization[lang].title,
            text: '',
            url: window.location.origin + "/public/order/check-list-mechanic/" + this.props.did,
        }).then(() => {
            var u = addNotification("Відправлено...");
            setTimeout(() => {
                removeNotification(u);
            }, 2000);
        }).catch((error) => {
            addNotification('Could not share link: ' + error, 2);
        });
    }

    copyLink() {
        const {addNotification, removeNotification, lang} = this.context;
        navigator.permissions.query({name:'clipboard-write'}).then((res) => {
            if (res.state === 'granted') {
                navigator.clipboard.writeText(window.location.origin + window.location.origin + "/public/order/check-list-mechanic/" + this.props.did).then(() => {
                    var u = addNotification("Скопійовано...");
                    setTimeout(() => {
                        removeNotification(u);
                    }, 2000);
                }).catch(error => {
                    addNotification('Could not copy text: ' + error, 2);
                });
            } else if (res.state === 'prompt') {
                addNotification('Could not copy text: no permission', 2);
            }
        });
    }

    render() {
        const {lang, access} = this.context;

        var html = this.props.sections.map((section, s) => {
            var questions = section.questions.filter(q => (q.status == '2' || q.status == '3'));

            if (questions.length > 0) {
                return (
                    <div className="row mb-3">
                        <div className="col">
                            <div className="card agis-card pt-2 pb-2" style={{borderTopLeftRadius: "15px", borderTopRightRadius: "15px", backgroundColor: '#f1f3f4'}}>
                                <div className="row">
                                    <div className="col">
                                        <h6><b>{section.gname}</b></h6>
                                    </div>
                                </div>
                            </div>

                            {questions.map((question, q) => {
                                var stylee = {};
                                if (q == questions.length-1) {
                                    stylee = {borderBottomLeftRadius: "15px", borderBottomRightRadius: "15px"};
                                }

                                return (
                                    <div className="card agis-card" key={"question_" + q} style={stylee}>
                                        <div className="row">
                                            <div className="col-12 pt-1">
                                                {question.status == '2' && (
                                                    <h6><span className="btn-agis-warning-outline">{localization[lang].question.planing}</span></h6>
                                                )}
                                                {question.status == '3' && (
                                                    <h6><span className="btn-agis-danger-outline">{localization[lang].question.critical}</span></h6>
                                                )}
                                                <div className="mt-1"></div>
                                                <big><b>{question.question}</b></big>
                                            </div>
                                            <div className="col-12">
                                                <span dangerouslySetInnerHTML={{__html: question.remark.replace(/\n/g, "<br />")}}></span>
                                            </div>
                                            {question.files.length > 0 && (
                                                question.files.map((file) =>
                                                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                        <Preview src={"/serve/order/check-list/mechanic/attachments/"+question.folder+"/"+file} removeFile={null} />
                                                    </div>
                                                )
                                            )}
                                            <hr/>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )
            }
        })

        return (
            <div className="container-fluid pl-0 pr-0">
                <div className="row mb-2">
                    <div className="col">
                        <h3>{localization[lang].save.found}</h3>
                    </div>
                </div>

                {html}

                <div className="row mb-3">
                    <div className="col-12">
                        <button className="btn-agis-primary w-100" onClick={(e) => this.props.save()}>{localization[lang].save.button}</button>
                    </div>
                </div>

                {access.includes('1001') && (
                    <div className="row mb-3">
                        <div className="col">
                            <div className="card agis-card pt-2 pb-2" style={{borderTopLeftRadius: "15px", borderTopRightRadius: "15px", borderBottomLeftRadius: "15px", borderBottomRightRadius: "15px"}}>
                                <div className="row">
                                    <div className="col text-center">
                                        <span style={{lineHeight: "24px"}}>{localization[lang].save.link}</span>
                                    </div>
                                </div>
                                <hr/>
                                {navigator.share ? (
                                    <div className="row mt-1">
                                        <div className="col text-center">
                                            <Link to={"/public/order/check-list-mechanic/" + this.props.did} target="_blank" className="a-agis-light-outline" style={{height: "40px", width: 'calc(99% - 10px)', minWidth: "0px"}}><FontAwesomeIcon icon={faExternalLinkAlt} /> {localization[lang].save.open}</Link>
                                        </div>
                                        <div className="col text-center">
                                            <Link onClick={this.shareLink} className="a-agis-light-outline" style={{height: "40px", width: 'calc(99% - 10px)', minWidth: "0px"}}><FontAwesomeIcon icon={faShareAlt} /> {localization[lang].save.share}</Link>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row mt-1">
                                        <div className="col text-center">
                                            <Link to={"/public/order/check-list-mechanic/" + this.props.did} target="_blank" className="a-agis-light-outline" style={{height: "40px", width: 'calc(99% - 10px)', minWidth: "0px"}}><FontAwesomeIcon icon={faExternalLinkAlt} /> {localization[lang].save.open}</Link>
                                        </div>
                                        <div className="col text-center">
                                            <Link onClick={this.copyLink} className="a-agis-light-outline" style={{height: "40px", width: 'calc(99% - 10px)', minWidth: "0px"}}><FontAwesomeIcon icon={faCopy} /> {localization[lang].save.copy}</Link>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default Mechanic;