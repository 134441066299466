import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import DocumentMeta from 'react-document-meta';
import ScaleLoader from "react-spinners/ScaleLoader";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload, faDownload, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../AppContext.js';
import Submenu from '../../../components/submenu.js';
import SubmenuContent from './submenu.js';
import OffsetTop from '../../../components/offsetTop.js';
import {dateToInputFormat, dateToDatabaseFormat} from '../../../utils/datetime.js';

const localization = {
    ua: {
        title: 'Driver Feedback',
        site: 'Дилер',
        from: 'Від',
        to: 'До',
        lastMonth: 'Попередній місяць',
        currentWeek: 'Поточний тиждень',
        currentMonth: 'Поточний місяць',
        table: {
            date: 'Дата візиту',
            status: 'Статус',
            statusAll: 'Усі',
            status1: 'Не опрацьовано',
            status2: 'Опрацьовано',
            workshop: 'Дилер',
            driver: 'Водій',
            phone: 'Телефон',
            company: 'Компанія',
            vehicle: 'Авто',
            recommendation: 'Рекомендація',
            satisfaction: 'Задоволення',
            text: 'Відповідь',
            actionButton: 'Опрацювати',
        }
    },
    ru: {
        title: 'Driver Feedback',
        site: 'Дилер',
        from: 'С',
        to: 'По',
        lastMonth: 'Предыдущий месяц',
        currentWeek: 'Текущая неделя',
        currentMonth: 'Текущий месяц',
        table: {
            date: 'Дата визита',
            status: 'Статус',
            statusAll: 'Все',
            status1: 'Не обработано',
            status2: 'Обработано',
            workshop: 'Дилер',
            driver: 'Водитель',
            phone: 'Телефон',
            company: 'Компания',
            vehicle: 'Авто',
            recommendation: 'Рекомендация',
            satisfaction: 'Удовлетворение',
            text: 'Ответ',
            actionButton: 'Обработать',
        }
    },
    en: {
        title: 'Driver Feedback',
        site: 'Site',
        from: 'From',
        to: 'To',
        lastMonth: 'Last month',
        currentWeek: 'Current week',
        currentMonth: 'Current month',
        table: {
            date: 'Visit date',
            status: 'Status',
            statusAll: 'All',
            status1: 'Open',
            status2: 'Closed',
            workshop: 'Workshop',
            driver: 'Driver',
            phone: 'Phone',
            company: 'Company',
            vehicle: 'Vehicle',
            recommendation: 'Recommendation',
            satisfaction: 'Satisfaction',
            text: 'Text answer',
            actionButton: 'Process',
        }
    },
}

class TextAnswers extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoadingSites: true,
            isLoadingAnswers: true,

            sites: [],
            site: null,
            from: null,
            to: null,

            answers: null,

            filterDate: "",
            filterStatus: 1,
            filterWorkshop: "",
            filterDriver: "",
            filterPhone: "",
            filterCompany: "",
            filterRecommendation: "",
            filterSatisfaction: "",
            filterText: "",
        }

        this.changeSite = this.changeSite.bind(this);
        this.changeFrom = this.changeFrom.bind(this);
        this.changeTo = this.changeTo.bind(this);

        this.changeLastMonth = this.changeLastMonth.bind(this);
        this.changeCurrentWeek = this.changeCurrentWeek.bind(this);
        this.changeCurrentMonth = this.changeCurrentMonth.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        this.loadSites();
    }

    loadSites() {
        const {addNotification, removeNotification, token} = this.context;

        var site = localStorage.getItem("driver-feedback-site");
        var from = localStorage.getItem("driver-feedback-from");
        var to = localStorage.getItem("driver-feedback-to");

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/crm/driver-feedback/sites', { token: token }).then((res) => {
                if (from == null || to == null) {
                    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
                    from = new Date(y, m - 1, 1).getTime();//first day of prev month
                    to = new Date(y, m, 0).getTime();//last day of prev month
        
                    localStorage.setItem('driver-feedback-from', from);
                    localStorage.setItem('driver-feedback-to', to);
                }
        
                if (site == null || (res.data.rows.filter(e => e.id == site).length == 0)) {
                    localStorage.setItem('driver-feedback-site', res.data.rows[0].id);
                    site = res.data.rows[0].id;
                }

                this.setState({
                    isLoadingSites: false,
                    sites: res.data.rows, 
                    site: site,
                    from: Number(from),
                    to: Number(to),
                }, () => this.loadAnswers());
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            if (from == null || to == null) {
                var date = new Date(), y = date.getFullYear(), m = date.getMonth();
                from = new Date(y, m - 1, 1).getTime();//first day of prev month
                to = new Date(y, m, 0).getTime();//last day of prev month
    
                localStorage.setItem('driver-feedback-from', from);
                localStorage.setItem('driver-feedback-to', to);
            }
    
            if (site == null) {
                localStorage.setItem('driver-feedback-site', 5);
            }

            this.setState({
                isLoadingSites: false,

                site: site,
                from: Number(from),
                to: Number(to),
                sites: [
                    {
                        id: "1",
                        name: "ВСЕ",
                    },
                    {
                        id: "-1",
                        name: "All Captive",
                    },
                    {
                        id: "-2",
                        name: "All Non-Captive",
                    },
                    {
                        id: "5",
                        name: "SCANIA Kiev",
                    },
                ]
            }, () => this.loadAnswers());
        }
    }

    changeSite(event) {
        this.setState({site: event.target.value, isLoadingAnswers: true}, () => this.loadAnswers());
    }

    changeFrom(event) {
        this.setState({from: new Date(event.target.value).getTime(), isLoadingAnswers: true}, () => {
            localStorage.setItem('driver-feedback-from', this.state.from);
            this.loadAnswers();
        });
    }

    changeTo(event) {
        this.setState({to: new Date(event.target.value).getTime(), isLoadingAnswers: true}, () => {
            localStorage.setItem('driver-feedback-to', this.state.to);
            this.loadAnswers();
        });
    }

    changeLastMonth() {
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        var from = new Date(y, m - 1, 1).getTime();//first day of prev month
        var to = new Date(y, m, 0).getTime();//last day of prev month

        this.setState({from: from, to: to, isLoadingAnswers: true}, () => {
            localStorage.setItem('driver-feedback-from', from);
            localStorage.setItem('driver-feedback-to', to);
            this.loadAnswers();
        });
    }

    changeCurrentWeek() {
        var date = new Date;

        var monday = date.getDate() - date.getDay() + 1;
        var sunday = monday + 6;

        var from = new Date(date.setDate(monday)).getTime();//first day of prev month
        var to = new Date(date.setDate(sunday)).getTime();//last day of prev month

        this.setState({from: from, to: to, isLoadingAnswers: true}, () => {
            localStorage.setItem('driver-feedback-from', from);
            localStorage.setItem('driver-feedback-to', to);
            this.loadAnswers();
        });
    }

    changeCurrentMonth() {
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();

        var from = new Date(y, m, 1, 0, 0, 0).getTime();  //first day of current month
        var to = new Date(y, m+1, 0, 23, 59, 59).getTime();  //last day of current month

        this.setState({from: from, to: to, isLoadingActions: true}, () => {
            localStorage.setItem('driver-feedback-from', from);
            localStorage.setItem('driver-feedback-to', to);
            this.loadAnswers();
        });
    }

    loadAnswers() {
        const {addNotification, removeNotification, token} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/crm/driver-feedback/text-answers/answers', { token: token, site: this.state.site, from: dateToDatabaseFormat(this.state.from), to: dateToDatabaseFormat(this.state.to) }).then((res) => {
                this.setState({
                    isLoadingAnswers: false,
                    answers: res.data.rows,
                });
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            this.setState({
                isLoadingAnswers: false,
                answers: [
                    {
                        id: "1",
                        status: "0",
                        date: "2020-01-25",
                        workshop: "Kyiv",
                        driver: "Vasia",
                        phone: "0508673516",
                        company: "Silpo",
                        recommendation: "10",
                        satisfaction: "5",
                        text: "Text",
                    },
                    {
                        id: "1",
                        status: "2",
                        date: "2020-01-25",
                        workshop: "Lviv",
                        driver: "Roman",
                        phone: "0508673516",
                        company: "Silpo",
                        recommendation: "10",
                        satisfaction: "5",
                        text: "Text",
                    },
                ]
            });
        }
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        return (
            <div>
                <DocumentMeta {...meta} />
                
                <Submenu>
                    <SubmenuContent />
                </Submenu>

                <br/><br/>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">

                            <div className="container-fluid pb-4 mb-4">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].site}:</span>
                                        <select className="question-input ml-1" style={{width: "220px"}} value={this.state.site + ""} onChange={this.changeSite}>
                                            {this.state.sites.map((item) => {
                                                return (
                                                    <option value={item.id}>{item.name}</option>
                                                )
                                            })}
                                        </select>

                                        <span className="ml-4">{localization[lang].from}:</span>
                                        <input type="date" className="question-input ml-1" style={{width: "220px"}} value={dateToInputFormat(this.state.from)} onChange={this.changeFrom} />

                                        <span className="ml-2">{localization[lang].to}:</span>
                                        <input type="date" className="question-input ml-1" style={{width: "220px"}} value={dateToInputFormat(this.state.to)} onChange={this.changeTo} />
                                        
                                        <button className="btn btn-link ml-4" onClick={this.changeLastMonth}>{localization[lang].lastMonth}</button>
                                        <button className="btn btn-link ml-2" onClick={this.changeCurrentWeek}>{localization[lang].currentWeek}</button>
                                        <button className="btn btn-link ml-2" onClick={this.changeCurrentMonth}>{localization[lang].currentMonth}</button>
                                    </div>
                                </div>
                            </div>

                            {!this.state.isLoadingAnswers && (
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col">
                                            <table className="table table-bordered table-hover" style={{fontSize: "12px"}}>
                                                <thead>
                                                    <tr style={{backgroundColor: "#586981", color: "#fff"}}>
                                                        <th class="align-middle text-center" style={{width: "100px"}}>{localization[lang].table.date}</th>
                                                        <th class="align-middle text-center" style={{width: "160px"}}>{localization[lang].table.status}</th>
                                                        <th class="align-middle text-center" style={{width: "220px"}}>{localization[lang].table.workshop}</th>
                                                        <th class="align-middle text-center" style={{width: "180px"}}>{localization[lang].table.driver}</th>
                                                        <th class="align-middle text-center" style={{width: "150px"}}>{localization[lang].table.phone}</th>
                                                        <th class="align-middle text-center" style={{width: "220px"}}>{localization[lang].table.company}</th>
                                                        <th class="align-middle text-center" style={{width: "150px"}}>{localization[lang].table.recommendation}</th>
                                                        <th class="align-middle text-center" style={{width: "150px"}}>{localization[lang].table.satisfaction}</th>
                                                        <th class="align-middle text-center">{localization[lang].table.text}</th>
                                                        <th class="align-middle text-center" style={{width: "100px"}}></th>
                                                    </tr>
                                                    <tr>
                                                        <td className="p-1"><input type="text" className="form-control form-control-sm w-100" value={this.state.filterDate} onChange={(e) => this.setState({filterDate: e.target.value})} /></td>
                                                        <td className="p-1">
                                                            <select className="form-control form-control-sm w-100" value={this.state.filterStatus+""} onChange={(e) => this.setState({filterStatus: Number(e.target.value)})}>
                                                                <option value="0">{localization[lang].table.statusAll}</option>
                                                                <option value="1">{localization[lang].table.status1}</option>
                                                                <option value="2">{localization[lang].table.status2}</option>
                                                            </select>
                                                        </td>
                                                        <td className="p-1"><input type="text" className="form-control form-control-sm w-100" value={this.state.filterWorkshop} onChange={(e) => this.setState({filterWorkshop: e.target.value})} /></td>
                                                        <td className="p-1"><input type="text" className="form-control form-control-sm w-100" value={this.state.filterDriver} onChange={(e) => this.setState({filterDriver: e.target.value})} /></td>
                                                        <td className="p-1"><input type="text" className="form-control form-control-sm w-100" value={this.state.filterPhone} onChange={(e) => this.setState({filterPhone: e.target.value})} /></td>
                                                        <td className="p-1"><input type="text" className="form-control form-control-sm w-100" value={this.state.filterCompany} onChange={(e) => this.setState({filterCompany: e.target.value})} /></td>
                                                        <td className="p-1"><input type="text" className="form-control form-control-sm w-100" value={this.state.filterRecommendation} onChange={(e) => this.setState({filterRecommendation: e.target.value})} /></td>
                                                        <td className="p-1"><input type="text" className="form-control form-control-sm w-100" value={this.state.filterSatisfaction} onChange={(e) => this.setState({filterSatisfaction: e.target.value})} /></td>
                                                        <td className="p-1"><input type="text" className="form-control form-control-sm w-100" value={this.state.filterText} onChange={(e) => this.setState({filterText: e.target.value})} /></td>
                                                        <td></td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.answers.
                                                        filter(x => x.date.toLowerCase().includes(this.state.filterDate)).
                                                        filter(x => this.state.filterStatus == 0 ? true : this.state.filterStatus == 2 ? x.status == "2" : x.status != "2").
                                                        filter(x => x.workshop.toLowerCase().includes(this.state.filterWorkshop)).
                                                        filter(x => x.driver.toLowerCase().includes(this.state.filterDriver)).
                                                        filter(x => x.phone.toLowerCase().includes(this.state.filterPhone)).
                                                        filter(x => x.company.toLowerCase().includes(this.state.filterCompany)).
                                                        filter(x => x.recommendation.toLowerCase().includes(this.state.filterRecommendation)).
                                                        filter(x => x.satisfaction.toLowerCase().includes(this.state.filterSatisfaction)).
                                                        filter(x => x.text.toLowerCase().includes(this.state.filterText)).
                                                    map((item) => {
                                                        return (
                                                            <tr>
                                                                <td class="align-middle text-center pt-1 pb-1" style={{height: "40px"}}>{item.date}</td>
                                                                <td class="align-middle pt-1 pb-1">{item.status == "2" ? localization[lang].table.status2 : localization[lang].table.status1}</td>
                                                                <td class="align-middle pt-1 pb-1">{item.workshop}</td>
                                                                <td class="align-middle pt-1 pb-1">{item.driver}</td>
                                                                <td class="align-middle pt-1 pb-1">{item.phone}</td>
                                                                <td class="align-middle pt-1 pb-1">{item.company}</td>
                                                                <td class="align-middle text-center pt-1 pb-1">{item.recommendation}</td>
                                                                <td class="align-middle text-center pt-1 pb-1">{item.satisfaction}</td>
                                                                <td class="align-middle pt-1 pb-1">{item.text}</td>
                                                                <td className="d-flex justify-content-between pt-1 pb-1" style={{minHeight: "40px"}}>
                                                                    {item.status != "2" && (
                                                                        <Link to={"/crm/driver-feedback/response-card/"+item.id} className="a-agis-light-outline" style={{height: "28px", lineHeight: "22px", width: "100%", minWidth: "0px", borderRadius: "10px", fontSize: "16px"}}>
                                                                            <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                                                                            <span style={{color: "#868e96", fontSize: "12px", lineHeight: "13px"}}>{localization[lang].table.actionButton}</span>
                                                                        </Link>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TextAnswers;