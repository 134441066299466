import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import AppContext from '../AppContext';

const localization = {
    ua: {
        title: 'Авторизацiя',
        login: 'E-mail (логiн)',
        password: 'Пароль',
        linkText: 'Нагадати пароль',
        buttonText: 'Увiйти'
    },
    ru: {
        title: 'Авторизация',
        login: 'E-mail (логин)',
        password: 'Пароль',
        linkText: 'Напомнить пароль',
        buttonText: 'Войти'
    },
    en: {
        title: 'Login',
        login: 'E-mail (login)',
        password: 'Password',
        linkText: 'Remind password',
        buttonText: 'Login'
    },
}

class Login extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            username: "",
            password: "",
        }
        
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);
    }

    handleInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.login();
        }
    }

    loginClick() {
        this.login();
    }

    login() {
        const {addNotification, removeNotification, authLogin} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/auth/login', { login: this.state.username, password: this.state.password }).then((res) => {
                authLogin(res.data.token, res.data.access, res.data.name, res.data.lang);
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            authLogin("token", "1;1001;2;2001;3;4;5;5003;5004;6;7;8;9;10;10005;12;14;15;16;17;18;6001;6002;6003;6004;6005;6006;6007;6008;16;17;12001;20;19", "Test build", "ua");
        }
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        return (
            <div className="h-100 w-100" style={{position: "absolute", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#f1f3f4"}}>
                <DocumentMeta {...meta} />
            
                <div className="container-fluid" style={{paddingBottom: "20%"}}>
                    <div className="row justify-content-center mt-4">
                        <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
                            <div className="card agis-card pt-1 pb-1">
                                <div className="row mt-2 mb-1">
                                    <div className="col" style={{display: "flex", alignItems: "center"}}>
                                        <img src="/serve/configuration/logo.png" style={{height: "30px"}} alt="logo" />
                                        <span className="display-4 ml-2" style={{fontSize: '1.2rem'}}><b>{this.state.company}</b></span>
                                    </div>
                                </div>

                                <div className="row mb-1">
                                    <div className="col">
                                        <input name="username" type="text" autocomplete="username" className="input-agis-block" style={{width: '100%'}} placeholder={localization[lang].login} value={this.state.username} onChange={this.handleInputChange} onKeyPress={this.handleKeyPress} />
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col">
                                        <input name="password" type="password" className="input-agis-block" style={{width: '100%'}} placeholder={localization[lang].password} value={this.state.password} onChange={this.handleInputChange} onKeyPress={this.handleKeyPress} />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col d-flex justify-content-between">
                                        {/* <Link to={"/restore"} class="btn btn-agis-link pl-0">{localization[lang].linkText}</Link> */}
                                        <span></span>
                                        <button className="btn-agis-primary" onClick={this.loginClick.bind(this)}>{localization[lang].buttonText}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login;