import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faSearch, faPlus, faFilter } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../AppContext.js';
import OffsetTop from '../../../components/offsetTop.js';

const localization = {
    ua: {
        title: 'Рейси',
        filter: {
            date: 'Дата'
        },
        searchText: 'Пошук...',
        noData: 'Данi вiдсутнi',
    },
    ru: {
        title: 'Рейсы',
        filter: {
            date: 'Дата'
        },
        searchText: 'Поиск...',
        noData: 'Данi вiдсутнi',
    },
    en: {
        title: 'Рейси',
        filter: {
            date: 'Дата'
        },
        searchText: 'Filter...',
        noData: 'Данi вiдсутнi',
    },
}

class InspectionWorkshopList extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,

            filterDateFrom: null,
            filterDateTo: null,
            searchText: '',

            balance: '',
            rows: [],
        }

        this.dateToInputFormat= this.dateToInputFormat.bind(this);
        this.dateToDatabaseFormat= this.dateToDatabaseFormat.bind(this);

        this.changeDateFrom= this.changeDateFrom.bind(this);
        this.changeDateTo= this.changeDateTo.bind(this);

        this.searchTextChange = this.searchTextChange.bind(this);
        this.searchTextKeyPress = this.searchTextKeyPress.bind(this);
    }

    componentDidMount() {
        const {token, lang, setMenuExpandText, addNotification, removeNotification} = this.context;
        setMenuExpandText(localization[lang].title);

        var dateTo = Date.now();
        var dateFrom = Date.now() - 604800000 * 4; // 4 weeks in millisec

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/driver/expenses/balance', { token: token }).then((res) => {
                this.setState({
                    filterDateFrom: dateFrom, 
                    filterDateTo: dateTo,
                    balance: res.data.rows[0].balance
                }, () => this.loadList());
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            this.setState({
                filterDateFrom: dateFrom, 
                filterDateTo: dateTo,
                balance: '468.40 UAH, 170.00 EUR, -777.85 AZN, -3043.00 MDL, -13.00 PLN, 31.00 CZK'
            }, () => this.loadList());
        }
    }

    dateToInputFormat(date) {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
            
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
    }

    dateToDatabaseFormat(date) {
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [day, month, year].join('.');
    }

    changeDateFrom(event) {
        this.setState({filterDateFrom: new Date(event.target.value).getTime()}, () => {
            this.loadList();
        });
    }

    changeDateTo(event) {
        this.setState({filterDateTo: new Date(event.target.value).getTime()}, () => {
            this.loadList();
        });
    }

    searchTextChange(e) {
        this.setState({searchText: e.target.value});
    }
    
    searchTextKeyPress(e) {
        if (e.key === 'Enter') {
            e.target.blur();
        }
    }

    loadList() {
        const {addNotification, removeNotification, token} = this.context;
        
        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/driver/expenses/list', { token: token, from: this.dateToDatabaseFormat(this.state.filterDateFrom), to: this.dateToDatabaseFormat(this.state.filterDateTo) }).then((res) => {
                this.setState({rows: res.data.rows, isLoading: false});
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            this.setState({
                isLoading: false,
                rows: [
                    {
                        did: "13", 
                        date: "20.10.2019", 
                        number: "12345678",
                        status: "Done",
                        state: 13,
                        driver1Name: "Yevhenii",
                        driver1Phone: "123456",
                        driver1Details: "main driver",
                        driver2Name: "Oleh",
                        driver2Phone: "654321",
                        driver2Details: "second driver",
                        vehicle: "Kia Sportage",
                        name: "Fuel check", 
                        time: "30.08.2023 13:00:00 .. 02.09.2023 16:05:00", 
                        remark: "Remark text example",
                        expenses: "1549 UAH",
                    }
                ]
            });
        }
    }

    renderList() {
        const {lang} = this.context;

        if (this.state.rows.length === 0) {
            return (
                <div className="row">
                    <div className="col">
                        <div className="card agis-card">
                            <div className="row">
                                <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                    <div>
                                        <h6><b>{localization[lang].noData}</b></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return this.state.rows.filter((item, i) => {
                // check by text filter
                if ([item.date, item.number, item.status, item.driver1Name, item.driver1Phone, item.driver1Details, item.driver2Name, item.driver2Phone, item.driver2Details, item.vehicle, item.name, item.time, item.remark, item.expenses].join(' ').toLowerCase().includes(this.state.searchText.toLowerCase())) {
                    // check by state
                    return true;
                }
                return false;
            }).map((item, i) => {
                return (
                    <div className="row mb-2" key={"list_" + i}>
                        <div className="col">
                            <Link to={"/driver/expenses/" + item.did} className="card-agis clickable pt-2 pb-2" style={{borderRadius: "15px"}}>
                                <div class="d-flex justify-content-between">
                                    <big><b>{item.number}</b> від {item.date}, <span className={item.state == 8 ? "text-warning" : item.state == 13 ? "text-danger" : item.state == 33 ? "text-success": ""}>{item.status}</span></big>
                                </div>
                                <div className="mt-1 mb-1">
                                    <big>{item.driver1Name}, <a href={"tel:"+item.driver1Phone}>{item.driver1Phone}</a>, <i>{item.driver1Details}</i></big>
                                </div>
                                {item.driver2Name != "" && (
                                    <div className="mb-1">
                                        <big>{item.driver2Name}, <a href={"tel:"+item.driver2Phone}>{item.driver2Phone}</a>, <i>{item.driver2Details}</i></big>
                                    </div>
                                )}
                                <div>
                                    <big>{item.vehicle}</big>
                                </div>
                                <div>
                                    <big><b>{item.name}</b></big>
                                </div>
                                <div>
                                    <big className="text-info">{item.time}</big>
                                </div>
                                <div>
                                    <big>{item.remark}</big>
                                </div>
                                <div className="text-right">
                                    <big className="text-success"><b>{item.expenses}</b></big>
                                </div>
                            </Link>
                        </div>
                    </div>
                );
            });
        }
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="container-fluid pl-0 pr-0">

                                <div className="row mb-4">
                                    <div className="col">
                                        <div className="card-agis pt-2 pb-2" style={{borderRadius: "15px", backgroundColor: "rgb(209, 231, 221)", borderColor: "rgb(163, 207, 187)", color: "rgb(10, 54, 34)" }}>
                                            <div class="mb-1">
                                                <big className="">Поточний залишок коштів</big>
                                            </div>
                                            <div>
                                                <big><b>{this.state.balance}</b></big>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row mb-2">
                                    <div className="col">
                                        <div style={{padding: ".3rem .35rem", backgroundColor: "#eee", borderRadius: "10px"}}>
                                            <small id="emailHelp" className="form-text text-muted">{localization[lang].filter.date}</small>
                                            <div className="d-flex justify-content-between">
                                                <input type="date" className="form-control form-control-sm" onChange={this.changeDateFrom} value={this.dateToInputFormat(this.state.filterDateFrom)}/>
                                                <input type="date" className="form-control form-control-sm" onChange={this.changeDateTo}   value={this.dateToInputFormat(this.state.filterDateTo)}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between mb-3">
                                    <input id="searchInput" type="text" className="question-input" style={{height: "40px", width: "100%", fontSize: "16px", borderRadius: "10px", borderTopRightRadius: "0px", borderBottomRightRadius: "0px", backgroundColor: "#fff"}} placeholder={localization[lang].searchText} value={this.state.searchText} onChange={(e) => this.searchTextChange(e)} onKeyPress={(e) => this.searchTextKeyPress(e)} />
                                    <button onClick={this.searchCodeButtonClick} className="a-agis-light-outline" style={{height: '40px', width: "40px", minWidth: "0px", borderRadius: "10px", borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px", lineHeight: "34px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 0 0 1px rgba(0,0,0,.05), 0 2px 3px 0 rgba(0,0,0,.1)"}}><FontAwesomeIcon icon={faSearch} style={{fontSize: "20px"}} /></button>
                                </div>

                                {this.renderList()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default InspectionWorkshopList;