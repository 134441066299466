import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faSearch, faPlus, faFilter } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../AppContext.js';
import OffsetTop from '../../components/offsetTop.js';

const localization = {
    ua: {
        title: 'Акти видачі/повернення',
        list: {
            ro: 'ЗН',
            details: 'Переглянути',
            types: {
                type11: 'Видача пiдзвiт',
                type12: 'Повернення з пiдзвiту',
                type38: 'Видача у виробництво',
                type39: 'Повернення iз виробництва',
                undefined: 'Невизначений'
            },
        },
        noData: 'Данi вiдсутнi',
    },
    ru: {
        title: 'Акты выдачи/получения',
        list: {
            ro: 'ЗН',
            details: 'Просмотреть',
            types: {
                type11: 'Выдача подотчёт',
                type12: 'Возврат из подотчёта',
                type38: 'Выдача в производство',
                type39: 'Возврат из производства',
                undefined: 'Неопределён'
            },
        },
        noData: 'Нет данных',
    },
    en: {
        title: 'Issuance / Receipt acts',
        list: {
            ro: 'RO',
            details: 'Details',
            types: {
                type11: 'Issuance report',
                type12: 'Returning report',
                type38: 'Issuance in production',
                type39: 'Returning from production',
                undefined: 'Undefined'
            },
        },
        noData: 'No data',
    },
}

class StashDocs extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,

            rows: []
        }
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        this.loadList();
    }

    loadList() {
        const {addNotification, removeNotification, token} = this.context;
        
        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/stash/docs', { token: token }).then((res) => {
                this.setState({rows: res.data.rows, isLoading: false});
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            this.setState({
                isLoading: false,
                rows: [
                    {
                        did: 221,
                        doc: "1903774",
                        doctype: 38,
                        date: "11.09.2019",
                        lines: 5,

                        oid: 221,
                        order: "1906181",

                        manufacturer: "Sсania",
                        type: "G440A4X2NA",
                        chassis: "5523965",
                        stateNumber: "AA4806XA"
                    },
                    {
                        did: 221,
                        doc: "1903775",
                        doctype: 39,
                        date: "12.11.2020",
                        lines: 8,

                        oid: null,
                        order: "",

                        manufacturer: "",
                        type: "",
                        chassis: "",
                        stateNumber: ""
                    },
                ]
            });
        }
    }

    renderList() {
        const {lang, access} = this.context;

        if (this.state.rows.length === 0) {
            return (
                <div className="row">
                    <div className="col">
                        <div className="card agis-card">
                            <div className="row">
                                <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                    <div>
                                        <h6><b>{localization[lang].noData}</b></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return this.state.rows.map((item, i) => {
                var typeText = "";

                switch (item.doctype) {
                    case 11: 
                        typeText = localization[lang].list.types.type11;
                        break;
                    case 12: 
                        typeText = localization[lang].list.types.type12;
                        break;
                    case 38: 
                        typeText = localization[lang].list.types.type38;
                        break;
                    case 39: 
                        typeText = localization[lang].list.types.type39;
                        break;
                    default: 
                        typeText = localization[lang].list.types.undefined;
                        break;
                }

                return (
                    <div className="row mb-2" key={"list_" + i}>
                        <div className="col">
                            <div className="card agis-card pt-2 pb-2" style={{borderTopLeftRadius: "15px", borderTopRightRadius: "15px", borderBottomLeftRadius: "15px", borderBottomRightRadius: "15px"}}>
                                <div className="row">
                                    <div className="col-12">
                                        <h6><b>Акт {item.doc}</b></h6>
                                    </div>
                                    <div className="col-12">
                                        <span>{item.date}</span>
                                    </div>
                                    <div className="col-8">
                                        <span>{typeText}</span>
                                    </div>
                                    <div className="col-4 text-right">
                                        <Link to={"/stash/doc/"+item.did} className="">{localization[lang].list.details}</Link>
                                    </div>
                                </div>
                                {item.oid != null && (
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            <span><b>{item.manufacturer} {item.type}</b></span>
                                        </div>
                                        <div className="col-12">
                                            <span>{item.chassis} / {item.stateNumber} </span>
                                        </div>
                                        <div className="col-12">
                                            {access.includes('6') ? (
                                                <Link to={"/order/"+item.oid+"/card"} className="">{localization[lang].list.ro} {item.order}</Link>
                                            ) : (
                                                <span>{localization[lang].list.ro} {item.order}</span>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                );
            });
        }
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="container-fluid pl-0 pr-0">
                                {this.renderList()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default StashDocs;