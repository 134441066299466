import React from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faBed, faBuilding, faHome, faWrench, faSmoking, faSearch } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../AppContext.js';
import OffsetTop from '../../components/offsetTop.js';

const localization = {
    ua: {
        title: 'Інформація про наряд',
        work: {
            title: 'Робоча інформація',
            priority: 'Пріорітет',
        },
        client: {
            title: 'Інформація про клієнта',
            company: 'Назва',
            driver: 'Представник',
            phone: 'Телефон',
            address: 'Адреса',
        },
        vehicle: {
            title: 'Інформація про автомобіль',
            manufacturer: 'Виробник',
            type: 'Модель',
            chassis: '№ шасі',
            stateNumber: 'Державний реєстр.номер',
            year: 'Рік випуску',
            milleage: 'Пробіг',
        },
        machine: {
            title: 'Інформація про техніку',
            machineName: 'Назва',
            machineVIN: 'Серiйний номер',
            machineMilleage: 'Мотогодини',
        },
        noData: 'Данi вiдсутнi'
    },
    ru: {
        title: 'Информация о наряде',
        work: {
            title: 'Рабочая информация',
            priority: 'Приоритет',
        },
        client: {
            title: 'Информация о клиенте',
            company: 'Название',
            driver: 'Представитель',
            phone: 'Телефон',
            address: 'Адрес',
        },
        vehicle: {
            title: 'Информация о технике',
            manufacturer: 'Производитель',
            type: 'Модель',
            chassis: '№ шасси',
            stateNumber: 'Гос.номер',
            year: 'Год выпуска',
            milleage: 'Пробег',
        },
        machine: {
            title: 'Информация об агрегате',
            machineName: 'Название',
            machineVIN: 'Серийный номер',
            machineMilleage: 'Моточасы',
        },
        noData: 'Нет данных'
    },
    en: {
        title: 'Order information',
        work: {
            title: 'Work information',
            priority: 'Priority',
        },
        client: {
            title: 'Client information',
            company: 'Name',
            driver: 'Representative',
            phone: 'Phone',
            address: 'Address',
        },
        vehicle: {
            title: 'Vehicle information',
            manufacturer: 'Manufacturer',
            type: 'Model',
            chassis: '№ chassis',
            stateNumber: 'State number',
            year: 'Year of production',
            milleage: 'Milleage',
        },
        machine: {
            title: 'Unit Information',
            machineName: 'Name',
            machineVIN: 'Serial number',
            machineMilleage: 'Working hours',
        },
        noData: 'No data'
    },
}

class Client extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            did: null,
            isLoading: true,

            information: {},
            isDriverEditable: false,
            isPhoneEditable: false,
            isMilleageEditable: false,
            isMachineMilleageEditable: false,
        }

        this.editMode = this.editMode.bind(this);

        this.setPriority = this.setPriority.bind(this);

        this.handleDriverChange = this.handleDriverChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleMilleageChange = this.handleMilleageChange.bind(this);
        this.handleMachineMilleageChange = this.handleMachineMilleageChange.bind(this);

        this.inputKeyPress = this.inputKeyPress.bind(this);
        this.saveDriver = this.saveDriver.bind(this);
        this.savePhone = this.savePhone.bind(this);
        this.saveMilleage = this.saveMilleage.bind(this);
        this.saveMachineMilleage = this.saveMachineMilleage.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        this.loadData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.did !== prevProps.match.params.did) {
            this.setState({did: this.props.match.params.did});
        }
    }

    loadData() {
        const {addNotification, removeNotification, token} = this.context;

        var did = this.props.match.params.did;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/order/information', { token: token, did: did }).then((res) => {
                this.setState({
                    did: did,
                    isLoading: false,
                    information: res.data.rows[0]
                });
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            this.setState({
                did: did,
                isLoading: false,
                information: {
                    address: "61162, м.Харків, пр. 50 років СРСР, 2/3",
                    chassis: "5306740",
                    company: "ПУБЛІЧНЕ АКЦІОНЕРНЕ ТОВАРИСТВО \"МИРОНІВСЬКИЙ ХЛІБОПРОДУКТ\" Харківська філія  ПАТ \"Миронівський хлібопродукт\"",
                    date: "03.12.2019",
                    did: "97184",
                    doc: "1910292",
                    driver: "Илья",
                    machineMilleage: "-",
                    machineName: "",
                    machineVIN: "",
                    manufacturer: "SCANIA",
                    milleage: "1 056 439",
                    phone: "057-713-69-12",
                    stateNumber: "AI2231HO",
                    type: "P380LA4X2HLA",
                    vin: "YS2P4X20005306740",
                    year: "15.10.2012",
                    priority: "2"
                }
            });
        }
    }

    editMode(field) {
        if (field == "driver") {
            this.setState({isDriverEditable: true});
        } else if (field == "phone") {
            this.setState({isPhoneEditable: true});
        } else if (field == "milleage") {
            this.setState({isMilleageEditable: true});
        } else if (field == "machineMilleage") {
            this.setState({isMachineMilleageEditable: true});
        }
    }

    handleDriverChange(event) {
        this.state.information.driver = event.target.value
        this.forceUpdate()
    }

    handlePhoneChange(event) {
        this.state.information.phone = event.target.value
        this.forceUpdate()
    }

    handleMilleageChange(event) {
        this.state.information.milleage = event.target.value
        this.forceUpdate()
    }

    handleMachineMilleageChange(event) {
        this.state.information.machineMilleage = event.target.value
        this.forceUpdate()
    }

    inputKeyPress(e) {
        if (e.key === 'Enter') {
            e.target.blur();
        }
    }

    setPriority(priority) {
        const {addNotification, removeNotification, token} = this.context;

        var u = addNotification("Завантаження...");
        axios.post('/api/order/information/edit', { token: token, did: this.state.did, fname: "FPRIORITY", fvalue: priority }).then((res) => {
            //this.setState({isDriverEditable: false});
            this.state.information.priority = priority;
            this.forceUpdate();
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    saveDriver() {
        const {addNotification, removeNotification, token} = this.context;

        var u = addNotification("Завантаження...");
        axios.post('/api/order/information/edit', { token: token, did: this.state.did, fname: "CDRIVER", fvalue: this.state.information.driver }).then((res) => {
            this.setState({isDriverEditable: false});
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    savePhone() {
        const {addNotification, removeNotification, token} = this.context;

        var u = addNotification("Завантаження...");
        axios.post('/api/order/information/edit', { token: token, did: this.state.did, fname: "CPHONE", fvalue: this.state.information.phone }).then((res) => {
            this.setState({isPhoneEditable: false});
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    saveMilleage() {
        const {addNotification, removeNotification, token} = this.context;

        var u = addNotification("Завантаження...");
        axios.post('/api/order/information/edit', { token: token, did: this.state.did, fname: "MILLEAGE", fvalue: this.state.information.milleage }).then((res) => {
            this.setState({isMilleageEditable: false});
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    saveMachineMilleage() {
        const {addNotification, removeNotification, token} = this.context;

        var u = addNotification("Завантаження...");
        axios.post('/api/order/information/edit', { token: token, did: this.state.did, fname: "AGREGAT_MH", fvalue: this.state.information.machineMilleage }).then((res) => {
            this.setState({isMachineMilleageEditable: false});
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            )
        }

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6 mb-2">



                            <div className="card agis-card pt-1 pb-1" style={{backgroundColor: '#f1f3f4'}}>
                                <div className="row">
                                    <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                        <div>
                                            <h6><b>{localization[lang].work.title}</b></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card agis-card mb-2">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].work.priority}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col d-flex justify-content-between">
                                        <button className={this.state.information.priority == "1" ? 'btn-agis-primary-outline' : 'btn-agis-light-outline bc-grey'} style={{height: "30px", width: 'calc(20% - 4px)', minWidth: "0px", fontSize: "14px"}} onClick={(e) => this.setPriority("1")}> <b>1</b></button>
                                        <button className={this.state.information.priority == "2" ? 'btn-agis-primary-outline' : 'btn-agis-light-outline bc-grey'} style={{height: "30px", width: 'calc(20% - 4px)', minWidth: "0px", fontSize: "14px"}} onClick={(e) => this.setPriority("2")}> <b>2</b></button>
                                        <button className={this.state.information.priority == "3" ? 'btn-agis-primary-outline' : 'btn-agis-light-outline bc-grey'} style={{height: "30px", width: 'calc(20% - 4px)', minWidth: "0px", fontSize: "14px"}} onClick={(e) => this.setPriority("3")}> <b>3</b></button>
                                        <button className={this.state.information.priority == "4" ? 'btn-agis-primary-outline' : 'btn-agis-light-outline bc-grey'} style={{height: "30px", width: 'calc(20% - 4px)', minWidth: "0px", fontSize: "14px"}} onClick={(e) => this.setPriority("4")}> <b>4</b></button>
                                        <button className={this.state.information.priority == "5" ? 'btn-agis-primary-outline' : 'btn-agis-light-outline bc-grey'} style={{height: "30px", width: 'calc(20% - 4px)', minWidth: "0px", fontSize: "14px"}} onClick={(e) => this.setPriority("5")}> <b>5</b></button>
                                    </div>
                                </div>
                            </div>

                            <div className="card agis-card pt-1 pb-1" style={{backgroundColor: '#f1f3f4'}}>
                                <div className="row">
                                    <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                        <div>
                                            <h6><b>{localization[lang].client.title}</b></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card agis-card">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].client.company}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <big><b>{this.state.information.company}</b></big>
                                    </div>
                                </div>
                            </div>
                            <div className="card agis-card">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].client.driver}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    {!this.state.isDriverEditable ? (
                                        <div className="col d-flex justify-content-between">
                                            <big><b>{this.state.information.driver}</b></big>
                                            <span class="text-muted mr-1"><a onClick={() => {this.editMode("driver")}}><FontAwesomeIcon icon={faEdit} /></a></span>
                                        </div>
                                    ) : (
                                        <div className="col d-flex justify-content-between">
                                            <input type="text" autoComplete="new-password" className="agis-input" onChange={this.handleDriverChange} onBlur={() => this.saveDriver()} value={this.state.information.driver} onKeyPress={(e) => this.inputKeyPress(e)} />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="card agis-card">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].client.phone}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    {!this.state.isPhoneEditable ? (
                                        <div className="col d-flex justify-content-between">
                                            <big><b>{this.state.information.phone}</b></big>
                                            <span class="text-muted mr-1"><a onClick={() => {this.editMode("phone")}}><FontAwesomeIcon icon={faEdit} /></a></span>
                                        </div>
                                    ) : (
                                        <div className="col d-flex justify-content-between">
                                            <input type="text" autoComplete="new-password" className="agis-input" onChange={this.handlePhoneChange} onBlur={() => this.savePhone()} value={this.state.information.phone} onKeyPress={(e) => this.inputKeyPress(e)} />
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* <div className="card agis-card">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].client.address}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <big><b>{this.state.information.address}</b></big>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 mb-2">
                            <div className="card agis-card pt-1 pb-1" style={{backgroundColor: '#f1f3f4'}}>
                                <div className="row">
                                    <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                        <div>
                                            <h6><b>{localization[lang].vehicle.title}</b></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card agis-card">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].vehicle.manufacturer}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <big><b>{this.state.information.manufacturer}</b></big>
                                    </div>
                                </div>
                            </div>
                            <div className="card agis-card">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].vehicle.type}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <big><b>{this.state.information.type}</b></big>
                                    </div>
                                </div>
                            </div>
                            <div className="card agis-card">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].vehicle.chassis}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <big><b>{this.state.information.chassis}</b></big>
                                    </div>
                                </div>
                            </div>
                            <div className="card agis-card">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].vehicle.stateNumber}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <big><b>{this.state.information.stateNumber}</b></big>
                                    </div>
                                </div>
                            </div>
                            <div className="card agis-card">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].vehicle.year}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <big><b>{this.state.information.year}</b></big>
                                    </div>
                                </div>
                            </div>
                            <div className="card agis-card">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].vehicle.milleage}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    {!this.state.isMilleageEditable ? (
                                        <div className="col d-flex justify-content-between">
                                            <big><b>{this.state.information.milleage}</b></big>
                                            <span class="text-muted mr-1"><a onClick={() => {this.editMode("milleage")}}><FontAwesomeIcon icon={faEdit} /></a></span>
                                        </div>
                                    ) : (
                                        <div className="col d-flex justify-content-between">
                                            <input type="text" autoComplete="new-password" className="agis-input" onChange={this.handleMilleageChange} onBlur={() => this.saveMilleage()} value={this.state.information.milleage} onKeyPress={(e) => this.inputKeyPress(e)} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {this.state.information.machineVIN != "" && (
                            <div className="col-xs-12 col-sm-12 col-md-6">
                                <div className="card agis-card pt-1 pb-1" style={{backgroundColor: '#f1f3f4'}}>
                                    <div className="row">
                                        <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                            <div>
                                                <h6><b>{localization[lang].machine.title}</b></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card agis-card">
                                    <div className="row">
                                        <div className="col">
                                            <span>{localization[lang].machine.machineName}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <big><b>{this.state.information.machineName}</b></big>
                                        </div>
                                    </div>
                                </div>
                                <div className="card agis-card">
                                    <div className="row">
                                        <div className="col">
                                            <span>{localization[lang].machine.machineVIN}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <big><b>{this.state.information.machineVIN}</b></big>
                                        </div>
                                    </div>
                                </div>
                                <div className="card agis-card">
                                    <div className="row">
                                        <div className="col">
                                            <span>{localization[lang].machine.machineMilleage}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {!this.state.isMachineMilleageEditable ? (
                                            <div className="col d-flex justify-content-between">
                                                <big><b>{this.state.information.machineMilleage}</b></big>
                                                <span class="text-muted mr-1"><a onClick={() => {this.editMode("machineMilleage")}}><FontAwesomeIcon icon={faEdit} /></a></span>
                                            </div>
                                        ) : (
                                            <div className="col d-flex justify-content-between">
                                                <input type="text" autoComplete="new-password" className="agis-input" onChange={this.handleMachineMilleageChange} onBlur={() => this.saveMachineMilleage()} value={this.state.information.machineMilleage} onKeyPress={(e) => this.inputKeyPress(e)} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default Client;