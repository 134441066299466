import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../AppContext.js';
import OffsetTop from '../../../components/offsetTop.js';

const localization = {
    ua: {
        title: 'Створення витрати',
        header: 'Створення витрати',
        type: 'Тип витрати',
        payment: 'Тип оплати',
        currency: 'Валюта',
        date: 'Дата',
        sum: 'Сума',
        remark: 'Примітка',
        button: 'Зберегти',
    },
    ru: {
        title: 'Создание траты',
        header: 'Создание затраты',
        type: 'Тип траты',
        payment: 'Тип оплаты',
        currency: 'Валюта',
        date: 'Дата',
        sum: 'Сумма',
        remark: 'Примечание',
        button: 'Сохранить',
    },
    en: {
        title: 'Create expenses',
        header: 'Create expenses',
        type: 'Type',
        payment: 'Payment type',
        currency: 'Currency',
        date: 'Date',
        sum: 'Sum',
        remark: 'Remark',
        button: 'Save',
    },
}

class CheckListNew extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,
            redirect: null,

            rows: [],
        }
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        this.setState({did: this.props.match.params.did}, () => this.loadList());
    }

    loadList() {
        const {addNotification, removeNotification, token} = this.context;
        
        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/driver/expenses/types', { token: token, type: "SPENTTYPE" }).then((res) => {
                this.setState({rows: res.data.rows, isLoading: false,});
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            this.setState({
                isLoading: false,
                rows: [
                    { id: 5, name: "Fuel", description: "" },
                    { id: 2, name: "Motel", description: "" },
                ],
            });
        }
    }

    renderList() {
        const {lang} = this.context;

        if (this.state.rows.length === 0) {
            return (
                <div className="row">
                    <div className="col">
                        <div className="card agis-card">
                            <div className="row">
                                <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                    <div>
                                        <h6><b>{localization[lang].noData}</b></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return this.state.rows.map((item, i) => {
                return (
                    <div className="row">
                        <div className="col">
                            <Link to={"/driver/expenses/"+ this.state.did + "/new/"+item.id} className="card agis-card position-component">
                                <div class="d-flex justify-content-between">
                                    <div className="row">
                                        <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                            <big><b>{item.name}</b></big>
                                            {/* <big>{item.l2}</big> */}
                                        </div>
                                    </div>
                                    <div className="align-middle" style={{display: "flex", alignItems: "center"}}>
                                        <span className="mr-1"><FontAwesomeIcon icon={faChevronRight}/></span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                );
            });
        }
    }

    render() {
        const {lang} = this.context;

        if (this.state.redirect !== null) {
            return <Redirect to={this.state.redirect} />
        }

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            {this.renderList()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CheckListNew;