import React from 'react'
import DocumentMeta from 'react-document-meta';
import axios from 'axios';
import CurrencyInput from 'react-currency-input';
import ScaleLoader from "react-spinners/ScaleLoader";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faMapMarkerAlt, faChevronDown, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../AppContext';
import OffsetTop from '../../../components/offsetTop.js';
import ScanButton from '../../../components/scanbutton.js';

const localization = {
    ua: {
        title: 'Видача',
        chooseWorkOrder: 'Виберіть наряд',
        chooseWorkOrderJob: 'Виберіть блок робіт',
        chooseWorker: 'Виберіть працівника',
        chooseGoods: 'Виберіть запчастину',
        chooseCheckOutBulk: 'Видача по списку',
        workOrder: 'Заказ наряд',
        workOrderJob: 'Блок робіт',
        worker: 'Співробітник',
        goods: 'Запчастина',
        quantity: 'Кількість',
        checkOutOne: 'Видати',
        checkOutBulkBlock: 'Видати по списку блок',
        checkOutBulkAll: 'Видати по списку усі',
        checkOutBulkSetAllDown: 'Очистити все',
        checkOutBulkSetAllUp: 'Видати все',
        checkOutBulkCancel: 'Відмінити',
        checkOutBulkSave: 'Зберегти',
    },
    ru: {
        title: 'Выдача',
        chooseWorkOrder: 'Выберите наряд',
        chooseWorkOrderJob: 'Выберите блок работ',
        chooseWorker: 'Выберите работника',
        chooseGoods: 'Выберите запчасть',
        chooseCheckOutBulk: 'Выдача по списку',
        workOrder: 'Заказ наряд',
        workOrderJob: 'Блок работ',
        worker: 'Сотрудник',
        goods: 'Запчасть',
        quantity: 'Количество',
        checkOutOne: 'Выдать',
        checkOutBulkBlock: 'Выдать по списку блок',
        checkOutBulkAll: 'Выдать по списку все',
        checkOutBulkSetAllDown: 'Очистить всё',
        checkOutBulkSetAllUp: 'Выдать всё',
        checkOutBulkCancel: 'Отменить',
        checkOutBulkSave: 'Сохранить',
    },
    en: {
        title: 'Check-out',
        chooseWorkOrder: 'Choose work order',
        chooseWorkOrderJob: 'Choose work order job',
        chooseWorker: 'Choose worker',
        chooseGoods: 'Choose part',
        chooseCheckOutBulk: 'Check-out bulk',
        workOrder: 'Work order',
        workOrderJob: 'Work order block',
        worker: 'Worker',
        goods: 'Part',
        quantity: 'Quantity',
        checkOutOne: 'Check-out',
        checkOutBulkBlock: 'Bulk check-out by block',
        checkOutBulkAll: 'Bulk check-out all',
        checkOutBulkSetAllDown: 'Clean all',
        checkOutBulkSetAllUp: 'Check-out all',
        checkOutBulkCancel: 'Cancel',
        checkOutBulkSave: 'Save',
    },
}

class CheckOut extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: false,
            
            workOrderText: "",
            workOrder: null,
            isWorkOrderLoading: false,
            isWorkOrderChecked: false,
            checkWorkOrderResult: null,
            
            isWorkOrderJobLoading: false,
            isWorkOrderJobChecked: false,
            checkWorkOrderJobResult: null,

            workerText: "",
            worker: null,
            isWorkerLoading: false,
            isWorkerChecked: false,
            checkWorkerResult: null,

            goodsText: "",
            goods: null,
            isGoodsLoading: false,
            isGoodsChecked: false,
            checkGoodsResult: null,

            total: "0",
            totalDefault: "1",

            bcodeLatest: null,
            isCheckOutBulkChecked: false,
            checkOutBulkResult: null,

            errorSave: null,
            errorBulkSave: null,
        }

        this.onWorkOrderChange = this.onWorkOrderChange.bind(this);
        this.onWorkOrderKeyPress = this.onWorkOrderKeyPress.bind(this);
        this.onWorkOrderPaste = this.onWorkOrderPaste.bind(this);
        this.scanWorkOrder = this.scanWorkOrder.bind(this);
        this.scanWorkOrderModal = this.scanWorkOrderModal.bind(this);
        this.checkWorkOrder = this.checkWorkOrder.bind(this);
        this.dismissWorkOrder = this.dismissWorkOrder.bind(this);

        this.checkWorkOrderJob = this.checkWorkOrderJob.bind(this);

        this.onWorkerChange = this.onWorkerChange.bind(this);
        this.onWorkerKeyPress = this.onWorkerKeyPress.bind(this);
        this.onWorkerPaste = this.onWorkerPaste.bind(this);
        this.scanWorker = this.scanWorker.bind(this);
        this.scanWorkerModal = this.scanWorkerModal.bind(this);
        this.checkWorker = this.checkWorker.bind(this);
        this.dismissWorker = this.dismissWorker.bind(this);

        this.onGoodsChange = this.onGoodsChange.bind(this);
        this.onGoodsKeyPress = this.onGoodsKeyPress.bind(this);
        this.onGoodsPaste = this.onGoodsPaste.bind(this);
        this.scanGoods = this.scanGoods.bind(this);
        this.scanGoodsModal = this.scanGoodsModal.bind(this);
        this.checkGoods = this.checkGoods.bind(this);
        this.dismissGoods = this.dismissGoods.bind(this);

        this.onTotalChange = this.onTotalChange.bind(this);
        this.onTotalKeyPress = this.onTotalKeyPress.bind(this);

        this.checkOutSave = this.checkOutSave.bind(this);

        this.checkOutBulk = this.checkOutBulk.bind(this);

        this.onCheckOutBulkSelectedChange = this.onCheckOutBulkSelectedChange.bind(this);
        this.onCheckOutBulkSelectedDown = this.onCheckOutBulkSelectedDown.bind(this);
        this.onCheckOutBulkSelectedUp = this.onCheckOutBulkSelectedUp.bind(this);
        this.scanBarcode = this.scanBarcode.bind(this);
        this.checkOutBulkCancel = this.checkOutBulkCancel.bind(this);
        this.checkOutBulkSave = this.checkOutBulkSave.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);
    }

    /*
        Work order
    */
    onWorkOrderChange(event) {
        if (event.target.value.length != 0) {
            this.setState({
                workOrderText: event.target.value, 
                isWorkOrderChecked: false,
                checkWorkOrderResult: null,
                workOrder: null,

                errorSave: null
            });
        } else {
            this.setState({
                workOrderText: event.target.value, 
                isWorkOrderChecked: false,
                checkWorkOrderResult: null,
                workOrder: null,

                errorSave: null
            });
        }
    }

    onWorkOrderKeyPress(e) {
        if (e.key === 'Enter') {
            this.setState({
                isWorkOrderLoading: true,
            }, () => {
                this.checkWorkOrder();
            });
        }
    }

    onWorkOrderPaste() {
        this.setState({
            isWorkOrderLoading: true,
        }, () => {
            this.checkWorkOrder();
        });
    }

    scanWorkOrder(value) {
        this.setState({
            workOrderText: value, 
            isWorkOrderLoading: true,
            isWorkOrderChecked: false,
            checkWorkOrderResult: null,
            workOrder: null,

            errorSave: null
        }, () => {
            this.checkWorkOrder();
        });
    }

    scanWorkOrderModal(status) {
        this.setState({
            isWorkOrderChecked: false,
            checkWorkOrderResult: null,
        });
    }

    checkWorkOrder() {
        const {addNotification, removeNotification, token} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/stock/handover/check-out/check', {
                token: token,
                type: 1,
                text: this.state.workOrderText,
            }).then((res) => {
                console.log(res.data);

                if (res.data.rows.length == 1) {
                    this.setState({
                        isWorkOrderLoading: false,
                        workOrder: res.data.rows[0]
                    }, () => {
                        if (!this.state.worker) {
                            document.getElementById('workerInput').focus();
                        } else if (!this.state.goods) {
                            document.getElementById('goodsInput').focus();
                        }
                    });
                } else {
                    if (res.data.rows.length != 0) {
                        document.getElementById('workOrderInput').blur();
                    }
                    
                    this.setState({
                        isWorkOrderLoading: false,
                        isWorkOrderChecked: true,
                        checkWorkOrderResult: res.data.rows
                    });
                }
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            setTimeout(() => {
                this.setState({
                    isWorkOrderLoading: false,
                    isWorkOrderChecked: true,
                    checkWorkOrderResult: [
                        {
                            id: 1,
                            name: "ЗН 10234",
                            locked: 1,
                            lockedtext: "Locked",
                            bcode: "1",
                            bname: "Блок 1",
                        },
                        {
                            id: 2,
                            name: "ЗН 20523",
                            locked: 0,
                            lockedtext: "",
                            bcode: "2",
                            bname: "Блок 2",
                        }
                    ]
                });
            }, 1000);
        }
    }

    renderCheckWorkOrderResult() {
        const {lang} = this.context;

        return this.state.checkWorkOrderResult.map((item, i) => {
            return (
                <div className="row mt-3" key={"act_" + i}>
                    <div className="col">
                        <div onClick={() => this.pickCheckWorkOrderResult(item)} className="agis-card clickable noselect pt-2 pb-2" style={{borderRadius: "10px"}}>
                            <div className="row">
                                <div className="col">
                                    <h6 style={{lineHeight: "30px"}}><b>{item.name}</b></h6>
                                    {item.locked == 1 && (
                                        <big className="mt-1 text-danger">{localization[lang].locked}</big>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    pickCheckWorkOrderResult(item) {
        this.setState({
            workOrder: item,
            isWorkOrderChecked: false,
            checkWorkOrderResult: null
        }, () => {
            if (!this.state.worker) {
                document.getElementById('workerInput').focus();
            } else if (!this.state.goods) {
                document.getElementById('goodsInput').focus();
            }
        });
    }

    dismissWorkOrder() {
        this.setState({
            workOrderText: "", 
            isWorkOrderLoading: false,
            isWorkOrderChecked: false,
            checkWorkOrderResult: null,
            workOrder: null,

            errorSave: null
        }, () => {
            document.getElementById('workOrderInput').focus();
        });
    }

    /*
        Work order job
    */
    checkWorkOrderJob() {
        const {addNotification, removeNotification, token} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/stock/handover/check-out/jobs', {
                token: token,
                id: this.state.workOrder.id,
            }).then((res) => {
                if (res.data.rows.length != 1) {
                    this.setState({
                        isWorkOrderJobLoading: false,
                        isWorkOrderJobChecked: true,
                        checkWorkOrderJobResult: res.data.rows
                    });
                }
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            setTimeout(() => {
                this.setState({
                    isWorkOrderJobLoading: false,
                    isWorkOrderJobChecked: true,
                    checkWorkOrderJobResult: [
                        {
                            bcode: "1",
                            bname: "Блок 111",
                        },
                        {
                          
                            bcode: "2",
                            bname: "Блок 222",
                        }
                    ]
                });
            }, 1000);
        }
    }
    
    renderCheckWorkOrderJobResult() {
        const {lang} = this.context;

        return this.state.checkWorkOrderJobResult.map((item, i) => {
            return (
                <div className="row mt-3" key={"act_" + i}>
                    <div className="col">
                        <div onClick={() => this.pickCheckWorkOrderJobResult(item)} className="agis-card clickable noselect pt-2 pb-2" style={{borderRadius: "10px"}}>
                            <div className="row">
                                <div className="col">
                                    <h6 style={{lineHeight: "30px"}}><b>{item.bname}</b></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    pickCheckWorkOrderJobResult(item) {
        const workOrder = this.state.workOrder
        workOrder.bcode = item.bcode
        workOrder.bname = item.bname

        this.setState({
            workOrder: workOrder,
            isWorkOrderJobChecked: false,
            checkWorkOrderJobResult: null
        }, () => {
            if (!this.state.worker) {
                document.getElementById('workerInput').focus();
            } else if (!this.state.goods) {
                document.getElementById('goodsInput').focus();
            }
        });
    }

    /*
        Worker
    */
    onWorkerChange(event) {
        if (event.target.value.length != 0) {
            this.setState({
                workerText: event.target.value, 
                isWorkerChecked: false,
                checkWorkerResult: null,
                worker: null,

                errorSave: null
            });
        } else {
            this.setState({
                workerText: event.target.value, 
                isWorkerChecked: false,
                checkWorkerResult: null,
                worker: null,

                errorSave: null
            });
        }
    }

    onWorkerKeyPress(e) {
        if (e.key === 'Enter') {
            this.setState({
                isWorkerLoading: true,
            }, () => {
                this.checkWorker();
            });
        }
    }

    onWorkerPaste() {
        this.setState({
            isWorkerLoading: true,
        }, () => {
            this.checkWorker();
        });
    }

    scanWorker(value) {
        this.setState({
            workerText: value, 
            isWorkerLoading: true,
            isWorkerChecked: false,
            checkWorkerResult: null,
            worker: null,

            errorSave: null
        }, () => {
            this.checkWorker();
        });
    }

    scanWorkerModal(status) {
        this.setState({
            isWorkerChecked: false,
            checkWorkerResult: null,
        });
    }

    checkWorker() {
        const {addNotification, removeNotification, token} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/stock/handover/check-out/check', {
                token: token,
                type: 2,
                text: this.state.workerText,
            }).then((res) => {
                console.log(res.data);

                if (res.data.rows.length == 1) {
                    this.setState({
                        isWorkerLoading: false,
                        worker: res.data.rows[0]
                    }, () => {
                        if (!this.state.goods) {
                            document.getElementById('goodsInput').focus();
                        }
                    });
                } else {
                    if (res.data.rows.length != 0) {
                        document.getElementById('workerInput').blur();
                    }
                    
                    this.setState({
                        isWorkerLoading: false,
                        isWorkerChecked: true,
                        checkWorkerResult: res.data.rows
                    });
                }
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            setTimeout(() => {
                this.setState({
                    isWorkerLoading: false,
                    isWorkerChecked: true,
                    checkWorkerResult: [
                        {
                            id: 1,
                            name: "Дорошенко Євген Юрійович",
                        },
                        {
                            id: 2,
                            name: "Черніков Сергій Павлович",
                        }
                    ]
                });
            }, 1000);
        }
    }

    renderCheckWorkerResult() {
        return this.state.checkWorkerResult.map((item, i) => {
            return (
                <div className="row mt-3" key={"act_" + i}>
                    <div className="col">
                        <div onClick={() => this.pickCheckWorkerResult(item)} className="agis-card clickable noselect pt-2 pb-2" style={{borderRadius: "10px"}}>
                            <div className="row">
                                <div className="col">
                                    <h6 style={{lineHeight: "30px"}}><b>{item.name}</b></h6>
                                    {/* <big className="mt-1 mb-1">{item.name}</big> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    pickCheckWorkerResult(item) {
        this.setState({
            worker: item,
            isWorkerChecked: false,
            checkWorkerResult: null
        }, () => {
            if (!this.state.goods) {
                document.getElementById('goodsInput').focus();
            }
        });
    }

    dismissWorker() {
        this.setState({
            workerText: "", 
            isWorkerLoading: false,
            isWorkerChecked: false,
            checkWorkerResult: null,
            worker: null,

            errorSave: null
        }, () => {
            document.getElementById('workerInput').focus();
        });
    }

    /*
        Goods
    */
    onGoodsChange(event) {
        if (event.target.value.length != 0) {
            this.setState({
                goodsText: event.target.value, 
                isGoodsChecked: false,
                checkGoodsResult: null,
                goods: null,

                total: this.state.totalDefault,

                errorSave: null
            });
        } else {
            this.setState({
                goodsText: event.target.value, 
                isGoodsChecked: false,
                checkGoodsResult: null,
                goods: null,

                total: this.state.totalDefault,

                errorSave: null
            });
        }
    }

    onGoodsKeyPress(e) {
        if (e.key === 'Enter') {
            this.setState({
                isGoodsLoading: true,
            }, () => {
                this.checkGoods();
            });
        }
    }

    onGoodsPaste() {
        setTimeout(() => {
            this.setState({
                isGoodsLoading: true,
            }, () => {
                this.checkGoods();
            });
        }, 100);
    }

    scanGoods(value) {
        this.setState({
            goodsText: value, 
            isGoodsLoading: true,
            isGoodsChecked: false,
            checkGoodsResult: null,
            goods: null,

            total: this.state.totalDefault,

            errorSave: null
        }, () => {
            this.checkGoods();
        });
    }

    scanGoodsModal(status) {
        this.setState({
            isGoodsChecked: false,
            checkGoodsResult: null,

            total: this.state.totalDefault,

            errorSave: null
        });
    }

    checkGoods() {
        const {addNotification, removeNotification, token} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/stock/handover/check-out/check', {
                token: token,
                type: 3,
                text: this.state.goodsText,
            }).then((res) => {
                console.log(res.data);

                if (res.data.rows.length == 1) {
                    this.setState({
                        isGoodsLoading: false,
                        goods: res.data.rows[0],
                        total: this.state.totalDefault
                    }, () => {
                        document.getElementById('totalInput').focus();
                    });
                } else {
                    if (res.data.rows.length != 0) {
                        document.getElementById('goodsInput').blur();
                    }

                    this.setState({
                        isGoodsLoading: false,
                        isGoodsChecked: true,
                        checkGoodsResult: res.data.rows
                    });
                }
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            setTimeout(() => {
                this.setState({
                    isGoodsLoading: false,
                    isGoodsChecked: true,
                    total: this.state.totalDefault,
                    checkGoodsResult: [
                        {
                            id: 1, 
                            name: "Наушники Talkman", 
                            location: "3.6.7",
                            total: "3 шт",
                        },
                        {
                            id: 2, 
                            name: "Наушники Talkman", 
                            location: "3.6.7",
                            total: "3 шт",
                        }
                    ]
                });
            }, 1000);
        }
    }

    renderCheckGoodsResult() {
        const {lang} = this.context;

        return this.state.checkGoodsResult.map((item, i) => {
            return (
                <div className="row mt-3" key={"act_" + i}>
                    <div className="col">
                        <div onClick={() => this.pickCheckGoodsResult(item)} className="agis-card clickable noselect pt-2 pb-2" style={{borderRadius: "10px"}}>
                            <div className="row">
                                <div className="col-12 mb-2">
                                    <h6><b>{item.name}</b></h6>
                                </div>
                                <div className="col-6">
                                    <big className="mb-1 text-info"><FontAwesomeIcon icon={faMapMarkerAlt} /> {item.location}</big>
                                </div>
                                <div className="col-6 text-right">
                                    <big className="mb-1 text-info"><b>{item.total}</b></big>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    pickCheckGoodsResult(item) {
        console.log(item);
        this.setState({
            goods: item,
            isGoodsChecked: false,
            checkGoodsResult: null,
            total: this.state.totalDefault
        }, () => {
            document.getElementById('totalInput').focus();
        });
    }

    dismissGoods() {
        this.setState({
            goodsText: "", 
            isGoodsLoading: false,
            isGoodsChecked: false,
            checkGoodsResult: null,
            goods: null,

            total: this.state.totalDefault,

            errorSave: null
        }, () => {
            document.getElementById('goodsInput').focus();
        });
    }

    /*
        Confirm
    */
    onTotalChange(event){
        this.setState({total: event.target.value});
    }

    onTotalKeyPress(e) {
        if (e.key === 'Enter') {
            document.getElementById('totalInput').blur();
        }
    }

    checkOutSave() {
        const {addNotification, removeNotification, token, lang} = this.context;

        var u = addNotification("Завантаження...");

        axios.post('/api/stock/handover/check-out/confirm', {
            token: token,
            workOrderID: this.state.workOrder.id,
            workerID:    this.state.worker.id,
            goodsID:     this.state.goods.id,
            total:       this.state.total,
            bcode:       this.state.workOrder.bcode,
        }).then((res) => {
            if (res.data.result > 0) {
                this.setState({
                    goodsText: "", 
                    isGoodsLoading: false,
                    isGoodsChecked: false,
                    checkGoodsResult: null,
                    goods: null,

                    total: this.state.totalDefault,

                    errorSave: null
                }, () => {
                    document.getElementById('goodsInput').focus();
                });
            } else {
                this.setState({errorSave: res.data.error});
            }
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
            this.setState({errorSave: error.response.data});
        }).finally(() => {
            removeNotification(u);
        });
    }

    /*
        Check out bulk
    */
    checkOutBulk(bcode, start) {
        const {addNotification, removeNotification, token} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/stock/handover/check-out/bulk/check', {
                token: token,
                did: this.state.workOrder.id,
                mid: this.state.worker.id,
                bcode: bcode,
                start: start,
            }).then((res) => {
                this.setState({
                    bcodeLatest: bcode,
                    isCheckOutBulkChecked: true,
                    checkOutBulkResult: res.data.rows
                });
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            setTimeout(() => {
                this.setState({
                    isCheckOutBulkChecked: true,
                    checkOutBulkResult: [
                        {
                            id: 1,
                            code: "123",
                            name: "Name 1",
                            address: "1.2.3",
                            total: "33",
                            available: "3.3",
                            selected: "1.2",
                            barcode: "123",
                        },
                        {
                            id: 2,
                            code: "456",
                            name: "Name 2",
                            address: "3.2.1",
                            total: "25",
                            available: "5.5",
                            selected: "2.2",
                            barcode: "",
                        },
                    ]
                });
            }, 1000);
        }
    }

    renderCheckOutBulkResult() {
        const {lang} = this.context;

        return this.state.checkOutBulkResult.map((item, i) => {
            return (
                <div className="row mt-3" key={"act_" + i}>
                    <div className="col">
                        <div className="agis-card pt-2 pb-2" style={{borderRadius: "10px"}}>
                            <div className="row">
                                <div className="col">
                                    <h6><b>{item.name}</b></h6>
                                    <div className="d-flex justify-content-between mt-1">
                                        <div className="d-flex justify-content-between pt-1" style={{minWidth: "50%", fontSize: "14px"}}>
                                            <big className="mb-1 text-info"><FontAwesomeIcon icon={faMapMarkerAlt} /> {item.address}</big>
                                            <big className="mb-1 text-info"><b>{item.total}</b></big>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <button className={'btn-agis-light-outline bc-grey'} style={{height: "30px", width: "40px", minWidth: "0px", fontSize: "14px"}} onClick={() => this.onCheckOutBulkSelectedDown(item.id)}><FontAwesomeIcon icon={faTimes} className="text-danger" /></button>
                                            <input
                                                type="number"
                                                //className="w-100"
                                                style={{paddingLeft: "10px", width: "60px", border: "0", color: "#464a4e", fontSize: "14px", lineHeight: "15px", fontSize: "1.1rem"}}
                                                value={item.selected}
                                                onChange={(e) => this.onCheckOutBulkSelectedChange(e, item.id)}
                                                onKeyDown={(e) => this.onCheckOutBulkSelectedKeyPress(e, item.id)}
                                                onBlur={(e) => this.onCheckOutBulkSelectedBlur(item.id)}
                                            />
                                            <button className={'btn-agis-light-outline bc-grey'} style={{height: "30px", width: "40px", minWidth: "0px", fontSize: "14px"}} onClick={() => this.onCheckOutBulkSelectedUp(item.id)}><FontAwesomeIcon icon={faCheck} className="text-success" /></button>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mt-2">
                                        <input
                                            type="text"
                                            className="agis-input w-100"
                                            style={{lineHeight: "15px", fontSize: "1rem"}}
                                            value={item.barcode}
                                            onChange={(e) => this.onCheckOutBulkBarcodeChange(e, item.id)}
                                            onKeyDown={(e) => this.onCheckOutBulkBarcodeKeyPress(e, item.id)}
                                            onBlur={(e) => this.onCheckOutBulkBarcodeBlur(item.id)}
                                        />
                                        <ScanButton onScan={this.scanBarcode} param={item.id} style={{height: '30px', width: "50px", minWidth: "0px", borderRadius: "5px", fontSize: "12px", lineHeight: "30px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 0 0 1px rgba(0,0,0,.05), 0 2px 3px 0 rgba(0,0,0,.1)"}} />
                                    </div>
                                    {item.error && (
                                        <div className="mt-2">
                                            <span className="text-danger" style={{fontSize: "1rem"}}>{item.error}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    checkOutBulkSetAll(up) {
        const {addNotification, removeNotification, token, lang} = this.context;

        var u = addNotification("Завантаження...");

        axios.post('/api/stock/handover/check-out/bulk/setAll', {
            token: token,
            bcode: this.state.bcodeLatest,
            type: up ? 1 : 0,
        }).then((res) => {
            if (res.data.error == "") {
                this.setState({errorBulkSave: null}, () => this.checkOutBulk(this.state.bcodeLatest, false));
            } else {
                this.setState({errorBulkSave: res.data.error});
            }
        }).catch((error) => {
            this.setState({errorBulkSave: error.response.data});
        }).finally(() => {
            removeNotification(u);
        });
    }

    onCheckOutBulkBarcodeChange(event, id) {
        const list = this.state.checkOutBulkResult
        list[list.findIndex(i => i.id == id)].barcode = event.target.value
        this.setState({checkOutBulkResult: list});
    }

    onCheckOutBulkBarcodeKeyPress(e, id) {
        if (e.key === 'Enter') {
            this.setState({
                //isGoodsLoading: true,
            }, () => {
                this.checkOutBulkBarcodeSet(id);
            });
        }
    }

    onCheckOutBulkBarcodeBlur(id) {
        this.checkOutBulkBarcodeSet(id);
    }

    scanBarcode(value, id) {
        const list = this.state.checkOutBulkResult
        list[list.findIndex(i => i.id == id)].barcode = value
        this.setState({checkOutBulkResult: list}, () => {
            this.checkOutBulkBarcodeSet(id);
        });
    }

    checkOutBulkBarcodeSet(id) {
        const {addNotification, removeNotification, token, lang} = this.context;

        const list = this.state.checkOutBulkResult

        var u = addNotification("Завантаження...");

        axios.post('/api/stock/handover/check-out/bulk/barcode', {
            token: token,
            id: id,
            barcode: list[list.findIndex(i => i.id == id)].barcode,
        }).then((res) => {
            list[list.findIndex(i => i.id == id)].error = res.data.error
        }).catch((error) => {
            this.setState({errorBulkSave: error.response.data});
        }).finally(() => {
            removeNotification(u);
        });
    }

    onCheckOutBulkSelectedChange(event, id) {
        const list = this.state.checkOutBulkResult
        list[list.findIndex(i => i.id == id)].selected = event.target.value
        this.setState({checkOutBulkResult: list});
    }

    onCheckOutBulkSelectedKeyPress(e, id) {
        if (e.key === 'Enter') {
            this.setState({
                //isGoodsLoading: true,
            }, () => {
                this.checkOutBulkSet(id);
            });
        }
    }

    onCheckOutBulkSelectedBlur(id) {
        this.checkOutBulkSet(id);
    }

    onCheckOutBulkSelectedDown(id) {
        const list = this.state.checkOutBulkResult
        list[list.findIndex(i => i.id == id)].selected = "0"
        this.setState({checkOutBulkResult: list}, () => this.checkOutBulkSet(id));
    }

    onCheckOutBulkSelectedUp(id) {
        const list = this.state.checkOutBulkResult
        list[list.findIndex(i => i.id == id)].selected = list[list.findIndex(i => i.id == id)].available
        this.setState({checkOutBulkResult: list}, () => this.checkOutBulkSet(id));
    }

    checkOutBulkSet(id) {
        const {addNotification, removeNotification, token, lang} = this.context;

        const list = this.state.checkOutBulkResult

        var u = addNotification("Завантаження...");

        axios.post('/api/stock/handover/check-out/bulk/set', {
            token: token,
            id: id,
            selected: list[list.findIndex(i => i.id == id)].selected,
        }).then((res) => {
            if (res.data.error == "") {
                this.setState({errorBulkSave: null});
            } else {
                this.setState({errorBulkSave: res.data.error});
            }
        }).catch((error) => {
            this.setState({errorBulkSave: error.response.data});
        }).finally(() => {
            removeNotification(u);
        });
    }

    checkOutBulkCancel() {
        this.setState({
            isCheckOutBulkChecked: false,

            goodsText: "", 
            isGoodsLoading: false,
            isGoodsChecked: false,
            checkGoodsResult: null,
            goods: null,

            total: this.state.totalDefault,

            bcodeLatest: null,
            errorSave: null
        })
    }

    checkOutBulkSave() {
        const {addNotification, removeNotification, token, lang} = this.context;

        var u = addNotification("Завантаження...");

        axios.post('/api/stock/handover/check-out/bulk/confirm', {
            token: token,
            bcode: this.state.bcodeLatest,
        }).then((res) => {
            if (res.data.result > 0) {
                this.setState({
                    isCheckOutBulkChecked: false,

                    goodsText: "", 
                    isGoodsLoading: false,
                    isGoodsChecked: false,
                    checkGoodsResult: null,
                    goods: null,

                    total: this.state.totalDefault,

                    errorBulkSave: null
                }, () => {
                    document.getElementById('goodsInput').focus();
                });
            } else {
                this.setState({errorBulkSave: res.data.error});
            }
        }).catch((error) => {
            //addNotification(error.response.data, error.response.status);
            this.setState({errorBulkSave: error.response.data});
        }).finally(() => {
            removeNotification(u);
        });
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                {this.state.isLoading && (
                    <div className="text-center">
                        <ScaleLoader height="20px" width="3px" radius="3px" margin="2px" color={"#4F81BC"} />
                    </div>
                )}

                {(this.state.isWorkOrderChecked && this.state.checkWorkOrderResult.length > 1) && (
                    <div className="fixed-bottom" onClick={this.scanWorkOrderModal} style={{zIndex: 2000, height: "100%", backgroundColor: "#80808094"}}>
                        <div className="fixed-bottom" onClick={(e) => {e.stopPropagation()}} style={{height: "85%", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", backgroundColor: "#fff", overflowX: "auto"}}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-10 offset-md-1">
                                        <div className="container-fluid pl-0 pr-0">
                                        
                                            <br/>

                                            <div className="row mb-4">
                                                <div className="col d-flex align-items-center">
                                                    <h5 className="text-muted" style={{lineHeight: "40px"}}>{localization[lang].chooseWorkOrder}</h5>
                                                </div>
                                            </div>

                                            {this.renderCheckWorkOrderResult()}
                                            <br/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {(this.state.isWorkOrderJobChecked && this.state.checkWorkOrderJobResult.length > 1) && (
                    <div className="fixed-bottom" onClick={() => this.setState({isWorkOrderJobChecked: false})} style={{zIndex: 2000, height: "100%", backgroundColor: "#80808094"}}>
                        <div className="fixed-bottom" onClick={(e) => {e.stopPropagation()}} style={{height: "85%", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", backgroundColor: "#fff", overflowX: "auto"}}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-10 offset-md-1">
                                        <div className="container-fluid pl-0 pr-0">
                                        
                                            <br/>

                                            <div className="row mb-4">
                                                <div className="col d-flex align-items-center">
                                                    <h5 className="text-muted" style={{lineHeight: "40px"}}>{localization[lang].chooseWorkOrderJob}</h5>
                                                </div>
                                            </div>

                                            {this.renderCheckWorkOrderJobResult()}
                                            <br/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {(this.state.isWorkerChecked && this.state.checkWorkerResult.length > 1) && (
                    <div className="fixed-bottom" onClick={this.scanWorkerModal} style={{zIndex: 2000, height: "100%", backgroundColor: "#80808094"}}>
                        <div className="fixed-bottom" onClick={(e) => {e.stopPropagation()}} style={{height: "85%", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", backgroundColor: "#fff", overflowX: "auto"}}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-10 offset-md-1">
                                        <div className="container-fluid pl-0 pr-0">
                                        
                                            <br/>

                                            <div className="row mb-4">
                                                <div className="col d-flex align-items-center">
                                                    <h5 className="text-muted" style={{lineHeight: "40px"}}>{localization[lang].chooseWorker}</h5>
                                                </div>
                                            </div>

                                            {this.renderCheckWorkerResult()}
                                            <br/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {(this.state.isGoodsChecked && this.state.checkGoodsResult.length > 1) && (
                    <div className="fixed-bottom" onClick={this.scanGoodsModal} style={{zIndex: 2000, height: "100%", backgroundColor: "#80808094"}}>
                        <div className="fixed-bottom" onClick={(e) => {e.stopPropagation()}} style={{height: "85%", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", backgroundColor: "#fff", overflowX: "auto"}}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-10 offset-md-1">
                                        <div className="container-fluid pl-0 pr-0">
                                        
                                            <br/>

                                            <div className="row mb-4">
                                                <div className="col d-flex align-items-center">
                                                    <h5 className="text-muted" style={{lineHeight: "40px"}}>{localization[lang].chooseGoods}</h5>
                                                </div>
                                            </div>

                                            {this.renderCheckGoodsResult()}
                                            <br/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {this.state.isCheckOutBulkChecked && (
                    <div className="fixed-bottom" onClick={() => this.setState({isCheckOutBulkChecked: false})} style={{zIndex: 2000, height: "100%", backgroundColor: "#80808094"}}>
                        <div className="fixed-bottom" onClick={(e) => {e.stopPropagation()}} style={{height: "85%", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", backgroundColor: "#fff", overflowX: "auto"}}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-10 offset-md-1">
                                        <div className="container-fluid pl-0 pr-0">
                                        
                                            <br/>

                                            <div className="row mb-2">
                                                <div className="col d-flex align-items-center">
                                                    <h5 className="text-muted" style={{lineHeight: "40px"}}>{localization[lang].chooseCheckOutBulk}</h5>
                                                </div>
                                            </div>

                                            <div className="w-100 d-flex justify-content-between mb-3">
                                                <button className="btn-agis-danger-outline bc-grey w-50" style={{height: "40px", fontSize: "14px", borderRadius: "0px"}} onClick={(e) => this.checkOutBulkSetAll(false)}> {localization[lang].checkOutBulkSetAllDown}</button>
                                                <button className="btn-agis-success-outline bc-grey w-50 ml-3" style={{height: "40px", fontSize: "14px", borderRadius: "0px"}} onClick={(e) => this.checkOutBulkSetAll(true)}> {localization[lang].checkOutBulkSetAllUp}</button>
                                            </div>

                                            {this.renderCheckOutBulkResult()}

                                            <br/>

                                            {this.state.errorBulkSave && (
                                                <div className="mb-3">
                                                    <span style={{fontSize: "1rem"}}><span className="text-danger"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation-triangle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-exclamation-triangle fa-w-18 fa-1x"><path fill="currentColor" d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z" class=""></path></svg></span> <b>{this.state.errorBulkSave}</b></span>
                                                </div>
                                            )}

                                            {/* <button onClick={this.checkOutBulkCancel} class="btn-agis-light-outline" style="height: 30px; width: 48%; min-width: 0px;"> {localization[lang].checkOutBulkCancel}</button> */}

                                            <button onClick={this.checkOutBulkSave} className="btn-agis-primary w-100" style={{height: "40px", fontSize: "14px", backgroundColor: "#4F81BC"}}>{localization[lang].checkOutBulkSave}</button>
                                            <br/><br/>
                                            <button onClick={this.checkOutBulkCancel} className="btn-agis-light-outline bc-grey w-100" style={{borderRadius: "0px"}}>{localization[lang].checkOutBulkCancel}</button>

                                            <br/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="container-fluid" style={this.state.isLoading ? {display: "none"} : {}}>
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            {this.state.workOrder ? (
                                <>
                                    <div className="agis-card pt-2 pb-2 mb-3">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="w-100">
                                                <span className="text-muted">{localization[lang].workOrder}</span>
                                                <h6><b>{this.state.workOrder.name}</b></h6>
                                                {this.state.workOrder.locked == 1 && (
                                                    <big className="mt-1 text-danger">{this.state.workOrder.lockedtext}</big>
                                                )}
                                            </div>
                                            <div style={{height: "20px", width: "40px", textAlign: "center"}}>
                                                <button onClick={this.dismissWorkOrder} className="text-muted" style={{height: "20px", minWidth: "20px", fontSize: "18px", border: "0", backgroundColor: "#fff"}}><svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10 fa-1x"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path></svg></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="agis-card pt-2 pb-2 mb-3">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="w-100">
                                                <span className="text-muted">{localization[lang].workOrderJob}</span>
                                                <h6><b>{this.state.workOrder.bname}</b></h6>
                                            </div>
                                            <div style={{height: "20px", width: "40px", textAlign: "center"}}>
                                                <button onClick={this.checkWorkOrderJob} className="text-muted" style={{height: "20px", minWidth: "20px", fontSize: "18px", border: "0", backgroundColor: "#fff"}}><FontAwesomeIcon icon={faChevronDown} /></button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className="d-flex align-items-center justify-content-between pt-2 pb-2 mb-3">
                                    <div className="agis-card">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="w-100">
                                                <span className="text-muted">{localization[lang].workOrder}</span>
                                                <input
                                                    id="workOrderInput"
                                                    type="text"
                                                    className="w-100"
                                                    style={{border: "0", color: "#464a4e", fontSize: "14px", lineHeight: "15px", fontSize: "1.1rem"}}
                                                    value={this.state.workOrderText}
                                                    onChange={this.onWorkOrderChange}
                                                    //onBlur={this.onRemarkBlur}
                                                    onKeyPress={this.onWorkOrderKeyPress}
                                                    onPaste={this.onWorkOrderPaste}
                                                    autoFocus={true}
                                                />
                                            </div>
                                            <div style={{height: "20px", width: "40px", textAlign: "center"}}>
                                                <button onClick={() => this.setState({ isWorkOrderLoading: true }, () => { this.checkWorkOrder() })} className="text-muted" style={{height: "20px", minWidth: "20px", fontSize: "18px", border: "0", backgroundColor: "#fff"}}><FontAwesomeIcon icon={faSearch} /></button>
                                            </div>
                                        </div>
                                    </div>
                                    <ScanButton onScan={this.scanWorkOrder} />
                                </div>
                            )}

                            {this.state.worker ? (
                                <div className="agis-card pt-2 pb-2 mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="w-100">
                                            <span className="text-muted">{localization[lang].worker}</span>
                                            <h6><b>{this.state.worker.name}</b></h6>
                                        </div>
                                        <div style={{height: "20px", width: "40px", textAlign: "center"}}>
                                            <button onClick={this.dismissWorker} className="text-muted" style={{height: "20px", minWidth: "20px", fontSize: "18px", border: "0", backgroundColor: "#fff"}}><svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10 fa-1x"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path></svg></button>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="d-flex align-items-center justify-content-between pt-2 pb-2 mb-3">
                                    <div className="agis-card">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="w-100">
                                                <span className="text-muted">{localization[lang].worker}</span>
                                                <input
                                                    id="workerInput"
                                                    type="text"
                                                    className="w-100"
                                                    style={{border: "0", color: "#464a4e", fontSize: "14px", lineHeight: "15px", fontSize: "1.1rem"}}
                                                    value={this.state.workerText}
                                                    onChange={this.onWorkerChange}
                                                    //onBlur={this.onRemarkBlur}
                                                    onKeyPress={this.onWorkerKeyPress}
                                                    onPaste={this.onWorkerPaste}
                                                    //autoFocus={true}
                                                />
                                            </div>
                                            <div style={{height: "20px", width: "40px", textAlign: "center"}}>
                                                <button onClick={() => this.setState({ isWorkerLoading: true }, () => { this.checkWorker() })} className="text-muted" style={{height: "20px", minWidth: "20px", fontSize: "18px", border: "0", backgroundColor: "#fff"}}><FontAwesomeIcon icon={faSearch} /></button>
                                            </div>
                                        </div>
                                    </div>
                                    <ScanButton onScan={this.scanWorker} />
                                </div>
                            )}

                           
                            {this.state.goods ? (
                                <div className="agis-card pt-2 pb-2 mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="w-100">
                                            <span className="text-muted">{localization[lang].goods}</span>
                                            <h6><b>{this.state.goods.name}</b></h6>
                                            <div className="row mt-2">
                                                <div className="col-6">
                                                    <big className="mb-1 text-info"><FontAwesomeIcon icon={faMapMarkerAlt} /> {this.state.goods.location}</big>
                                                </div>
                                                <div className="col-6 text-right">
                                                    <big className="mb-1 text-info"><b>{this.state.goods.total}</b></big>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{height: "20px", width: "40px", textAlign: "center"}}>
                                            <button onClick={this.dismissGoods} className="text-muted" style={{height: "20px", minWidth: "20px", fontSize: "18px", border: "0", backgroundColor: "#fff"}}><svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10 fa-1x"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path></svg></button>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="d-flex align-items-center justify-content-between pt-2 pb-2 mb-3">
                                    <div className="agis-card">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="w-100">
                                                <span className="text-muted">{localization[lang].goods}</span>
                                                <input
                                                    id="goodsInput"
                                                    type="text"
                                                    className="w-100"
                                                    style={{border: "0", color: "#464a4e", fontSize: "14px", lineHeight: "15px", fontSize: "1.1rem"}}
                                                    value={this.state.goodsText}
                                                    onChange={this.onGoodsChange}
                                                    //onBlur={this.onRemarkBlur}
                                                    onKeyPress={this.onGoodsKeyPress}
                                                    onPaste={this.onGoodsPaste}
                                                />
                                            </div>
                                            <div style={{height: "20px", width: "40px", textAlign: "center"}}>
                                                <button onClick={() => this.setState({ isGoodsLoading: true }, () => { this.checkGoods() })} className="text-muted" style={{height: "20px", minWidth: "20px", fontSize: "18px", border: "0", backgroundColor: "#fff"}}><FontAwesomeIcon icon={faSearch} /></button>
                                            </div>
                                        </div>
                                    </div>
                                    <ScanButton onScan={this.scanGoods} />
                                </div>
                            )}
                            
                            {this.state.goods && (
                                <div className="agis-card pt-2 pb-2 mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="w-100">
                                            <span className="text-muted">{localization[lang].quantity}</span>
                                            <input 
                                                id="totalInput"
                                                type="number"
                                                className="w-100"
                                                style={{border: "0", color: "#464a4e", fontSize: "14px", lineHeight: "15px", fontSize: "1.1rem"}}
                                                value={this.state.total} 
                                                onChange={this.onTotalChange}
                                                onKeyPress={this.onTotalKeyPress}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                            {this.state.errorSave && (
                                <div className="mb-3">
                                    <span style={{fontSize: "1rem"}}><span className="text-danger"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation-triangle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-exclamation-triangle fa-w-18 fa-1x"><path fill="currentColor" d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z" class=""></path></svg></span> <b>{this.state.errorSave}</b></span>
                                </div>
                            )}

                            {this.state.workOrder && this.state.worker && this.state.goods && this.state.total && (
                                <button onClick={this.checkOutSave} className="btn-agis-primary w-100" style={{backgroundColor: "#4F81BC"}}>{localization[lang].checkOutOne}</button>
                            )}

                            {this.state.workOrder && this.state.worker && (
                                <button onClick={() => this.checkOutBulk(this.state.workOrder.bcode, true)} className="btn-agis-primary w-100" style={{backgroundColor: "#4F81BC"}}>{localization[lang].checkOutBulkBlock}</button>
                            )}

                            {this.state.workOrder && this.state.worker && (
                                <button onClick={() => this.checkOutBulk(".", true)} className="btn-agis-primary w-100 mt-2" style={{backgroundColor: "#4F81BC"}}>{localization[lang].checkOutBulkAll}</button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CheckOut;