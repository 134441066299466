import React from 'react'
import { Link } from 'react-router-dom'
import DocumentMeta from 'react-document-meta';
import axios from 'axios';
import {scanImageData} from 'zbar.wasm';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faExpand } from '@fortawesome/free-solid-svg-icons';

import AppContext from '../AppContext';

import BeepSound from '../files/audio/beep.mp3';
import ErrorSound from '../files/audio/error.wav';

class ScanButton extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,
            isOpened: false,

            videoDevices: [],

            videoSide: null,
            videoDevice: null,

            tracks: null,
            stream: null,

            videoError: null,
        }
        
        this.video = React.createRef();

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);

        this.detect = this.detect.bind(this);
    }

    componentDidMount() {
        this.init();
    }

    componentWillUnmount() {
        if (this.state.tracks) {
            this.state.tracks.getTracks().forEach(track => track.stop());
        }
    }

    init() {
        const {videoSide, videoDevice, addNotification, removeNotification} = this.context;

        var devicesPromise = navigator.mediaDevices.enumerateDevices();

        var u = addNotification("Завантаження...");

        axios.all([devicesPromise]).then(axios.spread((d) => {
            this.setState({
                isLoading: false,

                videoDevices: d.filter((device) => {
                    return device.kind === 'videoinput';
                }),

                videoSide: videoSide,
                videoDevice: videoDevice,
            }, () => {
                removeNotification(u);
                //this.open();
            });
        }));
    }

    open() {
        this.setState({isOpened: true}, () => this.runCamera());
    }

    close() {
        this.setState({isOpened: false});
    }

    runCamera() {
        if (this.state.videoError == null) {
            this.video.current.srcObject = null;
        }

        if (this.state.videoSide && this.state.videoDevice) {
            const constraints = {
                video: {
                    facingMode: { exact: this.state.videoSide },
                    deviceId: { exact: this.state.videoDevice }
                },
                audio: false
            };
    
            navigator.mediaDevices.getUserMedia(constraints).then(res => {
                this.setState({tracks: res, videoError: null});
                this.video.current.srcObject = res;
            }).catch(err => {
                console.log(err);
                this.setState({videoError: err})
            });
        } else {
            this.setState({videoError: {name: "No device selected"}})
        }
    }

    detect(e) {
        e.stopPropagation();

        const canvas = document.createElement('canvas');

        const width = this.video.current.videoWidth;
        const height = this.video.current.videoHeight;

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(this.video.current, 0, 0, width, height);
        const imgData = ctx.getImageData(0, 0, width, height);

        scanImageData(imgData).then(res => {
            console.log(res);
            console.log(res[0].typeName);
            console.log(res[0].decode());

            if (res.length == 1) {
                this.beep(true);
                this.props.onScan(res[0].decode(), this.props.param);
                this.close();
            } else {
                this.beep(false);
            }
        }).catch(err => {
            console.log(err);
            this.beep(false);
        });
    }

    beep(status) {
        if (status) {
            var snd = new Audio(BeepSound);  
            snd.play();
        } else {
            var snd = new Audio(ErrorSound); 
            snd.play();
        }
    }

    render() {
        const {lang, isUpdate} = this.context;

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div>
                <button onClick={this.open} className="a-agis-light-outline ml-2" style={this.props.style ? this.props.style : {height: '40px', width: "60px", fontSize: "16px", minWidth: "0px", borderRadius: "10px", lineHeight: "40px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 0 0 1px rgba(0,0,0,.05), 0 2px 3px 0 rgba(0,0,0,.1)"}}><span><svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="barcode-read" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="svg-inline--fa fa-barcode-read fa-w-16 fa-2x"><path fill="currentColor" d="M152 0H8C3.6 0 0 3.6 0 8v152c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V32h120c4.4 0 8-3.6 8-8V8c0-4.4-3.6-8-8-8zm0 480H32V352c0-4.4-3.6-8-8-8H8c-4.4 0-8 3.6-8 8v152c0 4.4 3.6 8 8 8h144c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zM632 0H488c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h120v128c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V8c0-4.4-3.6-8-8-8zm0 344h-16c-4.4 0-8 3.6-8 8v128H488c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h144c4.4 0 8-3.6 8-8V352c0-4.4-3.6-8-8-8zM152 96h-48c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8zm336 320h48c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8zM408 96h-48c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8zm-192 0h-16c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8zm64 0h-16c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8z" class=""></path></svg></span></button>

                <div onClick={this.close} style={{
                    zIndex: 1000,
                    display: this.state.isOpened ? "block" : "none",
                    position: "fixed",
                    left: "0px",
                    top: "0px",
                    right: "0px",
                    bottom: "0px",
                    backgroundColor: "#808080cc",
                    fontSize: "14px"
                }}>
                    <div style={{height: "75%", width: "100%"}} className={this.state.videoError == null ? "d-flex align-items-start" : "d-none"}>
                        <video onClick={(e) => {e.stopPropagation()}} ref={this.video} className="w-100" muted={true} playsInline={true} autoPlay={true}></video>
                    </div>

                    {this.state.videoError != null && (
                        <div className="row mb-2">
                            <div className="col text-center text-danger p-3">
                                <span><b>{this.state.videoError.name}</b> {this.state.videoError.message}</span>                                                
                            </div>
                        </div>
                    )}

                    <div className="fixed-bottom text-center" style={{zIndex: "4", paddingBottom: "20px", height: "70px"}}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-4 d-flex align-items-center justify-content-center">
                                    <span style={{fontSize: "1.2rem", color: "#dcdcdc"}}>
                                        <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10 fa-2x"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z" class=""></path></svg>
                                    </span>
                                </div>
                                <div className="col-4 text-center">
                                    <button onClick={(e) => this.detect(e)} className="btn-agis-light-outline" style={{height: '60px', width: "60px", minWidth: "0px", borderRadius: "30px", lineHeight: "64px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 1px 1px rgba(0,0,0,.15)"}}><span style={{fontSize: "16px"}}><svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="barcode-read" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="svg-inline--fa fa-barcode-read fa-w-20 fa-2x"><path fill="currentColor" d="M152 0H8C3.6 0 0 3.6 0 8v152c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V32h120c4.4 0 8-3.6 8-8V8c0-4.4-3.6-8-8-8zm0 480H32V352c0-4.4-3.6-8-8-8H8c-4.4 0-8 3.6-8 8v152c0 4.4 3.6 8 8 8h144c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zM632 0H488c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h120v128c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V8c0-4.4-3.6-8-8-8zm0 344h-16c-4.4 0-8 3.6-8 8v128H488c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h144c4.4 0 8-3.6 8-8V352c0-4.4-3.6-8-8-8zM152 96h-48c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8zm336 320h48c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8zM408 96h-48c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8zm-192 0h-16c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8zm64 0h-16c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8z" class=""></path></svg></span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }
}

export default ScanButton;