import React from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';
import ScaleLoader from "react-spinners/ScaleLoader";

import AppContext from '../../../AppContext.js';
import Submenu from '../../../components/submenu.js';
import SubmenuContent from './submenu.js';
import OffsetTop from '../../../components/offsetTop.js';

import {dateToInputFormat, databaseFormatToDate, dateToDatabaseFormat} from '../../../utils/datetime.js';

const localization = {
    ua: {
        title: 'Driver Feedback',
        customer: {
            title: 'Інформація про клієнта',
            driver: 'Водій',
            company: 'Компанія',
            phone: 'Телефон',
            vehicle: 'Авто',
            stateNumber: 'Реєстраційний номер',
            workshop: 'Дилер',
            visitDate: 'Дата візиту',
            interviewDate: 'Дата опитування',
        },
        scores: {
            title: 'Результати опитування',
            recommendation: 'Рекомендація',
            satisfaction: 'Задоволення',
            remark: 'Відповідь',
        },
        action: {
            title: 'Розгляд',
            actionDate: 'Дата',
            actionName: "Им'я",
            actionRemark: 'Коментар / заходи',
            reasons: 'Причини невдоволення',
            reason1: 'Привітання та прийом',
            reason2: 'Наявність послуги (можливість зарезервувати візит)',
            reason3: 'Час ремонту',
            reason4: 'Якість ремонту (повторне відвідування, неповне тощо)',
            reason5: 'Технічні / комерційні навички персоналу',
            reason6: 'Стан транспортного засобу при поверненні',
            reason7: 'Ціна',
            reason8: 'Інформація (про роботу, затримки, додаткові роботи / витрати)',
            reason9: 'Години роботи',
            reason10: 'Інше (дайте короткий опис у вікні нижче)',
            statusQuestion: 'Відгук розглянуто?',
            statusText: 'Так, розглянуто',
            saveButton: 'Зберегти',
        }
    },
    ru: {
        title: 'Driver Feedback',
        customer: {
            title: 'Информация о клиенте',
            driver: 'Водитель',
            company: 'Компания',
            phone: 'Телефон',
            vehicle: 'Авто',
            stateNumber: 'Регистрационный номер',
            workshop: 'Дилер',
            visitDate: 'Дата визита',
            interviewDate: 'Дата опроса',
        },
        scores: {
            title: 'Оценки опроса',
            recommendation: 'Рекомендация',
            satisfaction: 'Удовлетворение',
            remark: 'Ответ',
        },
        action: {
            title: 'Рассмотрение',
            actionDate: 'Дата',
            actionName: 'Имя',
            actionRemark: 'Комментарий / меры',
            reasons: 'Причины недовольства',
            reason1: 'Приветствия и прием',
            reason2: 'Доступность услуги (возможность записи на визит)',
            reason3: 'Время ремонта',
            reason4: 'Качество ремонта (повторный визит, неполный и т.д)',
            reason5: 'Технические / коммерческие навыки персонала мастерской',
            reason6: 'Состояние автомобиля при возврате',
            reason7: 'Цена',
            reason8: 'Информация (о работе, задержках, дополнительных работах / затратах)',
            reason9: 'Часы работы',
            reason10: 'Другое (дайте короткое описание в окне ниже)',
            statusQuestion: 'Рассмотрение закрыто?',
            statusText: 'Да, закрыто',
            saveButton: 'Сохранить',
        }
    },
    en: {
        title: 'Driver Feedback',
        customer: {
            title: 'Customer detail',
            driver: 'Driver',
            company: 'Company',
            phone: 'Phone',
            vehicle: 'Vehicle',
            stateNumber: 'Registration number',
            workshop: 'Workshop',
            visitDate: 'Date of visit',
            interviewDate: 'Date of interview',
        },
        scores: {
            title: 'Survey scores',
            recommendation: 'Recommendation',
            satisfaction: 'Satisfaction',
            remark: 'Text answer',
        },
        action: {
            title: 'Your action',
            actionDate: 'Date',
            actionName: 'Name',
            actionRemark: 'Comments / action',
            reasons: 'Reasons for dissatisfaction',
            reason1: 'Greetings & reception',
            reason2: 'Availability of service (possibility to book appointment)',
            reason3: 'Repair time',
            reason4: 'Repair quality (repeat visit, incomplete etc)',
            reason5: 'Technical / commercial skills of workshop staff',
            reason6: 'Condition of vehicle at return',
            reason7: 'Price',
            reason8: 'Information (about work, delays, additional work / costs)',
            reason9: 'Opening hours',
            reason10: 'Other (give a short description in the box below)',
            statusQuestion: 'Is card closed?',
            statusText: 'Yes, closed',
            saveButton: 'Save',
        }
    },
}

class ResponseCard extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            redirect: null,

            isLoading: true,

            nps: null,
            satisfaction: null,
            answers: null,
            top2: null,
        }

        this.changeDate = this.changeDate.bind(this);
        this.changeName = this.changeName.bind(this);
        this.changeRemark = this.changeRemark.bind(this);

        this.changeReason1 = this.changeReason1.bind(this);
        this.changeReason2 = this.changeReason2.bind(this);
        this.changeReason3 = this.changeReason3.bind(this);
        this.changeReason4 = this.changeReason4.bind(this);
        this.changeReason5 = this.changeReason5.bind(this);
        this.changeReason6 = this.changeReason6.bind(this);
        this.changeReason7 = this.changeReason7.bind(this);
        this.changeReason8 = this.changeReason8.bind(this);
        this.changeReason9 = this.changeReason9.bind(this);
        this.changeReason10 = this.changeReason10.bind(this);
        this.changeReason10Remark = this.changeReason10Remark.bind(this);

        this.changeStatus = this.changeStatus.bind(this);

        this.save = this.save.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        this.load();
    }

    load() {
        const {addNotification, removeNotification, token} = this.context;

        var id = Number(this.props.match.params.id);

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/crm/driver-feedback/response-card/card', { token: token, id: id }).then((res) => {
                this.setState({
                    isLoading: false,

                    id: id,
                    
                    driver: res.data.rows[0].driver,
                    company: res.data.rows[0].company,
                    phone: res.data.rows[0].phone,
                    vehicle: res.data.rows[0].vehicle,
                    stateNumber: res.data.rows[0].stateNumber,
                    workshop: res.data.rows[0].workshop,
                    visitDate: res.data.rows[0].visitDate,
                    interviewDate: res.data.rows[0].interviewDate,

                    recommendation: res.data.rows[0].recommendation,
                    satisfaction: res.data.rows[0].satisfaction,
                    remark: res.data.rows[0].remark,

                    actionDate: databaseFormatToDate(res.data.rows[0].actionDate),
                    actionName: res.data.rows[0].actionName,
                    actionRemark: res.data.rows[0].actionRemark,

                    reason1: res.data.rows[0].reason1,
                    reason2: res.data.rows[0].reason2,
                    reason3: res.data.rows[0].reason3,
                    reason4: res.data.rows[0].reason4,
                    reason5: res.data.rows[0].reason5,
                    reason6: res.data.rows[0].reason6,
                    reason7: res.data.rows[0].reason7,
                    reason8: res.data.rows[0].reason8,
                    reason9: res.data.rows[0].reason9,
                    reason10: res.data.rows[0].reason10,
                    reason10Remark: res.data.rows[0].reason10Remark,

                    status: res.data.rows[0].status,
                });
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            this.setState({
                isLoading: false,

                id: id,

                driver: "Vasia",
                company: "Silpo",
                phone: "0508673516",
                vehicle: "Scania",
                stateNumber: "AA2231KA",
                workshop: "Kyiv",
                visitDate: "2020-01-25",
                interviewDate: "2020-01-25",

                recommendation: "5",
                satisfaction: "3",
                remark: "text",

                actionDate: databaseFormatToDate("25.01.2020"),
                actionName: "Kek",
                actionRemark: "Text",

                reason1: 1,
                reason2: 0,
                reason3: 1,
                reason4: 0,
                reason5: 0,
                reason6: 1,
                reason7: 0,
                reason8: 1,
                reason9: 0,
                reason10: 0,
                reason10Remark: "Text text",

                status: 1,
            });
        }
    }

    changeDate(event) {
        this.setState({actionDate: new Date(event.target.value).getTime()});
    }

    changeName(event) {
        this.setState({actionName: event.target.value});
    }

    changeRemark(event) {
        this.setState({actionRemark: event.target.value});
    }

    changeReason1(event) {
        this.setState({reason1: event.target.checked ? 1 : 0});
    }

    changeReason2(event) {
        this.setState({reason2: event.target.checked ? 1 : 0});
    }

    changeReason3(event) {
        this.setState({reason3: event.target.checked ? 1 : 0});
    }

    changeReason4(event) {
        this.setState({reason4: event.target.checked ? 1 : 0});
    }

    changeReason5(event) {
        this.setState({reason5: event.target.checked ? 1 : 0});
    }

    changeReason6(event) {
        this.setState({reason6: event.target.checked ? 1 : 0});
    }

    changeReason7(event) {
        this.setState({reason7: event.target.checked ? 1 : 0});
    }

    changeReason8(event) {
        this.setState({reason8: event.target.checked ? 1 : 0});
    }

    changeReason9(event) {
        this.setState({reason9: event.target.checked ? 1 : 0});
    }

    changeReason10(event) {
        this.setState({reason10: event.target.checked ? 1 : 0});
    }

    changeReason10Remark(event) {
        this.setState({reason10Remark: event.target.value});
    }

    changeStatus(event) {
        this.setState({status: event.target.checked ? 2 : 1});
    }

    save() {
        const {addNotification, removeNotification, token} = this.context;

        var u = addNotification("Завантаження...");
        axios.post('/api/crm/driver-feedback/response-card/save', {
            token: token, 
            id: this.state.id,

            actionDate: dateToDatabaseFormat(this.state.actionDate),
            actionName: this.state.actionName,
            actionRemark: this.state.actionRemark,

            reason1: this.state.reason1,
            reason2: this.state.reason2,
            reason3: this.state.reason3,
            reason4: this.state.reason4,
            reason5: this.state.reason5,
            reason6: this.state.reason6,
            reason7: this.state.reason7,
            reason8: this.state.reason8,
            reason9: this.state.reason9,
            reason10: this.state.reason10,
            reason10Remark: this.state.reason10Remark,

            status: this.state.status
        }).then((res) => {
            this.setState({redirect: "/crm/driver-feedback/action-list"});
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    render() {
        const {lang} = this.context;

        if (this.state.redirect !== null) {
            return <Redirect to={this.state.redirect} />
        }

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.isLoading) {
            return (
                <div></div>
            )
        }

        return (
            <div>
                <DocumentMeta {...meta} />

                <Submenu>
                    <SubmenuContent />
                </Submenu>

                <br/><br/>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">

                            <div className="container-fluid pb-4 mb-4">
                                <div className="row">
                                    <div className="col">
                                        <div className="agis-card pt-2 pb-2 pb-4 mb-4">
                                            <h4><b>{localization[lang].customer.title}</b></h4>

                                            <div className="row mt-3 mb-2">
                                                <div className="col-5">
                                                    <span className="text-muted" style={{fontSize: "16px"}}>{localization[lang].customer.driver}</span>
                                                </div>
                                                <div className="col">
                                                    <span style={{fontSize: "16px"}}><b>{this.state.driver}</b></span>
                                                </div>
                                            </div>

                                            <div className="row mb-2">
                                                <div className="col-5">
                                                    <span className="text-muted" style={{fontSize: "16px"}}>{localization[lang].customer.company}</span>
                                                </div>
                                                <div className="col">
                                                    <span style={{fontSize: "16px"}}><b>{this.state.company}</b></span>
                                                </div>
                                            </div>

                                            <div className="row mb-2">
                                                <div className="col-5">
                                                    <span className="text-muted" style={{fontSize: "16px"}}>{localization[lang].customer.phone}</span>
                                                </div>
                                                <div className="col">
                                                    <span style={{fontSize: "16px"}}><b>{this.state.phone}</b></span>
                                                </div>
                                            </div>

                                            <div className="row mb-2">
                                                <div className="col-5">
                                                    <span className="text-muted" style={{fontSize: "16px"}}>{localization[lang].customer.vehicle}</span>
                                                </div>
                                                <div className="col">
                                                    <span style={{fontSize: "16px"}}><b>{this.state.vehicle}</b></span>
                                                </div>
                                            </div>

                                            <div className="row mb-2">
                                                <div className="col-5">
                                                    <span className="text-muted" style={{fontSize: "16px"}}>{localization[lang].customer.stateNumber}</span>
                                                </div>
                                                <div className="col">
                                                    <span style={{fontSize: "16px"}}><b>{this.state.stateNumber}</b></span>
                                                </div>
                                            </div>

                                            <div className="row mb-2">
                                                <div className="col-5">
                                                    <span className="text-muted" style={{fontSize: "16px"}}>{localization[lang].customer.workshop}</span>
                                                </div>
                                                <div className="col">
                                                    <span style={{fontSize: "16px"}}><b>{this.state.workshop}</b></span>
                                                </div>
                                            </div>

                                            <div className="row mb-2">
                                                <div className="col-5">
                                                    <span className="text-muted" style={{fontSize: "16px"}}>{localization[lang].customer.visitDate}</span>
                                                </div>
                                                <div className="col">
                                                    <span style={{fontSize: "16px"}}><b>{this.state.visitDate}</b></span>
                                                </div>
                                            </div>

                                            <div className="row mb-2">
                                                <div className="col-5">
                                                    <span className="text-muted" style={{fontSize: "16px"}}>{localization[lang].customer.interviewDate}</span>
                                                </div>
                                                <div className="col">
                                                    <span style={{fontSize: "16px"}}><b>{this.state.interviewDate}</b></span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="agis-card pt-2 pb-2">
                                            <h4><b>{localization[lang].scores.title}</b></h4>

                                            <div className="row mt-3 mb-2">
                                                <div className="col-5">
                                                    <span className="text-muted" style={{fontSize: "16px"}}>{localization[lang].scores.recommendation}</span>
                                                </div>
                                                <div className="col">
                                                    <span style={{fontSize: "16px"}}><b>{this.state.recommendation}</b></span>
                                                </div>
                                            </div>

                                            <div className="row mb-2">
                                                <div className="col-5">
                                                    <span className="text-muted" style={{fontSize: "16px"}}>{localization[lang].scores.satisfaction}</span>
                                                </div>
                                                <div className="col">
                                                    <span style={{fontSize: "16px"}}><b>{this.state.satisfaction}</b></span>
                                                </div>
                                            </div>

                                            <div className="row mb-2">
                                                <div className="col-5">
                                                    <span className="text-muted" style={{fontSize: "16px"}}>{localization[lang].scores.remark}</span>
                                                </div>
                                                <div className="col">
                                                    <span style={{fontSize: "16px"}}><b>{this.state.remark}</b></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="agis-card pt-2 pb-2">
                                            <h4><b>{localization[lang].action.title}</b></h4>

                                            <div className="row mt-3 mb-2">
                                                <div className="col">
                                                    <span className="text-muted">{localization[lang].action.actionDate}</span>
                                                    <input type="date" className="question-input" value={dateToInputFormat(this.state.actionDate)} onChange={this.changeDate} />
                                                </div>
                                            </div>

                                            <div className="row mb-2">
                                                <div className="col">
                                                    <span className="text-muted">{localization[lang].action.actionName}</span>
                                                    <input type="text" className="question-input" value={(this.state.actionName)} onChange={this.changeName} />
                                                </div>
                                            </div>

                                            <div className="row mb-2">
                                                <div className="col">
                                                    <span className="text-muted">{localization[lang].action.actionRemark}</span>
                                                    <textarea className="question-input" rows={3} style={{height: "auto"}} value={this.state.actionRemark} onChange={this.changeRemark} />
                                                </div>
                                            </div>

                                            <div className="row mb-2">
                                                <div className="col">
                                                    <span className="text-muted">{localization[lang].action.reasons}</span>
                                                    <div className="mt-1">
                                                        <input id="greetingsReception" type="checkbox" className="ml-3 mr-2" style={{lineHeight: "16px"}} checked={this.state.reason1 == 1} onChange={this.changeReason1} /><label for="greetingsReception" style={{fontSize: "16px"}}><b>{localization[lang].action.reason1}</b></label>
                                                    </div>
                                                    <div className="mt-1">
                                                        <input id="availabilityOfService" type="checkbox" className="ml-3 mr-2" style={{lineHeight: "16px"}} checked={this.state.reason2 == 1} onChange={this.changeReason2} /><label for="availabilityOfService" style={{fontSize: "16px"}}><b>{localization[lang].action.reason2}</b></label>
                                                    </div>
                                                    <div className="mt-1">
                                                        <input id="repairTime" type="checkbox" className="ml-3 mr-2" style={{lineHeight: "16px"}} checked={this.state.reason3 == 1} onChange={this.changeReason3} /><label for="repairTime" style={{fontSize: "16px"}}><b>{localization[lang].action.reason3}</b></label>
                                                    </div>
                                                    <div className="mt-1">
                                                        <input id="repairQuality" type="checkbox" className="ml-3 mr-2" style={{lineHeight: "16px"}} checked={this.state.reason4 == 1} onChange={this.changeReason4} /><label for="repairQuality" style={{fontSize: "16px"}}><b>{localization[lang].action.reason4}</b></label>
                                                    </div>
                                                    <div className="mt-1">
                                                        <input id="technicalOrCommercialSkills" type="checkbox" className="ml-3 mr-2" style={{lineHeight: "16px"}} checked={this.state.reason5 == 1} onChange={this.changeReason5} /><label for="technicalOrCommercialSkills" style={{fontSize: "16px"}}><b>{localization[lang].action.reason5}</b></label>
                                                    </div>
                                                    <div className="mt-1">
                                                        <input id="vehicleCondition" type="checkbox" className="ml-3 mr-2" style={{lineHeight: "16px"}} checked={this.state.reason6 == 1} onChange={this.changeReason6} /><label for="vehicleCondition" style={{fontSize: "16px"}}><b>{localization[lang].action.reason6}</b></label>
                                                    </div>
                                                    <div className="mt-1">
                                                        <input id="price" type="checkbox" className="ml-3 mr-2" style={{lineHeight: "16px"}} checked={this.state.reason7 == 1} onChange={this.changeReason7} /><label for="price" style={{fontSize: "16px"}}><b>{localization[lang].action.reason7}</b></label>
                                                    </div>
                                                    <div className="mt-1">
                                                        <input id="information" type="checkbox" className="ml-3 mr-2" style={{lineHeight: "16px"}} checked={this.state.reason8 == 1} onChange={this.changeReason8} /><label for="information" style={{fontSize: "16px"}}><b>{localization[lang].action.reason8}</b></label>
                                                    </div>
                                                    <div className="mt-1">
                                                        <input id="openingHours" type="checkbox" className="ml-3 mr-2" style={{lineHeight: "16px"}} checked={this.state.reason9 == 1} onChange={this.changeReason9} /><label for="openingHours" style={{fontSize: "16px"}}><b>{localization[lang].action.reason9}</b></label>
                                                    </div>
                                                    <div className="mt-1">
                                                        <input id="other" type="checkbox" className="ml-3 mr-2" style={{lineHeight: "16px"}} checked={this.state.reason10 == 1} onChange={this.changeReason10} /><label for="other" style={{fontSize: "16px"}}><b>{localization[lang].action.reason10}</b></label>
                                                    </div>
                                                    <div className="pl-3 pr-2">
                                                        <input type="text" className="question-input" style={{lineHeight: "16px"}} value={this.state.reason10Remark} onChange={this.changeReason10Remark} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mt-4">
                                                <div className="col">
                                                    <span className="text-muted">{localization[lang].action.statusQuestion}</span>
                                                    <div className="mt-1">
                                                        <input id="status" type="checkbox" className="ml-3 mr-2" style={{lineHeight: "16px"}} checked={this.state.status == 2} onChange={this.changeStatus} /><label for="status" style={{fontSize: "16px"}}><b>{localization[lang].action.statusText}</b></label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col text-right">
                                                    <button className="btn-agis-primary" onClick={this.save}>{localization[lang].action.saveButton}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ResponseCard;