import React from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRoad, faBed, faBuilding, faHome, faWrench, faSmoking, faSearch, faPlus } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../AppContext.js';
import OffsetTop from '../../../components/offsetTop.js';

const localization = {
    ua: {
        title: 'Історія стемпiнгу',
        history: {
            todayTitle: 'Сьогоднi'
        },
        noData: 'Данi вiдсутнi'
    },
    ru: {
        title: 'История стемпинга',
        history: {
            todayTitle: 'Сегодня'
        },
        noData: 'Нет данных'
    },
    en: {
        title: 'Stamping history',
        history: {
            todayTitle: 'Today'
        },
        noData: 'No data'
    },
}

class History extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            stamps: []
        }

        this.normalizeStamp = this.normalizeStamp.bind(this);
        this.groupColor = this.groupColor.bind(this);
        this.groupIcon = this.groupIcon.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        this.loadList();
    }

    loadList() {
        const {addNotification, removeNotification, token} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();

            today = dd + '.' + mm + '.' + yyyy;

            var u = addNotification("Завантаження...");
            axios.post('/api/stamping/history', { token: token, date: today }).then((res) => {
                this.setState({stamps: res.data.rows});
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            this.setState(
                {
                    stamps: [
                        { 
                            gid: "1",
                            sid: 1,  
                            start: "25.10.19 8:12:09",
                            finish: "25.10.19 9:31:17",
                            hours: "1:10",
                            sname: "Підготовка до ремонту"
                        },
                        { 
                            gid: "2",
                            sid: 2,  
                            start: "25.10.19 09:32:28",
                            finish: "25.10.19 11:48:17",
                            hours: "2:20",
                            sname: "Обід"
                        },
                        { 
                            gid: "3",
                            sid: 3,  
                            start: "25.10.19 12:03:17",
                            finish: "",
                            hours: "3:30",
                            sname: "Дорога при ТО, ремонті автомобіля"
                        },
                        { 
                            gid: "4",
                            sid: 3,  
                            start: "25.10.19 2:03:17",
                            finish: "",
                            hours: "4:40",
                            sname: "Лікарняний"
                        },
                        { 
                            gid: "5",
                            sid: 3,  
                            start: "25.10.19 12:03:17",
                            finish: "",
                            hours: "5:50",
                            sname: "Консультація клієнта по телефону"
                        },
                        { 
                            gid: "6",
                            sid: 3,  
                            start: "25.10.19 12:03:17",
                            finish: "",
                            hours: "0",
                            sname: "Окончание рабочего дня"
                        }
                    ]
                },
            );
        }
    }

    groupColor(gid) {
        switch(gid) {
            case '1':
                return "#0091ea";
            case '2':  
                return "#ffab00";
            case '3':
                return "#b0bec5";
            case '4':  
                return "#c5cae9";
            case '5':
                return "#992450";
            default:
                return "";
          }
    }

    groupIcon(gid) {
        switch(gid) {
            case '1':
                return faWrench;
            case '2':  
                return faSmoking;
            case '3':
                return faRoad;
            case '4':  
                return faBed;
            case '5':
                return faBuilding;
            default:
                return faHome;
          }
    }

    normalizeStamp(stamp) {
        if (stamp == "") {
            return ". . .";
        }
        var datetime = stamp.split(" ");
        var time = datetime[1].split(":");
        var res = time[0] + ":" + time[1];

        if (res.length < 5) {
            res = "0"+res;
        }

        return res;
    }

    renderList() {
        const {lang} = this.context;

        if (this.state.stamps.length === 0) {
            return (
                <div className="row">
                    <div className="col">
                        <div className="card agis-card">
                            <div className="row">
                                <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                    <div>
                                        <h6><b>{localization[lang].noData}</b></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return this.state.stamps.map((item, i) => {
                var borderColor = this.groupColor(item.gid);

                return (
                    <div className="row pt-1 pb-1" key={"stamp_"+item.qid}>
                        <div className="col">
                            <div className="body pl-2" style={{borderLeft: "4px solid" + borderColor}}>
                                {(this.state.stamps.length == ++i && item.hours == "0") ? (
                                    <small><b>. . .</b></small>
                                ) :(
                                    <small>{item.hours}</small>
                                )}
                            </div>
                            <div className="body pl-2" style={{borderLeft: "4px solid" + borderColor}}>
                                <h6><b>{this.normalizeStamp(item.start)}</b> <span dangerouslySetInnerHTML={{__html: item.sname}}></span></h6>
                            </div>
                        </div>
                    </div>
                );
            }).reverse();
        }
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="container-fluid pl-0 pr-0">
                                <div className="row mb-2">
                                    <div className="col-8">
                                        <h3>{localization[lang].history.todayTitle}</h3>
                                    </div>
                                    <div className="col-4 text-right">
                                        {/* <button className="btn-agis-light-outline" style={{height: "35px", width: "35px", minWidth: "35px"}}><FontAwesomeIcon icon={faSearch}/></button> */}
                                        <Link to={"/order/stamping/pin"} className="btn-agis-light-outline" style={{height: "35px", width: "35px", minWidth: "35px"}}><FontAwesomeIcon icon={faPlus}/></Link>
                                    </div>
                                </div>
                                {this.renderList()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default History;