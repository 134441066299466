import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import AppContext from '../AppContext';


const localization = {
    ua: {
        title: 'Необхідне перезавантаження',
        text: "На ваш пристрій встановлена нова версія програми. Щоб уникнути небажаних помилок її необхідно перезавантажити. Якщо ви використовуєте програму в браузері(на комп'ютері або телефоні), то необхідно закрити усі вкладки програми щоб оновлення вступили в силу. Якщо ви використовуєте встановлений веб-додаток, то необхідно його перезавантажити. Детальніше як це зробити якщо ви використовуєте:",
        web: 'Браузер',
        mobile: 'Встановлений веб-додаток'
    },
    ru: {
        title: 'Необходима перезагрузка',
        text: 'На ваще устройство установлена новая версия программы. Чтобы избежать нежелательных ошибок её необходимо перезагрузить. Если вы используете программу в браузере (на компьютере или телефоне), то необходимо закрыть все вкладки программы чтобы обновления вступили в силу. Если вы используете установленное веб-приложение, то необходимо его перезагрузить. Подробнее как это сделать если вы используете:',
        web: 'Браузер',
        mobile: 'Установленный веб-приложение'
    },
    en: {
        title: 'Restart is required',
        text: 'New version of the program is installed on the device. To avoid unwanted errors, you must restart it. If you use the program in a browser (on a computer or phone), you must close all the tabs for the updates to take effect. If you are using an installed web application, you must restart it. More details on how to do this if you use:',
        web: 'Browser',
        mobile: 'Installed web application'
    },
}

class NewVersion extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
		}
    }

    render() {
        const {lang, isUpdate} = this.context;

        return (
            <div className="card-agis pt-2 pb-2" style={{
                display: isUpdate ? "block" : "none",
                zIndex: 1000,
                position: "fixed",
                left: "10px",
                right: "10px",
                bottom: "10px",

                borderRadius: "15px"

                // backgroundColor: "#f9edbe",
                // border: "1px solid #f0c36d",
                // boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                // color: "#222",
                // padding:" 6px 10px",

                // borderRadius: "10px",
                // fontSize: "14px"
            }}>
                <h6><b>Updating...</b></h6>
                {/* <span>{localization[lang].text}</span><br/>
                <a href="https://support.agis.group/agis-web/update#web" target="_blank">{localization[lang].web}</a><br/>
                <a href="https://support.agis.group/agis-web/update#mobile" target="_blank">{localization[lang].mobile}</a> */}
            </div>
        )
    }
}

export default NewVersion;