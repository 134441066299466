import React from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import DocumentMeta from 'react-document-meta';
import imageCompression from 'browser-image-compression';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faParking, faBuilding, faWrench, faList, faPause, faStop, faUtensils, faPlay, faComment, faCamera, faSave, faCog, faAmbulance, faPaperclip, faPlus, faTint, faChevronRight, faChevronDown, faTasks } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../AppContext.js';
import OffsetTop from '../../components/offsetTop.js';
import Preview from '../../components/preview.js';

const localization = {
    ua: {
        title: 'ЗН',
        blocks: 'Блоки робіт',
        stamping: {
            paused: 'Призупинено',
            start: 'Розпочати',
            pause: 'Призупинити',
            reason: 'Інший привід',
            continue: 'Продовжити',
            finish: 'Закінчити'
        },
        acceptance: {
            accepted: 'Погоджено',
            confirmed: 'Підтверджено',
            canceled: 'Відмова',
            acceptedreview: 'Потребує узгодження',
            confirmedreview: 'Потребує підтвердження',
        },
        job: {
            create: 'Створити',
            title: "Створення блоку робіт",
            placeholder: "Назва...",
            save: 'Зберегти',
        },
        labour: {
            create: 'Додати роботу',
            title: "Створення роботи",
            placeholder: "Назва...",
            save: 'Зберегти',
        },
        part: {
            create: 'Додати запчастину',
            title: "Додавання запчастини",
            placeholder: "Назва...",
            save: 'Зберегти',
        },
        checklist: {
            create: 'Додати чек-лист',
            title: "Створення чек-листа",
        },
        workpost: {
            change: "Змінити",
            title: "Обрати пост",
        },
        checklistlink: 'Чек-лист',
        acceptancelist: 'Акт приймання',
        recommendations: 'Рекомендації',
        about: 'Про клієнта',
        summary: 'Фіналізувати',
        noData: 'Данi вiдсутнi'
    },
    ru: {
        title: 'ЗН',
        blocks: 'Блоки работ',
        stamping: {
            paused: 'Приостановлено',
            start: 'Начать',
            pause: 'Приостановить',
            reason: 'Другой повод',
            continue: 'Продолжить',
            finish: 'Закончить'
        },
        acceptance: {
            accepted: 'Согласовано',
            confirmed: 'Подтверждено',
            canceled: 'Отказ',
            review: 'В ожидании',
        },
        job: {
            create: 'Создать',
            title: "Создание блока работ",
            placeholder: "Название...",
            save: 'Сохранить',
        },
        labour: {
            create: 'Добавить работу',
            title: "Создание работы",
            placeholder: "Название...",
            save: 'Сохранить',
        },
        part: {
            create: 'Добавить запчасть',
            title: "Добавление запчасти",
            placeholder: "Название...",
            save: 'Сохранить',
        },
        checklist: {
            create: 'Добавить чек-лист',
            title: "Создание чек-листа",
        },
        workpost: {
            change: "Изменить",
            title: "Выбрать пост",
        },
        checklistlink: 'Чек-лист',
        acceptancelist: 'Акт приёма',
        recommendations: 'Рекомендации',
        about: 'Про клиента',
        summary: 'Финализировать',
        noData: 'Нет данных'
    },
    en: {
        title: 'RO',
        blocks: 'Blocks',
        stamping: {
            paused: 'Paused',
            start: 'Start',
            pause: 'Pause',
            reason: 'Another reason',
            continue: 'Continue',
            finish: 'Stop'
        },
        acceptance: {
            accepted: 'Accepted',
            confirmed: 'Confirmed',
            canceled: 'Canceled',
            review: 'To review',
        },
        job: {
            create: 'Create',
            title: "Create job",
            placeholder: "Name...",
            save: 'Save',
        },
        labour: {
            create: 'Add labour',
            title: "Create labour",
            placeholder: "Name...",
            save: 'Save',
        },
        part: {
            create: 'Add part',
            title: "Adding part",
            placeholder: "Name...",
            save: 'Save',
        },
        checklist: {
            create: 'Add check list',
            title: "Create check list",
        },
        workpost: {
            change: "Change",
            title: "Select post",
        },
        checklistlink: 'Check list',
        acceptancelist: 'Acceptance list',
        recommendations: 'Recommendations',
        about: 'About',
        summary: 'Summary',
        noData: 'No data'
    },
}

class Card extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,

            did: null,
            item: null,

            header: {},
            blocks: [],
            workposts: [],

            isCreateJobOpened: false,
            crateJobTitle: "",

            isCreateLabourOpened: false,
            createLabourTitle: "",

            isCreatePartOpened: false,
            createPartTitle: "",

            checklistTemplates: [],
            isCreateChecklistOpened: false,

            isWorkpostSetOpened: false,

            isStashOpen: false,

            uploadFolder: ""
        }

        this.blockAcceptanceRemarkChange = this.blockAcceptanceRemarkChange.bind(this);
        this.blockAcceptanceRemarkBlur = this.blockAcceptanceRemarkBlur.bind(this);
        this.blockAcceptanceRemarkKeyPress = this.blockAcceptanceRemarkKeyPress.bind(this);
        this.blockAcceptanceClick = this.blockAcceptanceClick.bind(this);
        this.blocksAcceptanceClick = this.blocksAcceptanceClick.bind(this);
        this.docAcceptanceRemarkChange = this.docAcceptanceRemarkChange.bind(this);
        this.docAcceptanceRemarkBlur = this.docAcceptanceRemarkBlur.bind(this);
        this.docAcceptanceRemarkKeyPress = this.docAcceptanceRemarkKeyPress.bind(this);
        this.docAcceptanceClick = this.docAcceptanceClick.bind(this);

        this.stampingChange = this.stampingChange.bind(this);

        this.removeFile = this.removeFile.bind(this);
        this.triggerFileInput = this.triggerFileInput.bind(this);
        this.changeFileInput = this.changeFileInput.bind(this);

        this.openStash = this.openStash.bind(this);

        this.changeJobTitle = this.changeJobTitle.bind(this);
        this.createJob = this.createJob.bind(this);

        this.changeLabourTitle = this.changeLabourTitle.bind(this);
        this.createLabour = this.createLabour.bind(this);

        this.changePartTitle = this.changePartTitle.bind(this);
        this.createPart = this.createPart.bind(this);

        this.createChecklistOpen = this.createChecklistOpen.bind(this);
        this.createChecklist = this.createChecklist.bind(this);

        this.setWorkpost = this.setWorkpost.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        this.loadData();
    }

    componentDidUpdate(prevProps) {
        //Если открыт интерфейс карточки ЗН и юзер нажмёт в меню на сылку в ЗН который в работе, то нужно отловить изменение DID и перезагрузить блоки работ
        if (this.props.match.params.did !== prevProps.match.params.did) {
            this.loadData();
        } else {
            if (this.props.match.params.item !== prevProps.match.params.item) {
                if (this.props.match.params.item == undefined) {
                    this.setState({item: null});
                } else {
                    this.setState({item: Number(this.props.match.params.item)});
                }
            }
        }
    }

    loadData() {
        const {addNotification, removeNotification, setMenuExpandText, lang, token} = this.context;

        var did = Number(this.props.match.params.did);
        var item = null;
        if (this.props.match.params.item != undefined) {
            item = Number(this.props.match.params.item);
        }

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");

            if (item == null) {
                var h, b, w, hp, bp, wp;
                var hp = axios.post('/api/order/header', { token: token, did: did }).then((res) => {
                    h = res.data.rows[0];
                }).catch((error) => {
                    addNotification(error.response.data, error.response.status);
                }).finally(function () {
                    //removeNotification(u);
                });
    
                var bp = axios.post('/api/order/blocks/list', { token: token, did: did }).then((res) => {
                    b = res.data.blocks;
                }).catch((error) => {
                    addNotification(error.response.data, error.response.status);
                }).finally(function () {
                    //removeNotification(u);
                });
    
                var wp = axios.post('/api/order/workpost/list', { token: token, did: did }).then((res) => {
                    w = res.data.rows;
                }).catch((error) => {
                    addNotification(error.response.data, error.response.status);
                }).finally(function () {
                    //removeNotification(u);
                });
    
                Promise.all([hp, bp, wp]).then(() => {
                    this.setState({
                        did: did,
                        item: item,
                        header: h,
                        blocks: b,
                        workposts: w,
                        checklists: [],
                        isLoading: false,
                    }, () => {
                        setMenuExpandText(localization[lang].title + " " + this.state.header.doc);
                        removeNotification(u);
                    });
                });
            } else {
                var h, b, w, c, hp, bp, wp, cp;
                var hp = axios.post('/api/order/header', { token: token, did: did }).then((res) => {
                    h = res.data.rows[0];
                }).catch((error) => {
                    addNotification(error.response.data, error.response.status);
                }).finally(function () {
                    //removeNotification(u);
                });
    
                var bp = axios.post('/api/order/blocks/list', { token: token, did: did }).then((res) => {
                    b = res.data.blocks;
                }).catch((error) => {
                    addNotification(error.response.data, error.response.status);
                }).finally(function () {
                    //removeNotification(u);
                });
    
                var wp = axios.post('/api/order/workpost/list', { token: token, did: did }).then((res) => {
                    w = res.data.rows;
                }).catch((error) => {
                    addNotification(error.response.data, error.response.status);
                }).finally(function () {
                    //removeNotification(u);
                });
    
                var cp = axios.post('/api/order/blocks/checklist/list', { token: token, did: did, item: item }).then((res) => {
                    c = res.data.rows;
                }).catch((error) => {
                    addNotification(error.response.data, error.response.status);
                }).finally(function () {
                    //removeNotification(u);
                });
    
                Promise.all([hp, bp, wp, cp]).then(() => {
                    var sections = [];
    
                    c.forEach((item) => {
                        var index = sections.findIndex(i => i.gid === item.gid);
    
                        if (index < 0) {
                            sections.push({did: item.did, gid: item.gid, gname: item.gname, questions: [{qid: item.qid, question: item.question, description: item.description, descriptionExpand: false, status: item.status, remark: item.remark, folder: item.folder, files: item.files}]});
                        } else {
                            sections[index].questions.push({qid: item.qid, question: item.question, description: item.description, descriptionExpand: false, status: item.status, remark: item.remark, folder: item.folder, files: item.files});
                        }
                    });
    
                    this.setState({
                        did: did,
                        item: item,
                        header: h,
                        blocks: b,
                        workposts: w,
                        checklists: sections,
                        isLoading: false,
                    }, () => {
                        setMenuExpandText(localization[lang].title + " " + this.state.header.doc);
                        removeNotification(u);
                    });
                });
            }
        } else {
            var did = Number(this.props.match.params.did);
            var item = null;
            if (this.props.match.params.item != undefined) {
                item = Number(this.props.match.params.item);
            }

            this.setState({
                did: did,
                item: item,
                isLoading: false,
                header: {
                    did: "90493",
                    doc: "1903605",
                    status: "22",
                    date: "10.07.2019",

                    company: "Промислово-технічна компанія у вигляді товариства з обмеженою відповідальністю \"АГРОМАТ\"",
                    driver: "Грінчук Віктор",
                    phone: "0961334347, +380 44 452-3406;+38050-4480968;+30850-3159564 Ігор",
                    address: "03115, м.Київ, проспект Перемоги, 89-А",
                    
                    manufacturer: "SCANIA",
                    type: "G400LA4X2HNA",
                    chassis: "5441919",
                    stateNumber: "AA8435PC",
                    vin: "YS2G4X20005441919",
                    year: "15.09.2016",
                    milleage: "393 905",

                    machineName: "",
                    machineVIN: "",
                    machineMilleage: "-",
                    
                    mechanicName: "vasia",
                    sum: "2 222",
                    sign: "folder",

                    acceptanceblock: 1,
                    acceptance: 0,
                    acceptancestatus: 0,
                    acceptancedoc: 1,
                    acceptancedocstatus: 1,
                    acceptancedocremark: "Bla",

                    readonly: 0,
                    hideHours: 1,

                    workpost: "Улица мойка"
                },
                blocks: [
                    {
                        status: 3,
                        sname: "Виконано",
                        item: 1,
                        iname: "диагностика сцепления",
                        time: "0",
                        stime: "0",
                        folder: "0010TASKORDR000944590001",
                        sum: "2 222",

                        acceptanceid: 1,
                        acceptancestatus: 0,
                        acceptanceremark: "Все добре",

                        mred: "Василь Червоний",
                        myellow: "Юрій Жовтий",
                        mgreen: "Роман Зелений",

                        files:[],
                        items:[ 
                            {
                                status: 1,
                                titem: 1,
                                item: 1,
                                type: "L",
                                code: "123456789",
                                name: "Очищення салону/кабіни автомобіля",
                                total: "0.9",
                                unit: "год.",
                                sum: "",
                                mred: "Василь Червоний",
                                myellow: "Юрій Жовтий",
                                mgreen: "Роман Зелений",
                            },
                            {
                                status: 2,
                                titem: 1,
                                item: 1,
                                type: "L",
                                code: "123456789",
                                name: "Комплексна мийка легкового авто (мийка та прибирання вологи)",
                                total: "0.9",
                                unit: "год.",
                                sum: "",
                                mred: "Василь Червоний",
                                myellow: "Юрій Жовтий",
                                mgreen: "Роман Зелений",
                            },
                            {
                                status: 4,
                                titem: 1,
                                item: 1,
                                type: "L",
                                code: "123456789",
                                name: "Комплексна мийка легкового авто (мийка та прибирання вологи)",
                                total: "0.9",
                                unit: "год.",
                                sum: "",
                                mred: "Василь Червоний",
                                myellow: "Юрій Жовтий",
                                mgreen: "Роман Зелений",
                            },
                            {
                                status: 5,
                                titem: 1,
                                item: 1,
                                type: "L",
                                code: "123456789",
                                name: "Комплексна мийка легкового авто (мийка та прибирання вологи)",
                                total: "0.9",
                                unit: "год.",
                                sum: "",
                                mred: "Василь Червоний",
                                myellow: "Юрій Жовтий",
                                mgreen: "Роман Зелений",
                            },

                            {
                                status: 3,
                                sname: "Виконано",
                                titem: 1,
                                item: 2,
                                type: "P",
                                code: "KI.502161",
                                name: "Хомут пластмасовий 4.8x360",
                                total: "0 025",
                                unit: "шт.",
                                sum: "39,90",
                                mred: "Василь Червоний",
                                myellow: "Юрій Жовтий",
                                mgreen: "Роман Зелений",
                            },
                            {
                                status: 3,
                                sname: "Виконано",
                                titem: 1,
                                item: 3,
                                type: "P",
                                code: "KI.089010810",
                                name: "Очисник універсальний плюс",
                                total: "0 001",
                                unit: "шт.",
                                sum: "79,45",
                                mred: "Василь Червоний",
                                myellow: "Юрій Жовтий",
                                mgreen: "Роман Зелений",
                            }
                        ]
                    },
                    { 
                        status: 2,
                        sname:"Обід",
                        item:2,
                        iname:"Выездной сервис",
                        time:"745.14",
                        stime:"1",
                        folder:"0010TASKORDR000944590002",
                        sum:"2 222",

                        acceptanceid: 2,
                        acceptancestatus: -1,
                        acceptanceremark: "Не добре",

                        mred: "Василь Червоний",
                        myellow: "Юрій Жовтий",
                        mgreen: "Роман Зелений",

                        files:[ 
                            "IMG_20191211_101645_944.jpg",
                            "IMG_20191211_101723_859.mp4"
                        ],
                        items:[ 
                            { 
                                titem:2,
                                item:1,
                                type:"L",
                                code:"00005002-1",
                                name:"Виїзд механіка , перебування в дорозі(з урахуванням пробігу 50км)",
                                total:"0 001",
                                unit: "шт.",
                                sum:"1 001,70",
                                mred: "Василь Червоний",
                                myellow: "Юрій Жовтий",
                                mgreen: "Роман Зелений",
                            },
                        ]
                    }
                ],
                workposts: [
                    {
                        workpost: "Пост 1"
                    },
                    {
                        workpost: "Пост 2"
                    },
                    {
                        workpost: "Пост 3"
                    },
                    {
                        workpost: "Улица"
                    },
                    {
                        workpost: "Мойка"
                    },
                ],
                checklists: [
                    {
                        did: 1,
                        gid: 1, 
                        gname: '3. Паливна і вихлопна системи',
                        questions: [
                            { 
                                qid: 1, 
                                question: 'Паливопровід на предмет витоків і пошкоджень', 
                                description: "- Учтены запланированные объёмы работ.<br/>- Удобно перемещаться вокруг транспортных средств.", 
                                expandDescription: false, 
                                attachmentHover: false, 
                                status: '2', 
                                remark: 'line<br/>line2', 
                                folder: "000940830001", 
                                files: ["1.jpg", "2.mp4"] 
                            },
                            { 
                                qid: 2, 
                                question: 'Цілісність проводів кришки сепаратора', 
                                description: "", 
                                expandDescription: false, 
                                attachmentHover: false, 
                                status: '3', 
                                remark: 'KJK', 
                                folder: "000940830002", 
                                files: [] 
                            },
                        ],
                    },
                ]
            }, () => {
                setMenuExpandText(localization[lang].title + " " + this.state.header.doc);
            });
        }
    }

    stampingChange(item, status) {
        const {addNotification, removeNotification, token} = this.context;

        var u = addNotification("Завантаження...");
        axios.post('/api/order/stamping/change', { 
            token: token, 
            status: status,
            reason: 0,
            did: this.state.did,
            item: item
        }).then((res) => {
            //this.setState({});
            this.loadData();
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    removeFile = (filename, folder) => {
        const {addNotification, removeNotification} = this.context;

        var u = addNotification("Завантаження...");
        axios.post('/api/order/blocks/attachment/remove', { filename: filename, folder: folder }).then((res) => {
            //remove file names to list
            this.removeFromFileList(folder, filename);
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    triggerFileInput = (uploadFolder) => {
        this.setState({uploadFolder: uploadFolder}, () => {
            document.getElementById("fileInput").click();
        });
    }

    changeFileInput = event => {
        const {addNotification, removeNotification} = this.context;

        // Copy files to array
        var files = [];
        for (var i = 0; i < event.target.files.length; i++) {
            files.push(event.target.files[i]);
        }
        event.target.value = null;

        // Create data object
        var data = new FormData();
        data.append('length', files.length);
        data.append('folder', this.state.uploadFolder)

        // Attach files to data object
        var compressPromises = [];
        var fileIndex = 0; // file index because compressed images has different order

        for (var i = 0; i < files.length; i++) {
            if (files[i].type == "video/mp4") {
                data.append("file"+fileIndex, files[i]);
                fileIndex = fileIndex++;
            } else {
                compressPromises.push(imageCompression(files[i], {
                    maxSizeMB: 10,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true
                }));
            }
        }

        // Images should be compressed, wait
        Promise.all(compressPromises).then(results => {
            results.forEach(compressedFile => {
                data.append("file"+fileIndex, compressedFile, compressedFile.name);
                fileIndex = fileIndex++;
            });

            var u = addNotification("Завантаження...");
            axios.post('/api/order/blocks/attachment/add', data).then((res) => {
                this.addToFileList(this.state.uploadFolder, res.data.filename);
                this.setState({uploadFolder: ""});
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        });
    }

    addToFileList(folfer, filename) {
        var i = this.state.blocks.findIndex((item) => {
            return item.folder == folfer
        });

        if (i != -1) {
            this.state.blocks[i].files.push(filename);
            this.forceUpdate();
        }
    }

    removeFromFileList(folfer, filename) {
        var i = this.state.blocks.findIndex((item) => {
            return item.folder == folfer
        });

        if (i != -1) {
            var fi = this.state.blocks[i].files.findIndex((fitem) => {
                return fitem == filename
            });
            this.state.blocks[i].files.splice(fi, 1);
            this.forceUpdate();
        }
    }

    renderList() {
        const {lang} = this.context;

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            )
        }

        if (this.state.blocks.length === 0) {
            return (
                <div className="row">
                    <div className="col">
                        <div className="card agis-card">
                            <div className="row">
                                <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                    <div>
                                        <h6><b>{localization[lang].noData}</b></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return this.state.blocks.map((item, i) => {
                var progressTime = Number(item.time) * 100;
                var progressSTime = Number(item.stime) * 100;
                var percent = Math.round(progressTime * 100 / progressSTime);

                var progressColor;
                switch(item.status) {
                    case 2:
                        progressColor = "#ffc107";
                        break;
                    case 3:
                        progressColor = "#868e96";
                        break;
                    case 4:
                        progressColor = "#ffc107";
                        break;
                    case 5:
                        progressColor = "#868e96";
                        break;
                    default:
                        progressColor = "#28a745";
                        break;
                }

                return (
                    <div className="row">
                        <div className="col">
                            <Link to={"/order/"+this.props.match.params.did+"/card/" + item.item} className="card agis-card position-component pt-1 pb-1">
                                <div className="row mb-1">
                                    <div className="col">
                                        <h6><b>{item.iname}</b></h6>
                                    </div>
                                </div>

                                {(item.mred != "" || item.myellow != "" || item.mgreen != "") && (
                                    <div className="row mb-1">
                                        <div className="col">
                                            {item.myellow != "" && (
                                                <small className="text-warning mr-2">{item.myellow}</small>
                                            )}
                                            {item.mred != "" && (
                                                <small className="text-danger mr-2">{item.mred}</small>
                                            )}
                                            {item.mgreen != "" && (
                                                <small className="text-success mr-2">{item.mgreen}</small>
                                            )}
                                        </div>
                                    </div>
                                )}

                                {item.acceptanceid != 0 && (
                                    <div className="row mb-1">
                                        <div className="col">
                                            {this.state.header.status == "22" ? (
                                                <span className={item.acceptancestatus == 1 ? "text-success" : item.acceptancestatus == -1 ? "text-danger" : "text-warning"}>{item.acceptancestatus == 1 ? localization[lang].acceptance.confirmed : item.acceptancestatus == -1 ? localization[lang].acceptance.canceled : item.acceptancestatus == 0 && item.acceptanceid != 0 ? localization[lang].acceptance.confirmedreview : ''}</span>
                                            ) : (
                                                <span className={item.acceptancestatus == 1 ? "text-success" : item.acceptancestatus == -1 ? "text-danger" : "text-warning"}>{item.acceptancestatus == 1 ? localization[lang].acceptance.accepted : item.acceptancestatus == -1 ? localization[lang].acceptance.canceled : item.acceptancestatus == 0 && item.acceptanceid != 0 ? localization[lang].acceptance.acceptedreview : ''}</span>
                                            )}
                                        </div>
                                    </div>
                                )}

                                <div className="row">
                                    <div className="col">
                                        <div className="d-flex justify-content-between">
                                            <div className="">
                                                {item.status == 2 ? (
                                                    <span className="text-muted">{localization[lang].stamping.paused}: <b>{item.sname}</b></span>
                                                ) : (
                                                    <span className="text-muted">{item.sname}</span>
                                                )}
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                {item.files.length > 0 && (
                                                    <div className="text-muted"><FontAwesomeIcon icon={faPaperclip} /> {item.files.length}</div>
                                                )}
                                                <div className="text-right" style={{width: "80px"}}>{item.time} / {item.stime}</div>
                                            </div>
                                        </div>
                                        <div class="progress w-100" style={{height: "5px"}}>
                                            <div class="progress-bar" role="progressbar" style={{width: percent + "%", backgroundColor: progressColor}} aria-valuenow={progressTime} aria-valuemin="0" aria-valuemax={progressSTime}></div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                );
            })
        }
    }

    openStash(status) {
        this.setState({isStashOpen: status});
    }

    changeJobTitle(event) {
        this.setState({createJobTitle: event.target.value});
    }

    createJob() {
        const {addNotification, removeNotification, token} = this.context;

        var u = addNotification("Завантаження...");
        axios.post('/api/order/job/create', { 
            token: token, 
            did: this.state.did,
            title: this.state.createJobTitle,
        }).then((res) => {
            this.setState({isLoading: true, isCreateJobOpened: false, createJobTitle: ""}, () => this.loadData());
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    changeLabourTitle(event) {
        this.setState({createLabourTitle: event.target.value});
    }

    createLabour() {
        const {addNotification, removeNotification, token} = this.context;

        var i = this.state.blocks.findIndex((item) => {
            return item.item == this.state.item
        });
        var block = this.state.blocks[i];

        var u = addNotification("Завантаження...");
        axios.post('/api/order/labour/create', { 
            token: token, 
            did: this.state.did,
            item: block.item,
            title: this.state.createLabourTitle,
        }).then((res) => {
            this.setState({isLoading: true, isCreateLabourOpened: false, createLabourTitle: ""}, () => this.loadData());
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    changePartTitle(event) {
        this.setState({createPartTitle: event.target.value});
    }

    createPart() {
        const {addNotification, removeNotification, token} = this.context;

        var i = this.state.blocks.findIndex((item) => {
            return item.item == this.state.item
        });
        var block = this.state.blocks[i];

        var u = addNotification("Завантаження...");
        axios.post('/api/order/part/create', { 
            token: token, 
            did: this.state.did,
            item: block.item,
            title: this.state.createPartTitle,
        }).then((res) => {
            this.setState({isLoading: true, isCreatePartOpened: false, createPartTitle: ""}, () => this.loadData());
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    createChecklistOpen() {
        const {addNotification, removeNotification, token} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/order/blocks/checklist/templates', { 
                token: token, 
                // did: this.state.did,
                // item: block.item,
            }).then((res) => {
                this.setState({
                    checklistTemplates: res.data.rows,
                    isCreateChecklistOpened: true,
                });
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            this.setState({
                checklistTemplates: [
                    {gid: 1, name: "Checklist Group 1"},
                    {gid: 2, name: "Checklist Group 2"},
                    {gid: 3, name: "Checklist Group 3"},
                ],
                isCreateChecklistOpened: true,
            });
        }
    }

    createChecklist(gid) {
        const {addNotification, removeNotification, token} = this.context;

        var i = this.state.blocks.findIndex((item) => {
            return item.item == this.state.item
        });
        var block = this.state.blocks[i];

        var u = addNotification("Завантаження...");
        axios.post('/api/order/blocks/checklist/create', { 
            token: token, 
            did: this.state.did,
            item: block.item,
            gid: gid,
        }).then((res) => {
            this.setState({isLoading: true, isCreateChecklistOpened: false}, () => this.loadData());
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    renderWorkpostList() {
        const {lang} = this.context;

        if (this.state.workposts.length === 0) {
            return (
                <div className="row">
                    <div className="col">
                        <h6><b>{localization[lang].noData}</b></h6>
                    </div>
                </div>
            );
        } else {
            return this.state.workposts.map((item, i) => {
                return (
                    <div className="row mb-3" key={"list_" + i}>
                        <div className="col">
                            <div onClick={() => this.setWorkpost(item.workpost)} className="card-agis pt-3 pb-3 noselect clickable" style={{borderRadius: "15px"}}>
                                <h6><b>{item.workpost}</b></h6>
                            </div>
                        </div>
                    </div>
                );
            });
        }
    }

    setWorkpost(workpost) {
        const {addNotification, removeNotification, token} = this.context;

        var u = addNotification("Завантаження...");
        axios.post('/api/order/workpost/set', { 
            token: token, 
            did: this.state.did,
            workpost: workpost,
        }).then((res) => {
            this.setState({isLoading: true, isWorkpostSetOpened: false}, () => this.loadData());
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    blockAcceptanceRemarkChange(e, id) {
        var bi = this.state.blocks.findIndex((item) => {
            return item.item == id
        });

        if (bi != -1) {
            this.state.blocks[bi].acceptanceremark = e.target.value;
            this.forceUpdate();
        }
    }

    blockAcceptanceRemarkBlur(id) {
        const {addNotification, removeNotification, token} = this.context;

        var bi = this.state.blocks.findIndex((item) => {
            return item.item == id
        });

        if (bi != -1) {
            var u = addNotification("Завантаження...");
            axios.post('/api/order/blocks/accept', { 
                token: token, 
                did: this.state.did,
                acceptanceid: this.state.blocks[bi].acceptanceid, 
                acceptancestatus: this.state.blocks[bi].acceptancestatus, 
                acceptanceremark: this.state.blocks[bi].acceptanceremark 
            }).then((res) => {
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        }
    }

    blockAcceptanceRemarkKeyPress(e) {
        if (e.key === 'Enter') {
            e.target.blur();
        }
    }

    blockAcceptanceClick(id, status) {
        const {addNotification, removeNotification, token} = this.context;

        var bi = this.state.blocks.findIndex((item) => {
            return item.item == id
        });

        if (bi != -1) {
            var u = addNotification("Завантаження...");
            axios.post('/api/order/blocks/accept', { 
                token: token, 
                did: this.state.did,
                acceptanceid: this.state.blocks[bi].acceptanceid, 
                acceptancestatus: status, 
                acceptanceremark: this.state.blocks[bi].acceptanceremark 
            }).then((res) => {
                this.state.blocks[bi].acceptancestatus = status;
                this.forceUpdate();
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        }
    }

    blocksAcceptanceClick(status) {
        const {addNotification, removeNotification, token} = this.context;

        var u = addNotification("Завантаження...");
        axios.post('/api/order/blocks/accept', { 
            token: token, 
            did: this.state.did,
            acceptanceid: 0, 
            acceptancestatus: status, 
            acceptanceremark: ''
        }).then((res) => {
            this.loadData();
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    docAcceptanceRemarkChange(e, id) {
        this.state.header.acceptancedocremark = e.target.value;
        this.forceUpdate();
    }

    docAcceptanceRemarkBlur(id) {
        const {addNotification, removeNotification, token} = this.context;

        var u = addNotification("Завантаження...");
        axios.post('/api/order/blocks/accept', { 
            token: token, 
            did: this.state.did,
            acceptanceid: this.state.header.acceptancedoc, 
            acceptancestatus: this.state.header.acceptancedocstatus, 
            acceptanceremark: this.state.header.acceptancedocremark, 
        }).then((res) => {
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    docAcceptanceRemarkKeyPress(e) {
        if (e.key === 'Enter') {
            e.target.blur();
        }
    }

    docAcceptanceClick(status) {
        const {addNotification, removeNotification, token} = this.context;

        var u = addNotification("Завантаження...");
        axios.post('/api/order/blocks/accept', { 
            token: token, 
            did: this.state.did,
            acceptanceid: this.state.header.acceptancedoc, 
            acceptancestatus: status, 
            acceptanceremark: this.state.header.acceptancedocremark,
        }).then((res) => {
            this.loadData();
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    render() {
        const {lang, access} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.item != null) {
            var i = this.state.blocks.findIndex((item) => {
                return item.item == this.state.item
            });

            var item = this.state.blocks[i];

            var progressTime = Number(item.time) * 100;
            var progressSTime = Number(item.stime) * 100;
            var percent = Math.round(progressTime * 100 / progressSTime);

            var progressColor;
            switch(item.status) {
                case 2:
                    progressColor = "#ffc107";
                    break;
                case 3:
                    progressColor = "#868e96";
                    break;
                case 4:
                    progressColor = "#ffc107";
                    break;
                case 5:
                    progressColor = "#868e96";
                    break;
                default:
                    progressColor = "#28a745";
                    break;
            }

            return (
                <div>
                    <DocumentMeta {...meta} />
                    <OffsetTop submenu={false} />

                    {/* INPUT FILE UPLOAD */}
                    <input type="file" id="fileInput" className="d-none" onChange={this.changeFileInput} accept=".jpg,.jpeg,.png,.bmp,.tiff,.gif,.mp4"/>

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="container-fluid pl-0 pr-0">

                                    {this.state.isCreateLabourOpened && (
                                        <div className="fixed-bottom" onClick={() => {this.setState({isCreateLabourOpened: false})}} style={{zIndex: "2000", height: "100%", backgroundColor: "#80808094"}}>
                                            <div className="fixed-bottom" onClick={(e) => {e.stopPropagation()}} style={{borderTopLeftRadius: "15px", borderTopRightRadius: "15px", backgroundColor: "#fff", overflowX: "auto"}}>
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className="col-md-10 offset-md-1">
                                                            <div className="container-fluid pl-0 pr-0">
                                                                <div className="row mt-4 mb-2">
                                                                    <div className="col-12">
                                                                        <h5><b>{localization[lang].labour.title}</b></h5>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2 mb-2">
                                                                    <div className="col-12">
                                                                        <input type="text" className="input-agis-block" style={{height: '40px', width: '100%'}} placeholder={localization[lang].labour.placeholder} value={this.state.createLabourTitle} onChange={this.changeLabourTitle} autoFocus={true} />
                                                                    </div>
                                                                </div>

                                                                <div className="row mt-2 mb-3">
                                                                    <div className="col">
                                                                        <Link onClick={this.createLabour} className="a-agis-light-outline" style={{height: "40px", width: '100%', minWidth: "0px", backgroundColor: "#1a73e8", color: "white", border: "1px solid #ebebeb"}}>{localization[lang].labour.save}</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {this.state.isCreatePartOpened && (
                                        <div className="fixed-bottom" onClick={() => {this.setState({isCreatePartOpened: false})}} style={{zIndex: "2000", height: "100%", backgroundColor: "#80808094"}}>
                                            <div className="fixed-bottom" onClick={(e) => {e.stopPropagation()}} style={{borderTopLeftRadius: "15px", borderTopRightRadius: "15px", backgroundColor: "#fff", overflowX: "auto"}}>
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className="col-md-10 offset-md-1">
                                                            <div className="container-fluid pl-0 pr-0">
                                                                <div className="row mt-4 mb-2">
                                                                    <div className="col-12">
                                                                        <h5><b>{localization[lang].part.title}</b></h5>
                                                                    </div>
                                                                </div>

                                                                <div className="row mt-2 mb-2">
                                                                    <div className="col-12">
                                                                        <input type="text" className="input-agis-block" style={{height: '40px', width: '100%'}} placeholder={localization[lang].part.placeholder} value={this.state.createPartTitle} onChange={this.changePartTitle} autoFocus={true} />
                                                                    </div>
                                                                </div>

                                                                <div className="row mt-2 mb-3">
                                                                    <div className="col">
                                                                        <Link onClick={this.createPart} className="a-agis-light-outline" style={{height: "40px", width: '100%', minWidth: "0px", backgroundColor: "#1a73e8", color: "white", border: "1px solid #ebebeb"}}>{localization[lang].part.save}</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {this.state.isCreateChecklistOpened && (
                                        <div className="fixed-bottom" onClick={() => {this.setState({isCreateChecklistOpened: false})}} style={{zIndex: "2000", height: "100%", backgroundColor: "#80808094"}}>
                                            <div className="fixed-bottom" onClick={(e) => {e.stopPropagation()}} style={{borderTopLeftRadius: "15px", borderTopRightRadius: "15px", backgroundColor: "#fff", overflowX: "auto"}}>
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className="col-md-10 offset-md-1">
                                                            <div className="container-fluid pl-0 pr-0">
                                                                <div className="row mt-4 mb-2">
                                                                    <div className="col-12">
                                                                        <h5><b>{localization[lang].checklist.title}</b></h5>
                                                                    </div>
                                                                </div>

                                                                {this.state.checklistTemplates.map((item, i) => (
                                                                    <div className="row mb-2">
                                                                        <div className="col">
                                                                            <Link onClick={() => this.createChecklist(item.gid)} className="card agis-card position-component">
                                                                                <div class="d-flex align-items-center justify-content-between" style={{height: "40px"}}>
                                                                                    <big><b>{item.name}</b></big>
                                                                                    <span className="mr-1"><FontAwesomeIcon icon={faChevronRight}/></span>
                                                                                </div>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                ))}

                                                                <div className="mb-3"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className="row">
                                        <div className="col">
                                            <h5>{item.iname}</h5>
                                        </div>
                                    </div>

                                    {(item.mred != "" || item.myellow != "" || item.mgreen != "") && (
                                        <div className="row mb-1">
                                            <div className="col">
                                                {item.myellow != "" && (
                                                    <small className="text-warning mr-2">{item.myellow}</small>
                                                )}
                                                {item.mred != "" && (
                                                    <small className="text-danger mr-2">{item.mred}</small>
                                                )}
                                                {item.mgreen != "" && (
                                                    <small className="text-success mr-2">{item.mgreen}</small>
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    <div className="row mb-2">
                                        <div className="col">
                                            <div className="d-flex justify-content-between">
                                                <div className="">
                                                    {item.status == 2 ? (
                                                        <small className="text-muted">{localization[lang].stamping.paused}: <b>{item.sname}</b></small>
                                                    ) : (
                                                        <small className="text-muted">{item.sname}</small>
                                                    )}
                                                </div>
                                                <div className="">
                                                    <small>{item.time} / {item.stime}</small>
                                                </div>
                                            </div>
                                            <div class="progress w-100" style={{height: "5px"}}>
                                                <div class="progress-bar" role="progressbar" style={{width: percent + "%", backgroundColor: progressColor}} aria-valuenow={progressTime} aria-valuemin="0" aria-valuemax={progressSTime}></div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {access.includes('5003') && item.status == 1 && (
                                        <div className="row">
                                            <div className="col" style={{display: "flex", alignItems: "center"}}>
                                                <div className="w-100">
                                                    <div className="d-flex justify-content-center">
                                                        <button className="btn-agis-light-outline" style={{height: "40px", width: "40px", minWidth: "40px"}} onClick={() => {this.triggerFileInput(item.folder)}}><FontAwesomeIcon icon={faCamera}/></button>
                                                        <Link to={"/order/stamping/pin/1"} className="a-agis-light-outline" style={{height: "40px", width: 'calc(49% - 10px)', minWidth: "0px"}}><FontAwesomeIcon icon={faPause} style={{fontSize: "11px"}} /> {localization[lang].stamping.pause}</Link>
                                                        <button className="btn-agis-light-outline" style={{height: "40px", width: 'calc(49% - 10px)', minWidth: "0px"}} onClick={(e) => this.stampingChange(item.item, 5)}><FontAwesomeIcon icon={faStop} style={{fontSize: "11px"}} /> {localization[lang].stamping.finish} </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {access.includes('5003') && item.status == 2 && (
                                        <div className="row">
                                            <div className="col" style={{display: "flex", alignItems: "center"}}>
                                                <div className="w-100">
                                                    <div className="d-flex justify-content-center">
                                                        <button className="btn-agis-light-outline" style={{height: "40px", width: "40px", minWidth: "40px"}} onClick={() => {this.triggerFileInput(item.folder)}}><FontAwesomeIcon icon={faCamera}/></button>
                                                        <Link to={"/order/stamping/pin"} className="a-agis-light-outline" style={{height: "40px", width: 'calc(99% - 10px)', minWidth: "0px"}}><FontAwesomeIcon icon={faUtensils} /> {localization[lang].stamping.reason}</Link>
                                                        <button className="btn-agis-light-outline" style={{height: "40px", width: 'calc(99% - 10px)', minWidth: "0px"}} onClick={(e) => this.stampingChange(item.item, 3)}><FontAwesomeIcon icon={faPlay} style={{fontSize: "11px"}} /> {localization[lang].stamping.continue}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {access.includes('5003') && item.status == 4 && (
                                        <div className="row">
                                            <div className="col" style={{display: "flex", alignItems: "center"}}>
                                                <div className="w-100">
                                                    <div className="d-flex justify-content-center">
                                                        <button className="btn-agis-light-outline" style={{height: "40px", width: "40px", minWidth: "40px"}} onClick={() => {this.triggerFileInput(item.folder)}}><FontAwesomeIcon icon={faCamera}/></button>
                                                        <button className="btn-agis-light-outline" style={{height: "40px", width: 'calc(99% - 10px)', minWidth: "0px"}} onClick={(e) => this.stampingChange(item.item, 1)}><FontAwesomeIcon icon={faPlay} style={{fontSize: "11px"}} /> {localization[lang].stamping.start}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {access.includes('5003') && item.status == 5 && (
                                        <div className="row">
                                            <div className="col" style={{display: "flex", alignItems: "center"}}>
                                                <div className="w-100">
                                                    <div className="d-flex justify-content-center">
                                                        <button className="btn-agis-light-outline" style={{height: "40px", width: "40px", minWidth: "40px"}} onClick={() => {this.triggerFileInput(item.folder)}}><FontAwesomeIcon icon={faCamera}/></button>
                                                        <button className="btn-agis-light-outline" style={{height: "40px", width: 'calc(99% - 10px)', minWidth: "0px"}} onClick={(e) => this.stampingChange(item.item, 1)}><FontAwesomeIcon icon={faPlay} style={{fontSize: "11px"}} /> {localization[lang].stamping.start}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {this.state.header.readonly != 1 && (
                                        <div className="row">
                                            <div className="col" style={{display: "flex", alignItems: "center"}}>
                                                <div className="w-100">
                                                    <div className="d-flex justify-content-center">
                                                        <button className="btn-agis-light-outline" style={{height: "40px", width: "40px", minWidth: "40px"}} onClick={() => {this.triggerFileInput(item.folder)}}><FontAwesomeIcon icon={faCamera}/></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {item.acceptanceid != 0 && (
                                        <div className="row mt-3 mb-3">
                                            <div className="col">
                                                <div className="card agis-card pt-2 pb-2" style={{borderTopLeftRadius: "15px", borderTopRightRadius: "15px", borderBottomLeftRadius: "15px", borderBottomRightRadius: "15px"}}>
                                                    <div className="row mb-2">
                                                        <div className="col">
                                                            <h6><b>{this.state.header.status == "13" || this.state.header.status == "22" ? "Ви підтверджуєте виконані роботи?" : "Ви погоджуєте виконання робіт?"}</b></h6>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col">
                                                            <input
                                                                style={{
                                                                    width: "100%",
                                                                    padding: ".5rem .5rem",
                                                                    color: "#464a4e",
                                                                    fontsize: "14px",
                                                                    lineHeight: "15px",
                                                                    backgroundColor: "#fff",
                                                                    border: "0px solid #ebebeb",

                                                                    backgroundColor: "#f5f6f7",
                                                                    borderRadius: "10px",
                                                                    border: "1px solid #ebebeb",
                                                                }}
                                                            placeholder={"Коментар..."} value={item.acceptanceremark} onChange={(e) => this.blockAcceptanceRemarkChange(e, item.item)} onBlur={() => this.blockAcceptanceRemarkBlur(item.item)} onKeyDown={(e) => this.blockAcceptanceRemarkKeyPress(e)} />
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col d-flex justify-content-between">
                                                            <button className={item.acceptancestatus == 1 ? 'btn-agis-success-outline' : 'btn-agis-light-outline'} style={{height: "30px", width: '49%', minWidth: "0px"}} onClick={(e) => {this.blockAcceptanceClick(item.item, 1)}}>Так</button>
                                                            <button className={item.acceptancestatus == -1 ? 'btn-agis-danger-outline' : 'btn-agis-light-outline'}  style={{height: "30px", width: '49%', minWidth: "0px"}} onClick={(e) => {this.blockAcceptanceClick(item.item, -1)}}>Ні</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {item.files.length > 0 && (
                                        <div className="row mt-2">
                                            {item.files.map((file) =>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                    <Preview src={"/serve/order/blocks/"+item.folder+"/"+file} removeFile={() => {this.removeFile(file, item.folder)}} />
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    <div className="row mt-3 mb-2">
                                        <div className="col-12">
                                            <h6 className="text-primary">Чеклисти дефектування</h6>
                                        </div>
                                    </div>

                                    {this.state.checklists.map((item, i) => {
                                        var progress = item.questions.filter((q) => {
                                            return q.status !== "0";
                                        });
                        
                                        var progressPercentage = Math.round(100 / item.questions.length * progress.length)
                        
                                        var progressColor = "#4184b4";
                                        var qi = item.questions.findIndex((item) => {
                                            return (item.status == "2" || item.status == "3")
                                        });
                                        if (qi != -1) {
                                            progressColor = "#FFCF49";
                                        } else {
                                            var allGrey = true;
                                            item.questions.forEach(question => {if (question.status != "4"){allGrey = false;}});
                                            if (allGrey) {
                                                progressColor = "#868e96";
                                            }
                                        }
                        
                                        var fileQuantity = 0;
                                        for (const q of item.questions){
                                            if (q.files.length > 0) {
                                                fileQuantity += q.files.length;
                                            }
                                        }

                                        return (
                                            <div className="row mb-2" key={"section_"+item.gid}>
                                                <div className="col">
                                                    <Link to={"/order/check-list-mechanic/"+item.did} className="card agis-card pt-1 pb-1 position-component">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h6><b>{item.gname}</b></h6>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="d-flex align-items-center justify-content-between mb-1">
                                                                    <div className="w-50">
                                                                        {fileQuantity > 0 && (
                                                                            <span className="text-muted" style={{fontSize: "10px"}}>{fileQuantity} <FontAwesomeIcon icon={faCamera} /></span>
                                                                        )}
                                                                    </div>
                                                                    <div className="w-50 text-right">
                                                                        <span className="text-muted" style={{fontSize: "10px"}}>{progress.length + " / " + item.questions.length}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="progress w-100" style={{height: "5px"}}>
                                                                    <div class="progress-bar" role="progressbar" style={{width: progressPercentage+'%', backgroundColor: progressColor}} aria-valuenow={progressPercentage} aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        )
                                    })}

                                    <div className="row mt-2 mb-3">
                                        <div className="col-12">
                                            <Link onClick={() => this.createChecklistOpen()} className="a-agis-light-outline" style={{height: "36px", lineHeight: "28px", width: '100%', minWidth: "0px", backgroundColor: "#1a73e8", color: "white", fontSize: "12px", border: "1px solid #ebebeb"}}><FontAwesomeIcon icon={faPlus} /> Додати чеклист дефектування агрегата</Link>
                                        </div>
                                    </div>

                                    <br/>

                                    <div className="row">
                                        <div className="col-12">
                                            <div className="d-flex justify-content-between">
                                                {this.state.header.readonly != 1 && (access.includes('6005') || access.includes('6006')) && (
                                                    <Link onClick={() => {this.setState({isCreateLabourOpened: true})}} className="a-agis-light-outline" style={{height: "36px", lineHeight: "28px", width: '48%', minWidth: "0px", backgroundColor: "#1a73e8", color: "white", fontSize: "12px", border: "1px solid #ebebeb"}}><FontAwesomeIcon icon={faPlus} /> {localization[lang].labour.create}</Link>
                                                )}

                                                {this.state.header.readonly != 1 && (access.includes('6007') || access.includes('6008')) && (
                                                    <Link onClick={() => {this.setState({isCreatePartOpened: true})}} className="a-agis-light-outline" style={{height: "36px", lineHeight: "28px", width: '48%', minWidth: "0px", backgroundColor: "#1a73e8", color: "white", fontSize: "12px", border: "1px solid #ebebeb"}}><FontAwesomeIcon icon={faPlus} /> {localization[lang].part.create}</Link>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <br/>

                                    {item.items.length > 0 && (
                                        item.items.map((iitem, i) => {
                                            if (iitem.type == "L") {
                                                return <Labour item={iitem} stampingChange={this.stampingChange} />
                                            } else {
                                                return (
                                                    <div className="card agis-card">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <h6><b>{iitem.name}</b></h6>
                                                            </div>
                                                            <div className="col-9 mt-1">
                                                                <span className="text-muted">{iitem.type == "S" ? <FontAwesomeIcon icon={faAmbulance} /> : <FontAwesomeIcon icon={faCog} />} {iitem.code}</span>
                                                            </div>
                                                            <div className="col-3 text-right">
                                                                <span>{iitem.total} {iitem.unit}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="fixed-bottom text-right" style={{zIndex: "4", paddingRight: "10px", paddingBottom: "10px", height: "60px"}}>
                        <Link to={"/order/"+this.props.match.params.did+"/card"} className="a-agis-light-outline" style={{height: '50px', width: "50px", minWidth: "0px", borderRadius: "25px", lineHeight: "44px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 1px 1px rgba(0,0,0,.15)"}}><FontAwesomeIcon icon={faList} style={{fontSize: "20px"}} /></Link>
                    </div>
                
                </div>
            )
        }

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="container-fluid pl-0 pr-0">
                                <div className="row mb-2">
                                    <div className="col-7 d-flex align-items-center">
                                        <h3>{localization[lang].blocks}</h3>
                                    </div>
                                    {this.state.header.readonly != 1 && (access.includes('6003') || access.includes('6004')) && (
                                        <div className="col-5 text-right">
                                            <Link onClick={() => {this.setState({isCreateJobOpened: true})}} className="a-agis-light-outline" style={{height: "40px", width: '100%', minWidth: "0px", backgroundColor: "#1a73e8", color: "white", border: "1px solid #ebebeb"}}><FontAwesomeIcon icon={faPlus} /> {localization[lang].job.create}</Link>
                                        </div>
                                    )}
                                </div>

                                {this.state.isCreateJobOpened && (
                                    <div className="fixed-bottom" onClick={() => {this.setState({isCreateJobOpened: false})}} style={{zIndex: "2000", height: "100%", backgroundColor: "#80808094"}}>
                                        <div className="fixed-bottom" onClick={(e) => {e.stopPropagation()}} style={{borderTopLeftRadius: "15px", borderTopRightRadius: "15px", backgroundColor: "#fff", overflowX: "auto"}}>
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-md-10 offset-md-1">
                                                        <div className="container-fluid pl-0 pr-0">
                                                            
                                                            <div className="row mt-4 mb-2">
                                                                <div className="col-12">
                                                                    <h5><b>{localization[lang].job.title}</b></h5>
                                                                </div>
                                                            </div>

                                                            <div className="row mt-2 mb-2">
                                                                <div className="col-12">
                                                                    <input type="text" className="input-agis-block" style={{height: '40px', width: '100%'}} placeholder={localization[lang].job.placeholder} value={this.state.createJobTitle} onChange={this.changeJobTitle} autoFocus={true} />
                                                                </div>
                                                            </div>

                                                            <div className="row mt-2 mb-3">
                                                                <div className="col">
                                                                    <Link onClick={this.createJob} className="a-agis-light-outline" style={{height: "40px", width: '100%', minWidth: "0px", backgroundColor: "#1a73e8", color: "white", border: "1px solid #ebebeb"}}>{localization[lang].job.save}</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {this.renderList()}

                                {this.state.header.acceptanceblock != 0 && (
                                    <div className="row mt-4 mb-3">
                                        <div className="col">
                                            <div className="card agis-card pt-2 pb-2" style={{borderTopLeftRadius: "15px", borderTopRightRadius: "15px", borderBottomLeftRadius: "15px", borderBottomRightRadius: "15px"}}>
                                                <div className="row mb-2">
                                                    <div className="col">
                                                        <h6><b>{this.state.header.status == "13" || this.state.header.status == "22" ? "Ви підтверджуєте всі виконані роботи?" : "Ви погоджуєте виконання всіх робіт?"}</b></h6>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col d-flex justify-content-between">
                                                        <button className={'btn-agis-success-outline'} style={{height: "30px", width: '49%', minWidth: "0px"}} onClick={(e) => {this.blocksAcceptanceClick(1)}}>Так</button>
                                                        <button className={'btn-agis-danger-outline'}  style={{height: "30px", width: '49%', minWidth: "0px"}} onClick={(e) => {this.blocksAcceptanceClick(-1)}}>Ні</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {this.state.header.readonly != 1 && (
                                    <>
                                        <div className="row mt-4 mb-2">
                                            <div className="col-7 d-flex align-items-center">
                                                <h5><FontAwesomeIcon style={{color: "#868e96"}} icon={faParking} /> {this.state.header.workpost}</h5>
                                            </div>
                                            <div className="col-5 text-right">
                                                <Link onClick={() => {this.setState({isWorkpostSetOpened: true})}} className="a-agis-light-outline" style={{height: "40px", width: '100%', minWidth: "0px", backgroundColor: "#1a73e8", color: "white", border: "1px solid #ebebeb"}}><FontAwesomeIcon icon={faChevronDown} /> {localization[lang].workpost.change}</Link>
                                            </div>
                                        </div>

                                        {this.state.isWorkpostSetOpened && (
                                            <div className="fixed-bottom" onClick={() => {this.setState({isWorkpostSetOpened: false})}} style={{zIndex: "2000", height: "100%", backgroundColor: "#80808094"}}>
                                                <div className="fixed-bottom" onClick={(e) => {e.stopPropagation()}} style={{height: "85%", borderTopLeftRadius: "15px", borderTopRightRadius: "15px", backgroundColor: "#fff", overflowX: "auto"}}>
                                                    <div className="container-fluid">
                                                        <div className="row">
                                                            <div className="col-md-10 offset-md-1">
                                                                <div className="container-fluid pl-0 pr-0">
                                                                    <div className="row mt-4 mb-2">
                                                                        <div className="col-12">
                                                                            <h5><b>{localization[lang].workpost.title}</b></h5>
                                                                        </div>
                                                                    </div>

                                                                    {this.renderWorkpostList()}

                                                                    <br/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}

                                <div className="row mt-4">
                                    <div className="col" style={{display: "flex", alignItems: "center"}}>
                                        <div className="w-100">
                                            <div className="d-flex justify-content-center">
                                                <Link to={"/order/check-list-mechanic/" + this.state.did} className="a-agis-light-outline" style={{height: "40px", width: 'calc(99% - 10px)', minWidth: "0px"}}><FontAwesomeIcon icon={faTasks} /> {localization[lang].checklistlink}</Link>
                                                <Link to={"/order/control-list-manager/" + this.state.did} className="a-agis-light-outline" style={{height: "40px", width: 'calc(99% - 10px)', minWidth: "0px"}}><FontAwesomeIcon icon={faTasks} /> {localization[lang].acceptancelist}</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-1">
                                    <div className="col" style={{display: "flex", alignItems: "center"}}>
                                        <div className="w-100">
                                            <div className="d-flex justify-content-center">
                                                <Link to={"/order/" + this.state.did + "/recommendations"} className="a-agis-light-outline" style={{height: "40px", width: 'calc(99% - 10px)', minWidth: "0px"}}><FontAwesomeIcon icon={faComment} /> <span className={this.state.header.acceptance != 0 ? this.state.header.acceptancestatus == 1 ? "text-success" : "text-warning" : "text-muted"}>{localization[lang].recommendations}</span></Link>
                                                <Link to={"/order/" + this.state.did + "/summary"} className="a-agis-light-outline" style={{height: "40px", width: 'calc(99% - 10px)', minWidth: "0px"}}><FontAwesomeIcon icon={faSave} /> {localization[lang].summary}</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {this.state.header.readonly != 1 && (
                                    <div className="row mt-1">
                                        <div className="col" style={{display: "flex", alignItems: "center"}}>
                                            <div className="w-100">
                                                <div className="d-flex justify-content-center">
                                                    <Link to={"/order/" + this.state.did + "/about"} className="a-agis-light-outline" style={{height: "40px", width: 'calc(99% - 10px)', minWidth: "0px"}}><FontAwesomeIcon icon={faBuilding} /> {localization[lang].about}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {this.state.header.acceptancedoc != 0 && (
                                    <div className="row mt-4 mb-3">
                                        <div className="col">
                                            <div className="card agis-card pt-2 pb-2" style={{borderTopLeftRadius: "15px", borderTopRightRadius: "15px", borderBottomLeftRadius: "15px", borderBottomRightRadius: "15px"}}>
                                                <div className="row mb-2">
                                                    <div className="col">
                                                        <h6><b>{"Ви приймаєте ТЗ?"}</b></h6>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                padding: ".5rem .5rem",
                                                                color: "#464a4e",
                                                                fontsize: "14px",
                                                                lineHeight: "15px",
                                                                backgroundColor: "#fff",
                                                                border: "0px solid #ebebeb",

                                                                backgroundColor: "#f5f6f7",
                                                                borderRadius: "10px",
                                                                border: "1px solid #ebebeb",
                                                            }}
                                                        placeholder={"Коментар..."} value={this.state.header.acceptancedocremark} onChange={(e) => this.docAcceptanceRemarkChange(e)} onBlur={() => this.docAcceptanceRemarkBlur()} onKeyDown={(e) => this.docAcceptanceRemarkKeyPress(e)} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col d-flex justify-content-between">
                                                        <button className={this.state.header.acceptancedocstatus == 1 ? 'btn-agis-success-outline' : 'btn-agis-light-outline'} style={{height: "30px", width: '49%', minWidth: "0px"}} onClick={(e) => {this.docAcceptanceClick(1)}}>Так</button>
                                                        <button className={this.state.header.acceptancedocstatus == -1 ? 'btn-agis-danger-outline' : 'btn-agis-light-outline'}  style={{height: "30px", width: '49%', minWidth: "0px"}} onClick={(e) => {this.docAcceptanceClick(-1)}}>Ні</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class Labour extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            isOpened: false
        }

        this.stampingChange = this.stampingChange.bind(this);
    }

    componentDidMount() {
        if (this.props.item.status == 1 || this.props.item.status == 2) {
            this.setState({isOpened: true});
        }
    }

    stampingChange(e, item, status) {
        e.preventDefault();
        this.props.stampingChange(-1*item, status);
        if (status == 1 || status == 3) {
            this.setState({isOpened: true});
        } else {
            this.setState({isOpened: false});
        }
    }

    render() {
        const {lang, access} = this.context;

        return (
            <div className="card agis-card">
                <div className="row">
                    <div className="col-12 mb-1">
                        <h6 onClick={() => this.props.item.status > 0 && this.setState({isOpened: !this.state.isOpened})}><b>{this.props.item.name}</b></h6>
                    </div>
                    {(this.props.item.mred != "" || this.props.item.myellow != "" || this.props.item.mgreen != "") && (
                        <div className="col-12">
                            {this.props.item.myellow != "" && (
                                <small className="text-warning mr-2">{this.props.item.myellow}</small>
                            )}
                            {this.props.item.mred != "" && (
                                <small className="text-danger mr-2">{this.props.item.mred}</small>
                            )}
                            {this.props.item.mgreen != "" && (
                                <small className="text-success mr-2">{this.props.item.mgreen}</small>
                            )}
                        </div>
                    )}
                    <div className="col-9 mt-1">
                        <span className="text-muted"><FontAwesomeIcon icon={faWrench} /> {this.props.item.code}</span>
                    </div>
                    <div className="col-3 text-right">
                        <span>{this.props.item.total} {this.props.item.unit}</span>
                    </div>
                </div>
                <div className="row mt-1" style={this.state.isOpened ? {display: "block"} : {display: "none"}}>
                    {access.includes('5004') && this.props.item.status == 1 && (
                        <div className="col" style={{display: "flex", alignItems: "center"}}>
                            <div className="w-100">
                                <div className="d-flex justify-content-center">
                                    <Link to={"/order/stamping/pin/1"} className="a-agis-light-outline" style={{height: "30px", padding: "initial", width: 'calc(49% - 10px)', minWidth: "0px"}}><FontAwesomeIcon icon={faPause} style={{fontSize: "11px"}} /> {localization[lang].stamping.pause}</Link>
                                    <button className="btn-agis-light-outline" style={{height: "30px", width: 'calc(49% - 10px)', minWidth: "0px"}} onClick={(e) => this.stampingChange(e, this.props.item.item, 5)}><FontAwesomeIcon icon={faStop} style={{fontSize: "11px"}} /> {localization[lang].stamping.finish} </button>
                                </div>
                            </div>
                        </div>
                    )}
                    {access.includes('5004') && this.props.item.status == 2 && (
                        <div className="col" style={{display: "flex", alignItems: "center"}}>
                            <div className="w-100">
                                <div className="d-flex justify-content-center">
                                    <Link to={"/order/stamping/pin"} className="a-agis-light-outline" style={{height: "30px", padding: "initial", width: 'calc(99% - 10px)', minWidth: "0px"}}><FontAwesomeIcon icon={faUtensils} /> {localization[lang].stamping.reason}</Link>
                                    <button className="btn-agis-light-outline" style={{height: "30px", width: 'calc(99% - 10px)', minWidth: "0px"}} onClick={(e) => this.stampingChange(e, this.props.item.item, 3)}><FontAwesomeIcon icon={faPlay} style={{fontSize: "11px"}} /> {localization[lang].stamping.continue}</button>
                                </div>
                            </div>
                        </div>
                    )}
                    {access.includes('5004') && this.props.item.status == 4 && (
                        <div className="col" style={{display: "flex", alignItems: "center"}}>
                            <div className="w-100">
                                <div className="d-flex justify-content-center">
                                    <button className="btn-agis-light-outline" style={{height: "30px", width: 'calc(99% - 10px)', minWidth: "0px"}} onClick={(e) => this.stampingChange(e, this.props.item.item, 1)}><FontAwesomeIcon icon={faPlay} style={{fontSize: "11px"}} /> {localization[lang].stamping.start}</button>
                                </div>
                            </div>
                        </div>
                    )}
                    {access.includes('5004') && this.props.item.status == 5 && (
                        <div className="col" style={{display: "flex", alignItems: "center"}}>
                            <div className="w-100">
                                <div className="d-flex justify-content-center">
                                    <button className="btn-agis-light-outline" style={{height: "30px", width: 'calc(99% - 10px)', minWidth: "0px"}} onClick={(e) => this.stampingChange(e, this.props.item.item, 1)}><FontAwesomeIcon icon={faPlay} style={{fontSize: "11px"}} /> {localization[lang].stamping.start}</button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default Card;