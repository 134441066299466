import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faSearch, faPlus, faFilter, faCheck } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../AppContext.js';
import OffsetTop from '../../components/offsetTop.js';

const localization = {
    ua: {
        title: 'Акт',
        header: {
            doc: 'Номер акту',
            date: 'Дата',
            type: 'Тип документу',
            types: {
                type11: 'Видача пiдзвiт',
                type12: 'Повернення з пiдзвiту',
                type38: 'Видача у виробництво',
                type39: 'Повернення iз виробництва',
                undefined: 'Невизначений'
            },
            give: 'видав', 
            //11 38
            return: 'повернув', 
            //12 39
            took: 'прийняв',
        },
        signed: 'Підписано',
        sign: 'Підписати',
        noData: 'Данi вiдсутнi',
    },
    ru: {
        title: 'Акт',
        header: {
            doc: 'Номер акта',
            date: 'Дата',
            type: 'Тип документа',
            types: {
                type11: 'Выдача подотчёт',
                type12: 'Возврат из подотчёта',
                type38: 'Выдача в производство',
                type39: 'Возврат из производства',
                undefined: 'Неопределён'
            },
            giver: '',
            taker: '',

            give: 'Выдал', 
            //11 38
            return: 'Вернул', 
            //12 39
            took: 'Принял',
        },
        signed: 'Подписано',
        sign: 'Подписать',
        noData: 'Нет данных',
    },
    en: {
        title: 'Act',
        header: {
            doc: 'Act ID',
            date: 'Date',
            type: 'Type of document',
            types: {
                type11: 'Issuance report',
                type12: 'Returning report',
                type38: 'Issuance in production',
                type39: 'Returning from production',
                undefined: 'Undefined'
            },
            give: 'Give', 
            //11 38
            return: 'Return', 
            //12 39
            took: 'Took',
        },
        signed: 'Signed',
        sign: 'Sign',
        noData: 'No data',
    },
}

class StashDocs extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoadingHeader: true,
            isLoadingLines: true,

            did: null,

            header: {},
            lines: [],
            doctype: "",
        }

        this.sign = this.sign.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        this.loadData();
    }

    loadData() {
        const {addNotification, removeNotification, token, lang, setMenuExpandText} = this.context;

        var did = Number(this.props.match.params.did);

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");

            var h, l, hp, lp;

            var hp = axios.post('/api/stash/doc/header', { token: token, did: did }).then((res) => {
                h = res.data.rows[0];
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
            });

            var lp = axios.post('/api/stash/doc/lines', { token: token, did: did }).then((res) => {
                l = res.data.rows;
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
            });

            Promise.all([hp, lp]).then(() => {

                var doctype = "";
                switch (h.doctype) {
                    case 11: 
                        doctype = localization[lang].header.types.type11;
                        break;
                    case 12: 
                        doctype = localization[lang].header.types.type12;
                        break;
                    case 38: 
                        doctype = localization[lang].header.types.type38;
                        break;
                    case 39: 
                        doctype = localization[lang].header.types.type39;
                        break;
                    default: 
                        doctype = localization[lang].header.types.undefined;
                        break;
                }

                this.setState({
                    did: did,

                    header: h,
                    lines: l, 

                    doctype: doctype,

                    isLoadingHeader: false,
                    isLoadingLines: false,
                }, () => {
                    setMenuExpandText(localization[lang].title + " " + this.state.doctype);
                    removeNotification(u);
                });
            });
        } else {
            this.setState({
                did: did,

                isLoadingHeader: false,
                isLoadingLines: false,

                doctype: "Выдача подотчёт",

                header: {
                    doc: "1903774",
                    doctype: 38,
                    date: "11.09.2019",

                    giverName: "Маров Артем Вадимович",
                    giverSignDate: "11.01.20",
                    giverSignEnabled: 1,
                    takerName: null,
                    takerSignDate: null,
                    takerSignEnabled: 1
                },
                lines: [
                    {
                        code: "KI.0624000004",
                        name: "Набор спиральных сверл 1-13",
                        total: "1",
                        unit: "компл",
                        sum: "4464,63",
                    },
                    {
                        code: "KI.W2824",
                        name: "Трубний ключ 24\"",
                        total: "1",
                        unit: "шт",
                        sum: "1315,37",
                    },
                    {
                        code: "KI.S21H41250",
                        name: "Подовжувач шаровидний 1/2 250мм.",
                        total: "1",
                        unit: "шт",
                        sum: "126,22",
                    },
                ]
            }, () => {
                setMenuExpandText(localization[lang].title + " " + this.state.doctype);
            });
        }
    }

    sign(side) { //1 - giver, 2 - taker
        const {addNotification, removeNotification, token} = this.context;

        var u = addNotification("Завантаження...");
        axios.post('/api/stash/doc/sign', { token: token, did: this.state.did, side: side }).then((res) => {
            this.loadData();
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    renderList() {
        const {lang} = this.context;

        if (this.state.lines.length === 0) {
            return (
                <div className="row">
                    <div className="col">
                        <div className="card agis-card">
                            <div className="row">
                                <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                    <div>
                                        <h6><b>{localization[lang].noData}</b></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return this.state.lines.map((item, i) => {
                return (
                    <div className="row mb-2" key={"list_" + i}>
                        <div className="col">
                            <div className="card agis-card pt-2 pb-2" style={{borderTopLeftRadius: "15px", borderTopRightRadius: "15px", borderBottomLeftRadius: "15px", borderBottomRightRadius: "15px"}}>
                                <div className="row">
                                    <div className="col-12">
                                        <h6><b>{item.name}</b></h6>
                                    </div>
                                    <div className="col-5">
                                        <span>{item.code}</span>
                                    </div>
                                    <div className="col-3 text-center">
                                        <span>{item.total} {item.unit}</span>
                                    </div>
                                    <div className="col-4 text-right">
                                        <span>{item.sum} грн</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
        }
    }

    render() {
        const {lang} = this.context;

        if (this.state.isLoadingHeader || this.state.isLoadingLines) {
            return (
                <div>Loading...</div>
            );
        }

        

        const meta = {
			title: localization[lang].title + ": " + this.state.doctype,
			description: localization[lang].title + ": " + this.state.doctype,
			meta: {
			  	charset: 'utf-8'
			}
        };
        

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="container-fluid pl-0 pr-0">

                                <div className="row mb-2" style={{fontSize: "12px"}}>
                                    <div className="col-6">
                                        <h3><b>{this.state.header.doc}</b></h3>
                                    </div>
                                    <div className="col-6" style={{alignSelf: "flex-end"}}>
                                        <h6><b>{this.state.header.date}</b></h6>
                                    </div>
                                    <div className="col-6">
                                        <span className="text-muted">{localization[lang].header.doc}</span>
                                    </div>
                                    <div className="col-6">
                                        <span className="text-muted">{localization[lang].header.date}</span>
                                    </div>
                                </div>

                                <div className="row" style={{fontSize: "12px"}}>
                                    <div className="col-12">
                                        <h6><b>{this.state.doctype}</b></h6>
                                    </div>
                                    <div className="col-12">
                                        <span className="text-muted">{localization[lang].header.type}</span>
                                    </div>
                                </div>

                                <div className="row mt-3" style={{fontSize: "12px"}}>
                                    <div className="col-12">
                                        <h6><b>{this.state.header.giverName}</b></h6>
                                    </div>
                                    <div className="col-12">
                                        {(this.state.header.doctype == 11 || this.state.header.doctype == 38) ? (
                                            <span className="text-muted">{localization[lang].header.give}</span>
                                        ) : (
                                            <span className="text-muted">{localization[lang].header.return}</span>
                                        )}
                                    </div>
                                    {this.state.header.giverName !== null && (
                                        <div className="col-12">
                                            <span className="text-success">{localization[lang].signed} {this.state.header.giverSignDate}</span>
                                        </div>
                                    )}
                                    <div className="col-12">
                                        {this.state.header.giverSignEnabled === 1 && (
                                            <button className="btn-agis-primary" onClick={() => { this.sign(1) }}>{localization[lang].sign}</button>
                                        )}
                                    </div>
                                </div>

                                <div className="row mt-3" style={{fontSize: "12px"}}>
                                    <div className="col-12">
                                        <h6><b>{this.state.header.takerName}</b></h6>
                                    </div>
                                    <div className="col-12">
                                        <span className="text-muted">{localization[lang].header.took}</span>
                                    </div>
                                    {this.state.header.takerName !== null && (
                                        <div className="col-12">
                                            <span className="text-success">{localization[lang].signed} {this.state.header.takerSignDate}</span>
                                        </div>
                                    )}
                                    <div className="col-12">
                                        {this.state.header.takerSignEnabled === 1 && (
                                            <button className="btn-agis-primary" onClick={() => { this.sign(2) }}>{localization[lang].sign}</button>
                                        )}
                                    </div>
                                </div>

                                {/* {this.state.header.signer !== null ? (
                                    <div className="row mt-2" style={{fontSize: "12px"}}>
                                        <div className="col-12">
                                            <h6><b>{this.state.header.signer}</b></h6>
                                        </div>
                                        <div className="col-12">
                                            <span className="text-muted">{localization[lang].signed} {this.state.header.signdate}</span>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row">
                                        <div className="col-12 text-right">
                                            <button className="btn-agis-primary" onClick={this.sign}>{localization[lang].sign}</button>
                                        </div>
                                    </div>
                                )} */}

                                <br/>

                                {this.renderList()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default StashDocs;