import React from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faPlus, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../AppContext.js';
import OffsetTop from '../../../components/offsetTop.js';
import ScanButton from '../../../components/scanbutton.js';

const localization = {
    ua: {
        title: 'Акти інвентаризації',
        searchText: 'Пошук...',
        noData: 'Данi вiдсутнi',
    },
    ru: {
        title: 'Акты инвентаризации',
        searchText: 'Поиск...',
        noData: 'Нет данных',
    },
    en: {
        title: 'Inventorization acts',
        searchText: 'filter...',
        noData: 'No data',
    },
}

class List extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            searchText: '',
            type: '',
            rows: []
        }

        this.searchTextChange = this.searchTextChange.bind(this);
        this.searchTextKeyPress = this.searchTextKeyPress.bind(this);
        this.onScan = this.onScan.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        this.loadList();
        //document.getElementById("searchInput").focus();
    }

    loadList() {
        const {addNotification, removeNotification, token} = this.context;
        
        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/stock/inventorization/list', { token: token }).then((res) => {
                this.setState({rows: res.data.rows});
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            this.setState({rows: [
                { 
                    did: "221",
                    name: "AA9921PAб",
                    number: "2000010",
                    date: "20.09.2020",
                    stock: "ЗАПЧАСТИ",
                    from: "1 B 1",
                    to: "1 B 10",
                },
            ]});
        }
    }

    searchTextChange(e) {
        this.setState({searchText: e.target.value});
    }
    
    searchTextKeyPress(e) {
        if (e.key === 'Enter') {
            e.target.blur();
        }
    }

    onScan(value) {
        this.setState({searchText: value});
    }

    renderList() {
        const {lang} = this.context;

        if (this.state.rows.length === 0) {
            return (
                <div className="row">
                    <div className="col">
                        <div className="card agis-card">
                            <div className="body">
                                <div className="row">
                                    <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                        <div>
                                            <h6><b>{localization[lang].noData}</b></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return this.state.rows.filter((item, i) => {
                return item.name.toLowerCase().includes(this.state.searchText.toLowerCase())
            }).map((item, i) => {
                return (
                    <div className="row">
                        <div className="col">
                            <Link to={'/stock/inventorization/recount/' + item.did} className="card agis-card position-component">
                                <div class="d-flex align-items-center justify-content-between" style={{minHeight: "36px"}}>
                                    <big><b>{item.name}</b></big>
                                    <span className="mr-1"><FontAwesomeIcon icon={faChevronRight}/></span>
                                </div>
                            </Link>
                        </div>
                    </div>
                );
            });
        }
    }

    render() {
        const {lang, access} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="container-fluid pl-0 pr-0">

                                <div className="d-flex justify-content-between mb-3">
                                    <input id="searchInput" type="text" className="question-input" style={{height: "40px", width: "calc(100% - 60px)", fontSize: "16px", borderRadius: "10px", borderTopRightRadius: "0px", borderBottomRightRadius: "0px", backgroundColor: "#fff"}} placeholder={localization[lang].searchText} value={this.state.searchText} onChange={(e) => this.searchTextChange(e)} onKeyPress={(e) => this.searchTextKeyPress(e)} />
                                    <button onClick={this.searchCodeButtonClick} className="a-agis-light-outline" style={{height: '40px', width: "40px", minWidth: "0px", borderRadius: "10px", borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px", lineHeight: "34px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 0 0 1px rgba(0,0,0,.05), 0 2px 3px 0 rgba(0,0,0,.1)"}}><FontAwesomeIcon icon={faSearch} style={{fontSize: "20px"}} /></button>

                                    <ScanButton onScan={this.onScan} />
                                </div>

                                {this.renderList()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default List;