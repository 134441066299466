import React, { Component } from 'react'
import Chart from "chart.js";
let myLineChart;

//--Chart Style Options--//
Chart.defaults.global.defaultFontFamily = "'PT Sans', sans-serif"
Chart.defaults.global.legend.display = false;
//--Chart Style Options--//

export default class LineGraph extends Component {
    chartRef = React.createRef();

    componentDidMount() {
        this.buildChart();
    }

    componentDidUpdate() {
        this.buildChart();
    }

    buildChart = () => {
        const myChartRef = this.chartRef.current.getContext("2d");
        const { type, data, options } = this.props;

        //if (typeof myLineChart !== "undefined") myLineChart.destroy();

        myLineChart = new Chart(myChartRef, {
            type: type,
            data: data,
            options: options,
        });

    }

    render() {

        return (
            <div style={{
                //height: "50vh",
                width: "100%",
                background: "white",
            }}>
                <canvas
                    ref={this.chartRef}
                />
            </div>
        )
    }
}