import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../AppContext.js';

const localization = {
    ua: {
        NPSAndSatisfaction: 'NPS і задоволення',
        ActionList: 'Очікують розгляду',
        TextAnswers: 'Усі візити',
        ResultsPerWorkshop: 'Статистика задоволення',
        SampleSummary: 'Статистика розсилки',
        ReasonsForDissatisfaction: 'Причини невдоволення',
    },
    ru: {
        NPSAndSatisfaction: 'NPS и удовлетворение',
        ActionList: 'Ожидают рассмотрения',
        TextAnswers: 'Все визиты',
        ResultsPerWorkshop: 'Статистика удовлетворения',
        SampleSummary: 'Статистика рассылки',
        ReasonsForDissatisfaction: 'Причины недовольства',
    },
    en: {
        NPSAndSatisfaction: 'NPS and satisfaction',
        ActionList: 'Action list',
        TextAnswers: 'All visits',
        ResultsPerWorkshop: 'Results per workshop',
        SampleSummary: 'Sample summary',
        ReasonsForDissatisfaction: 'Reasons for dissatisfaction',
    },
}

class Page extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
        }
    }

    render() {
        const {lang} = this.context;

        var searchInputStyle = {
            height: '34px',
            width: '300px',
            backgroundColor: "#f1f3f4",
            //borderRadius: ".5rem"
        }

        if (this.state.searchExpand) {
            searchInputStyle = {...searchInputStyle, borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px"}
        }

        return (
            <div style={{backgroundColor: '#fff'}}>
                <div className="pl-3" style={{minHeight: '50px', lineHeight: '50px'}}>
                    <NavLink to={"/crm/driver-feedback/nps-and-satisfaction"} className="nav-agis-light-outline mr-3" style={{height: '28px', lineHeight: "18px", minWidth: "0px", fontSize: "14px"}}>{localization[lang].NPSAndSatisfaction}</NavLink>
                    <NavLink to={"/crm/driver-feedback/text-answers"} className="nav-agis-light-outline mr-3" style={{height: '28px', lineHeight: "18px", minWidth: "0px", fontSize: "14px"}}>{localization[lang].TextAnswers}</NavLink>
                    <NavLink to={"/crm/driver-feedback/action-list"} className="nav-agis-light-outline mr-3" style={{height: '28px', lineHeight: "18px", minWidth: "0px", fontSize: "14px"}}>{localization[lang].ActionList}</NavLink>
                    <NavLink to={"/crm/driver-feedback/results-per-workshop"} className="nav-agis-light-outline mr-3" style={{height: '28px', lineHeight: "18px", minWidth: "0px", fontSize: "14px"}}>{localization[lang].ResultsPerWorkshop}</NavLink>
                    <NavLink to={"/crm/driver-feedback/sample-summary"} className="nav-agis-light-outline mr-3" style={{height: '28px', lineHeight: "18px", minWidth: "0px", fontSize: "14px"}}>{localization[lang].SampleSummary}</NavLink>
                    <NavLink to={"/crm/driver-feedback/reasons-for-dissatisfaction"} className="nav-agis-light-outline mr-3" style={{height: '28px', lineHeight: "18px", minWidth: "0px", fontSize: "14px"}}>{localization[lang].ReasonsForDissatisfaction}</NavLink>
                </div>
            </div>
        )
    }
}

export default Page;