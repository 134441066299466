import React from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../AppContext.js';
import OffsetTop from '../../../components/offsetTop.js';

const localization = {
    ua: {
        title: 'Редагування заявки',
        header: 'Дані заявки',
        plates: 'Держ. номер',
        description: 'Виявлена несправність',
        detail: 'Деталь',
        part: 'Артикул (якщо є на запчастині)',
        remark: 'Примітка',
        button: 'Зберегти',
    },
    ru: {
        title: 'Редактирование заявки',
        header: 'Данные заявки',
        plates: 'Гос. номер',
        description: 'Выявленная неисправность',
        detail: 'Деталь',
        part: 'Артикул (если есть на запчасти)',
        remark: 'Примечание',
        button: 'Сохранить',
    },
    en: {
        title: 'Редагування заявки',
        header: 'Дані заявки',
        plates: 'Держ. номер',
        description: 'Виявлена несправність',
        detail: 'Деталь',
        part: 'Артикул (якщо є на запчастині)',
        remark: 'Примітка',
        button: 'Зберегти',
    },
}

class ServiceRequestEdit extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            redirect: null,
            isLoading: true,

            plates: "",
            description: "",
            detail: "",
            part: "",
            remark: "",
        }

        this.onInputChange = this.onInputChange.bind(this);

        this.saveCard = this.saveCard.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        this.setState({did: this.props.match.params.did}, () => {
            this.load();
        });
    }

    load() {
        const {addNotification, removeNotification, token} = this.context;
        
        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/service/request/edit', { token: token, did: this.state.did }).then((res) => {
                if (res.data.serror == "") {
                    this.setState({
                        isLoading: false,
                        plates: res.data.plates,
                        description: res.data.description,
                        detail: res.data.detail,
                        part: res.data.part,
                        remark: res.data.remark,
                        files: ["new"],
                    });
                } else {
                    addNotification(res.data.serror, 2);
                }

            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            this.setState({
                isLoading: false,
            });
        }
    }

    onInputChange(e, field) {
        switch (field) {
            case "plates":
                this.setState({plates: e.target.value.toUpperCase()});
                break
            case "description":
                this.setState({description: e.target.value});
                break
            case "detail":
                this.setState({detail: e.target.value});
                break
            case "part":
                this.setState({part: e.target.value});
                break
            case "remark":
                this.setState({remark: e.target.value});
                break
        }
    }

    saveCard() {
        const {addNotification, removeNotification, token} = this.context;

        var u = addNotification("Завантаження...");
        axios.post('/api/service/request/save', { 
            token: token,
            did: this.state.did,
            plates: this.state.plates,
            description: this.state.description,
            detail: this.state.detail,
            part: this.state.part,
            remark: this.state.remark,
        }).then((res) => {
            this.setState({error: res.data.serror}, () => {
                if (res.data.serror == "") {
                    this.setState({redirect: "/service/requests"});
                } else {
                    addNotification(res.data.serror, 2);
                }
            });
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    render() {
        const {lang} = this.context;

        if (this.state.redirect !== null) {
            return <Redirect to={this.state.redirect} />
        }

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            
                            <div className="card agis-card pt-1 pb-1 mt-3" style={{backgroundColor: '#f1f3f4'}}>
                                <div className="row">
                                    <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                        <div>
                                            <h6><b>{localization[lang].header}</b></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card agis-card">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].plates}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col d-flex justify-content-between">
                                        <input type="text" autoComplete="new-password" className="agis-input" onChange={(e) => this.onInputChange(e, "plates")} value={this.state.plates} />
                                    </div>
                                </div>
                            </div>
                            <div className="card agis-card">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].description}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col d-flex justify-content-between">
                                        <input type="text" autoComplete="new-password" className="agis-input" onChange={(e) => this.onInputChange(e, "description")} value={this.state.description} />
                                    </div>
                                </div>
                            </div>
                            <div className="card agis-card">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].detail}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col d-flex justify-content-between">
                                        <input type="text" autoComplete="new-password" className="agis-input" onChange={(e) => this.onInputChange(e, "detail")} value={this.state.detail} />
                                    </div>
                                </div>
                            </div>
                            <div className="card agis-card">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].part}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col d-flex justify-content-between">
                                        <input type="text" autoComplete="new-password" className="agis-input" onChange={(e) => this.onInputChange(e, "part")} value={this.state.part} />
                                    </div>
                                </div>
                            </div>
                            <div className="card agis-card">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].remark}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col d-flex justify-content-between">
                                        <input type="text" autoComplete="new-password" className="agis-input" onChange={(e) => this.onInputChange(e, "remark")} value={this.state.remark} />
                                    </div>
                                </div>
                            </div>

                            <button className="btn-agis-primary mt-3 w-100" onClick={this.saveCard}>{localization[lang].button}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ServiceRequestEdit;