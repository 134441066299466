import React from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faPlus, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../AppContext.js';
import OffsetTop from '../../components/offsetTop.js';
import ScanButton from '../../components/scanbutton.js';

const localization = {
    ua: {
        title: 'ЗН на ремонт',
        initial: 'Попередній',
        planned: 'Запланований',
        inwork: 'В роботі',
        all: 'Всі',
        searchText: 'Пошук...',
        noData: 'Данi вiдсутнi',
    },
    ru: {
        title: 'ЗН на ремонт',
        initial: 'Предварительный',
        planned: 'Запланирован',
        inwork: 'В работе',
        all: 'Все',
        searchText: 'Поиск...',
        noData: 'Нет данных',
    },
    en: {
        title: 'Repair orders',
        initial: 'Initial',
        planned: 'Planned',
        inwork: 'In work',
        all: 'All',
        searchText: 'Filter...',
        noData: 'No data',
    },
}

class List extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            filterState: null,
            searchText: '',
            type: '',
            rows: []
        }

        this.searchTextChange = this.searchTextChange.bind(this);
        this.searchTextKeyPress = this.searchTextKeyPress.bind(this);
        this.onScan = this.onScan.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        this.loadList();
        //document.getElementById("searchInput").focus();
    }

    loadList() {
        const {addNotification, removeNotification, token} = this.context;
        
        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/order/list', { token: token, type: '4' }).then((res) => {
                this.setState({rows: res.data.rows});
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            this.setState({rows: [
                { did: "221", state: 41, l1: "AA9921PAб DAF FTX105", l2: "ЗН №1900346, [41] - Сухоцький Игорь Олегович", barcode: "123456789" },
                { did: "221", state: 8, l1: "AA9921PAб DAF FTX105", l2: "ЗН №1900346, [8] - Сухоцький Игорь Олегович", barcode: "" },
                { did: "221", state: 4, l1: "AA9921PAб DAF FTX105", l2: "ЗН №1900346, [4] - Сухоцький Игорь Олегович", barcode: "" }
            ]});
        }
    }

    searchTextChange(e) {
        this.setState({searchText: e.target.value});
    }
    
    searchTextKeyPress(e) {
        if (e.key === 'Enter') {
            e.target.blur();
        }
    }

    onScan(value) {
        this.setState({searchText: value});
    }

    renderList() {
        const {lang} = this.context;

        if (this.state.rows.length === 0) {
            return (
                <div className="row">
                    <div className="col">
                        <div className="card agis-card">
                            <div className="body">
                                <div className="row">
                                    <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                        <div>
                                            <h6><b>{localization[lang].noData}</b></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return this.state.rows.filter((item, i) => {
                // check by text filter
                if (item.l1.toLowerCase().includes(this.state.searchText.toLowerCase()) || item.l2.toLowerCase().includes(this.state.searchText.toLowerCase()) || item.barcode.toLowerCase().includes(this.state.searchText.toLowerCase())) {
                    // check by state
                    if (this.state.filterState != null) {
                        if (this.state.filterState == 41 && item.state == 41) {
                            return true;
                        }

                        if (this.state.filterState == 8 && item.state == 8) {
                            return true;
                        }

                        if (this.state.filterState != 41 && this.state.filterState != 8 && item.state != 41 && item.state != 8) {
                            return true;
                        }

                        return false
                    }

                    return true;
                }

                return false;
            }).map((item, i) => {
                var bc = "#fff";
                var b = "";

                if (item.state == 41) {
                    bc = "#fff6d7";
                    b = "1px solid #ffeeba";
                }

                if (item.state == 8) {
                    bc = "#daecff";
                    b = "1px solid #b8daff";
                }

                return (
                    <div className="row">
                        <div className="col">
                            <Link to={'/order/' + item.did + '/card'} className="card agis-card position-component" style={{backgroundColor: bc, border: b}}>
                                <div class="d-flex justify-content-between">
                                    <div className="row">
                                        <div className="col">
                                            <big><b>{item.l1}</b></big><br/>
                                            <big>{item.l2}</big>
                                        </div>
                                    </div>
                                    <div className="align-middle" style={{display: "flex", alignItems: "center"}}>
                                        <span className="mr-1"><FontAwesomeIcon icon={faChevronRight}/></span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                );
            });
        }
    }

    render() {
        const {lang, access} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="container-fluid pl-0 pr-0">

                                <div className="row mb-3">
                                    <div className="col">
                                        <div className="card-agis pt-1 pb-1" style={{borderRadius: "10px"}}>
                                            <div className="d-flex justify-content-between">
                                                <button onClick={() => this.setState({filterState: 41})} className={'btn-agis-light-outline'} style={{height: "30px", width: '27%', minWidth: "0px", fontSize: "12px", color: "#737a81", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", backgroundColor: this.state.filterState == 41 && "#e7e7e7"}}>{localization[lang].initial}</button>
                                                <button onClick={() => this.setState({filterState: 8})} className={'btn-agis-light-outline'} style={{height: "30px", width: '27%', minWidth: "0px", fontSize: "12px", color: "#737a81", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", backgroundColor: this.state.filterState == 8 && "#e7e7e7"}}>{localization[lang].planned}</button>
                                                <button onClick={() => this.setState({filterState: 0})} className={'btn-agis-light-outline'} style={{height: "30px", width: '27%', minWidth: "0px", fontSize: "12px", color: "#737a81", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", backgroundColor: (this.state.filterState != null && this.state.filterState != 41 && this.state.filterState != 8) && "#e7e7e7"}}>{localization[lang].inwork}</button>
                                                <button onClick={() => this.setState({filterState: null})} className={'btn-agis-light-outline'} style={{height: "30px", width: '19%', minWidth: "0px", fontSize: "12px", color: "#737a81", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", backgroundColor: this.state.filterState == null && "#e7e7e7"}}>{localization[lang].all}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between mb-3">
                                    <input id="searchInput" type="text" className="question-input" style={{height: "40px", width: "calc(100% - 60px)", fontSize: "16px", borderRadius: "10px", borderTopRightRadius: "0px", borderBottomRightRadius: "0px", backgroundColor: "#fff"}} placeholder={localization[lang].searchText} value={this.state.searchText} onChange={(e) => this.searchTextChange(e)} onKeyPress={(e) => this.searchTextKeyPress(e)} />
                                    <button onClick={this.searchCodeButtonClick} className="a-agis-light-outline" style={{height: '40px', width: "40px", minWidth: "0px", borderRadius: "10px", borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px", lineHeight: "34px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 0 0 1px rgba(0,0,0,.05), 0 2px 3px 0 rgba(0,0,0,.1)"}}><FontAwesomeIcon icon={faSearch} style={{fontSize: "20px"}} /></button>

                                    <ScanButton onScan={this.onScan} />
                                </div>

                                {this.renderList()}

                                {(access.includes('6001') || access.includes('6002')) && (
                                    <div className="fixed-bottom text-right" style={{zIndex: "4", paddingRight: "10px", paddingBottom: "10px", height: "60px"}}>
                                        <Link to={"/order/new"} className="a-agis-light-outline" style={{height: '50px', width: "50px", minWidth: "0px", borderRadius: "25px", lineHeight: "44px", backgroundColor: "#1a73e8", color: "white", border: "1px solid #ebebeb", boxShadow: "0 1px 1px rgba(0,0,0,.15)"}}><FontAwesomeIcon icon={faPlus} style={{fontSize: "20px"}} /></Link>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default List;