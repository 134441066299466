import React from 'react';
import { Link } from 'react-router-dom'
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faCog, faPause, faStop, faPlay, faUtensils, faListOl, faWrench, faUser, faTasks, faFileSignature, faBoxOpen, faFileAlt, faExpand, faTruck, faBoxes, faComment, faComments, faRetweet, faFileUpload, faFileDownload, faPaste, faFileContract } from '@fortawesome/free-solid-svg-icons'

import ScaleLoader from 'react-spinners/ScaleLoader';

import AppContext from '../AppContext';

const localization = {
    ua: {
        title: 'Меню',
        pages: {
            orders: "ЗН на ремонт",
            checkListMechanic: 'Чек-листи механiка',
            controlListManager: 'Акт приймання техніки',
            inspectionWorkshop: 'Огляд ремзони',
            stamping: 'Стемпiнг',
            mechanicPlanning: 'Запланованi роботи',
            serviceRequests: 'Запити на ремонт',
            stash: 'Отриманi запчастини',
            stashdoc: 'Акти видачі/повернення',
            stockscan: 'Інф. про запчастину',
            territoryvehicles: 'Рух техніки на СТО',
            managerStamping: 'Стемпінг механіків',
            managerPlanning: 'Планування механіків',
            inventorization: 'Інвентаризація',
            stockReceiptList: 'Прихідні накладні',
            stockCheckOutInRegistry: 'Видача / Повернення реєстр',
            stockCheckOut: 'Видача у виробництво',
            stockCheckIn: 'Повернення із виробництва',
            driverFeedback: 'Driver Feedback',
            driverExpenses: 'Рейси',
            settings: 'Налаштування',
            help: 'Довiдка',
        },
        stamping: {
            title: 'Стемпінг',
            ro: 'ЗН',
            stateNumber: 'р.н.',
            paused: 'Призупинено',
            start: 'Розпочати',
            pause: 'Призупинити',
            reason: 'Інший привід',
            continue: 'Продовжити',
            finish: 'Закінчити'
        }
    },
    ru: {
        title: 'Меню',
        pages: {
            orders: "ЗН на ремонт",
            checkListMechanic: 'Чек-листы механика',
            controlListManager: 'Акт приёма техники',
            inspectionWorkshop: 'Осмотр ремзоны',
            stamping: 'Стемпинг',
            mechanicPlanning: 'Запланированные работы',
            serviceRequests: 'Заявки на ремонт',
            stash: 'Полученные запчасти',
            stashdoc: 'Акты выдачи/получения',
            stockscan: 'Инф. о запчасти',
            territoryvehicles: 'Движение техники на СТО',
            managerStamping: 'Стемпинг механиков',
            managerPlanning: 'Планирование механиков',
            inventorization: 'Инвентаризация',
            stockReceiptList: 'Приходные накладные',
            stockCheckOutInRegistry: 'Выдача / Возврат реестр',
            stockCheckOut: 'Выдача в производство',
            stockCheckIn: 'Возврат из производства',
            driverFeedback: 'Driver Feedback',
            driverExpenses: 'Рейсы',
            settings: 'Настройки',
            help: 'Помощь',
        },
        stamping: {
            title: 'Стемпинг',
            ro: 'ЗН',
            stateNumber: 'р.н.',
            paused: 'Приостановлено',
            start: 'Начать',
            pause: 'Приостановить',
            reason: 'Другой повод',
            continue: 'Продолжить',
            finish: 'Закончить'
        }
    },
    en: {
        title: 'Menu',
        pages: {
            orders: "Repair orders",
            checkListMechanic: 'Mechanic checklists',
            controlListManager: 'Vehicle acceptance act',
            inspectionWorkshop: 'Inspection of workshop',
            mechanicPlanning: 'Planned works',
            serviceRequests: 'Service requests',
            stamping: 'Stamping',
            stash: 'Received parts',
            stashdoc: 'Issuance / Receipt acts',
            stockscan: 'Part information',
            territoryvehicles: 'Vehicle movements on territory',
            managerStamping: 'Mechanics stamping',
            managerPlanning: 'Mechanics planning',
            inventorization: 'Inventorization',
            stockReceiptList: 'Receipts',
            stockCheckOutInRegistry: 'Check-out / in registry',
            stockCheckOut: 'Check-out for WO',
            stockCheckIn: 'Check-in from WO',
            driverFeedback: 'Driver Feedback',
            driverExpenses: 'Driver expenses',
            settings: 'Settings',
            help: 'Help',
        },
        stamping: {
            title: 'Stamping',
            ro: 'RO',
            stateNumber: 's.n.',
            paused: 'Paused',
            start: 'Start',
            pause: 'Pause',
            reason: 'Another reason',
            continue: 'Continue',
            finish: 'Stop'
        }
    },
}

class Menu extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            expand: false
        }

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        const {menuExpand, setMenuExpand} = this.context;
        setMenuExpand(!menuExpand);
    }

    render() {
        const {scale, lang, access, username, menuExpand, menuExpandText} = this.context;

        return (
            <div>
                {scale == 'xs' || scale == 'sm' ? (
                    <div className="fixed-top main-menu d-flex justify-content-between" style={{height: '50px', lineHeight: '50px', backgroundColor: '#fff'}}>
                        <div className="pl-2 text-right">
                            <button className="btn-agis-light-outline mr-1" style={{height: '40px', width: "40px", minWidth: "0px"}} onClick={this.onClick}><FontAwesomeIcon icon={faBars} style={{fontSize: "16px"}} /></button>
                            <span style={{color: "", fontSize: "16px", lineHeight: "1.5"}}>{menuExpand ? localization[lang].title : menuExpandText}</span> 
                        </div>
                        <div className="pr-2">
                            <img src={"/serve/configuration/logo.png"} style={{height: 30}}/>
                        </div>
                    </div>
                ) : (
                    <div className="fixed-top main-menu d-flex justify-content-between" style={{height: '50px', lineHeight: '50px', backgroundColor: '#fff'}}>
                        <div className="pl-2 text-right">
                            <button className="btn-agis-light-outline mr-1" style={{height: '40px', width: "40px", minWidth: "0px"}} onClick={this.onClick}><FontAwesomeIcon icon={faBars} style={{fontSize: "16px"}} /></button>
                            <span style={{color: "", fontSize: "16px", lineHeight: "1.5"}}>{menuExpand ? localization[lang].title : menuExpandText}</span> 
                        </div>
                        <div className="pr-2">
                            <Link to={"/profile"} className="a-agis-light-outline" style={{color: "#5f6368"}}>{username} <FontAwesomeIcon icon={faCog}/></Link>
                            <img src={"/serve/configuration/logo.png"} style={{height: 30}}/>
                        </div>
                    </div>
                )}

                {menuExpand && ( 
                    <div className="expand-menu">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-10 offset-md-1 pb-4">
                                    <div className="container-fluid pl-0 pr-0">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-4">
                                                <div className="row">
                                                    {access.includes('6') && (
                                                        <div className="col-4 mb-2 text-center">
                                                            <Link to={"/orders"} onClick={this.onClick} className="a-agis-light-outline" style={{height: '50px', width: "50px", minWidth: "0px", borderRadius: "25px", lineHeight: "44px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 1px 1px rgba(0,0,0,.15)"}}><FontAwesomeIcon icon={faWrench} style={{fontSize: "20px"}} /></Link>
                                                            <div style={{marginTop: "5px", color: "#868e96", fontSize: "12px", lineHeight: "13px"}}>{localization[lang].pages.orders}</div>
                                                        </div>
                                                    )}

                                                    {access.includes('1') && (
                                                        <div className="col-4 mb-2 text-center">
                                                            <Link to={"/order/check-list-mechanic"} onClick={this.onClick} className="a-agis-light-outline" style={{height: '50px', width: "50px", minWidth: "0px", borderRadius: "25px", lineHeight: "44px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 1px 1px rgba(0,0,0,.15)"}}><FontAwesomeIcon icon={faTasks} style={{fontSize: "20px"}} /></Link>
                                                            <div style={{marginTop: "5px", color: "#868e96", fontSize: "12px", lineHeight: "13px"}}>{localization[lang].pages.checkListMechanic}</div>
                                                        </div>
                                                    )}

                                                    {access.includes('2') && (
                                                        <div className="col-4 mb-2 text-center">
                                                            <Link to={"/order/control-list-manager"} onClick={this.onClick} className="a-agis-light-outline" style={{height: '50px', width: "50px", minWidth: "0px", borderRadius: "25px", lineHeight: "44px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 1px 1px rgba(0,0,0,.15)"}}><FontAwesomeIcon icon={faTasks} style={{fontSize: "20px"}} /></Link>
                                                            <div style={{marginTop: "5px", color: "#868e96", fontSize: "12px", lineHeight: "13px"}}>{localization[lang].pages.controlListManager}</div>
                                                        </div>
                                                    )}

                                                    {access.includes('4') && (
                                                        <div className="col-4 mb-2 text-center">
                                                            <Link to={"/workshop/inspection"} onClick={this.onClick} className="a-agis-light-outline" style={{height: '50px', width: "50px", minWidth: "0px", borderRadius: "25px", lineHeight: "44px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 1px 1px rgba(0,0,0,.15)"}}><FontAwesomeIcon icon={faFileSignature} style={{fontSize: "20px"}} /></Link>
                                                            <div style={{marginTop: "5px", color: "#868e96", fontSize: "12px", lineHeight: "13px"}}>{localization[lang].pages.inspectionWorkshop}</div>
                                                        </div>
                                                    )}

                                                    {access.includes('9') && (
                                                        <div className="col-4 mb-2 text-center">
                                                            <Link to={"/mechanic/planning"} onClick={this.onClick} className="a-agis-light-outline" style={{height: '50px', width: "50px", minWidth: "0px", borderRadius: "25px", lineHeight: "44px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 1px 1px rgba(0,0,0,.15)"}}><FontAwesomeIcon icon={faListOl} style={{fontSize: "20px"}} /></Link>
                                                            <div style={{marginTop: "5px", color: "#868e96", fontSize: "12px", lineHeight: "13px"}}>{localization[lang].pages.mechanicPlanning}</div>
                                                        </div>
                                                    )}

                                                    {access.includes('20') && (
                                                        <div className="col-4 mb-2 text-center">
                                                            <Link to={"/service/requests"} onClick={this.onClick} className="a-agis-light-outline" style={{height: '50px', width: "50px", minWidth: "0px", borderRadius: "25px", lineHeight: "44px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 1px 1px rgba(0,0,0,.15)"}}><FontAwesomeIcon icon={faFileContract} style={{fontSize: "20px"}} /></Link>
                                                            <div style={{marginTop: "5px", color: "#868e96", fontSize: "12px", lineHeight: "13px"}}>{localization[lang].pages.serviceRequests}</div>
                                                        </div>
                                                    )}

                                                    {access.includes('7') && (
                                                        <div className="col-4 mb-2 text-center">
                                                            <Link to={"/stash"} onClick={this.onClick} className="a-agis-light-outline" style={{height: '50px', width: "50px", minWidth: "0px", borderRadius: "25px", lineHeight: "44px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 1px 1px rgba(0,0,0,.15)"}}><FontAwesomeIcon icon={faBoxOpen} style={{fontSize: "20px"}} /></Link>
                                                            <div style={{marginTop: "5px", color: "#868e96", fontSize: "12px", lineHeight: "13px"}}>{localization[lang].pages.stash}</div>
                                                        </div>
                                                    )}

                                                    {access.includes('8') && (
                                                        <div className="col-4 mb-2 text-center">
                                                            <Link to={"/stash/docs"} onClick={this.onClick} className="a-agis-light-outline" style={{height: '50px', width: "50px", minWidth: "0px", borderRadius: "25px", lineHeight: "44px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 1px 1px rgba(0,0,0,.15)"}}><FontAwesomeIcon icon={faFileAlt} style={{fontSize: "20px"}} /></Link>
                                                            <div style={{marginTop: "5px", color: "#868e96", fontSize: "12px", lineHeight: "13px"}}>{localization[lang].pages.stashdoc}</div>
                                                        </div>
                                                    )}

                                                    {access.includes('10') && (
                                                        <div className="col-4 mb-2 text-center">
                                                            <Link to={"/stock/detail"} onClick={this.onClick} className="a-agis-light-outline" style={{height: '50px', width: "50px", minWidth: "0px", borderRadius: "25px", lineHeight: "50px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 1px 1px rgba(0,0,0,.15)"}}><span style={{fontSize: "12px"}}><svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="barcode-read" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="svg-inline--fa fa-barcode-read fa-w-20 fa-2x"><path fill="currentColor" d="M152 0H8C3.6 0 0 3.6 0 8v152c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V32h120c4.4 0 8-3.6 8-8V8c0-4.4-3.6-8-8-8zm0 480H32V352c0-4.4-3.6-8-8-8H8c-4.4 0-8 3.6-8 8v152c0 4.4 3.6 8 8 8h144c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zM632 0H488c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h120v128c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V8c0-4.4-3.6-8-8-8zm0 344h-16c-4.4 0-8 3.6-8 8v128H488c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h144c4.4 0 8-3.6 8-8V352c0-4.4-3.6-8-8-8zM152 96h-48c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8zm336 320h48c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8zM408 96h-48c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8zm-192 0h-16c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8zm64 0h-16c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8z" class=""></path></svg></span></Link>
                                                            <div style={{marginTop: "5px", color: "#868e96", fontSize: "12px", lineHeight: "13px"}}>{localization[lang].pages.stockscan}</div>
                                                        </div>
                                                    )}

                                                    {access.includes('12') && (
                                                        <div className="col-4 mb-2 text-center">
                                                            <Link to={"/territory/vehicles"} onClick={this.onClick} className="a-agis-light-outline" style={{height: '50px', width: "50px", minWidth: "0px", borderRadius: "25px", lineHeight: "44px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 1px 1px rgba(0,0,0,.15)"}}><FontAwesomeIcon icon={faTruck} style={{fontSize: "20px"}} /></Link>
                                                            <div style={{marginTop: "5px", color: "#868e96", fontSize: "12px", lineHeight: "13px"}}>{localization[lang].pages.territoryvehicles}</div>
                                                        </div>
                                                    )}

                                                    {access.includes('14') && (
                                                        <div className="col-4 mb-2 text-center">
                                                            <Link to={"/service/manager/stamping"} onClick={this.onClick} className="a-agis-light-outline" style={{height: '50px', width: "50px", minWidth: "0px", borderRadius: "25px", lineHeight: "44px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 1px 1px rgba(0,0,0,.15)"}}><FontAwesomeIcon icon={faListOl} style={{fontSize: "20px"}} /></Link>
                                                            <div style={{marginTop: "5px", color: "#868e96", fontSize: "12px", lineHeight: "13px"}}>{localization[lang].pages.managerStamping}</div>
                                                        </div>
                                                    )}

                                                    {access.includes('14') && (
                                                        <div className="col-4 mb-2 text-center">
                                                            <Link to={"/service/manager/planning"} onClick={this.onClick} className="a-agis-light-outline" style={{height: '50px', width: "50px", minWidth: "0px", borderRadius: "25px", lineHeight: "44px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 1px 1px rgba(0,0,0,.15)"}}><FontAwesomeIcon icon={faListOl} style={{fontSize: "20px"}} /></Link>
                                                            <div style={{marginTop: "5px", color: "#868e96", fontSize: "12px", lineHeight: "13px"}}>{localization[lang].pages.managerPlanning}</div>
                                                        </div>
                                                    )}

                                                    {access.includes('15') && (
                                                        <div className="col-4 mb-2 text-center">
                                                            <Link to={"/stock/inventorization/list"} onClick={this.onClick} className="a-agis-light-outline" style={{height: '50px', width: "50px", minWidth: "0px", borderRadius: "25px", lineHeight: "44px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 1px 1px rgba(0,0,0,.15)"}}><FontAwesomeIcon icon={faRetweet} style={{fontSize: "20px"}} /></Link>
                                                            <div style={{marginTop: "5px", color: "#868e96", fontSize: "12px", lineHeight: "13px"}}>{localization[lang].pages.inventorization}</div>
                                                        </div>
                                                    )}

                                                    {access.includes('16') && (
                                                        <div className="col-4 mb-2 text-center">
                                                            <Link to={"/stock/receipt/list"} onClick={this.onClick} className="a-agis-light-outline" style={{height: '50px', width: "50px", minWidth: "0px", borderRadius: "25px", lineHeight: "44px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 1px 1px rgba(0,0,0,.15)"}}><FontAwesomeIcon icon={faPaste} style={{fontSize: "20px"}} /></Link>
                                                            <div style={{marginTop: "5px", color: "#868e96", fontSize: "12px", lineHeight: "13px"}}>{localization[lang].pages.stockReceiptList}</div>
                                                        </div>
                                                    )}

                                                    {access.includes('17') && (
                                                        <div className="col-4 mb-2 text-center">
                                                            <Link to={"/stock/handover/check-out"} onClick={this.onClick} className="a-agis-light-outline" style={{height: '50px', width: "50px", minWidth: "0px", borderRadius: "25px", lineHeight: "44px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 1px 1px rgba(0,0,0,.15)"}}><FontAwesomeIcon icon={faFileUpload} style={{fontSize: "20px"}} /></Link>
                                                            <div style={{marginTop: "5px", color: "#868e96", fontSize: "12px", lineHeight: "13px"}}>{localization[lang].pages.stockCheckOut}</div>
                                                        </div>
                                                    )}

                                                    {access.includes('17') && (
                                                        <div className="col-4 mb-2 text-center">
                                                            <Link to={"/stock/handover/check-in"} onClick={this.onClick} className="a-agis-light-outline" style={{height: '50px', width: "50px", minWidth: "0px", borderRadius: "25px", lineHeight: "44px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 1px 1px rgba(0,0,0,.15)"}}><FontAwesomeIcon icon={faFileDownload} style={{fontSize: "20px"}} /></Link>
                                                            <div style={{marginTop: "5px", color: "#868e96", fontSize: "12px", lineHeight: "13px"}}>{localization[lang].pages.stockCheckIn}</div>
                                                        </div>
                                                    )}

                                                    {access.includes('18') && (
                                                        <div className="col-4 mb-2 text-center">
                                                            <Link to={"/crm/driver-feedback/nps-and-satisfaction"} onClick={this.onClick} className="a-agis-light-outline" style={{height: '50px', width: "50px", minWidth: "0px", borderRadius: "25px", lineHeight: "44px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 1px 1px rgba(0,0,0,.15)"}}><FontAwesomeIcon icon={faComments} style={{fontSize: "20px"}} /></Link>
                                                            <div style={{marginTop: "5px", color: "#868e96", fontSize: "12px", lineHeight: "13px"}}>{localization[lang].pages.driverFeedback}</div>
                                                        </div>
                                                    )}

                                                    {access.includes('19') && (
                                                        <div className="col-4 mb-2 text-center">
                                                            <Link to={"/driver/expenses"} onClick={this.onClick} className="a-agis-light-outline" style={{height: '50px', width: "50px", minWidth: "0px", borderRadius: "25px", lineHeight: "44px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 1px 1px rgba(0,0,0,.15)"}}><FontAwesomeIcon icon={faFileSignature} style={{fontSize: "20px"}} /></Link>
                                                            <div style={{marginTop: "5px", color: "#868e96", fontSize: "12px", lineHeight: "13px"}}>{localization[lang].pages.driverExpenses}</div>
                                                        </div>
                                                    )}
                                                   
                                                    <div className="col-4 mb-2 text-center">
                                                        <Link to={"/profile"} onClick={this.onClick} className="a-agis-light-outline" style={{height: '50px', width: "50px", minWidth: "0px", borderRadius: "25px", lineHeight: "44px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 1px 1px rgba(0,0,0,.15)"}}><FontAwesomeIcon icon={faUser} style={{fontSize: "20px"}} /></Link>
                                                        <br/>
                                                        <div style={{marginTop: "5px", color: "#868e96", fontSize: "12px", lineHeight: "13px"}}>{localization[lang].pages.settings}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {access.includes('5') && (
                                            <Stamping />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

class Stamping extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            stamping: null
        }

        this.onClick = this.onClick.bind(this);

        this.loadCurrent = this.loadCurrent.bind(this);
        this.stampingChange = this.stampingChange.bind(this);
    }

    componentDidMount() {
        this.loadCurrent();
    }

    onClick() {
        const {menuExpand, setMenuExpand} = this.context;
        setMenuExpand(!menuExpand);
    }

    loadCurrent() {
        const {addNotification, token} = this.context;

        if (process.env.NODE_ENV === 'production') {
            axios.post('/api/order/stamping/current', { token: token }).then((res) => {
                this.setState({stamping: res.data});
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                //removeNotification(u);
            });
        } else {
            setTimeout(() => {
                this.setState({
                    stamping: {
                        status: 1,
                        sname: "В роботі",
                        reason: "",
                        order: 94389,
                        item: 1,
                        iname: "Активувати датчики навантаження на вісь",
                        time: "0.22",
                        stime: "2.00",

                        did: "159841",
                        date: "",
                        manufacturer: "",
                        model: "",
                        chassis: "",
                        stateNumber: ""
                    },
                    // stamping: {
                    //     status: 2,
                    //     sname: "Призупинено",
                    //     reason: "Перерва",
                    //     order: 94389,
                    //     item: 1,
                    //     iname: "Активувати датчики навантаження на вісь",
                    //     time: "0.22",
                    //     stime: "2.00"
                    // },
                    // stamping: {
                    //     status: 11,
                    //     sname: "Відсутній",
                    //     reason: "Відсутній без причини",
                    //     order: 0,
                    //     item: 0,
                    //     iname: "",
                    //     time: "",
                    //     stime: ""
                    // },
                });
            }, 1000)
        }
    }

    stampingChange(status, e) {
        e.preventDefault();

        const {addNotification, removeNotification, token} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/order/stamping/change', { 
                token: token, 
                status: status,
                reason: 0,
                did: this.state.order,
                item: this.state.item
            }).then((res) => {
                this.loadCurrent();
            })
            .catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            this.loadCurrent();
        }
    }

    renderActivity(item) {
        const {lang} = this.context;

        var progressTime = Number(item.time) * 100;
        var progressSTime = Number(item.stime) * 100;
        var percent = Math.round(progressTime * 100 / progressSTime);

        switch(item.status) {
            case 1:
                return (
                    <Link to={"/order/" + item.order + "/card/" + item.item} className="card agis-card position-component pt-1 pb-1" onClick={this.onClick}>
                        <div className="row">
                            <div className="col">
                                <h6><b>{item.iname}</b></h6>
                            </div>
                        </div>
                        <div className="row mb-1">
                            {item.did != "" && (
                                <div className="col-4">
                                    <span className="text-muted">{localization[lang].stamping.ro} {item.did}</span>
                                </div>
                            )}

                            {item.stateNumber != "" && (
                                <div className="col-4">
                                    <span className="text-muted">{localization[lang].stamping.stateNumber} {item.stateNumber}</span>
                                </div>
                            )}
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="d-flex justify-content-between">
                                    <div className="">
                                        <span>{item.sname}</span>
                                    </div>
                                    <div className="">
                                        <span>{item.time} / {item.stime}</span>
                                    </div>
                                </div>
                                <div class="progress w-100" style={{height: "5px"}}>
                                    <div class="progress-bar bg-success" role="progressbar" style={{width: percent + "%"}} aria-valuenow={progressTime} aria-valuemin="0" aria-valuemax={progressSTime}></div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col" style={{display: "flex", alignItems: "center"}}>
                                <div className="w-100">
                                    <div className="d-flex justify-content-center">
                                        <Link to={"/order/stamping/pin/1"} className="a-agis-light-outline" style={{height: "40px", width: 'calc(49% - 10px)', minWidth: "0px"}}><FontAwesomeIcon icon={faPause} /> {localization[lang].stamping.pause}</Link>
                                        <button className="btn-agis-light-outline" style={{height: "40px", width: 'calc(49% - 10px)', minWidth: "0px"}} onClick={(e) => this.stampingChange(5, e)}><FontAwesomeIcon icon={faStop} /> {localization[lang].stamping.finish}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                );
            case 2:
                return (
                    <Link to={"/order/" + item.order + "/card/" + item.item} className="card agis-card position-component pt-1 pb-1" onClick={this.onClick}>
                        <div className="row">
                            <div className="col">
                                <h6><b>{item.iname}</b></h6>
                            </div>
                        </div>
                        <div className="row mb-1">
                            <div className="col-4">
                                <span className="text-muted">{localization[lang].stamping.ro} {item.did}</span>
                            </div>
                            <div className="col-4">
                                <span className="text-muted">{localization[lang].stamping.stateNumber} {item.stateNumber}</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="d-flex justify-content-between">
                                    <div className="">
                                        <span>{item.sname}: <b>{item.reason}</b></span>
                                    </div>
                                    <div className="">
                                        <span>{item.time} / {item.stime}</span>
                                    </div>
                                </div>
                                <div class="progress w-100" style={{height: "5px"}}>
                                    <div class="progress-bar bg-warning" role="progressbar" style={{width: percent + "%"}} aria-valuenow={progressTime} aria-valuemin="0" aria-valuemax={progressSTime}></div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="row mt-2">
                            <div className="col" style={{display: "flex", alignItems: "center"}}>
                                <div className="w-100">
                                    <div className="d-flex justify-content-center">
                                        <Link to={"/order/stamping/pin"} className="a-agis-light-outline" style={{height: "40px", width: 'calc(99% - 10px)', minWidth: "0px"}}><FontAwesomeIcon icon={faUtensils} /> {localization[lang].stamping.reason}</Link>
                                        {item.order != 0 && (
                                            <button className="btn-agis-light-outline" style={{height: "40px", width: 'calc(99% - 10px)', minWidth: "0px"}} onClick={(e) => this.stampingChange(3, e)}><FontAwesomeIcon icon={faPlay} /> {localization[lang].stamping.continue}</button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                );
            default:
                return (
                    <div className="card agis-card pt-1 pb-1">
                        <div className="row mb-1">
                            <div className="col">
                                <h6><b>{item.reason}</b></h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="d-flex justify-content-between">
                                    <div className="">
                                        <span className="text-muted">{item.sname}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
        }
    }

    render() {
        const {lang} = this.context;

        if (this.state.stamping == null) {
            return (
                <div className="fixed-bottom text-center pt-2" style={{backgroundColor: '#F1F3F4', boxShadow: '0px -1px 0px rgba(0,0,0,.15)'}}>
                     <ScaleLoader
                        sizeUnit={"px"}
                        height={20}
                        width={4}
                        color={'#868e96'}
                    />
                </div>
            )
        }
        return (
            <div className="fixed-bottom pt-2 pb-2" style={{backgroundColor: '#F1F3F4', boxShadow: '0px -1px 0px rgba(0,0,0,.15)'}}>
                
                <Link to={"/order/stamping/history"} className="a-agis-light-outline mr-1" style={{position: "absolute", top: "-15px", right: "10px", height: '40px', width: "40px", minWidth: "0px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 1px 1px rgba(0,0,0,.15)"}} onClick={this.onClick}><FontAwesomeIcon icon={faListOl} style={{fontSize: "16px"}} /></Link>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <span className="text-muted"><b>{localization[lang].stamping.title}</b></span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            {this.renderActivity(this.state.stamping)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Menu;