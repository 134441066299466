import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import DocumentMeta from 'react-document-meta';
import axios from 'axios';
import ScaleLoader from "react-spinners/ScaleLoader";

import AppContext from '../../../AppContext';
import OffsetTop from '../../../components/offsetTop.js';

import BeepSound from '../../../files/audio/beep.mp3';
import ErrorSound from '../../../files/audio/error.wav';

import ScanButton from '../../../components/scanbutton.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes, faSave, faMapMarkerAlt, faCheck, faUndo, faCamera } from '@fortawesome/free-solid-svg-icons'

const localization = {
    ua: {
        title: 'Перерахунок приходу',
        counted: 'Пораховані',
        mismatched: 'Розбіжності',
        results: 'Результат',
        stocks: 'Склад',
        code: 'Код',
        total: 'Кількість',
        check: {
            total: 'Кількість',
            stock: 'На склад',
            client: 'Клієнтам',
            counted: 'Пораховано',
        },
        result: {
            total: 'В документі',
            defective: 'Брак',
            rest: 'Залишилося',
            counted: 'Пораховано',
        },
        stock: {
            total: 'Кількість',
            base: 'Рекомендована локація',
        },
        discard: 'Відмінити',
        defective: 'Брак',
        add: 'Додати',
        errors: {
            error1: 'Не внесений код ЗЧ!',
            error2: 'Не знайдений акт з DID = ',
            error3: 'Документ вже закритий для змін та перерахування!'
        },
        save: 'Зафіксувати результат'
    },
    ru: {
        title: 'Пересчёт прихода',
        counted: 'Посчитанные',
        mismatched: 'Расхождения',
        results: 'Результат',
        stocks: 'Склад',
        code: 'Код',
        total: 'Количество',
        check: {
            total: 'Количество',
            stock: 'На склад',
            client: 'Клиентам',
            counted: 'Посчитано',
        },
        result: {
            total: 'В документе',
            defective: 'Брак',
            rest: 'Осталось',
            counted: 'Посчитано',
        },
        stock: {
            total: 'Количество',
            base: 'Рекомендованная локация',
        },
        discard: 'Отменить',
        defective: 'Брак',
        add: 'Добавить',

        errors: {
            error1: 'Не внесен код ЗЧ!',
            error2: 'не найден акт с DID = ',
            error3: 'Документ уже закрыт для изменений и пересчета!'
        },
        save: 'Зафиксировать результат'
    },
    en: {
        title: 'Recount receipt',
        counted: 'Counted',
        mismatched: 'Mismatched',
        results: 'Result',
        stocks: 'Stock',
        code: 'Code',
        total: 'Total',
        check: {
            total: 'In document',
            stock: 'Stock',
            client: 'Client',
            counted: 'Counted',
        },
        result: {
            total: 'Total',
            defective: 'Defective',
            rest: 'Rest',
            counted: 'Сounted',
        },
        stock: {
            total: 'In document',
            base: 'Recommended location',
        },
        discard: 'Discard',
        defective: 'Defective',
        add: 'Add',

        errors: {
            error1: 'No code!',
            error2: 'No document with DID = ',
            error3: 'Document is closed!'
        },
        save: 'Save result'
    },
}

class Recount extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,
            isCameraOn: true,
            
            videoSide: null,
            videoDevice: null,
            videoDevices: [],
            videoError: null,
            tracks: null,
            stream: null,

            did: null,
            header: null,
            counted: null,
            mismatched: null,
            result: null,
            stock: null,

            text: "",
            isChecking: false,
            
            check: null,

            view: "counted"
        }

        this.video = React.createRef();

        this.cameraToogle = this.cameraToogle.bind(this);

        this.onChange = this.onChange.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onPaste = this.onPaste.bind(this);
        this.onScan = this.onScan.bind(this);
        this.onCheck = this.onCheck.bind(this);

        this.discard = this.discard.bind(this);
        this.defective = this.defective.bind(this);
        this.add = this.add.bind(this);

        this.activate = this.activate.bind(this);

        this.onAddressChange = this.onAddressChange.bind(this);
        this.scanAddress = this.scanAddress.bind(this);

        this.changeView = this.changeView.bind(this);

        this.save = this.save.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        this.init();
        //this.detectDeices();
    }

    componentWillUnmount() {
        if (this.state.tracks) {
            this.state.tracks.getTracks().forEach(track => track.stop());
        }
    }

    init() {
        const {addNotification, removeNotification, token, videoSide, videoDevice} = this.context;

        var did = Number(this.props.match.params.did);

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");

            var headerPromise = axios.post('/api/stock/receipt/header', { token: token, did: did });
            var countedPromise = axios.post('/api/stock/receipt/counted', { token: token, did: did });
            var devicesPromise = navigator.mediaDevices.enumerateDevices();

            axios.all([headerPromise, countedPromise, devicesPromise]).then(axios.spread((h, c, d) => {
                this.setState({
                    //isLoading: false,

                    did: did,

                    header: h.data.rows[0],
                    counted: c.data.rows,

                    videoDevices: d.filter((device) => {
                        return device.kind === 'videoinput';
                    }),

                    videoSide: videoSide,
                    videoDevice: videoDevice,
                }, () => {
                    this.runCamera();
                });
            })).catch(error => {
                addNotification(error.response.data, error.response.status);

                this.setState({
                    isLoading: false,
                    //videoError: error
                })
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            var devicesPromise = navigator.mediaDevices.enumerateDevices();

            var u = addNotification("Завантаження...");

            axios.all([devicesPromise]).then(axios.spread((d) => {
                this.setState({
                    //isLoading: false,

                    did: did,

                    header: {
                        name: "1900012 вiд 16.10.2019, эСУ - МАКАРОВ - ЗАПЧАСТИэ, Стойка WURTH .. Стойка WURTH",
                        number: "2000010",
                        date: "20.09.2020",
                        stock: "ЗАПЧАСТИ",
                        from: "Scania Sweden",
                    },
                    counted: [
                        {
                            did: 79,
                            item: 36,
                            code: "1108017",
                            name: "кронштейн задній правий",
                            total: 3,
                            defective: 0,
                            unit: "шт",
                            active: 0,
                        },
                        {
                            did: 80,
                            item: 37,
                            code: "1108017",
                            name: "кронштейн задній",
                            total: 1,
                            defective: 1,
                            unit: "шт",
                            active: 1,
                        },
                    ],

                    videoDevices: d.filter((device) => {
                        return device.kind === 'videoinput';
                    }),

                    videoSide: videoSide,
                    videoDevice: videoDevice,
                }, () => {
                    this.runCamera();
                });
            })).catch(error => {
                addNotification(error.response.data, error.response.status);

                this.setState({
                    isLoading: false,
                    //videoError: err
                })
            }).finally(function () {
                removeNotification(u);
            });
        }
    }

    cameraToogle() {
        this.setState({isCameraOn: !this.state.isCameraOn}, () => this.state.isCameraOn ? this.runCamera() : this.state.tracks && this.state.tracks.getTracks().forEach(track => track.stop()));
    }

    runCamera() {
        if (!this.state.isCameraOn) {
            this.setState({
                isLoading: false,
            }, () => {
                //document.getElementById('textInput').focus();
            });
            return
        }

        if (this.state.videoError == null) {
            this.video.current.srcObject = null;
        }

        if (this.state.videoSide && this.state.videoDevice) {
            const constraints = {
                formats: [
                    'aztec',
                    'code_128',
                    'code_39',
                    'code_93',
                    'codabar',
                    'data_matrix',
                    'ean_13',
                    'ean_8',
                    'itf',
                    'pdf417',
                    'qr_code',
                    'upc_a',
                    'upc_e'
                  ],
                video: {
                    facingMode: {exact: this.state.videoSide },
                    deviceId: { exact: this.state.videoDevice }
                }
            };
    
            navigator.mediaDevices.getUserMedia(constraints).then(res => {
                const track = res.getVideoTracks()[0];
                let imageCapture = new ImageCapture(track);
                
                this.setState({
                    isLoading: false,

                    tracks: res, 
                    stream: imageCapture, 
                    videoError: null
                }, () => {
                    //document.getElementById('textInput').focus();
                });
    
                this.video.current.srcObject = res;
            }).catch(err => {
                console.log(err);
                this.setState({
                    isLoading: false,
                    videoError: err
                });
            });
        } else {
            this.setState({
                isLoading: false,
                videoError: {name: "No device selected"}
            }, () => {
                //document.getElementById('textInput').focus();
            });
        }
    }

    onChange(event) {
        this.setState({
            text: event.target.value, 
        }, () => {
            //this.checkWorker();
        });
    }

    onKeyPress(e) {
        if (e.key === 'Enter') {
            this.setState({
                isChecking: true,
            }, () => {
                this.onCheck();
            });
        }
    }

    onPaste() {
        setTimeout(() => {
            this.setState({
                text: document.getElementById('textInput').value,
                isChecking: true,
            }, () => {
                this.onCheck();
            });
        }, 100)
    }

    onScan() {
        const {addNotification, removeNotification, token} = this.context;

        if (process.env.NODE_ENV === 'production') {
            //save if exist before scanning
            if (this.state.check && this.state.check.ok == 1) {
                var u = addNotification("Завантаження...");

                axios.post('/api/stock/receipt/add', {
                    token: token,
                    did: this.state.did,
                    code: this.state.check.code,
                    total: this.state.check.total,
                    defective: 0,
                }).then((res) => {
                    axios.post('/api/stock/receipt/counted', { token: token, did: this.state.did }).then((res) => {
                        this.setState({
                            counted: res.data.rows,
                            check: null
                        }, () => {
                            this.state.stream.grabFrame().then(blob => {
                                console.log('Took photo:', blob);
                
                                let barcodeDetector = new window.BarcodeDetector();
                                barcodeDetector.detect(blob).then(detected => {
                                    if (detected.length == 1) {
                                        this.beep(true);
                
                                        this.setState({
                                            text: detected[0].rawValue, 
                                            isChecking: true,
                                        }, () => {
                                            this.onCheck();
                                        }, () => {
                                            //document.getElementById('textInput').focus();
                                        });
                                    } else {
                                        this.beep(false);
                                    }
                                }).catch(err => {
                                    this.beep(false);
                                    //this.setState({code: err+""});
                                });
                            });
                        });
                    }).catch((error) => {
                        addNotification(error.response.data, error.response.status);
                    }).finally(() => {
                        //removeNotification(u);
                    });
                }).catch((error) => {
                    addNotification(error.response.data, error.response.status);
                }).finally(() => {
                    removeNotification(u);
                });
            } else {
                this.state.stream.grabFrame().then(blob => {
                    console.log('Took photo:', blob);
    
                    let barcodeDetector = new window.BarcodeDetector();
                    barcodeDetector.detect(blob).then(detected => {
                        if (detected.length == 1) {
                            this.beep(true);
    
                            this.setState({
                                text: detected[0].rawValue, 
                                isChecking: true,
                            }, () => {
                                this.onCheck();
                            });
                        } else {
                            this.beep(false);
                        }
                    }).catch(err => {
                        console.log(err);
                        this.beep(false);
                        //this.setState({code: err+""});
                    });
                });
            }
        } else {
            this.beep(true);

            this.setState({
                text: "123456789", 
                isChecking: true,
                check: null,
            }, () => {
                this.onCheck();
            });
        }
    }

    onCheck() {
        const {addNotification, removeNotification, token} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var data;

            if (!this.state.check || this.state.check.ok == 0) {
                //код
                data = {
                    code: "",
                    text: this.state.text,
                    type: "2",
                    token: token,
                    did: this.state.did
                }
            } else {
                //количество
                data = {
                    code: this.state.check.code,
                    text: this.state.text,
                    type: "3",
                    token: token,
                    did: this.state.did
                }
            }

            var u = addNotification("Завантаження...");

            axios.post('/api/stock/receipt/check', data).then((res) => {
                console.log(res.data);

                this.setState({
                    isChecking: false,
                    text: "",
                    check: {
                        ok: res.data.rows[0].ok,
                        error: res.data.rows[0].error,
                        info: res.data.rows[0].info,
                        code: res.data.rows[0].code,
                        name: res.data.rows[0].name,
                        total: res.data.rows[0].total ? res.data.rows[0].total : 1,
                        stock: res.data.rows[0].stock,
                        client: res.data.rows[0].client,
                        counted: res.data.rows[0].counted,
                        unit: res.data.rows[0].unit
                    }
                }, () => {
                    document.getElementById('textInput').blur();
                });
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            setTimeout(() => {
                this.setState({
                    isChecking: false,
                    text: "",
                    check: {
                        ok: 1,
                        error: "Запчасть 66493 в справочнике не найдена!",
                        info: "info",
                        code: "123456",
                        name: "Запчасть 66493 в справочнике",
                        total: 10,
                        stock: 8,
                        client: 2,
                        counted: 10,
                        unit: "шт"
                    }
                }, () => {
                    document.getElementById('textInput').blur();
                });
            }, 1000);
        }
    }

    discard() {
        this.setState({
            text: "",
            check: null
        }, () => {
            //document.getElementById('textInput').focus();
        });
    }

    defective() {
        const {addNotification, removeNotification, token, lang} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");

            axios.post('/api/stock/receipt/add', {
                token: token,
                did: this.state.did,
                code: this.state.check.code,
                total: this.state.check.total,
                defective: this.state.check.total,
            }).then((res) => {
                console.log(res.data);

                switch (res.data.result) {
                    case -1: 
                        addNotification(localization[lang].errors.error1, 2);
                        break;
                    case -2:
                        addNotification(localization[lang].errors.error2, 2);
                        break;
                    case -3:
                        addNotification(localization[lang].errors.error3, 2);
                        break;
                    default:
                        axios.post('/api/stock/receipt/counted', { token: token, did: this.state.did }).then((res) => {
                            this.setState({
                                text: "",
                                check: null,
                                view: "counted",
                                counted: res.data.rows
                            }, () => {
                                //document.getElementById('textInput').focus();
                            });
                        }).catch((error) => {
                            addNotification(error.response.data, error.response.status);
                        }).finally(() => {
                            //removeNotification(u);
                        });
                        break;
                } 
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            setTimeout(() => {
                this.setState({
                    counted: [

                    ]
                });
            }, 1000);
        }
    }

    add() {
        const {addNotification, removeNotification, token, lang} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");

            axios.post('/api/stock/receipt/add', {
                token: token,
                did: this.state.did,
                code: this.state.check.code,
                total: this.state.check.total,
                defective: 0,
            }).then((res) => {
                console.log(res.data);

                switch (res.data.result) {
                    case -1: 
                        addNotification(localization[lang].errors.error1, 2);
                        break;
                    case -2:
                        addNotification(localization[lang].errors.error2, 2);
                        break;
                    case -3:
                        addNotification(localization[lang].errors.error3, 2);
                        break;
                    default:
                        axios.post('/api/stock/receipt/counted', { token: token, did: this.state.did }).then((res) => {
                            this.setState({
                                text: "",
                                check: null,
                                view: "counted",
                                counted: res.data.rows
                            }, () => {
                                //document.getElementById('textInput').focus();
                            });
                        }).catch((error) => {
                            addNotification(error.response.data, error.response.status);
                        }).finally(() => {
                            //removeNotification(u);
                        });
                        break;
                } 
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            setTimeout(() => {
                this.setState({
                    counted: [

                    ]
                });
            }, 1000);
        }
    }

    activate(item, active) {
        const {addNotification, removeNotification, token, lang} = this.context;

        var u = addNotification("Завантаження...");

        axios.post('/api/stock/receipt/activate', {
            token: token,
            did: this.state.did,
            item: item,
            active: active ? 1 : 0,
        }).then((res) => {
            switch (res.data.result) {
                case -2:
                    addNotification(localization[lang].errors.error2, 2);
                    break;
                case -3:
                    addNotification(localization[lang].errors.error3, 2);
                    break;
                default:
                    axios.post('/api/stock/receipt/counted', { token: token, did: this.state.did }).then((res) => {
                        this.setState({
                            //text: "",
                            //check: null,
                            
                            counted: res.data.rows
                        }, () => {
                            //document.getElementById('textInput').focus();
                        });
                    }).catch((error) => {
                        addNotification(error.response.data, error.response.status);
                    }).finally(() => {
                        //removeNotification(u);
                    });
                    break;
            }
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(() => {
            removeNotification(u);
        });
    }

    beep(status) {
        if (status) {
            var snd = new Audio(BeepSound);  
            snd.play();
        } else {
            var snd = new Audio(ErrorSound); 
            snd.play();
        }
    }

    save() {
        const {addNotification, removeNotification, token, lang} = this.context;

        var u = addNotification("Завантаження...");

        axios.post('/api/stock/receipt/save', {
            token: token,
            did: this.state.did,
        }).then((res) => {
            switch (res.data.result) {
                // case -2:
                //     addNotification(localization[lang].errors.error2, 2);
                //     break;
                // case -3:
                //     addNotification(localization[lang].errors.error3, 2);
                //     break;
                default:
                    this.setState({redirect: "/stock/receipt/list"});
                    break;
            }
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(() => {
            removeNotification(u);
        });
    }

    changeView(view) {
        const {addNotification, removeNotification, token, lang} = this.context;

        if (process.env.NODE_ENV === 'production') {
            if (view == "counted") {
                var u = addNotification("Завантаження...");
                axios.post('/api/stock/receipt/counted', { token: token, did: this.state.did }).then((res) => {
                    this.setState({view: view, counted: res.data.rows, mismatched: [], result: [], stock: []});
                }).catch((error) => {
                    addNotification(error.response.data, error.response.status);
                }).finally(() => {
                    removeNotification(u);
                });
            } else if (view == "mismatched") {
                var u = addNotification("Завантаження...");
                axios.post('/api/stock/receipt/mismatched', { token: token, did: this.state.did }).then((res) => {
                    this.setState({view: view, counted: [], mismatched: res.data.rows, result: [], stock: []});
                }).catch((error) => {
                    addNotification(error.response.data, error.response.status);
                }).finally(() => {
                    removeNotification(u);
                });
            } else if (view == "result") {
                var u = addNotification("Завантаження...");
                axios.post('/api/stock/receipt/result', { token: token, did: this.state.did }).then((res) => {
                    this.setState({view: view, counted: [], mismatched: [], result: res.data.rows, stock: []});
                }).catch((error) => {
                    addNotification(error.response.data, error.response.status);
                }).finally(() => {
                    removeNotification(u);
                });
            } else {
                var u = addNotification("Завантаження...");
                axios.post('/api/stock/receipt/stock', { token: token, did: this.state.did }).then((res) => {
                    this.setState({view: view, counted: [], mismatched: [], result: [], stock: res.data.rows});
                }).catch((error) => {
                    addNotification(error.response.data, error.response.status);
                }).finally(() => {
                    removeNotification(u);
                });
            }
        } else {
            this.setState({
                view: view, 
                counted: [
                    {
                        did: 79,
                        item: 36,
                        code: "1108017",
                        name: "кронштейн задній правий",
                        total: 3,
                        defective: 0,
                        unit: "шт",
                        active: 0,
                    },
                    {
                        did: 80,
                        item: 37,
                        code: "1108017",
                        name: "кронштейн задній",
                        total: 1,
                        defective: 1,
                        unit: "шт",
                        active: 1,
                    },
                ], 
                mismatched: [
                    {
                        code: "1345278",
                        name: "Кільце гумове, ущільнення кронштейну гальмівного механізму",
                        total: 2,
                        counted: 3,
                        defective: 0,
                        rest: -1,
                        unit: "шт",
                    }
                ], 
                result: [
                    {
                        code: "1345278",
                        name: "Кільце гумове, ущільнення кронштейну гальмівного механізму",
                        total: 2,
                        counted: 1,
                        defective: 0,
                        rest: 1,
                        unit: "шт",
                    }
                ],
                stock: [
                    {
                        item: 1,
                        code: "1345278",
                        name: "Кільце гумове, ущільнення кронштейну гальмівного механізму",
                        base: "A1B2C3",
                        address: "A1B2C3",
                        total: 2,
                        located: 1,
                    },
                    {
                        item: 2,
                        code: "1345278",
                        name: "Кільце гумове, ущільнення кронштейну гальмівного механізму",
                        base: "A1B2C3",
                        address: "",
                        total: 2,
                        located: 0,
                    },
                    {
                        item: 3,
                        code: "1345278",
                        name: "Кільце гумове, ущільнення кронштейну гальмівного механізму",
                        base: "A1B2C3",
                        address: "",
                        total: 2,
                        located: 0,
                    }
                ]
            });
        }
    }

    renderCountedList() {
        const {addNotification, removeNotification, token, lang} = this.context;

        if (this.state.counted && this.state.counted.length > 0) {
            return this.state.counted.map((item, i) => {
                return (
                    <div className="agis-card mb-2" style={item.active == 0 ? {backgroundColor: "#eee"} : {}}>
                        <div class="d-flex justify-content-between">
                            <div className="row">
                                <div className="col-12 mb-1">
                                    <h6><b>{item.name}</b></h6>
                                </div>
                                <div className="col-6">
                                    <span>{item.code}</span>
                                </div>
                                {/* <div className="col text-center">
                                    <span>{item.location}</span>
                                </div> */}
                                <div className="col-6 text-right">
                                    <span>{item.defective > 0 && <FontAwesomeIcon icon={faTimes} className="text-danger" />} <b>{item.total}</b> {item.unit}</span>
                                </div>
                            </div>
                            <div className="align-middle pl-3" style={{display: "flex", alignItems: "center"}}>
                                {item.active == 1 ? (
                                    <button className="btn-agis-light-outline" style={{height: '30px', width: "30px", lineHeight: "22px", minWidth: "0px"}} onClick={() => this.activate(item.item, false)}><FontAwesomeIcon icon={faTimes} style={{fontSize: "14px"}} /></button>
                                ) : (
                                    <button className="btn-agis-light-outline" style={{height: '30px', width: "30px", lineHeight: "22px", minWidth: "0px"}} onClick={() => this.activate(item.item, true)}><FontAwesomeIcon icon={faCheck} style={{fontSize: "14px"}} /></button>
                                )}
                            </div>
                        </div>
                    </div>
                );
            });
        }
    }

    renderMismatchedList() {
        const {addNotification, removeNotification, token, lang} = this.context;

        if (this.state.mismatched && this.state.mismatched.length > 0) {
            return this.state.mismatched.map((item, i) => {
                return (
                    <div className="agis-card mb-2" style={item.active == 0 ? {backgroundColor: "#eee"} : {}}>
                        <div class="d-flex justify-content-between">
                            <div className="row">
                                <div className="col-12 mb-1">
                                    <h6><b>{item.name}</b></h6>
                                </div>
                                <div className="col-6">
                                    <big className="mb-1"><small className="text-muted">{localization[lang].code + ":"}</small> <b>{item.code}</b></big>
                                </div>
                                <div className="col-6 text-right">
                                    <big className="mb-1"><small className="text-muted">{localization[lang].result.total + ":"}</small> <b>{item.total}</b></big>
                                </div>
                                <div className="col-4">
                                    <big><small className="text-muted">{localization[lang].result.defective + ":"}</small> <b>{item.defective}</b></big>
                                </div>
                                <div className="col-4 text-center">
                                    <big><small className="text-muted">{localization[lang].result.rest + ":"}</small> <b>{item.rest}</b></big>
                                </div>
                                <div className="col-4 text-right">
                                    <big><small className="text-muted">{localization[lang].result.counted + ":"}</small> <b>{item.counted}</b></big>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
        }
    }

    renderResultList() {
        const {addNotification, removeNotification, token, lang} = this.context;

        if (this.state.result && this.state.result.length > 0) {
            return this.state.result.map((item, i) => {
                return (
                    <div className="agis-card mb-2" style={item.active == 0 ? {backgroundColor: "#eee"} : {}}>
                        <div class="d-flex justify-content-between">
                            <div className="row">
                                <div className="col-12 mb-1">
                                    <h6><b>{item.name}</b></h6>
                                </div>
                                <div className="col-6">
                                    <big className="mb-1"><small className="text-muted">{localization[lang].code + ":"}</small> <b>{item.code}</b></big>
                                </div>
                                <div className="col-6 text-right">
                                    <big className="mb-1"><small className="text-muted">{localization[lang].result.total + ":"}</small> <b>{item.total}</b></big>
                                </div>
                                <div className="col-4">
                                    <big><small className="text-muted">{localization[lang].result.defective + ":"}</small> <b>{item.defective}</b></big>
                                </div>
                                <div className="col-4 text-center">
                                    <big><small className="text-muted">{localization[lang].result.rest + ":"}</small> <b>{item.rest}</b></big>
                                </div>
                                <div className="col-4 text-right">
                                    <big><small className="text-muted">{localization[lang].result.counted + ":"}</small> <b>{item.counted}</b></big>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
        }
    }

    renderStockList() {
        const {addNotification, removeNotification, token, lang} = this.context;

        if (this.state.stock && this.state.stock.length > 0) {
            return this.state.stock.map((item, i) => {
                return (
                    <div className="agis-card mb-2" style={item.located == 1 ? {backgroundColor: "#d4edda"} : {}}>
                        <div class="d-flex justify-content-between">
                            <div className="row">
                                <div className="col-12 mb-1">
                                    <h6><b>{item.name}</b></h6>
                                </div>
                                <div className="col-8">
                                    <big className="mb-1"><small className="text-muted">{localization[lang].code + ":"}</small> <b>{item.code}</b></big>
                                </div>
                                <div className="col-4 text-right">
                                    <h6 className="mb-1"><small className="text-muted">{localization[lang].stock.total + ":"}</small> <b>{item.total}</b></h6>
                                </div>
                                <div className="col-12">
                                    <h6><small className="text-muted">{localization[lang].stock.base + ":"}</small> <b className="text-info">{item.base}</b></h6>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex justify-content-between align-items-center mt-1">
                                        <input
                                            type="text"
                                            className="agis-input w-100"
                                            style={{lineHeight: "15px", fontSize: "1rem"}}
                                            value={item.address}
                                            onChange={(e) => this.onAddressChange(e, item.item)}
                                        />
                                        <ScanButton onScan={this.scanAddress} param={item.item} style={{marginLeft: '0px!important', height: '30px', width: "50px", minWidth: "0px", borderRadius: "5px", fontSize: "12px", lineHeight: "30px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 0 0 1px rgba(0,0,0,.05), 0 2px 3px 0 rgba(0,0,0,.1)"}} />
                                    
                                        <button onClick={() => this.addressSet(item.item, 0)} className="a-agis-light-outline ml-4" style={{height: '30px', width: "50px", minWidth: "40px", borderRadius: "5px", fontSize: "18px", lineHeight: "22px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 0 0 1px rgba(0,0,0,.05), 0 2px 3px 0 rgba(0,0,0,.1)"}}><FontAwesomeIcon icon={faTimes} className="text-danger" /></button>
                                        <button onClick={() => this.addressSet(item.item, 1)} className="a-agis-light-outline ml-2" style={{height: '30px', width: "50px", minWidth: "40px", borderRadius: "5px", fontSize: "18px", lineHeight: "22px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 0 0 1px rgba(0,0,0,.05), 0 2px 3px 0 rgba(0,0,0,.1)"}}><FontAwesomeIcon icon={faCheck} className="text-success" /></button>
                                    </div>
                                </div>
                                {(item.error && item.error != "") && (
                                    <div className="col-12 mt-2">
                                        <span style={{fontSize: "1rem"}}><span className="text-danger"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation-triangle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-exclamation-triangle fa-w-18 fa-1x"><path fill="currentColor" d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z" class=""></path></svg></span> <b>{item.error}</b></span>
                                    </div>
                                )}
                                
                            </div>
                        </div>
                    </div>
                );
            });
        }
    }

    onAddressChange(event, item) {
        const list = this.state.stock
        list[list.findIndex(i => i.item == item)].address = event.target.value
        this.setState({stock: list});
    }

    scanAddress(value, item) {
        const list = this.state.stock
        list[list.findIndex(i => i.item == item)].address = value
        this.setState({stock: list});
    }

    addressSet(item, locate) {
        const {addNotification, removeNotification, token, lang} = this.context;

        const list = this.state.stock

        var u = addNotification("Завантаження...");

        axios.post('/api/stock/receipt/locate', {
            token: token,
            did: this.state.did,
            item: item,
            address: list[list.findIndex(i => i.item == item)].address,
            locate: locate,
        }).then((res) => {
            list[list.findIndex(i => i.item == item)].error = res.data.error
            if (res.data.error === "") {
                list[list.findIndex(i => i.item == item)].base = res.data.base;
                list[list.findIndex(i => i.item == item)].located = locate;
            }
            this.setState({stock: list});
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(() => {
            removeNotification(u);
        });
    }

    render() {
        const {lang} = this.context;

        if (this.state.redirect != null) {
            return <Redirect push to={this.state.redirect} />
        }

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                {this.state.isLoading && (
                    <div className="text-center">
                    </div>
                )}
                
                <div className="container-fluid" style={this.state.isLoading ? {display: "none"} : {}}>
                    <div className="row">
                        <div className="col-md-10 offset-md-1">

                            {this.state.header && (
                                <div className="d-flex align-items-center justify-content-between mb-2">
                                    <div className="row">
                                        <div className="col d-flex align-items-center">
                                            <small style={{color: "#6f42c1"}}>{this.state.header.name}</small>
                                        </div>
                                    </div>
                                    <button onClick={this.cameraToogle} className={this.state.isCameraOn ? "btn-agis-primary-outline" : "btn-agis-light-outline"} style={{height: "24px", lineHeight: "16px", width: '40px', minWidth: "0px", fontSize: "12px"}}><FontAwesomeIcon icon={faCamera} className="text-muted" /></button>
                                </div>
                            )}

                            {(this.state.videoError == null && this.state.isCameraOn) && (
                                <div className="col">
                                    <video ref={this.video} className="w-100" autoPlay></video>
                                </div>
                            )}

                            {(this.state.videoError != null || !this.state.isCameraOn) && (
                                <div className="agis-card mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="w-100">
                                            <span className="text-muted">{(!this.state.check || this.state.check.ok == 0) ? localization[lang].code : localization[lang].total}</span>
                                            <input
                                                id="textInput"
                                                type={(!this.state.check || this.state.check.ok == 0) ? "search" : "number"}
                                                className="w-100"
                                                style={{border: "0", color: "#464a4e", fontSize: "14px", lineHeight: "15px", fontSize: "1.1rem"}}
                                                value={this.state.text}
                                                onChange={this.onChange}
                                                //onBlur={this.onRemarkBlur}
                                                onKeyPress={this.onKeyPress}
                                                onPaste={this.onPaste}
                                                //autoFocus={true}
                                            />
                                        </div>
                                        {this.state.isChecking ? (
                                            <div style={{height: "20px", width: "40px", textAlign: "center"}}>
                                                <ScaleLoader height="20px" width="3px" radius="3px" margin="2px" color={"#4F81BC"} />
                                            </div>
                                        ) : (
                                            <div style={{height: "20px", width: "40px", textAlign: "center"}}>
                                                <button onClick={() => this.setState({ isChecking: true }, () => { this.onCheck() })} className="text-muted" style={{height: "20px", minWidth: "20px", fontSize: "18px", border: "0", backgroundColor: "#fff"}}><FontAwesomeIcon icon={faSearch} /></button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {(this.state.videoError != null || !this.state.isCameraOn) && (
                                this.state.check && (
                                    this.state.check.ok == 1 ? (
                                        <div className="agis-card mb-3">
                                            <div className="row">
                                                <div className="col-12 mb-1">
                                                    <h6><b>{this.state.check.name}</b></h6>
                                                </div>
                                                <div className="col-6">
                                                    <big className="mb-1"><small className="text-muted">{localization[lang].code + ":"}</small> <b>{this.state.check.code}</b></big>
                                                </div>
                                                <div className="col-6 text-right">
                                                    <big className="mb-1"><small className="text-muted">{localization[lang].check.total + ":"}</small> <b>{this.state.check.total}</b></big>
                                                </div>
                                                <div className="col-4">
                                                    <big><small className="text-muted">{localization[lang].check.stock + ":"}</small> <b>{this.state.check.stock}</b></big>
                                                </div>
                                                <div className="col-4 text-center">
                                                    <big><small className="text-muted">{localization[lang].check.client + ":"}</small> <b>{this.state.check.client}</b></big>
                                                </div>
                                                <div className="col-4 text-right">
                                                    <big><small className="text-muted">{localization[lang].check.counted + ":"}</small> <b>{this.state.check.counted}</b></big>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <button onClick={this.discard} className="btn-agis-light-outline" style={{width: "48%"}}><FontAwesomeIcon icon={faUndo} className="text-warning" /> {localization[lang].discard}</button>
                                                <button onClick={this.defective} className="btn-agis-light-outline" style={{width: "48%"}}><FontAwesomeIcon icon={faTimes} className="text-danger" /> {localization[lang].defective}</button>
                                                <button onClick={this.add} className="btn-agis-light-outline" style={{width: "48%"}}><FontAwesomeIcon icon={faSave} className="text-success" /> {localization[lang].add}</button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="alert alert-danger mb-2">{this.state.check.error}</div>
                                    )
                                )
                            )}

                            <div className="d-flex justify-content-between mt-2 mb-2">
                                <button onClick={() => {this.changeView("counted")}} className={this.state.view == "counted" ? "btn-agis-primary-outline" : "btn-agis-light-outline"} style={{height: "24px", lineHeight: "16px", width: '33%', minWidth: "0px", fontSize: "11px"}}>{localization[lang].counted}</button>
                                <button onClick={() => {this.changeView("mismatched")}} className={this.state.view == "mismatched" ? "btn-agis-primary-outline" : "btn-agis-light-outline"} style={{height: "24px", lineHeight: "16px", width: '33%', minWidth: "0px", fontSize: "11px"}}>{localization[lang].mismatched}</button>
                                <button onClick={() => {this.changeView("result")}} className={this.state.view == "result" ? "btn-agis-primary-outline" : "btn-agis-light-outline"} style={{height: "24px", lineHeight: "16px", width: '33%', minWidth: "0px", fontSize: "11px"}}>{localization[lang].results}</button>
                                <button onClick={() => {this.changeView("stock")}} className={this.state.view == "stock" ? "btn-agis-primary-outline" : "btn-agis-light-outline"} style={{height: "24px", lineHeight: "16px", width: '33%', minWidth: "0px", fontSize: "11px"}}>{localization[lang].stocks}</button>

                            </div>

                            {this.state.view == "counted" ? (
                                this.renderCountedList()
                            ) : this.state.view == "mismatched" ? (
                                this.renderMismatchedList()
                            ) : this.state.view == "result" ? (
                                <div>
                                    {this.renderResultList()}
                                    <button className="btn-agis-primary w-100" onClick={this.save}>{localization[lang].save}</button>
                                </div>
                            ) : (
                                this.renderStockList()
                            )}

                            <br/>
                        </div>
                    </div>
                </div>

                {(this.state.videoError == null && this.state.isCameraOn) && (
                    <div className="fixed-bottom pt-3 pb-3" style={{zIndex: 299, backgroundColor: "#F1F3F4", boxShadow: "0px -1px 0px rgba(0,0,0,.15)"}}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-10 offset-md-1">
                                    <div className="container-fluid pl-0 pr-0">

                                        {this.state.check && (
                                            this.state.check.ok == 1 ? (
                                                <div className="agis-card mb-2">
                                                    <div className="row">
                                                        <div className="col-12 mb-1">
                                                            <h6><b>{this.state.check.name}</b></h6>
                                                        </div>
                                                        <div className="col-6">
                                                            <big className="mb-1"><small className="text-muted">{localization[lang].code + ":"}</small> <b>{this.state.check.code}</b></big>
                                                        </div>
                                                        <div className="col-6 text-right">
                                                            <big className="mb-1"><small className="text-muted">{localization[lang].check.total + ":"}</small> <b>{this.state.check.total}</b></big>
                                                        </div>
                                                        <div className="col-4">
                                                            <big><small className="text-muted">{localization[lang].check.stock + ":"}</small> <b>{this.state.check.stock}</b></big>
                                                        </div>
                                                        <div className="col-4 text-center">
                                                            <big><small className="text-muted">{localization[lang].check.client + ":"}</small> <b>{this.state.check.client}</b></big>
                                                        </div>
                                                        <div className="col-4 text-right">
                                                            <big><small className="text-muted">{localization[lang].check.counted + ":"}</small> <b>{this.state.check.counted}</b></big>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mt-1">
                                                        <button onClick={this.discard} className="btn-agis-light-outline" style={{width: "48%"}}><FontAwesomeIcon icon={faUndo} className="text-warning" /> {localization[lang].discard}</button>
                                                        <button onClick={this.defective} className="btn-agis-light-outline" style={{width: "48%"}}><FontAwesomeIcon icon={faTimes} className="text-danger" /> {localization[lang].defective}</button>
                                                        <button onClick={this.add} className="btn-agis-light-outline" style={{width: "48%"}}><FontAwesomeIcon icon={faSave} className="text-success" /> {localization[lang].add}</button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="alert alert-danger mb-2">{this.state.check.error}</div>
                                            )
                                        )}
                                    
                                        <div className="agis-card">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="w-100">
                                                    <span className="text-muted">{(!this.state.check || this.state.check.ok == 0) ? localization[lang].code : localization[lang].total}</span>
                                                    <input
                                                        id="textInput"
                                                        type={(!this.state.check || this.state.check.ok == 0) ? "search" : "number"}
                                                        className="w-100"
                                                        style={{border: "0", color: "#464a4e", fontSize: "14px", lineHeight: "15px", fontSize: "1.1rem"}}
                                                        value={this.state.text}
                                                        onChange={this.onChange}
                                                        //onBlur={this.onRemarkBlur}
                                                        onKeyPress={this.onKeyPress}
                                                        onPaste={this.onPaste}
                                                        //autoFocus={true}
                                                    />
                                                </div>
                                                {this.state.isChecking ? (
                                                    <div style={{height: "20px", width: "40px", textAlign: "center"}}>
                                                        <ScaleLoader height="20px" width="3px" radius="3px" margin="2px" color={"#4F81BC"} />
                                                    </div>
                                                ) : (
                                                    <div style={{height: "26px", width: "40px", textAlign: "center"}}>
                                                        <button onClick={this.onScan} className="text-muted" style={{height: "26px", minWidth: "30px", fontSize: "18px", border: "0", backgroundColor: "#fff"}}><svg style={{fontSize: "26px"}} aria-hidden="true" focusable="false" data-prefix="fal" data-icon="barcode-read" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="svg-inline--fa fa-barcode-read fa-w-20 fa-1x"><path fill="currentColor" d="M152 0H8C3.6 0 0 3.6 0 8v152c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V32h120c4.4 0 8-3.6 8-8V8c0-4.4-3.6-8-8-8zm0 480H32V352c0-4.4-3.6-8-8-8H8c-4.4 0-8 3.6-8 8v152c0 4.4 3.6 8 8 8h144c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zM632 0H488c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h120v128c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V8c0-4.4-3.6-8-8-8zm0 344h-16c-4.4 0-8 3.6-8 8v128H488c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h144c4.4 0 8-3.6 8-8V352c0-4.4-3.6-8-8-8zM152 96h-48c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8zm336 320h48c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8zM408 96h-48c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8zm-192 0h-16c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8zm64 0h-16c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8z"></path></svg></button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}


            </div>
        )
    }
}

export default Recount;