import React from 'react'
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faPause, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../AppContext.js';
import OffsetTop from '../../../components/offsetTop.js';

const localization = {
    ua: {
        title: 'Стемпiнг івенти',
        groups: 'Групи статусів',
        noData: 'Данi вiдсутнi'
    },
    ru: {
        title: 'Стемпiнг івенти',
        groups: 'Группы статусов',
        noData: 'Нет данных'
    },
    en: {
        title: 'Stamping events',
        groups: 'Status groups',
        noData: 'No data'
    },
}

class Pin extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            redirect: null,

            isLoading: true,
            gid: null,
            groups: []
        }

        this.loadList = this.loadList.bind(this);

        this.groupColor = this.groupColor.bind(this);
        this.groupIcon = this.groupIcon.bind(this);
        //this.stampingChange = this.stampingChange.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        this.loadList();
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.gid !== prevProps.match.params.gid) {
            if (this.props.match.params.gid == undefined) {
                this.setState({gid: null});
            } else {
                this.setState({gid: Number(this.props.match.params.gid)});
            }
        }
    }

    loadList() {
        const {addNotification, removeNotification, token} = this.context;

        var gid = null;
        if (this.props.match.params.gid != undefined) {
            gid =  Number(this.props.match.params.gid);
        }

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/stamping/statuses', { token: token }).then((res) => {
                var groups = [];

                res.data.rows.forEach((item) => {
                    var index = groups.findIndex(i => i.gid === item.gid);

                    if (index < 0) {
                        groups.push({gid: item.gid, gname: item.gname, statuses: [{sid: item.sid, sname: item.sname, description: item.description}]});
                    } else {
                        groups[index].statuses.push({sid: item.sid, sname: item.sname, description: item.description});
                    }
                });

                this.setState({
                    gid: gid,
                    isLoading: false,
                    groups: groups
                });
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            this.setState(
                {
                    gid: gid,
                    isLoading: false,
                    groups: [
                        { 
                            gid: 1, 
                            gname: 'Призупинення', 
                            statuses: [
                                { sid: 1, sname: 'Немає роботи', description: "Очікування, вільний час" },
                                { sid: 2, sname: 'Обід', description: "" },
                                { sid: 3, sname: 'Очікування видачі ЗЧ', description: "" },
                                { sid: 4, sname: 'Перерва в роботі', description: "Відпочинок" },
                                { sid: 5, sname: 'ТО, ремонт автомобіля', description: "" },
                            ]
                        },
                        { 
                            gid: 3, 
                            gname: 'Дорога', 
                            statuses: [
                                { sid: 1, sname: 'Немає роботи', description: "Очікування, вільний час" },
                            ]
                        },
                        { 
                            gid: 5, 
                            gname: 'Адміністративні', 
                            statuses: [
                                { sid: 1, sname: 'Немає роботи', description: "Очікування, вільний час" },
                            ]
                        },
                        { 
                            gid: 6, 
                            gname: 'Закінчення', 
                            statuses: [
                                { sid: 1, sname: 'Немає роботи', description: "Очікування, вільний час" },
                            ]
                        },
                    ],
                },
            );
        }
    }

    groupColor(gid) {
        switch(gid) {
            case 1:
                return "#ffab00";
            default:
                return "#b0bec5";
          }
    }

    groupIcon(gid) {
        switch(gid) {
            case 1:
                return faPause;
            default:
                return faMinus;
          }
    }

    stampingChange(reason) {
        const {addNotification, removeNotification, token} = this.context;

        var u = addNotification("Завантаження...");
        axios.post('/api/order/stamping/change', { 
            token: token, 
            status: 2,
            reason: reason,
            did: 0,
            item: 0
        }).then((res) => {
            this.setState({redirect: "/order/stamping/history"});
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    renderList() {
        const {lang} = this.context;

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            )
        }

        if (this.state.groups.length === 0) {
            return (
                <div className="row">
                    <div className="col">
                        <div className="card agis-card">
                            <div className="row">
                                <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                    <div>
                                        <h6><b>{localization[lang].noData}</b></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return this.state.groups.map((item, i) => {
                return (
                    <div className="row">
                        <div className="col">
                            <Link to={"/order/stamping/pin/" + item.gid} className="card agis-card position-component pt-1 pb-1">
                                <div class="d-flex justify-content-start">
                                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "40px"}}>
                                        <h6 className="pr-1" style={{color: this.groupColor(item.gid)}}><FontAwesomeIcon icon={this.groupIcon(item.gid)}/></h6>
                                    </div>
                                    <div className="row">
                                        <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                            <div>
                                                <h6><b>{item.gname}</b></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                );
            })
        }
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.redirect !== null) {
            return <Redirect to={this.state.redirect} />
        }

        if (this.state.gid != null) {
            var i = this.state.groups.findIndex((item) => {
                return item.gid == this.state.gid
            });

            var group = this.state.groups[i];

            return (
                <div>
                    <DocumentMeta {...meta} />
                    <OffsetTop submenu={false} />
    
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="container-fluid pl-0 pr-0">
                                    <div className="row mb-2">
                                        <div className="col">
                                            {/* <h3>{localization[lang].tasks}</h3> */}
                                            <h3>{group.gname}</h3>
                                        </div>
                                    </div>

                                    {group.statuses.map((item, i) => {
                                        return (
                                            <div className="row">
                                                <div className="col">
                                                    <a className="card agis-card position-component pt-1 pb-1" onClick={(e) => this.stampingChange(item.sid)}>
                                                        <div class="d-flex justify-content-between">
                                                            <div className="row mb-1">
                                                                <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                                                    <div>
                                                                        <h6><b>{item.sname}</b></h6>
                                                                        {item.description != "" && (
                                                                            <div className="row">
                                                                                <div className="col">
                                                                                    {item.description}
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{display: "flex", alignItems: "center"}}>
                                                                <span className="ml-1 mr-1"><FontAwesomeIcon icon={faPlus}/></span>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="fixed-bottom text-right" style={{zIndex: "4", paddingRight: "10px", paddingBottom: "10px", height: "60px"}}>
                        <Link to={"/order/stamping/pin"} className="a-agis-light-outline" style={{height: '50px', width: "50px", minWidth: "0px", borderRadius: "25px", lineHeight: "44px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 1px 1px rgba(0,0,0,.15)"}}><FontAwesomeIcon icon={faList} style={{fontSize: "20px"}} /></Link>
                    </div>
                
                </div>
            )
        }

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="container-fluid pl-0 pr-0">
                                <div className="row mb-2">
                                    <div className="col">
                                        <h3>{localization[lang].groups}</h3>
                                    </div>
                                </div>

                                {this.renderList()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Pin;