import React from 'react';
import AppContext from '../AppContext';

class OffsetTop extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
		}
    }

    render() {
        const {scale} = this.context;

        if (this.props.submenu) {
            if (scale == 'xs') {
                return (
                    <div style={{height: '75px'}}>
                        <div className="fixed-top" style={{zIndex: "1", height: '50px', boxShadow: "0 1px 6px 0 rgba(32,33,36,0.28)"}}>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div style={{height: '115px'}}>
                        <div className="fixed-top" style={{zIndex: "1", height: '90px', boxShadow: "0 1px 6px 0 rgba(32,33,36,0.28)"}}>
                        </div>
                    </div>
                )
            }
        }

        return (
            <div style={{height: '75px'}}>
                <div className="fixed-top" style={{zIndex: "1", height: '50px', boxShadow: "0 1px 6px 0 rgba(32,33,36,0.28)"}}>
                </div>
            </div>
        )
    }
}

export default OffsetTop;