import React from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../AppContext.js';
import OffsetTop from '../../../components/offsetTop.js';

const localization = {
    ua: {
        title: 'Акти приймання техніки',
        searchText: 'Пошук...',
        noData: 'Данi вiдсутнi',
    },
    ru: {
        title: 'Акты приёма техники',
        searchText: 'Поиск...',
        noData: 'Нет данных',
    },
    en: {
        title: 'Vehicle acceptance acts',
        searchText: 'Filter...',
        noData: 'No data',
    },
}

class List extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            searchText: '',
            type: '',
            rows: []
        }

        this.searchTextChange = this.searchTextChange.bind(this);
        this.searchTextKeyPress = this.searchTextKeyPress.bind(this);
        this.searchTextClear = this.searchTextClear.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        this.loadList();
    }

    loadList() {
        const {addNotification, removeNotification, token} = this.context;
        
        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/order/list', { token: token, type: '2' }).then((res) => {
                this.setState({rows: res.data.rows});
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            this.setState({rows: [
                { did: "221", l1: "AA9921PAб DAF FTX105", l2: "ЗН №1900346, [740] - Сухоцький Игорь Олегович" },
                { did: "221", l1: "AA9921PAб DAF FTX105", l2: "ЗН №1900346, [740] - Сухоцький Игорь Олегович" },
                { did: "221", l1: "AA9921PAб DAF FTX105", l2: "ЗН №1900346, [740] - Сухоцький Игорь Олегович" }
            ]});
        }
    }

    searchTextChange(e) {
        this.setState({searchText: e.target.value});
    }
    
    searchTextKeyPress(e) {
        if (e.key === 'Enter') {
            e.target.blur();
        }
    }

    searchTextClear() {
        this.setState({searchText: ''});
    }

    renderList() {
        const {lang} = this.context;

        if (this.state.rows.length === 0) {
            return (
                <div className="row">
                    <div className="col">
                        <div className="card agis-card">
                            <div className="row">
                                <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                    <div>
                                        <h6><b>{localization[lang].noData}</b></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return this.state.rows.filter((item, i) => {
                return item.l1.toLowerCase().includes(this.state.searchText.toLowerCase()) || item.l2.toLowerCase().includes(this.state.searchText.toLowerCase())
            }).map((item, i) => {
                return (
                    <div className="row">
                        <div className="col">
                            <Link to={'/order/control-list-manager/' + item.did} className="card agis-card position-component">
                                <div class="d-flex justify-content-between">
                                    <div className="row">
                                        <div className="col">
                                            <big><b>{item.l1}</b></big><br/>
                                            <big>{item.l2}</big>
                                        </div>
                                    </div>
                                    <div className="align-middle" style={{display: "flex", alignItems: "center"}}>
                                        <span className="mr-1"><FontAwesomeIcon icon={faChevronRight}/></span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                );
            });
        }
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="container-fluid pl-0 pr-0">
                                <div className="row mb-4">
                                    <div className="col">
                                        <input type="text" className="input-agis-block" style={{width: '100%'}} placeholder={localization[lang].searchText} value={this.state.searchText} onChange={(e) => this.searchTextChange(e)} onKeyPress={(e) => this.searchTextKeyPress(e)} />
                                    </div>
                                </div>
                                {this.renderList()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default List;