export function dateToInputFormat(date) {
    var d = new Date(date);
    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    var year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
        
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

export function dateToDatabaseFormat(date) {
    var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day, month, year].join('.');
}

export function monthToInputFormat(date) {
    var d = new Date(date);
    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    var year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
        
    if (day.length < 2) 
        day = '0' + day;

    return [year, month].join('-');
}

export function monthToDatabaseFormat(date) {
    var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return ["01", month, year].join('.');
}

export function databaseFormatToDate(date) {
    var d = date.split(".");
    return new Date(d[2], d[1] -1, d[0]);
}