import React from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';
import ScaleLoader from "react-spinners/ScaleLoader";

import AppContext from '../../../AppContext.js';
import Submenu from '../../../components/submenu.js';
import SubmenuContent from './submenu.js';
import OffsetTop from '../../../components/offsetTop.js';

import {dateToInputFormat, databaseFormatToDate, dateToDatabaseFormat} from '../../../utils/datetime.js';

const localization = {
    ua: {
        title: 'Driver Feedback',
        customer: {
            title: 'Інформація про клієнта',
            driver: 'Водій',
            company: 'Компанія',
            phone: 'Телефон',
            vehicle: 'Авто',
            stateNumber: 'Реєстраційний номер',
            workshop: 'Дилер',
            visitDate: 'Дата візиту',
            interviewDate: 'Дата опитування',
        },
        scores: {
            title: 'Результати опитування',
            recommendation: 'Рекомендація',
            satisfaction: 'Задоволення',
            remark: 'Відповідь',
        },
        action: {
            title: 'Розгляд',
            actionDate: 'Дата',
            actionName: "Им'я",
            actionRemark: 'Коментар / заходи',
            reasons: 'Причини невдоволення',
            reason1: 'Привітання та прийом',
            reason2: 'Наявність послуги (можливість зарезервувати візит)',
            reason3: 'Час ремонту',
            reason4: 'Якість ремонту (повторне відвідування, неповне тощо)',
            reason5: 'Технічні / комерційні навички персоналу',
            reason6: 'Стан транспортного засобу при поверненні',
            reason7: 'Ціна',
            reason8: 'Інформація (про роботу, затримки, додаткові роботи / витрати)',
            reason9: 'Години роботи',
            reason10: 'Інше (дайте короткий опис у вікні нижче)',
            statusQuestion: 'Відгук розглянуто?',
            statusText: 'Так, розглянуто',
            saveButton: 'Зберегти',
        }
    },
    ru: {
        title: 'Driver Feedback',
        customer: {
            title: 'Информация о клиенте',
            driver: 'Водитель',
            company: 'Компания',
            phone: 'Телефон',
            vehicle: 'Авто',
            stateNumber: 'Регистрационный номер',
            workshop: 'Дилер',
            visitDate: 'Дата визита',
            interviewDate: 'Дата опроса',
        },
        scores: {
            title: 'Оценки опроса',
            recommendation: 'Рекомендация',
            satisfaction: 'Удовлетворение',
            remark: 'Ответ',
        },
        action: {
            title: 'Рассмотрение',
            actionDate: 'Дата',
            actionName: 'Имя',
            actionRemark: 'Комментарий / меры',
            reasons: 'Причины недовольства',
            reason1: 'Приветствия и прием',
            reason2: 'Доступность услуги (возможность записи на визит)',
            reason3: 'Время ремонта',
            reason4: 'Качество ремонта (повторный визит, неполный и т.д)',
            reason5: 'Технические / коммерческие навыки персонала мастерской',
            reason6: 'Состояние автомобиля при возврате',
            reason7: 'Цена',
            reason8: 'Информация (о работе, задержках, дополнительных работах / затратах)',
            reason9: 'Часы работы',
            reason10: 'Другое (дайте короткое описание в окне ниже)',
            statusQuestion: 'Рассмотрение закрыто?',
            statusText: 'Да, закрыто',
            saveButton: 'Сохранить',
        }
    },
    en: {
        title: 'Driver Feedback',
        customer: {
            title: 'Customer detail',
            driver: 'Driver',
            company: 'Company',
            phone: 'Phone',
            vehicle: 'Vehicle',
            stateNumber: 'Registration number',
            workshop: 'Workshop',
            visitDate: 'Date of visit',
            interviewDate: 'Date of interview',
        },
        scores: {
            title: 'Survey scores',
            recommendation: 'Recommendation',
            satisfaction: 'Satisfaction',
            remark: 'Text answer',
        },
        action: {
            title: 'Your action',
            actionDate: 'Date',
            actionName: 'Name',
            actionRemark: 'Comments / action',
            reasons: 'Reasons for dissatisfaction',
            reason1: 'Greetings & reception',
            reason2: 'Availability of service (possibility to book appointment)',
            reason3: 'Repair time',
            reason4: 'Repair quality (repeat visit, incomplete etc)',
            reason5: 'Technical / commercial skills of workshop staff',
            reason6: 'Condition of vehicle at return',
            reason7: 'Price',
            reason8: 'Information (about work, delays, additional work / costs)',
            reason9: 'Opening hours',
            reason10: 'Other (give a short description in the box below)',
            statusQuestion: 'Is card closed?',
            statusText: 'Yes, closed',
            saveButton: 'Save',
        }
    },
}

class ResponseCard extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            redirect: null,

            isLoading: true,

            nps: null,
            csi: null,
            remark: null,
        }

        this.changeRemark = this.changeRemark.bind(this);

        this.save = this.save.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        this.load();
    }

    load() {
        const {addNotification, removeNotification, token} = this.context;

        var id = Number(this.props.match.params.id);

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/crm/driver-feedback/response-card/card', { token: token, id: id }).then((res) => {
                this.setState({
                    isLoading: false,

                    id: id,
                    
                    hash: res.data.rows[0].hash,
                    driver: res.data.rows[0].driver,
                    company: res.data.rows[0].company,
                    phone: res.data.rows[0].phone,
                    vehicle: res.data.rows[0].vehicle,
                    stateNumber: res.data.rows[0].stateNumber,
                    workshop: res.data.rows[0].workshop,
                    visitDate: res.data.rows[0].visitDate,
                    interviewDate: res.data.rows[0].interviewDate,
                });
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            this.setState({
                isLoading: false,

                id: id,

                hash: "RE77DF",
                driver: "Vasia",
                company: "Silpo",
                phone: "0508673516",
                vehicle: "Scania",
                stateNumber: "AA2231KA",
                workshop: "Kyiv",
                visitDate: "2020-01-25",
                interviewDate: "2020-01-25",
            });
        }
    }

    changeRemark(event) {
        this.setState({remark: event.target.value});
    }

    save() {
        const {addNotification, removeNotification} = this.context;

        var u = addNotification("Завантаження...");
        axios.post('/api/order/feedback/save', {
            id: this.state.hash,
            mark1: this.state.nps,
            mark2: this.state.csi,
            remark: this.state.remark,
        }).then((res) => {
            this.setState({redirect: "/crm/driver-feedback/text-answers"});
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    render() {
        const {lang} = this.context;

        if (this.state.redirect !== null) {
            return <Redirect to={this.state.redirect} />
        }

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.isLoading) {
            return (
                <div></div>
            )
        }

        return (
            <div>
                <DocumentMeta {...meta} />

                <Submenu>
                    <SubmenuContent />
                </Submenu>

                <br/><br/>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">

                            <div className="container-fluid pb-4 mb-4">
                                <div className="row">
                                    <div className="col">
                                        <div className="agis-card pt-2 pb-2 pb-4 mb-4">
                                            <h4><b>{localization[lang].customer.title}</b></h4>

                                            <div className="row mt-3 mb-2">
                                                <div className="col-5">
                                                    <span className="text-muted" style={{fontSize: "16px"}}>{localization[lang].customer.driver}</span>
                                                </div>
                                                <div className="col">
                                                    <span style={{fontSize: "16px"}}><b>{this.state.driver}</b></span>
                                                </div>
                                            </div>

                                            <div className="row mb-2">
                                                <div className="col-5">
                                                    <span className="text-muted" style={{fontSize: "16px"}}>{localization[lang].customer.company}</span>
                                                </div>
                                                <div className="col">
                                                    <span style={{fontSize: "16px"}}><b>{this.state.company}</b></span>
                                                </div>
                                            </div>

                                            <div className="row mb-2">
                                                <div className="col-5">
                                                    <span className="text-muted" style={{fontSize: "16px"}}>{localization[lang].customer.phone}</span>
                                                </div>
                                                <div className="col">
                                                    <span style={{fontSize: "16px"}}><b>{this.state.phone}</b></span>
                                                </div>
                                            </div>

                                            <div className="row mb-2">
                                                <div className="col-5">
                                                    <span className="text-muted" style={{fontSize: "16px"}}>{localization[lang].customer.vehicle}</span>
                                                </div>
                                                <div className="col">
                                                    <span style={{fontSize: "16px"}}><b>{this.state.vehicle}</b></span>
                                                </div>
                                            </div>

                                            <div className="row mb-2">
                                                <div className="col-5">
                                                    <span className="text-muted" style={{fontSize: "16px"}}>{localization[lang].customer.stateNumber}</span>
                                                </div>
                                                <div className="col">
                                                    <span style={{fontSize: "16px"}}><b>{this.state.stateNumber}</b></span>
                                                </div>
                                            </div>

                                            <div className="row mb-2">
                                                <div className="col-5">
                                                    <span className="text-muted" style={{fontSize: "16px"}}>{localization[lang].customer.workshop}</span>
                                                </div>
                                                <div className="col">
                                                    <span style={{fontSize: "16px"}}><b>{this.state.workshop}</b></span>
                                                </div>
                                            </div>

                                            <div className="row mb-2">
                                                <div className="col-5">
                                                    <span className="text-muted" style={{fontSize: "16px"}}>{localization[lang].customer.visitDate}</span>
                                                </div>
                                                <div className="col">
                                                    <span style={{fontSize: "16px"}}><b>{this.state.visitDate}</b></span>
                                                </div>
                                            </div>

                                            <div className="row mb-2">
                                                <div className="col-5">
                                                    <span className="text-muted" style={{fontSize: "16px"}}>{localization[lang].customer.interviewDate}</span>
                                                </div>
                                                <div className="col">
                                                    <span style={{fontSize: "16px"}}><b>{this.state.interviewDate}</b></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="agis-card pt-2 pb-2">
                                            <h4><b>{localization[lang].scores.title}</b></h4>

                                            <div className="row mt-3 mb-2">
                                                <div className="col">
                                                    <p className="font-weight-bold mb-1">Наскiльки в цiлому Ви задоволенi останнім вiдвiдуванням сервicного центру?</p>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <button type="button" onClick={() => this.setState({csi: 1})} className="btn btn-light mr-2 w-100" style={this.state.csi > 0 ? {backgroundColor: "#3858a0", color: "white"} : {backgroundColor: "#ebebeb", color: "black"}}>1</button>
                                                        <button type="button" onClick={() => this.setState({csi: 2})} className="btn btn-light mr-2 w-100" style={this.state.csi > 1 ? {backgroundColor: "#3858a0", color: "white"} : {backgroundColor: "#ebebeb", color: "black"}}>2</button>
                                                        <button type="button" onClick={() => this.setState({csi: 3})} className="btn btn-light mr-2 w-100" style={this.state.csi > 2 ? {backgroundColor: "#3858a0", color: "white"} : {backgroundColor: "#ebebeb", color: "black"}}>3</button>
                                                        <button type="button" onClick={() => this.setState({csi: 4})} className="btn btn-light mr-2 w-100" style={this.state.csi > 3 ? {backgroundColor: "#3858a0", color: "white"} : {backgroundColor: "#ebebeb", color: "black"}}>4</button>
                                                        <button type="button" onClick={() => this.setState({csi: 5})} className="btn btn-light w-100"      style={this.state.csi > 4 ? {backgroundColor: "#3858a0", color: "white"} : {backgroundColor: "#ebebeb", color: "black"}}>5</button>
                                                    </div>
                                                    <div className="d-flex justify-content-between mt-2">
                                                        <div className="text-muted" style={{lineHeight: "10px", fontSize: "10px"}}>Дуже незадоволений</div>
                                                        <div className="text-muted" style={{lineHeight: "10px", fontSize: "10px"}}>Дуже задоволений</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mt-3 mb-2">
                                                <div className="col">
                                                    <p class="font-weight-bold mb-1">Наскiльки Ви готовi порекомендувати сервicний центр?</p>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <button type="button" onClick={() => this.setState({nps: 1})} className="btn btn-light mr-1 mr-md-2 w-100" style={this.state.nps > 0 ? {backgroundColor: "#3858a0", color: "white"} : {backgroundColor: "#ebebeb", color: "black"}}>1</button>
                                                        <button type="button" onClick={() => this.setState({nps: 2})} className="btn btn-light mr-1 mr-md-2 w-100" style={this.state.nps > 1 ? {backgroundColor: "#3858a0", color: "white"} : {backgroundColor: "#ebebeb", color: "black"}}>2</button>
                                                        <button type="button" onClick={() => this.setState({nps: 3})} className="btn btn-light mr-1 mr-md-2 w-100" style={this.state.nps > 2 ? {backgroundColor: "#3858a0", color: "white"} : {backgroundColor: "#ebebeb", color: "black"}}>3</button>
                                                        <button type="button" onClick={() => this.setState({nps: 4})} className="btn btn-light mr-1 mr-md-2 w-100" style={this.state.nps > 3 ? {backgroundColor: "#3858a0", color: "white"} : {backgroundColor: "#ebebeb", color: "black"}}>4</button>
                                                        <button type="button" onClick={() => this.setState({nps: 5})} className="btn btn-light mr-1 mr-md-2 w-100" style={this.state.nps > 4 ? {backgroundColor: "#3858a0", color: "white"} : {backgroundColor: "#ebebeb", color: "black"}}>5</button>
                                                        <button type="button" onClick={() => this.setState({nps: 6})} className="btn btn-light mr-1 mr-md-2 w-100" style={this.state.nps > 5 ? {backgroundColor: "#3858a0", color: "white"} : {backgroundColor: "#ebebeb", color: "black"}}>6</button>
                                                        <button type="button" onClick={() => this.setState({nps: 7})} className="btn btn-light mr-1 mr-md-2 w-100" style={this.state.nps > 6 ? {backgroundColor: "#3858a0", color: "white"} : {backgroundColor: "#ebebeb", color: "black"}}>7</button>
                                                        <button type="button" onClick={() => this.setState({nps: 8})} className="btn btn-light mr-1 mr-md-2 w-100" style={this.state.nps > 7 ? {backgroundColor: "#3858a0", color: "white"} : {backgroundColor: "#ebebeb", color: "black"}}>8</button>
                                                        <button type="button" onClick={() => this.setState({nps: 9})} className="btn btn-light mr-1 mr-md-2 w-100" style={this.state.nps > 8 ? {backgroundColor: "#3858a0", color: "white"} : {backgroundColor: "#ebebeb", color: "black"}}>9</button>
                                                        <button type="button" onClick={() => this.setState({nps: 10})} className="btn btn-light mr-1 mr-md-2 w-100" style={this.state.nps > 9 ? {backgroundColor: "#3858a0", color: "white"} : {backgroundColor: "#ebebeb", color: "black"}}>10</button>
                                                    </div>
                                                    <div className="d-flex justify-content-between mt-2">
                                                        <div className="text-muted" style={{lineHeight: "10px", fontSize: "10px"}}>Безумовно не рекомендую</div>
                                                        <div className="text-muted" style={{lineHeight: "10px", fontSize: "10px"}}>Безумовно рекомендую</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mt-3 mb-2">
                                                <div className="col">
                                                    <p class="font-weight-bold mb-1">Хотiли б Ви щось додати, щоб ми могли покращити нашi послуги?</p>
                                                    <textarea class="form-control" style={{border: "0px"}} placeholder="Коментар..." rows="2" onChange={this.changeRemark}></textarea>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col text-right">
                                                    <button className="btn-agis-primary" style={{backgroundColor: "#3858a0"}} onClick={this.save}>{localization[lang].action.saveButton}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ResponseCard;