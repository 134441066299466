import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faSearch, faPlus, faFilter, faExpand, faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../AppContext.js';
import OffsetTop from '../../components/offsetTop.js';

import Scanner from '../../components/scanner.js';

const localization = {
    ua: {
        title: 'Інформація про запчастину',
        header: {
            search: 'Код запчастини...'
        },
        form: {
            location: 'Локація на складі',
            ordered: 'Очікується',
            received: 'Отримано',

            total: 'Залишки на складах',
            totalHint: 'Свій / Внутрішні / Центральний',
            reserv: 'Резерв',
            reservHint: 'Всього / На складі / Видано',

            prev: "Попередній номер",
            prevTotal: ", залишок",
            next: "Новий номер",
            nextTotal: ", залишок",

            buyingPrice: 'Вартість покупки, з ПДВ',
            sellingPrice: 'Вартість продажу, з ПДВ',

            specialPriceStart: 'Акційна пропозиція',
            specialPriceEnd: ', з ПДВ',
        },
        noData: 'Данi вiдсутнi',
    },
    ru: {
        title: 'Информация о запчасти',
        header: {
            search: 'Код запчасти...'
        },
        form: {
            location: 'Локация на складе',
            ordered: 'Ожидается',
            received: 'Получено',

            total: 'Остатки на складах',
            totalHint: 'Свой / Внутренние / Центральный',
            reserv: 'Резерв',
            reservHint: 'Всего / На складе / Выдано',

            prev: "Предыдущий номер",
            prevTotal: ", остаток",
            next: "Новый номер",
            nextTotal: ", остаток",

            buyingPrice: 'Стоимость покупки, с НДС',
            sellingPrice: 'Стоимость продажи, с НДС',

            specialPriceStart: 'Акционное предложение',
            specialPriceEnd: ', с НДС',
        },
        noData: 'Нет данных',
    },
    en: {
        title: 'Information about spare part',
        header: {
            search: 'Code...'
        },
        form: {
            location: 'Location',
            ordered: 'Ordered',
            received: 'Received',

            total: 'Total',
            totalHint: 'Own / Internal / Central',
            reserv: 'Reserv',
            reservHint: 'All / Stock / Stash',

            prev: "Previous code",
            prevTotal: ", total",
            next: "New code",
            nextTotal: ", total",

            buyingPrice: 'Net price',
            sellingPrice: 'Price',

            specialPriceStart: 'Special offer',
            specialPriceEnd: '',
        },
        noData: 'No data',
    },
}

class StockDetail extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            redirect: null, 

            isScannerOpened: false,

            isFocusOn: true,

            searchCode: "",
            isSearched: false,

            isPicked: false,

            rows: null,
        }

        this.searchCodeChange = this.searchCodeChange.bind(this);
        this.searchCodeFocus = this.searchCodeFocus.bind(this);
        this.searchCodeBlur = this.searchCodeBlur.bind(this);
        this.searchCodeKeyPress = this.searchCodeKeyPress.bind(this);
        this.searchCodeButtonClick = this.searchCodeButtonClick.bind(this);

        this.scanCodeButtonClick = this.scanCodeButtonClick.bind(this);
        this.onCodeScanClose = this.onCodeScanClose.bind(this);

        this.onCodeScan = this.onCodeScan.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        var code = this.props.match.params.code;

        //if code exist
        if (code) {
            this.setState({searchCode: code, isFocusOn: false}, () => {
                this.search();
            });
        } else {
            document.getElementById("codeSearchInput").focus();
        }

        window.addEventListener("keypress", this.barcodeScan.bind(this));
    }

    barcodeScan(event) {
        if (!this.state.isFocusOn) {
            if (event.key === 'Enter') {
                this.setState({redirect: "/stock/detail/" + this.state.searchCode});
            } else if (event.key.match(/^[0-9a-zA-Z]+$/)) {
                this.setState({searchCode: this.state.searchCode + event.key});
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.code !== prevProps.match.params.code) {
            if (this.props.match.params.code) {
                this.setState({
                    searchCode: this.props.match.params.code
                }, () => {
                    this.search();
                    //document.getElementById("codeSearchInput").blur();
                });
            } else {
                this.setState({
                    searchCode: "", 
                    isSearched: false,
                    rows: [],

                    isFocusOn: true
                }, () => {
                    //this.clear();
                    document.getElementById("codeSearchInput").focus();
                });
            }
        }
    }

    searchCodeChange(event) {
        this.setState({searchCode: event.target.value});
    }

    searchCodeKeyPress(e) {
        if (e.key === 'Enter') {
            this.setState({redirect: "/stock/detail/" + this.state.searchCode});
        }
    }

    searchCodeFocus(event) {
        this.setState({isFocusOn: true});
    }

    searchCodeBlur(event) {
        this.setState({isFocusOn: false});
    }

    searchCodeButtonClick() {
        this.setState({redirect: "/stock/detail/" + this.state.searchCode});
    }

    scanCodeButtonClick() {
        this.setState({isScannerOpened: true});
    }

    onCodeScan(value) {
        this.setState({redirect: "/stock/detail/" + value});
    }

    onCodeScanClose() {
        this.setState({isScannerOpened: false});
    }

    search() {
        const {addNotification, removeNotification, token} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/stock/detail', { token: token, searchCode: this.state.searchCode }).then((res) => {
                this.setState({
                    redirect: null,

                    isScannerOpened: false,
                    isSearched: true,

                    searchCode: "",
                    isFocusOn: false,
                    
                    rows: res.data.rows
                }, () => {
                    document.getElementById("codeSearchInput").blur();
                });
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            if (this.state.searchCode == "123") {
                this.setState({
                    redirect: null,

                    isScannerOpened: false,
                    isSearched: true,

                    searchCode: "",

                    isFocusOn: false,

                    rows: [
                        {
                            code: "123456789",
                            name: "Name 1",
                            location: "Location",

                            ordered: "ordered",
                            received: "received",

                            total: "total",
                            totalImporter: "totalImporter",
                            totalDealers: "totalDealers",

                            reserve: "reserve",
                            reserveStock: "reserveStock",
                            reserveStash: "reserveStash",

                            prev: "Prev",
                            prevTotal: "prevTotal",
                            next: "Next",
                            nextTotal: "nextTotal",
                            
                            buyingPrice: "buyingPrice",
                            sellingPrice: "sellingPrice",
                            specialPrice: "specialPrice",
                            specialName: "specialName",
                        },
                        {
                            code: "1483543",
                            name: "Трубка пластикова",
                            location: "3.B.5",

                            ordered: "12",
                            received: "20",

                            total: "3",
                            totalImporter: "33",
                            totalDealers: "25",

                            reserve: "2",
                            reserveStock: "1",
                            reserveStash: "1",

                            prev: "813016",
                            prevTotal: "2",
                            next: "2443492",
                            nextTotal: "40.9",
                            

                            buyingPrice: "152,92",
                            sellingPrice: "0,00",
                            specialPrice: "96,00",
                            specialName: "Распродажа",
                        },
                    ]
                }, () => {
                    document.getElementById("codeSearchInput").blur();
                });
            } else if (this.state.searchCode == "123456789") {
                this.setState({
                    redirect: null,

                    isScannerOpened: false,
                    isSearched: true,

                    isFocusOn: false,

                    rows: [
                        {
                            code: "1483543",
                            name: "Трубка пластикова",
                            location: "3.B.5",

                            ordered: "12",
                            received: "20",

                            total: "3",
                            totalImporter: "33",
                            totalDealers: "25",

                            reserve: "2",
                            reserveStock: "1",
                            reserveStash: "1",

                            prev: "813016",
                            prevTotal: "",
                            next: "2443492",
                            nextTotal: "40.9",
                            

                            buyingPrice: "152,92",
                            sellingPrice: "0,00",
                            specialPrice: "96,00",
                            specialName: "Распродажа",
                        },
                    ]
                }, () => {
                    document.getElementById("codeSearchInput").blur();
                });
            } else {
                this.setState({
                    redirect: null,

                    isScannerOpened: false,
                    isSearched: true,

                    isFocusOn: false,

                    rows: []
                }, () => {
                    document.getElementById("codeSearchInput").blur();
                });
            }
        }
    }

    renderList() {
        const {lang, access} = this.context;

        if (this.state.rows.length === 0) {
            return (
                <div className="row">
                    <div className="col">
                        <div className="card agis-card">
                            <div className="row">
                                <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                    <div>
                                        <h6><b>{localization[lang].noData}</b></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (this.state.rows.length === 1) {
            var item = this.state.rows[0];

            return (
                <div className="row">
                    <div className="col">
                        <div className="card-agis pt-2 pb-2 mb-2" style={{borderRadius: "15px"}}>
                            <h3><b>{item.code}</b></h3>
                            <div style={{fontSize: "1.3rem"}}>{item.name}</div>

                            <div className="row mt-3 mb-2">
                                <div className="col-5">
                                    <span className="text-muted">{localization[lang].form.location}</span>
                                    {access.includes('10005') ? (
                                        <Link to={"/stock/relocate/" + item.code}><h6 style={{fontSize: "1.5rem"}}><b>{item.location !== "" ? item.location : "-"}</b></h6></Link>
                                    ) : (
                                        <h6 style={{fontSize: "1.5rem"}}><b>{item.location !== "" ? item.location : "-"}</b></h6>
                                    )}
                                </div>
                                <div className="col-4 text-center">
                                    <span className="text-muted">{localization[lang].form.ordered}</span>
                                    <h6><b>{item.ordered !== "" ? item.ordered : "-"}</b></h6>
                                    
                                </div>
                                <div className="col-3 text-center">
                                    <span className="text-muted">{localization[lang].form.received}</span>
                                    <h6><b>{item.received !== "" ? item.received : "-"}</b></h6>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col text-center">
                                    <div className="text-muted">{localization[lang].form.total}</div>
                                    <div style={{lineHeight: "28px"}}>
                                        <span className="text-info" style={{fontSize: "1.6rem"}}><b>{item.total !== "" ? item.total : "-"}</b></span>
                                        <span className="ml-3 mr-3">/</span>
                                        <span className="" style={{fontSize: "1.3rem"}}><b>{item.totalDealers !== "" ? item.totalDealers : "-"}</b></span>
                                        <span className="ml-3 mr-3">/</span>
                                        <span className="" style={{fontSize: "1.3rem"}}><b>{item.totalImporter !== "" ? item.totalImporter : "-"}</b></span>
                                    </div>
                                    <div className="text-muted" style={{fontSize: "9px", lineHeight: "11px"}}>{localization[lang].form.totalHint}</div>
                                </div>
                                <div className="col text-center">
                                    <div className="text-muted">{localization[lang].form.reserv}</div>
                                    <div style={{lineHeight: "28px"}}>
                                        <span className="text-info" style={{fontSize: "1.6rem"}}><b>{item.reserve !== "" ? item.reserve : "-"}</b></span>
                                        <span className="ml-3 mr-3">/</span>
                                        <span className="" style={{fontSize: "1.3rem"}}><b>{item.reserveStock !== "" ? item.reserveStock : "-"}</b></span>
                                        <span className="ml-3 mr-3">/</span>
                                        <span className="" style={{fontSize: "1.3rem"}}><b>{item.reserveStash !== "" ? item.reserveStash : "-"}</b></span>
                                    </div>
                                    <div className="text-muted" style={{fontSize: "9px", lineHeight: "11px"}}>{localization[lang].form.reservHint}</div>
                                </div>
                            </div>
                        </div>

                        <div className="card-agis pt-2 pb-2 mb-2" style={{borderRadius: "15px"}}>
                            <div className="row">
                                <div className="col text-center">
                                    <span className="text-muted">{localization[lang].form.buyingPrice}</span>
                                    <h6><b>{item.buyingPrice !== "" ? item.buyingPrice : "-"} грн</b></h6>
                                </div>
                                <div className="col text-center">
                                    <span className="text-muted">{localization[lang].form.sellingPrice}</span>
                                    <h5 className="text-info"><b>{item.sellingPrice !== "" ? item.sellingPrice : "-"} грн</b></h5>
                                </div>
                            </div>

                            {item.specialPrice !== "" && (
                                <div className="row mt-3">
                                    <div className="col text-center">
                                        <span className="text-muted">{localization[lang].form.specialPriceStart + " \"" + item.specialName + "\"" + localization[lang].form.specialPriceEnd}</span>
                                        <h6 className="text-danger"><b>{item.specialPrice} грн</b></h6>
                                    </div>
                                </div>
                            )}
                        </div>

                        {item.prev != "" && (
                            <Link to={"/stock/detail/" + item.prev} className="card-agis pt-2 pb-2 mb-2 position-component" style={{borderRadius: "15px"}}>
                                <div className="d-flex align-items-center justify-content-start">
                                    <FontAwesomeIcon icon={faChevronLeft} className="text-muted mr-3" style={{fontSize: "20px"}} />
                                    <div>
                                        <h5><b>{item.prev}</b></h5>
                        <span className="text-muted">{localization[lang].form.prev} {item.prevTotal.length != 0 && (<span>{localization[lang].form.prevTotal} <b>{item.prevTotal}</b></span>)}</span>
                                    </div>
                                </div>
                            </Link>
                        )}

                        {item.next != "" && (
                            <Link to={"/stock/detail/" + item.next} className="card-agis pt-2 pb-2 mb-2 position-component" style={{borderRadius: "15px"}}>
                                <div className="d-flex align-items-center justify-content-end">
                                    <div className="text-right">
                                        <h5><b>{item.next}</b></h5>
                                        <span className="text-muted">{localization[lang].form.next} {item.nextTotal.length != 0 && (<span>{localization[lang].form.nextTotal} <b>{item.nextTotal}</b></span>)}</span>
                                    </div>
                                    <FontAwesomeIcon icon={faChevronRight} className="text-muted ml-3" style={{fontSize: "20px"}} />
                                </div>
                            </Link>
                        )}

                        

                    </div>
                </div>
            );
        } else {
            return this.state.rows.map((item, i) => {
                return (
                    <div className="row mb-2" key={"list_" + i}>
                        <div className="col">
                            <Link to={"/stock/detail/" + item.code} className="card-agis pt-2 pb-2 position-component" style={{borderRadius: "15px"}}>
                                <h4><b>{item.code}</b></h4>
                                <span style={{fontSize: "1.3rem"}}>{item.name}</span>
                            </Link>
                        </div>
                    </div>
                );
            });
        }
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.redirect != null) {
            return <Redirect push to={this.state.redirect} />
        }

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                {this.state.isScannerOpened && (
                    <Scanner onCodeScan={this.onCodeScan} onCodeScanClose={this.onCodeScanClose} />
                )}

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="container-fluid pl-0 pr-0">

                                <div className="d-flex justify-content-between mb-3">
                                    <input id="codeSearchInput" type="text" className="question-input" style={{height: "50px", width: "calc(100% - 50px)", fontSize: "18px", borderRadius: "10px", borderTopRightRadius: "0px", borderBottomRightRadius: "0px", backgroundColor: "#fff"}} placeholder={localization[lang].header.search} value={this.state.searchCode} onChange={this.searchCodeChange} onKeyPress={this.searchCodeKeyPress} onFocus={this.searchCodeFocus} onBlur={this.searchCodeBlur} />
                                    <button onClick={this.searchCodeButtonClick} className="a-agis-light-outline" style={{height: '50px', width: "50px", minWidth: "0px", borderRadius: "10px", borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px", lineHeight: "44px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 0 0 1px rgba(0,0,0,.05), 0 2px 3px 0 rgba(0,0,0,.1)"}}><FontAwesomeIcon icon={faSearch} style={{fontSize: "20px"}} /></button>

                                    <button onClick={this.scanCodeButtonClick} className="a-agis-light-outline ml-3" style={{height: '50px', width: "70px", minWidth: "0px", borderRadius: "10px", lineHeight: "54px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 0 0 1px rgba(0,0,0,.05), 0 2px 3px 0 rgba(0,0,0,.1)"}}><span style={{fontSize: "16px"}}><svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="barcode-read" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="svg-inline--fa fa-barcode-read fa-w-20 fa-2x"><path fill="currentColor" d="M152 0H8C3.6 0 0 3.6 0 8v152c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V32h120c4.4 0 8-3.6 8-8V8c0-4.4-3.6-8-8-8zm0 480H32V352c0-4.4-3.6-8-8-8H8c-4.4 0-8 3.6-8 8v152c0 4.4 3.6 8 8 8h144c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zM632 0H488c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h120v128c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V8c0-4.4-3.6-8-8-8zm0 344h-16c-4.4 0-8 3.6-8 8v128H488c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h144c4.4 0 8-3.6 8-8V352c0-4.4-3.6-8-8-8zM152 96h-48c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8zm336 320h48c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8zM408 96h-48c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8zm-192 0h-16c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8zm64 0h-16c-4.4 0-8 3.6-8 8v304c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V104c0-4.4-3.6-8-8-8z" class=""></path></svg></span></button>
                                </div>

                                {this.state.isSearched && (
                                    this.renderList()
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default StockDetail;