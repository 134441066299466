import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';
import imageCompression from 'browser-image-compression';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faSearch, faPlus, faFilter, faChevronLeft, faCircle, faFlag, faCar, faCamera } from '@fortawesome/free-solid-svg-icons'

import Preview from '../../../components/preview.js';

import AppContext from '../../../AppContext.js';
import OffsetTop from '../../../components/offsetTop.js';

const localization = {
    ua: {
        title: 'Запити на ремонт',
        filter: 'Стан:',
        all: 'Всі',
        state08: 'Створені',
        state23: 'Анульовані',
        state45: 'Відхилені',
        state42: 'Виконати при наступному ТО',
        state51: 'Заплановані',
        state13: 'Виконані',
        searchText: 'Пошук...',
        noData: 'Данi вiдсутнi',
        edit: 'Редагувати',
        cancel: 'Анулювати',
    },
    ru: {
        title: 'Запрос на ремонт',
        filter: 'Состояние:',
        all: 'Все',
        state08: 'Созданные',
        state23: 'Аннулированные',
        state45: 'Отклоненные',
        state42: 'Выполнить при следующем ТО',
        state51: 'Запланированные',
        state13: 'Выполненные',
        searchText: 'Поиск...',
        noData: 'Нет данных',
        edit: 'Редактировать',
        cancel: 'Аннулировать',
    },
    en: {
        title: 'Service requests',
        filter: 'Стан:',
        all: 'All',
        state08: 'Створено',
        state23: 'Анульовано',
        state45: 'Відхилено',
        state42: 'Виконати при наступному ТО',
        state51: 'Заплановано',
        state13: 'Виконано',
        searchText: 'Filter...',
        noData: 'No data',
        edit: 'Edit',
        cancel: 'Cancel',
    },
}

class ServiceRequests extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,
            rows: [],
            uploadFolder: "",

            filterState: null,
            searchText: '',
        }

        this.changeFilterState = this.changeFilterState.bind(this);
        this.searchTextChange = this.searchTextChange.bind(this);
        this.searchTextKeyPress = this.searchTextKeyPress.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        this.loadList();
    }

    loadList() {
        const {addNotification, removeNotification, token} = this.context;
        
        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/service/requests', { token: token }).then((res) => {
                this.setState({rows: res.data.rows, isLoading: false});
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            this.setState({
                isLoading: false,
                rows: [
                    {
                        did: 1,
                        date: "2016-07-24 09:51",
                        number: "451",
                        status: "Створений",
                        driver: "Yevhenii",
                        vehicle: "KIA Sportage",
                        description: "Car is broken, IDK why",
                        part: "12345-123-456",
                        remark: "It's really hot today",
                        state: 8,
                        edit: 1,
                        cancel: 1,
                        cancelRemark: "Canceled tomorrow",
                        files: ["new"],
                    },
                    {
                        did: 2,
                        date: "2017-07-24 09:51",
                        number: "452",
                        status: "Анульований",
                        driver: "",
                        vehicle: "Seat Altea2",
                        description: "Car is broken, IDK why",
                        part: "",
                        remark: "",
                        state: 23,
                        edit: 0,
                        cancel: 0,
                        cancelRemark: "",
                        files: ["new"],
                    },
                    {
                        did: 3,
                        date: "2018-07-24 09:51",
                        number: "453",
                        status: "Відхилений",
                        driver: "",
                        vehicle: "Seat Altea3",
                        description: "Car is broken, IDK why",
                        part: "",
                        remark: "",
                        state: 45,
                        edit: 0,
                        cancel: 0,
                        cancelRemark: "",
                        files: ["new"],
                    },
                    {
                        did: 4,
                        date: "2019-07-24 09:51",
                        number: "454",
                        status: "Виконати при наступному ТО",
                        driver: "",
                        vehicle: "Seat Altea4",
                        description: "Car is broken, IDK why",
                        part: "",
                        remark: "",
                        state: 42,
                        edit: 0,
                        cancel: 0,
                        cancelRemark: "",
                        files: ["new"],
                    },
                    {
                        did: 5,
                        date: "2020-07-24 09:51",
                        number: "455",
                        status: "Заплановано",
                        driver: "",
                        vehicle: "Seat Altea5",
                        description: "Car is broken, IDK why",
                        part: "",
                        remark: "",
                        state: 51,
                        edit: 0,
                        cancel: 0,
                        cancelRemark: "",
                        files: ["new"],
                    },
                    {
                        did: 6,
                        date: "2021-07-24 09:51",
                        number: "456",
                        status: "Виконаний",
                        driver: "",
                        vehicle: "Seat Altea6",
                        description: "Car is broken, IDK why",
                        part: "",
                        remark: "",
                        state: 13,
                        edit: 0,
                        cancel: 0,
                        cancelRemark: "",
                        files: ["new"],
                    },
                ]
            });
        }
    }
   
    changeFilterState(event) {
        this.setState({filterState: Number(event.target.value) ? event.target.value : null});
    }

    searchTextChange(e) {
        this.setState({searchText: e.target.value});
    }
    
    searchTextKeyPress(e) {
        if (e.key === 'Enter') {
            e.target.blur();
        }
    }

    removeFile = (filename, folder) => {
        const {addNotification, removeNotification} = this.context;

        var u = addNotification("Завантаження...");
        axios.post('/api/service/request/attachment/remove', { filename: filename, folder: folder + "" }).then((res) => {
            //remove file names to list
            this.removeFromFileList(folder, filename);
        }).catch((error) => {
            addNotification(error.response.data, error.response.status);
        }).finally(function () {
            removeNotification(u);
        });
    }

    triggerFileInput = (uploadFolder) => {
        this.setState({uploadFolder: uploadFolder + ""}, () => {
            document.getElementById("fileInput").click();
        });
    }

    changeFileInput = event => {
        const {addNotification, removeNotification} = this.context;

        // Copy files to array
        var files = [];
        for (var i = 0; i < event.target.files.length; i++) {
            files.push(event.target.files[i]);
        }
        event.target.value = null;

        // Create data object
        var data = new FormData();
        data.append('length', files.length);
        data.append('folder', this.state.uploadFolder)

        // Attach files to data object
        var compressPromises = [];
        var fileIndex = 0; // file index because compressed images has different order

        for (var i = 0; i < files.length; i++) {
            if (files[i].type == "video/mp4") {
                data.append("file"+fileIndex, files[i]);
                fileIndex = fileIndex++;
            } else {
                compressPromises.push(imageCompression(files[i], {
                    maxSizeMB: 10,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true
                }));
            }
        }

        // Images should be compressed, wait
        Promise.all(compressPromises).then(results => {
            results.forEach(compressedFile => {
                data.append("file"+fileIndex, compressedFile, compressedFile.name);
                fileIndex = fileIndex++;
            });

            var u = addNotification("Завантаження...");
            axios.post('/api/service/request/attachment/add', data).then((res) => {
                this.addToFileList(this.state.uploadFolder, res.data.filename);
                this.setState({uploadFolder: ""});
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        });
    }

    addToFileList(folfer, filename) {
        var i = this.state.rows.findIndex((item) => {
            return item.did == folfer
        });

        if (i != -1) {
            this.state.rows[i].files.push(filename);
            this.forceUpdate();
        }
    }

    removeFromFileList(folfer, filename) {
        var i = this.state.rows.findIndex((item) => {
            return item.did == folfer
        });

        if (i != -1) {
            var fi = this.state.rows[i].files.findIndex((fitem) => {
                return fitem == filename
            });
            this.state.rows[i].files.splice(fi, 1);
            this.forceUpdate();
        }
    }

    renderList() {
        const {lang} = this.context;

        if (this.state.rows.length === 0) {
            return (
                <div className="row">
                    <div className="col">
                        <div className="card agis-card">
                            <div className="row">
                                <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                    <div>
                                        <h6><b>{localization[lang].noData}</b></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return this.state.rows.filter((item, i) => {
                // check by text filter
                if ([item.date, item.number, item.status, item.driver, item.vehicle, item.description, item.part, item.remark, item.cancelRemark].join(' ').toLowerCase().includes(this.state.searchText.toLowerCase())) {
                    // check by state
                    if (this.state.filterState != null) {
                        if (this.state.filterState == 8 && item.state == 8) {
                            return true;
                        }

                        if (this.state.filterState == 23 && item.state == 23) {
                            return true;
                        }

                        if (this.state.filterState == 42 && item.state == 42) {
                            return true;
                        }

                        if (this.state.filterState == 45 && item.state == 45) {
                            return true;
                        }

                        if (this.state.filterState == 51 && item.state == 51) {
                            return true;
                        }

                        if (this.state.filterState == 13 && item.state == 13) {
                            return true;
                        }

                        return false
                    }

                    return true;
                }

                return false;
            }).map((item, i) => {
                var statusColor = ""
                switch (item.state) {
                    case 23: 
                    statusColor = "text-warning"
                    break
                    case 42: 
                    statusColor = "text-info"
                    break
                    case 45: 
                    statusColor = "text-danger"
                    break
                    case 51: 
                    statusColor = "text-info"
                    break
                    case 13: 
                    statusColor = "text-success"
                    break
                }

                return (
                    <div className="row mb-2" key={"request_" + i}>
                        <div className="col">
                            <div className="card-agis pt-2 pb-2" style={{borderRadius: "15px"}}>
                                <div className="d-flex align-items-center justify-content-between mb-1">
                                    <big><b>{item.number}</b> від {item.date}, <span className={statusColor}>{item.status}</span></big>
                                    {item.state == 8 && (
                                        <button className="btn-agis-light-outline" style={{height: "40px", width: "40px", minWidth: "40px"}} onClick={() => {this.triggerFileInput(item.did)}}><FontAwesomeIcon icon={faCamera}/></button>
                                    )}
                                </div>
                                {item.state == 23 && (
                                    <div>
                                        <span className="text-danger mb-2">{item.cancelRemark}</span>
                                    </div>
                                )}
                                {item.driver != "" && (
                                    <div>
                                        <span>{item.driver}</span>
                                    </div>
                                )}
                                <div>
                                    <span>{item.vehicle}</span>
                                </div>
                                <div className='mt-2'>
                                    <big><b>{item.description}</b></big>
                                </div>
                                {item.part != "" && (
                                    <div>
                                        <span className='text-info'>{item.part}</span>
                                    </div>
                                )}
                                {item.remark != "" && (
                                    <div className='mt-2'>
                                        <span>{item.remark}</span>
                                    </div>
                                )}

                                {item.edit == 1 && (
                                    <Link to={"/service/request/edit/" + item.did}>
                                        <button className="btn-agis-primary-outline mt-2" style={{height: "30px", width: '100%', minWidth: "0px"}}>{localization[lang].edit}</button>
                                    </Link>
                                )}

                                {item.cancel == 1 && (
                                    <Link to={"/service/request/cancel/" + item.did}>
                                        <button className="btn-agis-danger-outline mt-2" style={{height: "30px", width: '100%', minWidth: "0px"}}>{localization[lang].cancel}</button>
                                    </Link>
                                )}

                                {item.files.length > 0 && (
                                    <div className="row mt-2">
                                        {item.files.map((file) =>
                                            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                {item.state == 8 ? (
                                                    <Preview src={"/serve/service/request/"+item.did+"/"+file} removeFile={() => this.removeFile(file, item.did)} />
                                                ) : (
                                                    <Preview src={"/serve/service/request/"+item.did+"/"+file} />
                                                )}
                                                
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                );
            });
        }
    }

    render() {
        const {lang, access} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                {/* INPUT FILE UPLOAD */}
                <input type="file" id="fileInput" className="d-none" onChange={this.changeFileInput} accept=".jpg,.jpeg,.png,.bmp,.tiff,.gif,.mp4"/>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="container-fluid pl-0 pr-0">
                                <div className="row mb-3">
                                    <div className="col">
                                        <div className="card-agis pt-1 pb-1" style={{borderRadius: "10px"}}>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <span>{localization[lang].filter}</span>
                                                <select className="question-input ml-1" value={this.state.filterState} onChange={this.changeFilterState}>
                                                    <option value="">{localization[lang].all}</option>
                                                    <option value="8">{localization[lang].state08}</option>
                                                    <option value="23">{localization[lang].state23}</option>
                                                    <option value="42">{localization[lang].state42}</option>
                                                    <option value="45">{localization[lang].state45}</option>
                                                    <option value="51">{localization[lang].state51}</option>
                                                    <option value="13">{localization[lang].state13}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between mb-3">
                                    <input id="searchInput" type="text" className="question-input" style={{height: "40px", width: "100%", fontSize: "16px", borderRadius: "10px", borderTopRightRadius: "0px", borderBottomRightRadius: "0px", backgroundColor: "#fff"}} placeholder={localization[lang].searchText} value={this.state.searchText} onChange={(e) => this.searchTextChange(e)} onKeyPress={(e) => this.searchTextKeyPress(e)} />
                                    <button onClick={this.searchCodeButtonClick} className="a-agis-light-outline" style={{height: '40px', width: "40px", minWidth: "0px", borderRadius: "10px", borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px", lineHeight: "34px", backgroundColor: "white", border: "1px solid #ebebeb", boxShadow: "0 0 0 1px rgba(0,0,0,.05), 0 2px 3px 0 rgba(0,0,0,.1)"}}><FontAwesomeIcon icon={faSearch} style={{fontSize: "20px"}} /></button>
                                </div>

                                {this.renderList()}

                                {access.includes('20') && (
                                    <div className="fixed-bottom text-right" style={{zIndex: "4", paddingRight: "10px", paddingBottom: "10px", height: "60px"}}>
                                        <Link to={"/service/request/new"} className="a-agis-light-outline" style={{height: '50px', width: "50px", minWidth: "0px", borderRadius: "25px", lineHeight: "44px", backgroundColor: "#1a73e8", color: "white", border: "1px solid #ebebeb", boxShadow: "0 1px 1px rgba(0,0,0,.15)"}}><FontAwesomeIcon icon={faPlus} style={{fontSize: "20px"}} /></Link>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ServiceRequests;