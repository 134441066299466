import React from 'react'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';
import ScaleLoader from "react-spinners/ScaleLoader";

import AppContext from '../../../AppContext.js';
import Submenu from '../../../components/submenu.js';
import SubmenuContent from './submenu.js';
import OffsetTop from '../../../components/offsetTop.js';

import {monthToInputFormat, monthToDatabaseFormat} from '../../../utils/datetime.js';

import Graph from "../../../components/graph.js";

const localization = {
    ua: {
        title: 'Driver Feedback',
        site: 'Дилер',
        month: 'Місяць',
        lastMonth: 'Останні 12 місяців',
        selectedMonth: 'Обраний місяць',
        nps: {
            title: 'NPS - Net Promoter Score',
            text1: 'Наскільки ймовірно, що ви рекомендували б цю станцію іншим? (Шкала 0-10)',
            text2: 'Відповіді, останні 6 місяців',
        },

        satisfaction: {
            title: 'Загальне задоволення',
            text1: 'Наскільки Ви загалом задоволені останнім відвідуванням станції? (Шкала 1-5)',
            text2: 'Оцінка 4 + 5, останні 6 місяців',
        }
    },
    ru: {
        title: 'Driver Feedback',
        site: 'Дилер',
        month: 'Мeсяц',
        lastMonth: 'Последние 12 месяцев',
        selectedMonth: 'Выбранный месяц',
        nps: {
            title: 'NPS - Net Promoter Score',
            text1: 'Насколько вероятно, что вы порекомендуете эту станцию другим? (Шкала 0-10)',
            text2: 'Ответы, последние 6 месяцев',
        },

        satisfaction: {
            title: 'Общий уровень удовлетворенности',
            text1: 'Насколько вы в целом довольны последним посещением станции? (Шкала 1-5)',
            text2: 'Оценка 4 + 5, последние 6 месяцев',
        }
    },
    en: {
        title: 'Driver Feedback',
        site: 'Site',
        month: 'Month',
        lastMonth: 'Last 12 month',
        selectedMonth: 'Selected month',
        nps: {
            title: 'NPS - Net Promoter Score',
            text1: 'How likely is it that you would recommend this workshop to others? (Scale 0-10)',
            text2: 'Answer categories, last 6 month',
        },

        satisfaction: {
            title: 'Overall satisfaction',
            text1: 'How satisfied are you overall with last workshop visit? (Scale 1-5)',
            text2: 'Top 2 boxes (score 4 + 5), last 6 month',
        }
    },
}

class NPS extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoadingSites: true,
            isLoadingScores: true,

            sites: [],
            site: null,
            from: null,

            nps: null,
            satisfaction: null,
            answers: null,
            top2: null,
        }

        this.changeSite = this.changeSite.bind(this);
        this.changeFrom = this.changeFrom.bind(this);
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        this.loadSites();
    }

    loadSites() {
        const {addNotification, removeNotification, token} = this.context;

        var site = localStorage.getItem("driver-feedback-site");
        var from = localStorage.getItem("driver-feedback-from");
        var to = localStorage.getItem("driver-feedback-to");

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/crm/driver-feedback/sites', { token: token }).then((res) => {
                if (from == null || to == null) {
                    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
                    from = new Date(y, m - 1, 1).getTime();//first day of prev month
                    to = new Date(y, m, 0).getTime();//last day of prev month
        
                    localStorage.setItem('driver-feedback-from', from);
                    localStorage.setItem('driver-feedback-to', to);
                }
        
                if (site == null || (res.data.rows.filter(e => e.id == site).length == 0)) {
                    localStorage.setItem('driver-feedback-site', res.data.rows[0].id);
                    site = res.data.rows[0].id;
                }

                this.setState({
                    isLoadingSites: false,
                    sites: res.data.rows, 
                    site: site,
                    from: Number(from),
                }, () => this.loadScores());
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            if (from == null || to == null) {
                var date = new Date(), y = date.getFullYear(), m = date.getMonth();
                from = new Date(y, m - 1, 1).getTime();//first day of prev month
                to = new Date(y, m, 0).getTime();//last day of prev month
    
                localStorage.setItem('driver-feedback-from', from);
                localStorage.setItem('driver-feedback-to', to);
            }
    
            if (site == null) {
                localStorage.setItem('driver-feedback-site', 5);
            }

            this.setState({
                isLoadingSites: false,

                site: site,
                from: Number(from),
                sites: [
                    {
                        id: "1",
                        name: "ВСЕ",
                    },
                    {
                        id: "-1",
                        name: "All Captive",
                    },
                    {
                        id: "-2",
                        name: "All Non-Captive",
                    },
                    {
                        id: "5",
                        name: "SCANIA Kiev",
                    },
                ]
            }, () => this.loadScores());
        }
    }

    changeSite(event) {
        this.setState({site: event.target.value, isLoadingScores: true}, () => {
            localStorage.setItem('driver-feedback-site', this.state.site);
            this.loadScores();
        });
    }

    changeFrom(event) {
        this.setState({from: new Date(event.target.value + "-01").getTime(), isLoadingScores: true}, () => {
            localStorage.setItem('driver-feedback-from', this.state.from);
            this.loadScores();
        });
    }

    loadScores() {
        const {addNotification, removeNotification, token} = this.context;
        
        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");

            var npsPromise = axios.post('/api/crm/driver-feedback/nps-and-satisfaction/nps', { token: token, site: this.state.site, date: monthToDatabaseFormat(this.state.from) });
            var satisfactionPromise = axios.post('/api/crm/driver-feedback/nps-and-satisfaction/satisfaction', { token: token, site: this.state.site, date: monthToDatabaseFormat(this.state.from) });
            var answersPromise = axios.post('/api/crm/driver-feedback/nps-and-satisfaction/answers', { token: token, site: this.state.site, date: monthToDatabaseFormat(this.state.from) });
            var top2Promise = axios.post('/api/crm/driver-feedback/nps-and-satisfaction/top2', { token: token, site: this.state.site, date: monthToDatabaseFormat(this.state.from) });

            axios.all([npsPromise, satisfactionPromise, answersPromise, top2Promise]).then(axios.spread((nps, satisfaction, answers, top2) => {
                this.setState({
                    isLoadingScores: false,
                    nps: nps.data.rows[0],
                    satisfaction: satisfaction.data.rows[0],
                    answers: answers.data.rows,
                    top2: top2.data.rows,
                });
            })).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            this.setState({
                isLoadingScores: false,
                nps: {
                    yearTotal: "7272",
                    yearResult: "42",
                    monthTotal: "655",
                    monthResult: "49",
                },
                satisfaction: {
                    yearTotal: "7272",
                    yearAverage: "4",
                    monthTotal: "655",
                    monthAverage: "4.2",
                },
                answers: [
                    {
                        month: "2020-07",
                        total: "247",
                        bottom: "17",
                        middle: "27",
                        top: "56",
                    },
                    {
                        month: "2020-08",
                        total: "217",
                        bottom: "19",
                        middle: "25",
                        top: "56",
                    },
                    {
                        month: "2020-09",
                        total: "233",
                        bottom: "18",
                        middle: "29",
                        top: "54",
                    },
                    {
                        month: "2020-10",
                        total: "220",
                        bottom: "20",
                        middle: "26",
                        top: "55",
                    },
                    {
                        month: "2020-11",
                        total: "238",
                        bottom: "17",
                        middle: "25",
                        top: "58",
                    },
                    {
                        month: "2020-12",
                        total: "322",
                        bottom: "13",
                        middle: "26",
                        top: "62",
                    },
                ],
                top2: [
                    {
                        month: "2020-07",
                        total: "322",
                        result: "74",
                    },
                    {
                        month: "2020-08",
                        total: "322",
                        result: "74",
                    },
                    {
                        month: "2020-09",
                        total: "322",
                        result: "75",
                    },
                    {
                        month: "2020-10",
                        total: "322",
                        result: "74",
                    },
                    {
                        month: "2020-11",
                        total: "322",
                        result: "76",
                    },
                    {
                        month: "2020-12",
                        total: "322",
                        result: "78",
                    },
                ],
            });
        }
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        return (
            <div>
                <DocumentMeta {...meta} />

                <Submenu>
                    <SubmenuContent />
                </Submenu>

                <br/><br/>

                <div className="container-fluid" style={this.state.isLoadingSites ? {display: "none"} : {}}>
                    <div className="row">
                        <div className="col-md-10 offset-md-1">

                            <div className="container-fluid pb-4 mb-4">
                                <div className="row">
                                    <div className="col">
                                        <span>{localization[lang].site}:</span>
                                        <select className="question-input ml-1 mr-4" style={{width: "220px"}} value={this.state.site + ""} onChange={this.changeSite}>
                                            {this.state.sites.map((item) => {
                                                return (
                                                    <option value={item.id}>{item.name}</option>
                                                )
                                            })}
                                        </select>

                                        <span>{localization[lang].month}:</span>
                                        <input type="month" className="question-input ml-1" style={{width: "220px"}} value={monthToInputFormat(this.state.from)} onChange={this.changeFrom} />
                                    </div>
                                </div>
                            </div>

                            {!this.state.isLoadingScores && (
                                <div className="container-fluid">
                                    <div className="row pb-4 mb-4">
                                        <div className="col-5">
                                            <h4><b>{localization[lang].nps.title}</b></h4>
                                            <p>{localization[lang].nps.text1}</p>

                                            {this.state.nps ? (
                                                <div className="d-flex justify-content-around">
                                                    <div className="text-center">
                                                        <span>{localization[lang].lastMonth}</span>
                                                        <h1><b>{this.state.nps.yearResult}</b></h1>
                                                        <small>n = {this.state.nps.yearTotal}</small>
                                                    </div>
                                                    <div className="text-center">
                                                        <span>{localization[lang].selectedMonth}: {monthToInputFormat(this.state.from)}</span>
                                                        <h1><b>{this.state.nps.monthResult}</b></h1>
                                                        <small>n = {this.state.nps.monthTotal}</small>
                                                    </div>
                                                </div>
                                            ) : (
                                                <p><b>No data</b></p>
                                            )}
                                        </div>
                                        <div className="col-5 offset-1">
                                            <h4><b>{localization[lang].satisfaction.title}</b></h4>
                                            <p>{localization[lang].satisfaction.text1}</p>

                                            {this.state.satisfaction ? (
                                                <div className="d-flex justify-content-around">
                                                    <div className="text-center">
                                                        <span>{localization[lang].lastMonth}</span>
                                                        <h1><b>{this.state.satisfaction.yearAverage}</b></h1>
                                                        <small>n = {this.state.satisfaction.yearTotal}</small>
                                                    </div>
                                                    <div className="text-center">
                                                        <span>{localization[lang].selectedMonth}: {monthToInputFormat(this.state.from)}</span>
                                                        <h1><b>{this.state.satisfaction.monthAverage}</b></h1>
                                                        <small>n = {this.state.satisfaction.monthTotal}</small>
                                                    </div>
                                                </div>
                                            ) : (
                                                <p><b>No data</b></p>
                                            )}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-4">
                                            <p>{localization[lang].nps.text2}</p>

                                            {this.state.answers.length > 0 ? (
                                                <div style={{
                                                    width: "100%",
                                                    background: "var(--theme-primary)",
                                                    color: "white",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    flexDirection: "column"
                                                }}>
                                                    <Graph
                                                        type={"line"}
                                                        data={{
                                                            labels: this.state.answers.map(a => [a.month, "n = " + a.total]),
                                                            datasets: [
                                                                {
                                                                    label: "Top",
                                                                    data: this.state.answers.map(a => (a.top)),
                                                                    fill: false,
                                                                    backgroundColor: "#00B050",
                                                                    borderColor: "#00B050"
                                                                },
                                                                {
                                                                    label: "Middle",
                                                                    data: this.state.answers.map(a => (a.middle)),
                                                                    fill: false,
                                                                    backgroundColor: "#FFC000",
                                                                    borderColor: "#FFC000"
                                                                },
                                                                {
                                                                    label: "Bottom",
                                                                    data: this.state.answers.map(a => (a.bottom)),
                                                                    fill: false,
                                                                    backgroundColor: "#FF0000",
                                                                    borderColor: "#FF0000"
                                                                }
                                                            ]
                                                        }}
    
                                                        options={{
                                                            animation: {
                                                                duration: 0 // general animation time
                                                            },
                                                            hover: {
                                                                animationDuration: 0 // duration of animations when hovering an item
                                                            },
                                                            responsiveAnimationDuration: 0, // animation duration after a resize
                                                            elements: {
                                                                line: {
                                                                    tension: 0 // disables bezier curves
                                                                }
                                                            },
                                                            scales: {
                                                                yAxes: [{
                                                                    ticks: {
                                                                        suggestedMin: 0,
                                                                        beginAtZero: true,
                                                                        suggestedMax: 100,
                                                                    }
                                                                }]
                                                            },
                                                            
                                                            tooltips: {
                                                                mode: 'index',
                                                                //enabled: false,
    
                                                                callbacks: {
                                                                    //title: (tooltipItems, data) => data.labels[tooltipItems[0].index],
                                                                    //label: (tooltipItems, data) => data.datasets[tooltipItems.datasetIndex].label + ': ' + tooltipItems.value + '%'
                                                                    title: (tooltipItems, data) => "n = " + this.state.answers[tooltipItems[0].index].total,
                                                                    label: (tooltipItems, data) => tooltipItems.value + "%"
                                                                }                 
                                                            },
    
                                                        
                                                        }}
                                                    />
                                                </div>
                                            ) : (
                                                <p><b>No data</b></p>
                                            )}
                                            
                                        </div>
                                        <div className="col-4 offset-2">
                                            <p>{localization[lang].satisfaction.text2}</p>

                                            {this.state.top2.length > 0 ? (
                                                <div style={{
                                                    width: "100%",
                                                    background: "var(--theme-primary)",
                                                    color: "white",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    flexDirection: "column"
                                                }}>
                                                    <Graph
                                                        type={"bar"}
                                                        data={{
                                                            labels: this.state.top2.map(a => [a.month, a.result + "%"]),
                                                            datasets: [
                                                                {
                                                                    label: "Top",
                                                                    data: this.state.top2.map(a => (a.result)),
                                                                    fill: false,
                                                                    backgroundColor: "#041E42",
                                                                    borderColor: "#041E42"
                                                                },
                                                            ]
                                                        }}
    
                                                        options={{
                                                            animation: {
                                                                duration: 0 // general animation time
                                                            },
                                                            hover: {
                                                                animationDuration: 0 // duration of animations when hovering an item
                                                            },
                                                            responsiveAnimationDuration: 0, // animation duration after a resize
                                                            elements: {
                                                                line: {
                                                                    tension: 0 // disables bezier curves
                                                                }
                                                            },
                                                            scales: {
                                                                yAxes: [{
                                                                    ticks: {
                                                                        suggestedMin: 0,
                                                                        beginAtZero: true,
                                                                        suggestedMax: 100,
                                                                    }
                                                                }]
                                                            },
                                                            tooltips: {
                                                                mode: 'index',
                                                                //enabled: false,
    
                                                                callbacks: {
                                                                    //title: (tooltipItems, data) => data.labels[tooltipItems[0].index],
                                                                    //label: (tooltipItems, data) => data.datasets[tooltipItems.datasetIndex].label + ': ' + tooltipItems.value + '%'
                                                                    title: (tooltipItems, data) => "n = " + this.state.top2[tooltipItems[0].index].total,
                                                                    label: (tooltipItems, data) => tooltipItems.value + "%"
                                                                }                 
                                                            },
                                                        }}
                                                    />
                                                </div>
                                            ) : (
                                                <p><b>No data</b></p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NPS;