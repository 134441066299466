import React from 'react'
import DocumentMeta from 'react-document-meta';
import axios from 'axios';

import AppContext from '../AppContext';
import OffsetTop from '../components/offsetTop.js';

const localization = {
    ua: {
        title: 'Головна',
        updates: [
            {
                title: 'Січень',
                version: '2022.01',
                essences: [
                    {
                        title: 'Стемпінг механіків',
                        lines: [
                            'Добавлена можливість самостійно розпочинати та закінчувати зміну',
                            'Стемпінг можна виконувати на всіх активних ЗН (не обов’язково пущених в роботу)',
                            'Додано можливість стемпінгу по роботі',
                        ]
                    },
                    {
                        title: 'Налаштування',
                        lines: [
                            'Додано відображення штрих-коду співробітника для використання при скануванні в різних інтерфейсах'
                        ]
                    },
                    {
                        title: 'Заказ-наряди',
                        lines: [
                            'Додано можливість самостійно створювати заказ-наряд по вибраній техніці',
                            'Додано можливість пошуку ЗН по штрих-коду, який можна вносити вручну або сканувати',
                            'В перегляді заказ-наряду, в розділі «Робоча інформація» добавлено блок «Пріоритет», де механік може вказати пріоритетність виконання робіт, і ця інформація відображається в реєстрі ЗН в програмі'
                        ]
                    },
                    {
                        title: 'Чек-листи',
                        lines: [
                            'Добавлено можливість пошуку Чек-листа по штрих-коду, який можна вносити вручну або сканувати'
                        ]
                    },
                    {
                        title: 'Інвентаризація',
                        lines: [
                            'Додана можливість швидкого включення/відключення камери для сканування ШК товару'
                        ]
                    },
                    {
                        title: 'Прихідні накладні',
                        lines: [
                            'Перерахування складом прихідної накладної як через ручний ввод товару так і з допомогою сканування ШК товарів',
                            'Перегляд розбіжностей',
                            'Фіксація результату перерахування'
                        ]
                    },
                ]
            },


            {
                title: 'Березень',
                version: '2021.03',
                essences: [
                    {
                        title: 'Видача / повернення у виробництво',
                        lines: [
                            'Додана можливість видати / повернути запчастину та матеріали у / з виробництва.'
                        ]
                    },
                    {
                        title: 'Перерахування приходу',
                        lines: [
                            'Додана можливість перерахувати прихідну накладну з можливістю переглянути розбіжностей.'
                        ]
                    },
                    {
                        title: 'Інвентаризація',
                        lines: [
                            'Додана можливість швидкого включення / відключення камери для сканування.'
                        ]
                    },
                    {
                        title: 'Заказ-наряди',
                        lines: [
                            'Додана можливість пошуку скануючи штрих-код заказ-наряду.'
                        ]
                    },
                    {
                        title: 'Чек-лист механика',
                        lines: [
                            'Додана можливість пошуку скануючи штрих-код чек-листа.'
                        ]
                    },
                    {
                        title: 'Авторизація',
                        lines: [
                            'По закінченню сесії користувач в автоматичному режимі переадресовується на сторінку авторизації.'
                        ]
                    },
                ]
            },
            {
                title: 'Жовтень',
                version: '2020.10',
                essences: [
                    {
                        title: 'Чек-лист механіка',
                        lines: [
                            'На сторінку додана інформація про виробника та держ. номер для полегшення ідентифікації авто. Додана можливість зміни пріоритету ЗН. Додана можливість відмітити усю секцію як Добре та Не перевірялось.'
                        ]
                    },
                ]
            },
            {
                title: 'Вересень',
                version: '2020.09',
                essences: [
                    {
                        title: 'Інвентаризація',
                        lines: [
                            'Доданий інтерфейс інвентаризації.'
                        ]
                    },
                ]
            },
            {
                title: 'Серпень',
                version: '2020.08',
                essences: [
                    {
                        title: 'Фото матеріали',
                        lines: [
                            'Додана можливість збільшити зображення натиснувши на нього.'
                        ]
                    },
                    {
                        title: 'Перегляд запланованих робіт механіків',
                        lines: [
                            'Додана сторінка для менеджера для перегляду запланованих робіт механіків.'
                        ]
                    },
                    {
                        title: 'Перегляд стемпінгу механіків',
                        lines: [
                            'Додана сторінка для менеджера для перегляду стемпінгу механіків.'
                        ]
                    },
                    {
                        title: 'Детальна інформація про запчастину',
                        lines: [
                            'Додана можливість зміни локації товару.'
                        ]
                    },
                    {
                        title: 'Рух техніки на СТО',
                        lines: [
                            'Додана сторінка для перегляду руху авто на території СТО.'
                        ]
                    },
                ]
            },
            {
                title: 'Липень',
                version: '2020.07',
                essences: [
                    {
                        title: 'Перегляд запланованих робіт механіка',
                        lines: [
                            'Додана сторінка для перегляду запланованих робіт механіка.'
                        ]
                    },
                    {
                        title: 'Модуль роботи з камерою та модуль для роботи зі штрих-кодами',
                        lines: [
                            'На сторінці налаштувань додано блок налаштування камери для подальшої роботи зі штрих-кодами. Щоб уникнути небажаних помилок перейдіть у налаштування і виберіть робочу камеру за допомогою якої буде вібдуватися скануавання. Зверніть увагу, що цей модуль працює лише на смартфонах з найновішою версією Google Chrome 84.'
                        ]
                    },
                    {
                        title: 'Детальна інформація про запчастину',
                        lines: [
                            'Додана сторінка Детальна інформація про запчастину, де за кодом запчастини можна переглянути її основну інформацію: назву, локацію та залишок на складі, а також подивитися заміни.'
                        ]
                    },
                ]
            },
            {
                title: 'Червень',
                version: '2020.06',
                essences: [
                    {
                        title: 'Публічні сторінки чек-листа механіка, акт прийому авто, фіналізація ЗН',
                        lines: [
                            'Додано назву та телефон компанії, контакти відповідального за наряд менеджера'
                        ]
                    },
                    {
                        title: 'Публічна сторінка фіналізації ЗН',
                        lines: [
                            'Додана функція зворотнього зв\'язку зі своєчасним оповіщенням менеджера'
                        ]
                    },
                ]
            },
            {
                title: 'Травень',
                version: '2020.05',
                essences: [
                    {
                        title: 'Чек-лист механіка',
                        lines: [
                            'Редизайн публічної сторінки (аналог фіналізації ЗН), перейти на яку можна із МИФ, а також посилання на яку можна відправити клієнту'
                        ]
                    },
                    {
                        title: 'Акт прийому авто (контрольний лист менеджера)',
                        lines: [
                            'Додана публічна сторінка (аналог фіналізації ЗН), перейти на яку можна із МИФ, а також посилання на яку можна відправити клієнту'
                        ]
                    },
                ]
            },
            {
                title: 'Лютий',
                version: '2020.02',
                essences: [
                    {
                        title: 'Заказ-наряд',
                        lines: [
                            'Додано можливість підписати ЗН'
                        ]
                    },
                    {
                        title: 'Отриманi запчастини',
                        lines: [
                            'Додано можливість детально переглянути усі запчастини і прилади які видані механікам під звіт'
                        ]
                    },
                    {
                        title: 'Акти видачі/повернення',
                        lines: [
                            'Додано можливість переглянути акти видачі/повернення з можливістю їх авторизації механіком'
                        ]
                    },
                ]
            },
            {
                title: 'Січень',
                version: '2020.01',
                essences: [
                    {
                        title: 'Чек-лист механіка',
                        lines: [
                            'Додано обробку варіанта «не перевірялось»',
                            'Додано можливість прикріпляти відео',
                            'На сторінці «Збереження» виводиться підсумковий перелік виявлених недоліків (з фото та відео)'
                        ]
                    },
                    {
                        title: 'Контрольний лист',
                        lines: [
                            'Додано обробку варіантів «відсутній», «не перевірялось»',
                            'Додано можливість прикріпляти відео',
                            'На сторінці «Збереження» виводиться підсумковий перелік виявлених недоліків (з фото та відео)'
                        ]
                    },
                    {
                        title: 'Рекомендації',
                        lines: [
                            'Додано можливість прикріпляти відео'
                        ]
                    },
                    {
                        title: 'Огляд ремзони',
                        lines: [
                            'Додано можливість відбору документів по датам',
                            'Додано можливість прикріпляти відео',
                            'На сторінці «Збереження» виводиться підсумковий перелік виявлених недоліків (з фото та відео)'
                        ]
                    },
                    {
                        title: 'Заказ-наряд',
                        lines: [
                            'По вибраному ЗН вивід блоків робіт з відображенням актуального затраченого механіками часу та статусом виконання',
                            'Відображення змісту блока роботи (запчастини, роботи, послуги)',
                            'Можливість прикріпляти фото та відео до блоку робіт',
                            'Фіксація роботи механіка по блоку робіт (початок, призупинення, завершення)',
                            'Внесення рекомендацій по ЗН',
                            'Перегляд інформації про клієнта та техніку, можливість внесення змін (контактна особа, телефон, пробіг)',
                            'Фіналізація виконаного ЗН з повною інформацією по ЗН',
                            'Публічна сторінка ЗН (аналог фіналізації), перейти на яку можна із МИФ, а також посилання на яку можна відправити клієнту'
                        ]
                    },
                    {
                        title: 'Стемпінг',
                        lines: [
                            'Вивід на головному екрані інформації про поточний стан роботи механіка',
                            'Перегляд історії роботи механіка',
                            'Призупинення роботи з різних причин'
                        ]
                    },
                ]
            }
        ]
    },
    ru: {
        title: 'Главная',
        updates: []
    },
    en: {
        title: 'Updates',
        updates: []
    }
}

class Home extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            
        }
    }

    componentDidMount() {
        const {lang, setMenuExpandText, setMenuExpand} = this.context;
        setMenuExpandText(localization[lang].title);
        setMenuExpand(true);
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                {localization[lang].updates.map((update, u) => {
                    return (
                        <div className="container-fluid mb-4" key={"update_" + u}>
                            <div className="row">
                                <div className="col-md-10 offset-md-1">
                                    <div className="container-fluid pl-0 pr-0">
                                        <div className="row mb-2">
                                            <div className="col">
                                                <h3>{update.title}</h3> <small>(v{update.version})</small>
                                            </div>
                                        </div>

                                        {update.essences.map((essence, e) => {
                                            return (
                                                <div className="mb-2" key={"essence_" + e}>
                                                    <div className="row">
                                                        <div className="col">
                                                            <h6 className="mb-1"><b>{essence.title}</b></h6>

                                                            {essence.lines.map((line, l) => {
                                                                return (
                                                                    <div className="mb-1" style={{lineHeight: "1.1rem", fontSize: "12px"}}>- {line}</div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}

            </div>
        )
    }
}

export default Home;