import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faSearch, faPlus, faFilter } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../AppContext.js';
import OffsetTop from '../../components/offsetTop.js';

const localization = {
    ua: {
        title: 'Отриманi запчастини',
        header: {
            sum: 'Загальна сума'
        },
        list: {
            ro: 'ЗН',
            act: 'Акт'
        },
        noData: 'Данi вiдсутнi',
    },
    ru: {
        title: 'Полученные запчасти',
        header: {
            sum: 'Общая сумма'
        },
        list: {
            ro: 'ЗН',
            act: 'Акт'
        },
        noData: 'Нет данных',
    },
    en: {
        title: 'Received parts',
        header: {
            sum: 'Total amount'
        },
        list: {
            ro: 'RO',
            act: 'Act'
        },
        noData: 'No data',
    },
}

class StashList extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,

            sum: 0,
            rows: []
        }
    }

    componentDidMount() {
        const {lang, setMenuExpandText} = this.context;
        setMenuExpandText(localization[lang].title);

        this.loadList();
    }

    loadList() {
        const {addNotification, removeNotification, token} = this.context;
        
        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");
            axios.post('/api/stash/list', { token: token }).then((res) => {
                var sum = res.data.rows.reduce((accumulator, currentValue) => accumulator + currentValue.sum, 0).toFixed(2);

                this.setState({rows: res.data.rows, sum: sum, isLoading: false});
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            this.setState({
                isLoading: false,
                rows: [
                    {
                        code: "KI.0624000004",
                        name: "Набор спиральных сверл 1-13",
                        total: "1",
                        unit: "компл",
                        sum: 4464.63,

                        did: 221,
                        doc: "SK1822388",

                        oid: 221,
                        order: "1906181",

                        manufacturer: "Sсania",
                        type: "G440A4X2NA",
                        chassis: "5523965",
                        stateNumber: "AA4806XA"
                    },
                    {
                        code: "KI.W2824",
                        name: "Трубний ключ 24\"",
                        total: "1",
                        unit: "шт",
                        sum: 1315.37,

                        did: 222,
                        doc: "SK1822388",

                        oid: null,
                        order: "",

                        manufacturer: "",
                        type: "",
                        chassis: "",
                        stateNumber: ""
                    },
                    {
                        code: "KI.S21H41250",
                        name: "Подовжувач шаровидний 1/2 250мм.",
                        total: "1",
                        unit: "шт",
                        sum: 126.22,

                        did: 223,
                        doc: "SK1822388",

                        oid: null,
                        order: "",

                        manufacturer: "",
                        type: "",
                        chassis: "",
                        stateNumber: ""
                    },
                ]
            });
        }
    }

    renderList() {
        const {lang, access} = this.context;

        if (this.state.rows.length === 0) {
            return (
                <div className="row">
                    <div className="col">
                        <div className="card agis-card">
                            <div className="row">
                                <div className="col" style={{display: "flex", alignItems: "center", height: "30px"}}>
                                    <div>
                                        <h6><b>{localization[lang].noData}</b></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return this.state.rows.map((item, i) => {
                return (
                    <div className="row mb-2" key={"list_" + i}>
                        <div className="col">
                            <div className="card agis-card pt-2 pb-2" style={{borderTopLeftRadius: "15px", borderTopRightRadius: "15px", borderBottomLeftRadius: "15px", borderBottomRightRadius: "15px"}}>
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <h6><b>{item.name}</b></h6>
                                    </div>
                                    <div className="col-5">
                                        <span>{item.code}</span>
                                    </div>
                                    <div className="col-3 text-center">
                                        <span>{item.total} {item.unit}</span>
                                    </div>
                                    <div className="col-4 text-right">
                                        <span>{item.sum} грн</span>
                                    </div>
                                </div>
                                {item.oid != null && (
                                    <div className="row">
                                        <div className="col-12">
                                            <span><b>{item.manufacturer} {item.type}</b></span>
                                        </div>
                                        <div className="col-12">
                                            <span>{item.chassis} / {item.stateNumber} </span>
                                        </div>
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col-6">
                                        {(item.oid != null) && (
                                            access.includes('6') ? (
                                                <Link to={"/order/"+item.oid+"/card"} className="">{localization[lang].list.ro} {item.order}</Link>
                                            ):(
                                                <span>ЗН {item.order}</span>
                                            )
                                        )}
                                    </div>
                                    <div className="col-6 text-right">
                                        <Link to={"/stash/doc/"+item.did} className="">{localization[lang].list.act} {item.doc}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
        }
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div>
                <DocumentMeta {...meta} />
                <OffsetTop submenu={false} />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="container-fluid pl-0 pr-0">

                            <div className="row mb-3">
                                    <div className="col">
                                        <div className="card agis-card pt-2 pb-2" style={{borderTopLeftRadius: "15px", borderTopRightRadius: "15px", borderBottomLeftRadius: "15px", borderBottomRightRadius: "15px"}}>
                                            <div className="row">
                                                <div className="col">
                                                    <span style={{lineHeight: "24px"}}>{localization[lang].header.sum}</span>
                                                </div>
                                                <div className="col text-right">
                                                    <h5><b>{this.state.sum} грн</b></h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {this.renderList()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default StashList;